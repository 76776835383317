import { getDataFromServer } from "../util";

// Saques
export const getIncomePayments = () => getDataFromServer("GET", "/payment/income", null, true);
export const getRedemptionPayment = contractId =>
  getDataFromServer("GET", `/payment/redemption?contractId=${contractId}`, null, true);
export const getAllRedemptionPayment = payed =>
  getDataFromServer("GET", `/payment/redemptions?payed=${payed}`, null, true);
export const getInfoPayments = userId => getDataFromServer("GET", `/payment/infoPayment?userId=${userId}`, null, true);
//export const getAllRedemptionPayment = payed =>
//getDataFromServer("GET", `/payment/redemptions?payed=${payed}`, null, true);
//export const getInfoPayments = userId => getDataFromServer("GET", `/payment/infoPayment?userId=${userId}`, null, true);
//export const getRedemptionPayment = contractId =>
//getDataFromServer("GET", `/payment/redemption?contractId=${contractId}`, null, true);
export const updatePayment = (id, formProps) => getDataFromServer("PUT", `payment/${id}`, formProps, true);

export const getTotalValue = (startDate, finalDate) =>
  getDataFromServer("GET", "/payment/total?startDate=" + startDate + "&finalDate=" + finalDate, null, true);
