import {
  Button,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Email from "@material-ui/icons/Email";
import React, { Component, Fragment } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAccount, requestWithdrawal, showSuccessMessage } from "../actions";
import CustomInputForm from "../custom/CustomInput";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { validateAddress as asyncValidate } from "../util";
import platformConfig from "../platformConfig";
import {
  validateCriptoAddress,
  partnerWithdrawTypeByNetwork,
  networkByPartnerWithdrawType,
  networkNameByPartnerWithdrawType,
} from "../util";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class FormWithdraw extends Component {
  state = {
    step: 0,
    walletAddress: false,
    typeAddress: false,
    account: {},
    amount: 0,
    LCTBTCSell: 0,
    LCTBTCBuy: 0,
    LCTFiat: 0,
    address: null,
    addressTemp: null,
    checked: false,
    disabledOk: false,
    partnerWithdraw: undefined,
    partnerWithdrawType: undefined,
    partnerWithdrawTypeTemp: 0,
    validpartnerWithdraw: false,
    loading: false,
  };

  componentDidMount() {
    const { getConfig } = this.props;
    getConfig();
    this.updateAccount();
  }

  updateAccount = () => {
    getAccount().then((data) => {
      const account = data;
      this.setState({ account });
    });
  };

  accept = () => {
    const { address, partnerWithdraw, partnerWithdrawType, amount } = this.state;
    const { getMe, showSuccessMessage, showErrorMessage, history } = this.props;
    this.setState({ disabledOk: true });
    var date = new Date();
    requestWithdrawal({
      address,
      partnerWithdraw,
      partnerWithdrawType,
      amount,
      saveAddress: true,
      authorizeDate: date,
    })
      .then((response) => {
        getMe();
        showSuccessMessage("app.email.confirmation.sent");
        history.push("/history");
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  onSubmit = (formProps) => {
    //this.props.change();
    this.setState({ step: 1 });
  };

  validateAmount = (value) => {
    if (value > this.state.account.balance) {
      return <FormattedMessage id="app.balance.unavailable" />;
    }
    if (value < this.props.config.minCustomerWithdraw) {
      return (
        <>
          <FormattedMessage id="app.form.amount.error.min" />
          {this.props.config.minCustomerWithdraw}
          {this.props.config.LCTCode}
        </>
      );
    }
  };

  renderSecondStep = () => {
    const { address, amount, disabledOk } = this.state;
    return (
      <FormDialog
        title="app.withdraw.email.confirm"
        open={true}
        variant="secondary"
        noHeader
        onClose={(e) => this.setState({ step: 0 })}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <IconButton>
            <Email style={{ fontSize: 50 }} />
          </IconButton>
        </Grid>

        <Typography style={{ marginBottom: 16 }}>
          <FormattedMessage id="app.withdraw.email.confirm.text1" />
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          disabled={disabledOk || this.props.pristine || this.props.submitting || this.props.invalid}
          onClick={(e) => this.accept()}
        >
          Ok
        </Button>
      </FormDialog>
    );
  };

  typeAddress = () => {
    this.setState({ typeAddress: true });
  };

  confirmAddress = () => {
    const { addressTemp, partnerWithdrawTypeTemp } = this.state;
    const { showSuccessMessage, showErrorMessage } = this.props;
    this.setState({ loading: true });
    const withdrawTokenNetwork = networkByPartnerWithdrawType[partnerWithdrawTypeTemp].split("/");
    const token = withdrawTokenNetwork[0];
    const network = withdrawTokenNetwork[1];
    validateCriptoAddress(addressTemp)
      .then((data) => {
        const networks = data
          .filter((item) => item.type === "address" && item.network === network)
          .map((item) => item.network);
        if (networks.length === 0) {
          showErrorMessage("app.address.invalid");
          this.setState({ loading: false });
        } else {
          showSuccessMessage(<FormattedHTMLMessage id="app.address.valid" values={{ values: networks }} />);
          this.setState({
            walletAddress: false,
            address: addressTemp,
            partnerWithdrawType: partnerWithdrawTypeTemp,
            loading: false,
          });
        }
      })
      .catch((e) => {
        if (e.message == "TRANSACTION_ERROR") {
          showErrorMessage("app.address.invalid");
        } else {
          showErrorMessage(<FormattedHTMLMessage id="app.address.invalid.1" />);
          this.setState({ walletAddress: false, address: addressTemp, partnerWithdrawType: partnerWithdrawTypeTemp });
        }
        this.setState({ loading: false });
      });
  };

  render() {
    const { handleSubmit, user, config } = this.props;
    const {
      account,
      loading,
      step,
      partnerWithdraw,
      partnerWithdrawType,
      typeAddress,
      walletAddress,
      address,
      partnerWithdrawTypeTemp,
    } = this.state;
    if (!config.LCTInputMask) return null;
    const lctMask = config.LCTInputMask((amount) => this.setState({ amount }));
    if (step > 0) {
      return this.renderSecondStep();
    }
    return (
      <PageContainer>
        <HeaderPageItem title="app.withdraw" showBackButton destination={"/history"} />
        <CardPageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: 16,
                }}
              >
                <InfoItem
                  caption={<FormattedMessage id="app.camel.balance" />}
                  text={<Number value={account.balance} currency="LCT" />}
                />
              </div>
              <Field
                fullWidth
                component={CustomInputForm}
                name="amount"
                label="app.camel.amount"
                InputLabelProps={{ shrink: true }}
                {...lctMask}
                validate={this.validateAmount}
              />
              <br />
              <br />
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <FormattedMessage id="app.withdraw.destination" />
                </FormLabel>
                <RadioGroup
                  value={partnerWithdraw}
                  onChange={(e) => {
                    this.setState({ partnerWithdraw: e.target.value });
                    if (e.target.value == 0) {
                      this.setState({
                        address: user.withdrawAddress,
                        partnerWithdrawType: partnerWithdrawTypeByNetwork[user.withdrawAddressNetwork],
                        checked: true,
                      });
                    } else if (e.target.value == 1) {
                      this.setState({ address: config.partnerAddress, partnerWithdrawType: 0, checked: false });
                    } else if (e.target.value == 2) {
                      this.setState({ address: config.address, partnerWithdrawType: 0, checked: true });
                    }
                  }}
                >
                  {user.taxResidence == "Brasil" && (
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="app.withdraw.partner" />}
                    />

                    // <FormControlLabel value="1" control={<Radio color="primary" />} label="DigiPlata" />
                  )}

                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="app.wallet.your" />}
                  />

                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="app.reaport" />}
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              <br />
              {partnerWithdraw == 0 ? (
                <>
                  <Typography style={{ marginTop: 16 }} gutterBottom color="primary">
                    <FormattedMessage id="app.withdraw.text" />
                  </Typography>

                  <div color="textSecondary" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <Typography>{address ?? <FormattedMessage id="app.noAddress" />}</Typography>
                      <Typography variant="caption" gutterBottom>
                        {address ? networkNameByPartnerWithdrawType[partnerWithdrawType] : ""}
                      </Typography>
                    </div>
                    <IconButton
                      onClick={(e) => {
                        this.props.change("address", undefined);
                        this.setState({ typeAddress: true });
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>

                  <FormDialog
                    title="app.withdraw.type.confirm"
                    open={typeAddress}
                    variant="secondary"
                    noHeader
                    onClose={(e) => this.setState({ typeAddress: false })}
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h6" color="primary">
                        <FormattedMessage id="app.withdraw.text0" />
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        <FormattedHTMLMessage id="app.qrcode.text3" />
                      </Typography>
                      {user.taxResidence == "Brasil" && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 8, marginBottom: 8 }}
                          onClick={(e) => {
                            this.setState({
                              partnerWithdrawTypeTemp: 0,
                              typeAddress: false,
                              walletAddress: true,
                            });
                          }}
                        >
                          {networkNameByPartnerWithdrawType[0]}
                        </Button>
                      )}
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 2,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[2]}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 1,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[1]}
                      </Button>
                    </CardContent>
                  </FormDialog>
                  <FormDialog
                    title="app.withdraw.address.confirm"
                    open={walletAddress}
                    variant="secondary"
                    noHeader
                    onClose={(e) => this.setState({ walletAddress: false })}
                  >
                    <Typography gutterBottom variant="h6" color="primary">
                      <FormattedMessage id="app.wallet.inform" />
                    </Typography>
                    {partnerWithdrawTypeTemp !== 0 && (
                      <Typography gutterBottom variant="body1">
                        <FormattedHTMLMessage id="app.qrcode.text1.2" />
                        <br />
                        <b>{networkNameByPartnerWithdrawType[partnerWithdrawTypeTemp]}</b>
                      </Typography>
                    )}
                    <Field
                      fullWidth
                      component={CustomInputForm}
                      name="address"
                      label="app.wallet.address"
                      InputLabelProps={{ shrink: true }}
                      // disabled={this.state.checked}
                      onChange={(e) => {
                        this.state.addressTemp = e.target.value;
                      }}
                    />
                    {loading ? (
                      <Typography>
                        <FormattedMessage id="app.wallet.validating" />
                      </Typography>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16, marginBottom: 16 }}
                        onClick={(e) => this.confirmAddress()}
                      >
                        Ok
                      </Button>
                    )}
                  </FormDialog>
                </>
              ) : partnerWithdraw == 1 ? (
                <Fragment>
                  {user.taxResidence == "Brasil" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormLabel style={{ marginBottom: 16 }} component="legend">
                          <FormattedMessage id="app.withdraw.partner.type" />
                        </FormLabel>
                        <RadioGroup
                          value={partnerWithdrawType}
                          onChange={(e) => {
                            this.setState({ partnerWithdrawType: e.target.value, checked: true });
                          }}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" style={{ alignSelf: "flex-start" }} />}
                            label={
                              <div style={{ marginTop: 8 }}>
                                {" "}
                                <FormattedMessage id="app.automatic.selling.button" />
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" style={{ alignSelf: "flex-start" }} />}
                            label={
                              <div style={{ marginTop: 8 }}>
                                <FormattedMessage id="app.balance.btc.button" />
                              </div>
                            }
                            style={{ marginTop: 20 }}
                          />
                        </RadioGroup>
                      </FormControl>
                      <Typography style={{ marginTop: 16 }} gutterBottom>
                        <FormattedMessage id="app.withdraw.text2" />
                      </Typography>
                    </>
                  ) : (
                    "" // <>
                    //   <Typography style={{ marginTop: 16 }} gutterBottom>
                    //     Su dinero será enviado a DigiPlata. Recuerde que la cantidad enviada será en Bitcoin al precio
                    //     actual.
                    //   </Typography>
                    // </>
                  )}
                </Fragment>
              ) : (
                partnerWithdraw == 2 && (
                  <Fragment>
                    <Typography gutterBottom>
                      <FormattedHTMLMessage id="app.withdraw.text3" values={{ company: platformConfig.name }} />
                    </Typography>
                    <Typography gutterBottom color="error">
                      <FormattedMessage id="app.withdraw.text3.1" />
                    </Typography>
                    <Typography gutterBottom color="error">
                      <FormattedMessage id="app.withdraw.text3.2" />
                    </Typography>
                  </Fragment>
                )
              )}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 16, marginBottom: 16 }}
                disabled={!this.state.checked || this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.next" />
              </Button>
            </form>
          </CardContent>
        </CardPageItem>
        <CardPageItem />
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "app.form.amount.error";
  }
  if (!values.address) {
    errors.address = "app.form.address.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    user: state.user.user.me,
    config: state.config,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "withdrawForm",
    enableReinitialize: true,
    validate,
  })
)(FormWithdraw);
