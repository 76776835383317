import React, { Component } from "react";
import LCContractBR from "./LCContractBR";
import LCContractEN from "./LCContractEN";
import LCContractES from "./LCContractES";

class LCContract extends Component {
  render() {
    let { duration, amount, type, max, lang, addendum } = this.props;
    if (lang === "br")
      return <LCContractBR amount={amount} duration={duration} type={type} max={max} addendum={addendum} />;
    else if (lang === "en")
      return <LCContractEN amount={amount} duration={duration} type={type} max={max} addendum={addendum} />;
    else if (lang === "es")
      return <LCContractES amount={amount} duration={duration} type={type} max={max} addendum={addendum} />;
  }
}

export default LCContract;
