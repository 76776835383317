import { Button, CardContent } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { setInvestment } from "../actions/Investments";
import { FormattedMessage } from "react-intl";

class AdminInvestmentForm extends Component {
  state = {
    showConfigType: false
  };

  onSubmit = formProps => {
    const { history, showErrorMessage } = this.props;

    setInvestment(formProps)
      .then(response => {
        history.push("/investments");
      })
      .catch(e => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <PageContainer>
        <HeaderPageItem title="app.addInvestment" showBackButton destination="/investments" />
        <CardPageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="description"
                label="app.description"
                InputLabelProps={{
                  shrink: true
                }}
                component={CustomInputForm}
              />

              <Button
                fullWidth
                id="bSubmitTypeForm"
                variant="contained"
                color="primary"
                type="submit"
                disabled={this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.button.save" />
              </Button>
            </form>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.address) {
    errors.address = "app.walletAddress.invalid";
  }

  if (!values.minConsultantWithdraw) {
    errors.minConsultantWithdraw = "app.agent.inform.withdraw.min";
  } else if (values.minConsultantWithdraw <= 0) {
    errors.minConsultantWithdraw = "app.agent.withdraw.greater.zero";
  }

  if (!values.minCustomerWithdraw) {
    errors.minCustomerWithdraw = "app.customer.inform.withdraw.min";
  } else if (values.minCustomerWithdraw <= 0) {
    errors.minCustomerWithdraw = "app.customer.withdraw.greater.zero";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    initialValues: state.config
  };
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "investmentForm",
    enableReinitialize: true,
    validate
  })
)(AdminInvestmentForm);
