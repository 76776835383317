import React, { Component } from "react";
import {
	Button,
	CardContent,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	Divider,
	FormGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	InputBase,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import PageContainer, { CardPageItem } from "../custom/Page";
import SearchIcon from "@material-ui/icons/Search";
import performRequest from "../configAxios";
import { capitalCaseNormalizer, ListRoles } from "../util";
import { Link } from "react-router-dom";
import HeaderPageItem from "../custom/HeaderPageItem";
import AbtcAvatar from "../custom/Avatar";
import { FormattedMessage } from "react-intl";
import { getUsers } from "../actions";
class AdminUsers extends Component {
	state = {
		originalUsers: [],
		users: [],
		customers: [],
		usersKyc: [],
		loading: true,
		openDialogFilter: false,
		listRoles: ListRoles,
		listAllRoles: true,
	};

	componentDidMount() {
		this.loadUsers();
	}

	loadUsers= () => {		
		getUsers((this.props.currentUser.role=="ADMIN"||this.props.currentUser.role=="KYC")?{}
						:{taxresidence: this.props.currentUser.taxResidence}).then((response) => {
				let usersKyc = response.filter((c) => c.role !== "ADMIN" && c.role !== "KYC");
				this.setState({
					originalUsers: response,
					users: response,
					usersKyc: usersKyc,
					loading: false,
				});
			})		
			.catch();
	};

	searchUsers = (name) => {
		if (name.trim().length > 0) {
			performRequest("GET", `user?name=${name}${this.props.currentUser.role=="ADMIN"||this.props.currentUser.role=="KYC"||this.props.currentUser.role=="FINANCE_MANAGER"?"":`&taxresidence=${this.props.currentUser.taxResidence}`}`, null, true)
				.then((response) => {
					this.setState({
						originalUsers: response.data.data,
						users: response.data.data,
						usersKyc: response.data.data,
					});
				})
				.catch();
		} else {
			this.loadUsers();
		}
	};
	onChangeRole = (event) => {
		let all = true;
		this.setState({
			listRoles: this.state.listRoles.map((role) => {
				if (role.name == event.target.name) {
					role.value = event.target.checked;
				}
				if (!role.value) {
					all = false;
				}
				return role;
			}),
			listAllRoles: all,
		});
	};
	onChangeAllRoles = (event) => {
		this.setState({
			listRoles: this.state.listRoles.map((role) => {
				role.value = event.target.checked;
				return role;
			}),
			listAllRoles: event.target.checked,
		});
	};

	onChangeFilter = () => {
		let newList = [];
		this.state.originalUsers.forEach((user) => {
			this.state.listRoles.forEach((role) => {
				if (role.name == user.role && role.value == true) {
					newList.push(user);
				}
			});
		});
		this.setState({ users: newList });
	};

	render() {
		const { users, loading, usersKyc } = this.state;
		const { currentUser } = this.props;

		if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
		return (
			<>
				<PageContainer>
					{currentUser.role !== "ADMIN" ? (
						<HeaderPageItem title="app.users" />
					) : (
						<HeaderPageItem title="app.users" onMore={(e) => this.setState({ menuAnchor: e.target })}>
							<Menu
								onClose={(e) => this.setState({ menuAnchor: undefined })}
								open={!!this.state.menuAnchor}
								anchorEl={this.state.menuAnchor}
							>
								<MenuItem
									component={Link}
									to={"/newUser"}
									onClick={(e) => this.setState({ menuAnchor: undefined })}
								>
									<FormattedMessage id="app.addUser" />
								</MenuItem>
								

								<MenuItem
									onClick={(e) => this.setState({ openDialogFilter: true, menuAnchor: undefined })}
								>
									<FormattedMessage id="app.filter" />
								</MenuItem>
							</Menu>
						</HeaderPageItem>
					)}

					<CardPageItem>
						<CardContent>
							<IconButton aria-label="Search">
								<SearchIcon />
							</IconButton>
							<FormattedMessage id="app.searchUsers">
								{(placeholder) => (
									<InputBase
										placeholder={placeholder}
										onChange={(e) => this.searchUsers(e.target.value)}
									/>
								)}
							</FormattedMessage>
							<List>
								{currentUser.role !== "ADMIN" ? (
									usersKyc.map((c) => {
										return (
											<ListItem
												button
												alignItems="flex-start"
												key={c.id}
												component={Link}
												to={"/user/" + c.id}
											>
												<ListItemAvatar>
													<AbtcAvatar imageUrl={c.photoUrl} disableLink />
												</ListItemAvatar>
												<ListItemText
													key={c.id}
													primaryTypographyProps={{ component: "div" }}
													secondaryTypographyProps={{ component: "div" }}
													primary={
														<div
															color="textSecondary"
															style={{
																display: "flex",
																justifyContent: "space-between",
															}}
														>
															<span>{capitalCaseNormalizer(c.name)}</span>
															<span> {"Id: " + c.id}</span>
														</div>
													}
													secondary={
														<div>
															<div
																key={c.id}
																color="textSecondary"
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<span>{c.email}</span>
															</div>
															<span>
																{" "}
																(
																<FormattedMessage id={"app.enuns." + c.role} />)
															</span>
															<span style={{ color: "red" }}>
																{" "}
																{!c.apt ? <FormattedMessage id={`app.inapt`} /> : ""}
															</span>
														</div>
													}
												/>
											</ListItem>
										);
									})
								) : users.length > 0 ? (
									///////

									users.map((c) => {
										return (
											<ListItem
												button
												alignItems="flex-start"
												key={c.id}
												component={Link}
												to={"/user/" + c.id}
											>
												<ListItemAvatar>
													<AbtcAvatar imageUrl={c.photoUrl} disableLink />
												</ListItemAvatar>
												<ListItemText
													primaryTypographyProps={{ component: "div" }}
													secondaryTypographyProps={{ component: "div" }}
													primary={
														<div
															color="textSecondary"
															style={{
																display: "flex",
																justifyContent: "space-between",
															}}
														>
															<span>{capitalCaseNormalizer(c.name)}</span>
															<span> {"Id: " + c.id}</span>
														</div>
													}
													secondary={
														<div>
															<div
																color="textSecondary"
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<span>{c.email}</span>
															</div>
															<span>
																(
																<FormattedMessage id={"app.enuns." + c.role} />)
															</span>
															<span style={{ color: "red" }}>
																{" "}
																{!c.apt ? <FormattedMessage id={`app.inapt`} /> : ""}
															</span>
														</div>
													}
												/>
											</ListItem>
										);
									})
								) : (
									/////
									<Typography gutterBottom color="textSecondary" variant="caption">
										<FormattedMessage id="app.users.not.filter" />
									</Typography>
								)}
							</List>
						</CardContent>
					</CardPageItem>
				</PageContainer>
				<Dialog open={this.state.openDialogFilter} fullWidth>
					<DialogContent>
						<FormControl component="fieldset">
							<FormLabel component="legend">
								<FormattedMessage id="app.profile.filter" />
							</FormLabel>
							<FormGroup></FormGroup>
							<FormControlLabel
								key={"all"}
								control={
									<Checkbox
										checked={this.state.listAllRoles}
										onChange={this.onChangeAllRoles}
										color="primary"
									/>
								}
								label={<FormattedMessage id="app.all" />}
							/>
							<Divider />
							<FormGroup>
								{this.state.listRoles.map((role) => (
									<FormControlLabel
										key={role.name}
										control={
											<Checkbox
												checked={role.value}
												onChange={this.onChangeRole}
												name={role.name}
												color="primary"
											/>
										}
										label={<FormattedMessage id={role.label} />}
									/>
								))}
							</FormGroup>
						</FormControl>
						<Grid container direction="row" justify="flex-end" alignItems="center">
							<Button
								onClick={() => {
									this.onChangeFilter();
									this.setState({ openDialogFilter: false });
								}}
								color="primary"
							>
								<FormattedMessage id="app.confirm" />
							</Button>
							<Button onClick={() => this.setState({ openDialogFilter: false })} color="primary">
								<FormattedMessage id="app.cancel" />
							</Button>
						</Grid>
					</DialogContent>
				</Dialog>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.user.me,
	};
}

export default compose(connect(mapStateToProps, actions))(AdminUsers);
