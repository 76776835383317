import React, { Component, Fragment } from "react";
import { CardContent, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../actions";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { withRouter } from "react-router-dom";
import { getInvestmentHistory } from "../actions/Investments";
import InfoItem from "../custom/InfoItem";
import { FormattedMessage } from "react-intl";

class AdminInvestmentHistory extends Component {
  state = { loading: false, history: [] };

  loadInvestmentData = id => {
    this.setState({ loading: true });
    getInvestmentHistory(id)
      .then(data => {
        this.setState({
          history: data,
          loading: false
        });
      })
      .catch(e => {
        this.setState({
          loading: false
        });
      });
  };

  componentDidMount() {
    this.loadInvestmentData(this.props.match.params.id);
  }

  render() {
    const { loading, history } = this.state;
    if (loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem
            title={
              <FormattedMessage
                id="app.investment.title"
                values={this.props.match.params.id}
              />
            }
            showBackButton
            destination={"/investments/" + this.props.match.params.id}
          />

          <CardPageItem>
            <CardContent>
              {history.map((m, index) => (
                <Fragment key={index}>
                  <InfoItem
                    caption={m.createdAt}
                    text={m.currentYield}
                    extraCaption={m.projectedYield}
                  />
                </Fragment>
              ))}
              )}
            </CardContent>
          </CardPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default withRouter(
  connect(mapStateToProps, actions)(AdminInvestmentHistory)
);
