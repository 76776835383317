import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import Moment from "moment";
import React, { Component } from "react";
import { CustomView, isIOS } from "react-device-detect";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as actions from "../actions";
import performRequest from "../configAxios";
import customInput, { CustomDateOfBirthPicker } from "../custom/CustomInput";
import customSelect from "../custom/CustomSelect";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import { asyncValidateRegister as asyncValidate, cnpjNormalizer, cpfNormalizer, numberNormalizer } from "../util";
import { country } from "./components/Terms";
import cookie from "react-cookies";
import platformConfig from "../platformConfig";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

const messages = {
  br: messages_br,
  en: messages_en,
  es: messages_es,
};

class Register extends Component {
  state = {
    terms: false,
    policy: false,
    termsButton: false,
    typePf: true,
    lang: "?lang=en", // default em ingles caso o usuario não tenha preenchido o form ainda
    locale: "en",
    changingLang: false,
  };

  typeRegister = [
    { value: "NATURAL_PERSON", input: "app.naturalPerson" },
    { value: "COMPANY", input: "app.person.legal" },
  ];

  languages = [
    { value: "en", input: "app.language.en" },
    { value: "br", input: "app.language.br" },
    { value: "es", input: "app.language.es" },
  ];

  documentTypes = {
    SELECT: { value: "", input: "app.form.selectTaxResidence" },
    CPF: { value: "CPF", input: "app.cpf" },
    CNPJ: { value: "CNPJ", input: "app.cnpj" },
    NIT: { value: "NIT", input: "app.nit" },
    CC: { value: "NIT", input: "app.cc" },
  };

  documentTypesByTaxResidence = {
    Argentina: {
      pf: [this.documentTypes.NIT],
      pj: [this.documentTypes.NIT],
    },
    Austrália: {
      pf: [this.documentTypes.NIT],
      pj: [this.documentTypes.NIT],
    },
    Brasil: {
      pf: [this.documentTypes.CPF],
      pj: [this.documentTypes.CNPJ],
    },
    Colômbia: {
      pf: [this.documentTypes.CC],
      pj: [this.documentTypes.NIT],
    },
    Uruguai: {
      pf: [this.documentTypes.NIT],
      pj: [this.documentTypes.NIT],
    },
  };

  normalizersByDocumentType = {
    CPF: cpfNormalizer,
    CNPJ: cnpjNormalizer,
    NIT: numberNormalizer,
  };

  onSubmit = (formProps) => {
    this.setState({ formProps, terms: true });
  };

  accept = () => {
    const { formProps, recaptcha } = this.state;
    const { showSuccessMessage, showErrorMessage, history, theme } = this.props;
    const params = { ...formProps, recaptcha };
    if (platformConfig.id === "digiplata") {
      params.agentId = 1;
    }
    performRequest("POST", "/user/register", params)
      .then((response) => {
        showSuccessMessage("app.register.success");
        history.push("/");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  validateTerms = (value) => (value ? undefined : "app.termsAndConditions");

  onChangeType = (type) => {
    this.props.reset();
    this.props.change("locale", this.state.locale);
    this.setState({ typePf: type == "NATURAL_PERSON" ? true : false, type });
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken });
  };

  onChangeLengageTerm(locale) {
    // en, br, es
    this.setState({ changingLang: true });
    if (locale == "br") {
      this.setState({ lang: "?lang=pt", locale });
    } else if (locale == "es") {
      this.setState({ lang: "?lang=es", locale });
    } else {
      this.setState({ lang: "?lang=en", locale });
    }
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookie.save("lclocale", locale, { path: "/", expires });
    setTimeout(() => {
      this.props.initialize({ locale });
      this.setState({ changingLang: false });
    }, 1);
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      taxResidence,
      documentType,
      theme: { palette },
    } = this.props;
    const { typePf, locale, changingLang } = this.state;
    const personType = typePf ? "pf" : "pj";
    const documents =
      taxResidence == null ? [this.documentTypes.SELECT] : this.documentTypesByTaxResidence[taxResidence][personType];
    const documentNormalizer = this.normalizersByDocumentType[documentType];
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            padding: 16,
          }}
        >
          <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
            {platformConfig.id === "digiplata" ? (
              <img src={platformConfig.asset(platformConfig.logo)} alt="logo" width="300" height="120" />
            ) : (
              <img src={platformConfig.asset(platformConfig.logo)} alt="logo" width="200" height="200" />
            )}
          </div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="locale"
              label="app.language"
              component={customSelect}
              values={this.languages}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => this.onChangeLengageTerm(e.target.value)}
            />
            <Field
              name="type"
              label="app.personType"
              component={customSelect}
              values={this.typeRegister}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => this.onChangeType(e.target.value)}
            />
            <Field name="name" label="app.name" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field
              name="taxResidence"
              label="app.taxResidence"
              component={customSelect}
              values={country}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              name="documentType"
              label="app.documentType"
              component={customSelect}
              values={documents}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              name="documentId"
              label="app.documentIdTax"
              component={customInput}
              InputLabelProps={{ shrink: true }}
              normalize={documentNormalizer}
            />
            {typePf && (
              <>
                <CustomView condition={isIOS}>
                  <Field
                    name="dateOfBirth"
                    label="app.dateOfBirth"
                    component={customInput}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                  />
                </CustomView>
                <CustomView condition={!isIOS}>
                  <Field
                    name="dateOfBirth"
                    label="app.dateOfBirth"
                    component={CustomDateOfBirthPicker}
                    InputLabelProps={{ shrink: true }}
                  />
                </CustomView>
              </>
            )}
            <Field name="email" label="app.email" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field
              name="confirmEmail"
              label="app.confirmEmail"
              component={customInput}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              name="password"
              label="app.password"
              type="password"
              component={customInput}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              name="confirmPassword"
              label="app.confirmPassword"
              type="password"
              component={customInput}
              InputLabelProps={{ shrink: true }}
            />
            {platformConfig.id === "luxcapital" && (
              <>
                <Field
                  name="agentId"
                  label="app.agentId"
                  component={customInput}
                  InputLabelProps={{ shrink: true }}
                  normalize={numberNormalizer}
                />
                <Typography gutterBottom variant="caption" color="primary">
                  <FormattedMessage id="app.register.text" />{" "}
                  <a
                    style={{ color: palette.primary.main }}
                    href={platformConfig.links.site + this.state.lang + "#contact"}
                  >
                    <FormattedMessage id="app.clickHere" />
                  </a>
                </Typography>
              </>
            )}

            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              style={{ marginTop: 16 }}
              disabled={pristine || invalid || submitting || changingLang}
            >
              <FormattedMessage id="app.signup" />
            </Button>
          </form>
          <Dialog open={this.state.terms} variant="secondary" onClose={(e) => this.setState({ terms: false })}>
            <DialogTitle style={{ backgroundColor: platformConfig.theme.primary }}>
              <div style={{ textAlign: "center" }}>
                {platformConfig.id === "luxcapital" ? (
                  <FormattedMessage id="app.confirmTerms" />
                ) : (
                  <FormattedMessage id="app.confirm" />
                )}
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "justify",
                }}
              >
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  style={{ marginTop: 16 }}
                  component={Button}
                  href={platformConfig.links.termos + this.state.lang}
                  target="_blank"
                >
                  <div style={{ color: palette.primary.main }}>
                    {platformConfig.id === "luxcapital" ? (
                      <FormattedMessage id="app.termsAndConditions.link" />
                    ) : (
                      <FormattedMessage id="app.confirm.register" />
                    )}
                  </div>
                </Button>
                <FormControlLabel
                  label={
                    <FormattedMessage id={platformConfig.id === "luxcapital" ? "app.withdrawTerms" : "app.confirm"} />
                  }
                  control={
                    <Field
                      component={FormCheckbox}
                      name="termsAndConditions"
                      color="primary"
                      validate={this.validateTerms}
                    />
                  }
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16, marginBottom: 16 }}
                  disabled={pristine || invalid || submitting || changingLang}
                  onClick={(e) => this.accept()}
                >
                  <FormattedMessage id="app.signup" />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <div style={{ marginTop: 32 }}>
            {platformConfig.id === "luxcapital" && (
              <>
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  style={{ marginTop: 16 }}
                  component={Button}
                  href={platformConfig.links.termos + this.state.lang}
                  target="_blank"
                >
                  <FormattedMessage id="app.termsAndConditions" />
                </Button>
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  style={{ marginTop: 16 }}
                  component={Button}
                  href={platformConfig.links.politica + this.state.lang}
                  target="_blank"
                >
                  <FormattedMessage id="app.privacyPolicy" />
                </Button>
              </>
            )}

            {/* <FormDialog
            title="app.termsAndConditions"
            open={this.state.termsButton}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ termsButton: false })}
          >
            <Typography component="div">
							<Terms />
						</Typography>
          </FormDialog>
          <FormDialog
            title="app.privacyPolicy"
            open={this.state.policy}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ policy: false })}
          >
            <Typography component="div">
              <Policy />
            </Typography>
          </FormDialog> */}
          </div>
        </div>
      </IntlProvider>
    );
  }
}

const styles = (theme) => ({
  cardHeader: {
    textAlign: "center",
  },
  card: {
    marginTop: "100px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

const validate = (values, props) => {
  const errors = {};
  if (!props.anyTouched) return errors;
  if (!values.name) {
    errors.name = "app.form.name.error";
  } else if (values.name.trim().length == 0) {
    errors.name = "app.name.invalid";
  }
  if (!values.locale) {
    errors.locale = "app.form.locale.error";
  }
  if (!values.taxResidence) {
    errors.taxResidence = "app.form.taxResidence.error";
  }
  if (!values.documentType) {
    errors.documentType = "app.form.documentType.error";
  } else if (values.documentType.trim().length == 0) {
    errors.documentType = "app.form.documentType.error";
  }
  if (!values.documentId) {
    errors.documentId = "app.form.documentId.error";
  } else if (values.documentId.trim().length == 0) {
    errors.documentId = "app.documentId.invalid";
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = "app.form.dateOfBirth.error";
  } else {
    const years = Moment().diff(Moment(values.dateOfBirth).format("YYYY,MM,DD"), "years");
    if (years < 18) {
      errors.dateOfBirth = "app.form.dateOfBirth.min";
    }
  }
  if (!values.email) {
    errors.email = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
    errors.email = "app.form.email.invalid";
  } else {
    if (!values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error";
    } else if (values.email !== values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error.equals";
    }
  }
  if (!values.password) {
    errors.password = "app.password.error";
  } else if (values.password.length < 6) {
    errors.password = "app.password.min";
  } else {
    if (!values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error.equals";
    }
  }
  if (!values.agentId) {
    errors.agentId = "app.form.agentId.error";
  }
  return errors;
};

const selector = formValueSelector("registerForm");

function mapStateToProps(state) {
  return {
    initialValues: {
      locale: "en",
    },
    auth: state.auth.authenticated,
    currentUser: state.user.user.me,
    taxResidence: selector(state, "taxResidence"),
    documentType: selector(state, "documentType"),
  };
}

export default withTheme(
  compose(
    withStyles(styles),
    connect(mapStateToProps, actions),
    reduxForm({
      form: "registerForm",
      enableReinitialize: false,
      keepDirtyOnReinitialize: false,
      asyncValidate,
      asyncBlurFields: ["email", "documentId", "taxResidence", "agentId"],
      asyncChangeFields: ["agentId"],
      validate,
      touchOnChange: true,
    })
  )(Register)
);
