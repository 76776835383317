import { Button, CardContent, CircularProgress, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAllCustomerContracts } from "../actions/Contract";
import { getCustomer } from "../actions/User";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import UserDetail from "./components/UserDetail";
import ContractPageItem from "./ContractPageItem";

class AgentDataCustomer extends Component {
  state = {
    customer: {},
    contracts: {},
    loading: true,
    notContractAgent: 0,
  };

  componentDidMount() {
    const { currentUser } = this.props;

    getCustomer(this.props.match.params.id)
      .then((data) => {
        this.setState({ customer: data });
      })
      .catch((e) => this.props.history.push("/404"));
    getAllCustomerContracts(this.props.match.params.id).then((data) => {
      this.setState({ contracts: data, loading: false });
    });
    if (currentUser.contractAgent) {
      if (currentUser.contractAgent.status == "PENDING") {
        this.setState({ notContractAgent: 1 });
      } else if (currentUser.contractAgent.status == "ACTIVE") {
        this.setState({ notContractAgent: 2 });
      } else {
        this.setState({ notContractAgent: 0 });
      }
    }
  }

  render() {
    const { customer, contracts, loading } = this.state;

    if (customer.role == undefined) {
      this.state.loading = true;
    } else {
      this.state.loading = false;
    }
    if (this.state.loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.customerInfo" showBackButton destination="/customers" />
        </PageItem>
        <CardPageItem raised>
          <UserDetail currentUser={this.props.currentUser} user={customer.id}></UserDetail>
        </CardPageItem>
        <CardPageItem>
          <CardContent>
            <Button
              fullWidth
              disabled={customer.apt && this.state.notContractAgent == 2 ? false : true}
              variant="contained"
              color="primary"
              component={Link}
              to={"/newContract/" + customer.id}
              style={{ marginTop: 16 }}
            >
              <FormattedMessage id="app.newContract" />
            </Button>
            {this.state.notContractAgent == 1 && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                component={Link}
                to={"/contractAgent/" + this.props.currentUser.id}
                style={{ marginTop: 16 }}
              >
                <FormattedHTMLMessage id="app.contractAgent.approve" />
              </Button>
            )}
            {!customer.apt && (
              <Typography gutterBottom color="primary" variant="caption">
                <FormattedHTMLMessage id="app.customer.still.inapt" />
              </Typography>
            )}
            {this.state.notContractAgent == 0 && (
              <>
                <br />
                <Typography gutterBottom color="primary" variant="caption">
                  <FormattedHTMLMessage id="app.warning" />:
                </Typography>
                <Typography variant="caption">
                  {" "}
                  <FormattedHTMLMessage id="app.contractAgent.detail.text1" />
                  <br />
                  <FormattedHTMLMessage id="app.administrator.contact.please" />
                </Typography>
              </>
            )}
          </CardContent>
        </CardPageItem>
        {contracts && contracts.length > 0 ? (
          <>
            <CardContent>
              <Typography>
                <FormattedMessage id="app.navigationBar.contracts" />:
              </Typography>
            </CardContent>
            {contracts.map((c) => (
              <ContractPageItem key={c.id} contract={c} user={this.props.currentUser.id} />
            ))}
          </>
        ) : (
          <CardContent>
            <Typography>
              <FormattedMessage id="app.customer.contracts.empty" />
            </Typography>
          </CardContent>
        )}
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "dashboardForm",
    enableReinitialize: true,
  })
)(AgentDataCustomer);
