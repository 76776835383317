import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTheme, Button } from "@material-ui/core";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { connect } from "react-redux";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import CropFreeIcon from '@material-ui/icons/CropFree';
import { FormattedMessage } from "react-intl";

class ContractChart extends Component {
  state = {
    chartShowPer: false,
    chartShowBalance: true,
  };

  funcTeste = (tempList) => {
    tempList.map((l) => {
      if (l.estim) {
        var temp = l.estim.toFixed(2);
        l.estim = parseFloat(temp);
      }
      if (l.yield_per != null) {
        var temp = l.yield_per.toFixed(2);
        l.yield_per = parseFloat(temp);
      }
      if (l.balance) {
        var temp = l.balance.toFixed(2);
        l.balance = parseFloat(temp);
      }
    });
  };

  render() {
    const {
      chartData,
      theme: { palette },
      contract,
      fullScreen,
      currentMonth,
      marginBottom,
      height,
    } = this.props;

    if (!chartData) {
      return null;
    } else {
      this.funcTeste(chartData);
    }

    /*
      Componente de gráfico:
      https://recharts.org/
    */

    return (
      // Não pegou a tradução
      <div style={{ width: "100%", height: height, marginBottom: marginBottom }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData} margin={{ right: 32, left: 16 }}>
            <XAxis dataKey="day" />
            <YAxis
              yAxisId="per"
              width={50}
              unit=" %"
              orientation="left"
              domain={[0, 0.5]}
              hide={!this.state.chartShowPer}
              stroke={palette.text.secondary}
            />
            <YAxis
              yAxisId="val"
              width={50}
              orientation="right"
              domain={["dataMin", "dataMax"]}
              hide={!this.state.chartShowBalance}
              stroke={palette.primary.main}
            />

            <Line
              yAxisId="per"
              type="monotone"
              dataKey="yield_per"
              name="Add"
              stroke={palette.text.secondary}
              dot={false}
              hide={!this.state.chartShowPer}
            />
            <Line
              yAxisId="val"
              type="monotone"
              dataKey="estim"
              name="Balance"
              stroke={palette.text.secondary}
              strokeDasharray="5 5"
              dot={false}
              hide={!this.state.chartShowBalance}
            />
            <Line
              yAxisId="val"
              type="monotone"
              dataKey="balance"
              name="Saldo"
              stroke={palette.primary.main}
              dot={{ strokeWidth: 0, fill: palette.primary.main, r: 2 }}
              hide={!this.state.chartShowBalance}
            />
          </LineChart>
        </ResponsiveContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button
              size="small"
              onClick={(e) =>
                this.setState({
                  chartShowBalance: !this.state.chartShowBalance,
                })
              }
              style={{
                fontSize: "60%",
                color: this.state.chartShowBalance ? palette.primary.main : palette.text.secondary,
                borderTop: "1px solid " + (this.state.chartShowBalance ? palette.primary.main : "rgba(0,0,0,0)"),
                borderRadius: 0,
                marginRight: 2,
              }}
            >
              <FormattedMessage id="app.balance" />
            </Button>
            <Button
              size="small"
              onClick={(e) => this.setState({ chartShowPer: !this.state.chartShowPer })}
              style={{
                fontSize: "60%",
                color: this.state.chartShowPer ? palette.text.primary : palette.text.secondary,
                borderTop: "1px solid " + (this.state.chartShowPer ? palette.text.primary : "rgba(0,0,0,0)"),
                borderRadius: 0,
              }}
            >
              <FormattedMessage id="app.contract.increase" />
            </Button>
          </div>
          {fullScreen && (
            <Button
              size="small"
              component={Link}
              to={"/contractChart/" + contract.id + "/" + (currentMonth.month() + 1) + "/" + currentMonth.year()}
              style={{ marginTop: -8 }}
            >
              <CropFreeIcon htmlColor={palette.text.secondary} />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ContractChart.defaultProps = {
  height: 120,
  marginBottom: 20,
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, null)(ContractChart));
