import React, { Component } from "react";
import { CardContent, Typography, CardActionArea, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import { reduxForm } from "redux-form";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Link } from "react-router-dom";
import HeaderPageItem from "../custom/HeaderPageItem";
import { getInvestments } from "../actions/Investments";
import DescriptionIcon from "@material-ui/icons/Description";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

class AdminInvestment extends Component {
  state = {
    investments: [],
    loading: false
  };

  loadInvestments = () => {
    this.setState({ investments: [], loading: true });
    getInvestments()
      .then(data => {
        this.setState({ investments: data, loading: false });
      })
      .catch(e => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.loadInvestments();
  }

  render() {
    const { investments } = this.state;
    if (this.state.loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <HeaderPageItem title="app.investments" onMore={e => this.setState({ menuAnchor: e.target })}>
          <Menu
            onClose={e => this.setState({ menuAnchor: undefined })}
            open={!!this.state.menuAnchor}
            anchorEl={this.state.menuAnchor}
          >
            <MenuItem component={Link} to={"/newInvestment"} onClick={e => this.setState({ menuAnchor: undefined })}>
              <FormattedMessage id="app.addInvestment" />
            </MenuItem>
          </Menu>
        </HeaderPageItem>
        {this.state.loading && (
          <CardPageItem>
            <CircularProgress style={{ marginTop: 50 }} />
          </CardPageItem>
        )}
        {investments.length == 0 ? (
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage id="app.data.empty" />
          </Typography>
        ) : (
          <>
            {investments.map(c => (
              <this.InvestmentPageItem key={c.id} investment={c} />
            ))}
          </>
        )}
      </PageContainer>
    );
  }

  InvestmentPageItem = ({ investment: { id, createdAt, description } }) => (
    <CardPageItem raised>
      <CardActionArea component={Link} to={"/investments/" + id}>
        <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
          <DescriptionIcon color="action" />
          <Typography variant="body1" gutterBottom style={{ flexGrow: 1, marginLeft: 8 }}>
            {description}
          </Typography>
          <ChevronRightIcon color="action" />
        </CardContent>
      </CardActionArea>
    </CardPageItem>
  );
}

const validate = values => {
  const errors = {};
  if (!values.type) {
    errors.type = "app.type.error";
  }
  if (!values.active) {
    errors.active = "app.status.error";
  }
  if (!values.startDate) {
    errors.startDate = "app.startPeriod.mustbe.selected";
  }
  if (!values.finalDate) {
    errors.finalDate = "app.endPeriod.mustbe.selected";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "contractForm",
    enableReinitialize: true,
    validate
  })
)(AdminInvestment);
