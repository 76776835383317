import React, { Component } from "react";
import { PageItem } from "./Page";
import { Card, Paper, withTheme } from "@material-ui/core";

class FixedPageItem extends Component {
  render() {
    const {
      isCard,
      position,
      height,
      elevation,
      children,
      style,
      theme
    } = this.props;
    let pos = position == "top" ? { top: 50 } : { bottom: 56 };
    let Comp = isCard ? Card : Paper;
    let elev = elevation == undefined ? 8 : elevation;
    return (
      <>
        <Comp
          square
          elevation={elev}
          style={{
            position: "fixed",
            width: "100%",
            maxWidth: theme.props.maxPageWidth,
            height,
            ...pos,
            zIndex: 2,
            ...style
          }}
        >
          {children}
        </Comp>
        <PageItem style={{ height }} />
      </>
    );
  }
}
export default withTheme(FixedPageItem);
