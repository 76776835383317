import React, { Component } from "react";
import { CardContent, Button, Typography, Checkbox, FormControlLabel, CircularProgress } from "@material-ui/core";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import { getContract, setContractToWaitingAport, executeContract, getConfigurações } from "../actions";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LCContract from "./components/LCContract";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class CustomerAccept extends Component {
  state = {
    contract: {},
    step: 0,
    account: {},
    amount: 0,
    BTCUSDt: 0,
    BRLUSDt: 0,
    address: null,
    btn2: true,
    btn: true,
    config: {},
  };

  componentDidMount() {
    this.loadContract();
    getConfigurações().then((data) => {
      this.setState({ config: data });
    });
  }

  loadContract() {
    this.setState({ contract: {} });
    getContract(this.props.contractId).then((data) => {
      this.setState({ contract: data });
    });
  }
  approveSigned = (e) => {
    setContractToWaitingAport(this.state.contract.id, {
      address: this.state.config.address,
    }).then((data) => this.props.onOk());
  };
  execute = (e) => {
    executeContract(this.state.contract.id, {
      initialDate: moment().add(1, "DAY").format("YYYY-MM-DD"),
      dueDate: moment().add(this.state.contract.type.durationInMonth, "MONTH").format("YYYY-MM-DD"),
    }).then((data) => {
      this.setState({ contract: data });
      this.props.onOk();
    });
  };

  renderAccept = () => {
    return (
      <CardContent>
        {this.contrato()}

        <FormControlLabel
          label={<FormattedMessage id="app.withdrawTerms" />}
          control={
            <Field
              component={FormCheckbox}
              name="acceptContract"
              color="primary"
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ btn2: false });
                } else {
                  this.setState({ btn2: true });
                }
              }}
            />
          }
        />
        <Button
          disabled={this.state.btn2}
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          onClick={(e) => {
            this.approveSigned();
          }}
        >
          Ok
        </Button>
      </CardContent>
    );
  };
  renderReceipt = () => {
    return (
      <CardContent>
        {this.recibo()}

        <FormControlLabel
          label={<FormattedMessage id="app.withdrawTerms" />}
          control={
            <Field
              component={FormCheckbox}
              name="acceptReceipt"
              color="primary"
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ btn: false });
                } else {
                  this.setState({ btn: true });
                }
              }}
            />
          }
        />
        <Button
          disabled={this.state.btn}
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          onClick={(e) => {
            this.execute();
          }}
        >
          Ok
        </Button>
      </CardContent>
    );
  };
  contrato = () => {
    const { contract } = this.state;

    if (!contract.id) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    }
    return (
      <>
        <Typography gutterBottom component="div">
          <LCContract
            amount={contract.previsionAmount}
            duration={contract.type.durationInMonth}
            type={contract.type.type}
            max={contract.type.durationInMonth * contract.type.maxMonthlyYield}
            lang={this.props.currentUser.locale}
          />
        </Typography>
      </>
    );
  };
  recibo = () => {
    const { contract } = this.state;

    if (!contract.id) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    }
    return (
      <>
        <Typography gutterBottom component="div">
          <LCContract
            amount={contract.amount}
            duration={contract.type.durationInMonth}
            type={contract.type.type}
            max={contract.type.durationInMonth * contract.type.maxMonthlyYield}
            lang={this.props.currentUser.locale}
          />
        </Typography>
      </>
    );
  };
  render() {
    const { acceptContract } = this.props;
    //const { contract } = this.state;

    if (acceptContract) {
      return this.renderAccept();
    } else {
      return this.renderReceipt();
    }
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "Valor deve ser informado";
  }
  if (!values.address) {
    errors.address = "Endereço deve ser informado";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    config: state.config,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, actions),
  reduxForm({
    form: "newSaque",
    enableReinitialize: true,
    validate,
  })
)(CustomerAccept);
