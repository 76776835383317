import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getYields } from "../actions";
import { getTotalValue } from "../actions/Payment";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import FixedPageItem from "../custom/FixedPageItem";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { PageItem } from "../custom/Page";
import { capitalCaseNormalizer } from "../util";
class Customer extends Component {
  state = {
    customers: [],
    loading: true,
    yields: 0,
    totalValueMonth: 0,
  };

  componentDidMount() {
    const { currentUser } = this.props;

    if (!currentUser.id) {
      this.props.getMe().then((r) => this.getCustomers());
    } else {
      this.getCustomers();
    }
    getYields().then((data) => {
      this.setState({ yields: data });
    });
    let month = moment().format("YYYY-MM-01");
    getTotalValue(month, moment(month).endOf("month").format("YYYY-MM-DD")).then((data) => {
      this.setState({ totalValueMonth: data });
    });
  }

  getCustomers = () => {
    performRequest("GET", `user/customers?status=ACTIVE`, null, true)
      .then((response) => {
        this.setState({ customers: response.data.data, loading: false });
      })
      .catch();
  };
  searchUsers = (name) => {
    if (name.trim().length > 0) {
      performRequest("GET", `user/customers?status=ALL&name=${name}`, null, true)
        .then((response) => {
          this.setState({ customers: response.data.data });
        })
        .catch();
    } else {
      this.getCustomers();
    }
  };
  render() {
    const { customers, loading, totalValueMonth } = this.state;

    let totalAmount = 0;
    this.state.customers.map((cus) => {
      return (totalAmount += cus.openContractsAmount);
    });
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <HeaderPageItem title="app.navigationBar.customers" />
        <PageItem>
          <Card>
            <CardContent>
              <IconButton aria-label="Search">
                <SearchIcon />
              </IconButton>
              <FormattedMessage id="app.searchCustomers">
                {(placeholder) => (
                  <InputBase placeholder={placeholder} onChange={(e) => this.searchUsers(e.target.value)} />
                )}
              </FormattedMessage>
              <List>
                {customers.map((c) => {
                  return (
                    <ListItem button alignItems="flex-start" key={c.id} component={Link} to={"/customer/" + c.id}>
                      <ListItemAvatar>
                        <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{ component: "div" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div
                            color="textSecondary"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{capitalCaseNormalizer(c.name)}</span>
                          </div>
                        }
                        secondary={
                          <div>
                            <span>{c.email}</span>
                            <div
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span color="secondary">
                                <Number value={c.openContractsAmount} currency="LCT" />
                              </span>
                            </div>
                            {"("}
                            {<FormattedMessage id={"app.enuns." + c.role} />}
                            {") "}
                            <span style={{ color: "red" }}> {!c.apt ? <FormattedMessage id="app.inapt" /> : ""} </span>
                          </div>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </PageItem>
        <FixedPageItem isCard position="bottom" height={90}>
          <CardContent>
            <InfoItem
              variant="h5"
              caption={<FormattedMessage id="app.agent.wallet" />}
              extraCaption={<FormattedMessage id="app.commission.thisMonth" />}
              text={<Number value={totalAmount ? totalAmount : 0} currency="LCT" />}
              extraText={<Number value={totalValueMonth.val ? totalValueMonth.val : 0} currency="LCT" />}
            />
          </CardContent>
        </FixedPageItem>
      </PageContainer>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = "app.type.error";
  }
  if (!values.active) {
    errors.active = "app.status.error";
  }
  if (!values.startDate) {
    errors.startDate = "app.startPeriod.mustbe.selected";
  }
  if (!values.finalDate) {
    errors.finalDate = "app.endPeriod.mustbe.selected";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "customerForm",
    enableReinitialize: true,
    validate,
  })
)(Customer);
