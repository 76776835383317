import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

const styles = theme => ({
  infoList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm
  },
  infoListItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  infoListItemLabel: {
    marginBottom: theme.spacing(1)
  },
  infoListItemInfo: {
    marginBottom: theme.spacing(1)
  }
});

/**
 * Lista de pares de informação. Cada par possui label e informação, sendo mostrados em duas colunas,
 * label na esquerda e informação na direita.
 */
class InfoList extends Component {
  render = () => {
    const { bold, children, classes } = this.props;
    return (
      <div className={classes.infoList}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            classes: classes,
            bold: bold,
            ...child.props
          });
        })}
      </div>
    );
  };
}
export default withStyles(styles)(withWidth()(InfoList));

export function InfoListItem({ label, info, bold, classes }) {
  return (
    <>
      <div className={classes.infoListItem}>
        <Typography
          gutterBottom
          component="div"
          align="left"
          className={classes.infoListItemLabel}
        >
          {label}
        </Typography>
        <Typography
          gutterBottom
          component="div"
          align="right"
          className={classes.infoListItemInfo}
          style={{ fontWeight: bold ? "bold" : "normal" }}
        >
          {info}
        </Typography>
      </div>
    </>
  );
}
