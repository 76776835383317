import { AppBar, Badge, Button, CardContent, Typography, withTheme } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getKyc } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";

class DPDashboard extends Component {
  state = {
    pj: this.props.currentUser.isPj,
  };

  loadKyc = () => {
    getKyc().then((data) => {
      const userKyc = data;
      this.setState({ userKyc });
    });
  };
  componentDidMount() {
    this.loadKyc();
  }
  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { userKyc, pj } = this.state;
    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);

    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: `8px ${palette.primary.main} solid` }}
            />
          </AppBar>
        </HeaderPageItem>

        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" style={{ width: "100%", textAlign: "center" }}>
              {currentUser.name}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/more"
              style={{ marginTop: 30, width: "100%", textAlign: "center" }}
            >
              <FormattedMessage id="app.data.personal" />
            </Button>
            <Badge
              badgeContent={badges.qtd}
              color={badges.cor}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              overlap="circle"
            ></Badge>
            <Typography
              gutterBottom
              variant="body2"
              color="textSecondary"
              style={{ marginTop: 10, textAlign: "center" }}
            >
              <FormattedMessage id="app.documents.update.title" />
            </Typography>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised style={{ marginTop: 16 }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center" }}>
              <b>
                <FormattedMessage id="app.dp.dashboard.info.title" />
              </b>
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary" style={{ textAlign: "center" }}>
              <FormattedMessage id="app.dp.dashboard.info1" />
            </Typography>
          </CardContent>
        </CardPageItem>

        <CardPageItem>
          <CardContent>
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to="/logout"
              style={{ width: "100%", textAlign: "center" }}
            >
              <FormattedMessage id="app.logout" />
            </Button>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, null)(DPDashboard));
