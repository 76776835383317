import React, { Component } from "react";
import { Button, CardContent, Card, CardActions } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomInputForm from "../custom/CustomInput";
import performRequest from "../configAxios";
import * as actions from "../actions";

import { createNumberMask } from "redux-form-input-masks";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem } from "../custom/Page";
import { FormattedMessage } from "react-intl";

class AdminDepositForm extends Component {
  state = {
    loading: true
  };
  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props;
    await performRequest("GET", `/contract/deposits/${id}`, null, true)
      .then(response => {
        this.setState({ deposit: response.data.data, loading: false });
      })
      .catch(e => {
        history.push("/notfound");
      });
  }

  onSubmit = formProps => {
    const {
      showErrorMessage,
      showSuccessMessage,
      match: {
        params: { id }
      }
    } = this.props;
    performRequest(
      "POST",
      `/investment-statement`,
      { ...formProps, investmentId: id },
      true
    )
      .then(response => {
        showSuccessMessage("app.save");
        this.props.history.push(`/investments/${id}`);
      })
      .catch(e => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;

    const percentualMask = createNumberMask({
      decimalPlaces: 2,
      locale: "pt-BR"
    });

    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.deposit" showBackButton />
        </PageItem>
        <PageItem>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="amount"
                  label="app.value"
                  InputLabelProps={{
                    shrink: true
                  }}
                  component={CustomInputForm}
                  {...percentualMask}
                />

                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <FormattedMessage id="app.accept" />
                  </Button>
                  <Button
                    title="app.reject"
                    fullWidth
                    variant="contained"
                    color="primary"
                  ></Button>
                </CardActions>
              </form>
            </CardContent>
          </Card>
        </PageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.yield) {
    errors.yield = "app.yeld.informed";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    user: state.user.user.me
  };
}

export default compose(
  reduxForm({
    form: "adminDepositForm",
    enableReinitialize: true,
    validate
  }),
  connect(mapStateToProps, actions)
)(AdminDepositForm);
