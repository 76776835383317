import React, { Component } from "react";
import { numberFormatter } from "../../util";

class LCContractES extends Component {
  render() {
    let { duration, amount, type, max, addendum } = this.props;
    max = max.toFixed(2).replace(".", ","); // Formatar para 2 casas decimais
    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p>
            <span>Ap&eacute;ndice a los T&eacute;rminos de uso de la plataforma</span>
          </p>
          <p>
            <span>
              La aceptaci&oacute;n de este ap&eacute;ndice implica el acuerdo expreso del USUARIO que ratifica las
              cl&aacute;usulas y condiciones previstas en el documento titulado T&Eacute;RMINOS Y CONDICIONES DE USO de
              la plataforma, as&iacute; como los siguientes t&eacute;rminos:
            </span>
            <span>:</span>
          </p>
          <ol>
            <li>
              <p className="bold">OBJETO</p>
              <ol>
                <li>
                  <span>
                    Constituye por OBJETO de este LCContract el establecimiento de normas espec&iacute;ficas relativas a
                    la custodia de criptoactivos de la siguiente manera.
                  </span>
                </li>
                <li>
                  <span>El criptoactivo bajo custodia es una MONEDA ESTABLE.</span>
                </li>
                <li>
                  <span>
                    La MODALIDAD de custodia es del tipo {type == "OPEN" ? "OPEN" : "CERRADO"}, tal como se define en
                    los T&Eacute;RMINOS Y CONDICIONES DE USO.
                  </span>
                </li>
                <li>
                  <span>
                    La DURACI&Oacute;N de este LCContract es de {duration} meses a partir de la fecha de
                    aceptaci&oacute;n de este acuerdo.
                  </span>
                </li>
                <li>
                  <span>
                    El VOLUMEN CUSTODIADO corresponde a {numberFormatter(amount)}, pagados tras la aceptaci&oacute;n de
                    este LCCONTRACT.
                  </span>
                </li>
                <li>
                  <span>
                    El resultado esperado es variable y puede alcanzar, sin garant&iacute;as, el L&Iacute;MITE
                    m&aacute;ximo del {max}% al final de la duraci&oacute;n.
                  </span>
                  <ol>
                    <li>
                      <span>
                        Este resultado se calcula parcialmente cada mes en el VOLUMEN CUSTODIADO, que no incluye los
                        resultados del mes en s&iacute; ni de meses anteriores.
                      </span>
                    </li>
                    <li>
                      <span>
                        Los resultados medidos mensualmente por las operaciones de este LCCONTRACT, no est&aacute;n
                        cubiertos por sus t&eacute;rminos, siendo asignados directamente en el extracto del usuario, en
                        la plataforma, seg&uacute;n lo dispuesto en los T&Eacute;RMINOS Y CONDICIONES DE USO.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">DEL FUNCIONAMIENTO DE LCCONTRACT</p>
              <ol>
                <li>
                  <span>
                    Las reglas del funcionamiento de LCCONTRACTS se definen en los T&Eacute;RMINOS Y CONDICIONES DE USO.
                  </span>
                </li>
                <li>
                  <span>
                    Aceptando el presente, el USUARIO ratifica todas las cl&aacute;usulas y condiciones descritas en los
                    T&Eacute;RMINOS Y CONDICIONES DE USO.
                  </span>
                </li>
                <li>
                  <span>
                    El USUARIO autoriza, a partir de ahora, el uso de los criptoactivos custodiados en operaciones de
                    desarrollo tecnol&oacute;gico, arbitraje y comercio, seleccionados a discreci&oacute;n por Lux
                    Capital, LLC.
                  </span>
                </li>
                <li>
                  <span>
                    Este LCCONTRACT se activar&aacute; un d&iacute;a h&aacute;bil (D+1) tras la confirmaci&oacute;n de
                    la contribuci&oacute;n del usuario, de acuerdo con las condiciones de los T&Eacute;RMINOS Y
                    CONDICIONES DE USO.
                  </span>
                </li>
                <li>
                  <span>
                    El VOLUMEN CUSTODIADO permanecer&aacute; bajo custodia durante toda la duraci&oacute;n de este
                    LCCONTRACT, y no podr&aacute; canjearse antes de este per&iacute;odo.
                  </span>
                  <ol>
                    <li>
                      <span>
                        En el caso excepcional de la necesidad de redenci&oacute;n anticipada del VOLUMEN COSTEADO, el
                        USUARIO deber&aacute; proceder de acuerdo con lo dispuesto en los T&Eacute;RMINOS Y CONDICIONES
                        DE USO.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>
                    El aumento del volumen criptoactivo originado por las operaciones de este LCCONTRACT se puede ver en
                    el extracto del USUARIO y estar&aacute; disponible para su uso dentro de los plazos adecuados, de
                    acuerdo con la MODALIDAD contratada aqu&iacute;.
                  </span>
                </li>
                <li>
                  <span>
                    Al final del plazo de este LCCONTRACT, el volumen total costeado ya no se utilizar&aacute; en las
                    operaciones, y el volumen respectivo est&aacute; disponible para AMORTIZACI&Oacute;N por el USUARIO.
                  </span>
                  <ol>
                    <li>
                      <span>
                        Despu&eacute;s de LA AMORTIZACI&Oacute;N integral de los criptoactivos en poder de Lux Capital,
                        LLC, el volumen se asignar&aacute; en el extracto del usuario en un plazo de cuatro d&iacute;as
                        laborables (D+4).
                      </span>
                    </li>
                    <li>
                      <span>
                        Corresponde al USUARIO tener conocimiento de la fecha de finalizaci&oacute;n del plazo para
                        llevar a cabo la REDENCI&Oacute;N de su volumen criptoactivo
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">
                El USUARIO acepta las disposiciones contenidas en este instrumento, as&iacute; como ratifica las
                disposiciones contenidas en los T&Eacute;RMINOS Y CONDICIONES DE USO.
              </p>
            </li>
            {addendum && (
              <li>
                <p className="bold">APÉNDICE</p>
                <br />
                <div style={{ marginLeft: 30 }}>
                  <p style={{ whiteSpace: "pre-line" }}>{addendum}</p>
                </div>
              </li>
            )}
          </ol>
        </div>
      </>
    );
  }
}

export default LCContractES;
