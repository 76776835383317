import { Button, CardContent, CircularProgress, Menu, MenuItem, Typography, withTheme } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { contractRedeem, getConfigurações, getContract } from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { validateContract } from "../util";
import { HelpDialog } from "./components/HelpDialog";

class CustomerTerminatedContract extends Component {
  state = {
    contract: {},
    menuAnchor: undefined,
    step: false,
    saldoTotal: null,
    config: {},
  };

  loadContract = () => {
    getContract(this.props.match.params.id).then((data) => {
      this.setState({
        contract: data,
        saldoTotal: data.amount + data.yield.val,
      });
    });
  };

  componentDidMount() {
    this.loadContract();
    getConfigurações().then((data) => {
      this.setState({ config: data });
    });
  }

  onOk = () => {
    this.props.history.push("/contracts");
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { contract, config } = this.state;
    if (!contract.id || config.redemptionDelay < 0) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else if (!validateContract(currentUser, contract)) {
      return null;
    } else {
      const agentBack = currentUser.id !== contract.user.id;

      return (
        <PageContainer>
          <HeaderPageItem
            title={"app.lccontract"}
            showBackButton
            destination={agentBack ? "/customer/" + contract.user.id : "/contracts"}
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/contractHistory/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.monthlyHistory" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          {contract.phase == "CANCELED" ? (
            <>
              <CardPageItem>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    <FormattedMessage id="app.contract.canceled" />
                  </Typography>
                </CardContent>
              </CardPageItem>
            </>
          ) : contract.phase == "REDEEMED" ? (
            <>
              <CardPageItem>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    <FormattedMessage id="app.contract.redeemed" />
                  </Typography>
                </CardContent>
              </CardPageItem>
              <CardPageItem raised>
                <CardContent>
                  <InfoItem
                    caption={<FormattedMessage id="app.dashboard.total.title" />}
                    extraCaption={<FormattedMessage id="app.increasePercentage" />}
                    text={<Number value={contract.amount} currency="LCT" />}
                    extraText={<Number value={contract.yield.per} display="percent" />}
                  />
                </CardContent>
              </CardPageItem>
            </>
          ) : (
            <>
              <CardPageItem>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    <FormattedMessage id="app.contract.finished" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.contract.finished.text1" />
                  </Typography>
                </CardContent>
              </CardPageItem>
              <CardPageItem raised>
                <CardContent>
                  <InfoItem
                    caption={<FormattedMessage id="app.dashboard.total.title" />}
                    extraCaption="%"
                    text={<Number value={contract.amount} currency="LCT" />}
                    extraText={<Number value={contract.yield.per} display="percent" />}
                  />
                  <InfoItem
                    variant="h4"
                    color="primary"
                    center
                    caption={<FormattedMessage id="app.increaseTotal" />}
                    text={<Number value={contract.yield.val} currency="LCT" />}
                  />
                </CardContent>
              </CardPageItem>

              <CardPageItem>
                <CardContent>
                  <Typography gutterBottom>
                    <FormattedHTMLMessage id="app.contract.finished.text2" />
                  </Typography>
                  <Typography component="div" variant="caption" style={{ width: "100%", height: 150 }}>
                    <ResponsiveContainer width="99%" height="100%">
                      <LineChart
                        data={[
                          {
                            data: 0,
                            value: contract.amount + contract.yield.val,
                          },
                          {
                            data: 1,
                            value: (contract.amount + contract.yield.val) * 1.5,
                          },
                        ]}
                        margin={{ right: 30, left: 16 }}
                      >
                        <XAxis dataKey="date" />
                        <YAxis
                          yAxisId="val"
                          width={20}
                          orientation="right"
                          domain={["dataMin", "dataMax"]}
                          stroke={palette.primary.main}
                        />
                        <Line
                          yAxisId="val"
                          type="monotone"
                          dataKey="value"
                          name="Saldo"
                          stroke={palette.primary.main}
                          strokeDasharray="5 5"
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: 16 }}
                    component={Link}
                    to={"/reaport/" + contract.id}
                  >
                    <FormattedMessage id="app.reinvest.button" defaultMessage="Reinvestir" />
                  </Button>
                  <Typography variant="caption" color="textSecondary" gutterBottom>
                    <FormattedMessage id="app.contract.finished.text3" />
                  </Typography>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: 16 }}
                    onClick={() => {
                      contractRedeem(contract.id);
                      this.setState({ step: true });
                    }}
                  >
                    <FormattedMessage id="app.redemption.button" />
                  </Button>
                  <HelpDialog
                    open={this.state.step}
                    onClose={() => {
                      this.onOk();
                      this.setState({ step: false });
                    }}
                    title="app.contract.finished.dialog"
                  >
                    <FormattedMessage
                      id="app.contract.finished.dialog.text"
                      values={{ days: config.redemptionDelay }}
                    />
                  </HelpDialog>
                </CardContent>
              </CardPageItem>
            </>
          )}
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  }

  return errors;
};

export default compose(
  withRouter,
  withTheme,
  reduxForm({ form: "transForm", validate }),
  connect(mapStateToProps, actions)
)(CustomerTerminatedContract);
