import { ConnectedRouter } from "connected-react-router";
import moment from "moment";
import "moment/locale/pt-br";
import React from "react";
import cookie from "react-cookies";
import ReactDOM from "react-dom";
import Moment from "react-moment";
import { Provider } from "react-redux";
import App from "./App";
import configureStore, { history } from "./configStore";
import platformConfig from "./platformConfig";
import * as serviceWorker from "./serviceWorker";

if (!process.env.REACT_APP_PLATFORM_ID) {
  console.error("Variável REACT_APP_PLATFORM_ID não especificada.");
} else {
  const savedLocale = cookie.load("lclocale") ? cookie.load("lclocale") : "en";
  moment.locale(savedLocale == "br" ? "pt-br" : savedLocale);
  Moment.globalFormat = savedLocale == "br" ? "DD/MM/YYYY" : "YYYY-MM-DD";
  moment.tz.setDefault(platformConfig.timeZone);

  const { store } = configureStore();

  ReactDOM.render(
    <>
      {platformConfig.metaTags}
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history} />
        </ConnectedRouter>
      </Provider>
    </>,
    document.querySelector("#root")
  );

  serviceWorker.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          if (event.target.state == "activated") {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    },
  });
}
