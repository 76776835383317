import {
  AppBar,
  Badge,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  withTheme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getKyc, getRedemptionPayment, getStatusRedeemContracts } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import ChevronRight from "@material-ui/icons/ChevronRight";
import * as rssParser from "react-native-rss-parser";
import Number from "../custom/Number";
import AbtcMaterialTable, {
  newColumn,
  newColumnBTC,
  newColumnCOPD,
  newColumnDate,
  newColumnText,
  newColumnUSDt,
} from "./components/TableList";
import { getPendingWithdrawals } from "../actions";
import { addDays, difDateSignal, difDateSignal2, diffDatedays } from "../util";
import { CardActions } from "@material-ui/core";
import moment from "moment";
import { CardActionArea } from "@material-ui/core";
import MaterialTable from "material-table";
import platformConfig from "../platformConfig";

class FinanceDashboard extends Component {
  state = {
    pj: this.props.currentUser.isPj,
    rss: undefined,
    originalData: [],
  };

  loadKyc = () => {
    getKyc().then((data) => {
      const userKyc = data;
      this.setState({ userKyc });
    });
  };
  loadWithdraw = () => {
    getPendingWithdrawals().then((data) => {
      data.forEach((w) => {
        w.limitDate = addDays(w.authorizeDate, 4);
        w.daysToLimit = difDateSignal(w.limitDate);
        let dueTodayL = data.filter((w) => w.daysToLimit == 0);
        let lateL = data.filter((w) => w.daysToLimit < 0);

        this.setState({
          showWithdraw: data.length > 0,
          showDueToday: dueTodayL.length > 0,
          showlate: lateL.length > 0,
          dueToday: dueTodayL.length,
          late: lateL.length,
          count: data.length,
          valueTotalUSDT: data.reduce((sum, w) => sum + w.valueWithFee, 0),
          dueTodayTotalUSDT: dueTodayL.reduce((sum, w) => sum + w.valueWithFee, 0),
          lateTotalUSDT: lateL.reduce((sum, w) => sum + w.valueWithFee, 0),
        });
      });
    });
  };
  loadRedeem = () => {
    getStatusRedeemContracts().then((data) => {
      this.setState({
        showRedeem: data.length > 0,
        countR: data.length,
        redeemTotalUSDT: data.reduce((sum, w) => sum + w.amount, 0),
      });
    });
  };
  componentDidMount() {
    this.loadKyc();
    this.loadWithdraw();
    this.loadRedeem();
    // site da digiplata não tem feed teste feito pelo tema para não quebrar telas que chamam o blog
    if (platformConfig.id === "luxcapital") {
      fetch(platformConfig.links.feed)
        .then((response) => response.text())
        .then((responseData) => rssParser.parse(responseData))
        .then((rss) => {
          this.setState({ rss });
        });
    }
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { userKyc, pj } = this.state;
    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);

    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout" color="inherit">
              <ExitToAppIcon style={{ transform: "rotate(180deg)" }} />
            </IconButton>
            <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more" color="inherit">
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: `8px ${palette.primary.main} solid` }}
              style={{}}
            />
          </AppBar>
        </HeaderPageItem>

        <CardPageItem>
          <CardContent>
            <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
              {currentUser.name}
              <br />
              (<FormattedMessage id="app.enuns.ROLE_FINANCE_MANAGER" />)
            </Typography>
          </CardContent>
        </CardPageItem>
        {this.state.showWithdraw && (
          <CardActionArea component={Link} to={"/historyWithdrawal"}>
            <CardPageItem style={{ margin: 8 }} raised>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography gutterBottom color="primary">
                    <FormattedMessage id="app.withdrawals.pending" />
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography color="textSecondary" variant="caption">
                    <FormattedMessage id="app.description" />
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    <FormattedMessage id="app.value" />
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography color="textSecondary">
                    <FormattedMessage id="app.withdrawals.all" />
                    {" - "}
                    <Number value={this.state.count} />
                  </Typography>
                  <Typography color="textSecondary">
                    <Number value={this.state.valueTotalUSDT} currency="LCT" />{" "}
                  </Typography>
                </div>
                {this.state.showDueToday && (
                  <div style={{ color: "#D4290D", display: "flex", justifyContent: "space-between" }}>
                    <Typography>
                      <FormattedMessage id="app.expiration.today" />
                      {" - "}
                      <Number value={this.state.dueToday} />
                    </Typography>{" "}
                    <Typography>
                      <Number value={this.state.dueTodayTotalUSDT} currency="LCT" />
                    </Typography>
                  </div>
                )}
                {this.state.showlate && (
                  <div style={{ backgroundColor: "#D4290D", display: "flex", justifyContent: "space-between" }}>
                    <Typography color="textSecondary">
                      <FormattedMessage id="app.delayed" />
                      {" - "}
                      <Number value={this.state.late} />
                    </Typography>
                    <Typography color="textSecondary">
                      <Number value={this.state.lateTotalUSDT} currency="LCT" />
                    </Typography>
                  </div>
                )}
              </CardContent>
            </CardPageItem>
          </CardActionArea>
        )}
        {this.state.showRedeem && (
          <CardActionArea component={Link} to={"/historyWithdrawal?" + 3}>
            <CardPageItem style={{ margin: 8 }} raised>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography gutterBottom color="primary">
                    <FormattedMessage id="app.contractsR" />
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography color="textSecondary" variant="caption">
                    <FormattedMessage id="app.description" />
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    <FormattedMessage id="app.value" />
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography color="textSecondary">
                    <FormattedMessage id="app.redeem.total" />
                    {" - "}
                    <Number value={this.state.countR} />
                  </Typography>
                  <Typography color="textSecondary">
                    <Number value={this.state.redeemTotalUSDT} currency="LCT" />{" "}
                  </Typography>
                </div>
              </CardContent>
            </CardPageItem>
          </CardActionArea>
        )}
        {this.state.rss && (
          <CardPageItem raised>
            <CardContent>
              <Typography gutterBottom variant="body1" style={{ textAlign: "center" }}>
                Últimas notícias
              </Typography>
              <List dense>
                {this.state.rss.items.slice(0, 5).map((item) => (
                  <ListItem button component="a" href={item.links[0].url} key={item.id}>
                    <ListItemText primary={item.title} secondary={<Moment fromNow date={item.published} />} />
                    <ListItemSecondaryAction>
                      <ChevronRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </CardPageItem>
        )}

        <PageItem style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="app.dashboard.text1" />
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <FormattedMessage id="app.dashboard.text2" />
          </Typography>
          <Button variant="outlined" color="primary" href={platformConfig.links.blog}>
            <FormattedMessage id="app.access.blog" />
          </Button>
        </PageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, null)(FinanceDashboard));
