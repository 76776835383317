import {
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import QRCode from "qrcode.react";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { approveWithdrawal, getWithdrawById, rejectWithdrawal } from "../../actions";
import FormDialog from "../../custom/FormDialog";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import QuotationUpdater from "../../custom/QuotationUpdater";
import {
  addDays,
  Roles,
  validateCriptoAddress,
  networkByPartnerWithdrawType,
  networkNameByPartnerWithdrawType,
} from "../../util";
import FormUpdateAddress from "./FormUpdateAddress";
import FormUpdateFee from "./FormUpdateFee";
import { GenericDialog } from "./GenericDialog";

// Deixada cotação aqui no saque porque na grande maioria dos saques ela será útil.
// (ela só dará problema em saques para COPD)

class AdminWIthdrawalPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProps: {
        transactionId: "",
        withdrawDate: null,
        amount: null,
        fee: null,
        btc: "",
      },
      LCTBTCSell: 0,
      withdrawal: null,
      navWithdrawall: false,
      transactionId: "",
      transaction: null,
      rejectionReason: "",
      openTransaction: false,
      openRejectReason: false,
      loading: true,
      edit: false,
      flagApprove: false,
      flagRejected: false,
      btnActive: false,
      btnReject: true,
      editWallet: false,
      newAddress: "",
      flagWallet: false,
      btAddress: false,
      wId: this.props.selectWithdrawal.id,
    };
  }

  componentDidMount() {
    const { wId } = this.state;
    getWithdrawById(wId)
      .then((data) => {
        this.setState({
          withdrawal: data,
          loading: false,
          navWithdrawall: data.navWithdrawall,
        });
      })
      .catch((e) => {});
  }

  onSubmit = () => {
    this.setState({ flagApprove: true });
  };

  confirmWithdrawl = () => {
    let formProps = this.state.formProps;
    var date = new Date();
    formProps = {
      transactionId: this.state.transactionId ? this.state.transactionId : "0",
      withdrawDate: this.state.transaction ? this.state.transaction.received : date,
      amount: this.state.withdrawal.amount - this.state.withdrawal.fee,
      btc: this.state.transaction ? this.state.transaction.transactionValue : 0,
      address: this.state.withdrawal.address,
    };
    this.setState({ flagApprove: true });
    const { wId } = this.state;
    const { showSuccessMessage, showErrorMessage } = this.props;
    approveWithdrawal(wId, formProps)
      .then((data) => {
        showSuccessMessage("app.withdrawal.approved");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  rejectWithdrawal = () => {
    const { wId } = this.state;
    const { showSuccessMessage, showErrorMessage, history } = this.props;
    let formProps = {
      rejectionReason: this.state.rejectionReason,
    };
    rejectWithdrawal(wId, formProps)
      .then((data) => {
        showSuccessMessage("app.withdrawal.reject");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  setTransaction = (transaction) => {
    this.setState({
      openTransaction: true,
      transactionId: transaction.id,
      transaction,
    });
  };

  submitTransactionId = (idTransaction) => {
    //currentUser.role == Roles.Admin
    const { showErrorMessage, showSuccessMessage } = this.props;
    const { withdrawal, fee } = this.state;
    // Por padrão assume rede BTC.
    // Se não for saque para carteira externa, é isso que será usado (para parceiras, por exemplo).
    let token = "BTC";
    let network = "BTC";
    if (withdrawal.partnerWithdraw === 0) {
      const withdrawTokenNetwork = networkByPartnerWithdrawType[withdrawal.partnerwithdrawtype].split("/");
      token = withdrawTokenNetwork[0];
      network = withdrawTokenNetwork[1];
    }
    const tokenPrice = token === "USDT" ? 5 : 240000; // TODO: obter o preço atual do token.
    const date = new Date();
    const total = withdrawal.valueWithFee / tokenPrice;
    this.setState({ loading: true });
    validateCriptoAddress(idTransaction)
      .then((data) => {
        this.setState({ loading: false });
        const networks = data
          .filter((item) => item.type === "tx" && item.network === network)
          .map((item) => item.network);
        if (networks.length === 0) {
          showErrorMessage("Transação inválida.");
        } else {
          this.setTransaction({
            id: idTransaction,
            total,
            confirmed: date,
            received: date,
            fee,
            transactionValue: 0.00000025,
            feeInXbt: 0.00000015,
            amount: null,
            messageError: [],
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        if (e.message == "TRANSACTION_ERROR") {
          showErrorMessage("Transação inválida.");
        } else {
          showErrorMessage(
            "Não foi possível validar a transação.\nNão serão salvos os valores reais da mesma.\nÉ recomendado tentar mais tarde."
          );
          this.setTransaction({
            id: idTransaction,
            total,
            confirmed: date,
            received: date,
            fee,
            transactionValue: 0.00000025,
            feeInXbt: 0.00000015,
            amount: null,
            messageError: [
              "Não foi possível validar a transação.\nNão serão salvos os valores reais da mesma.\nÉ recomendado tentar mais tarde.",
            ],
          });
        }
      });
  };

  validaTransactionId = (value) => {
    this.setState({ transactionId: value });
    this.state.btnActive = value.length >= 30;
  };

  validaRejectReason = (value) => {
    this.setState({ rejectionReason: value });
    if (value.length < 5) {
      this.state.btnReject = true;
    } else {
      this.state.btnReject = false;
    }
  };

  onRejecteReason = () => {
    this.setState({ flagRejected: true });
  };

  render() {
    const { currentUser, onActionComplete } = this.props;
    const { withdrawal, transaction, loading, LCTBTCSell } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <CardContent>
        <div>
          {/* Status */}
          <Typography variant="h5" color="primary">
            <FormattedMessage id={`app.enuns.${withdrawal.status}`} />
          </Typography>
          <Typography>
            <FormattedMessage id="app.adminWithdrawal.text1" />
          </Typography>
          <Divider />
          <br />
          {/* Cliente */}
          <InfoItem
            caption={<FormattedMessage id="app.contract.canceledBy.customer" />}
            text={withdrawal.account.user.name}
          />
          {/* Data Autorização e Data de pedido */}
          <InfoItem
            caption={<FormattedMessage id="app.confirmed" />}
            text={
              <Moment
                date={withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt}
                format="DD/MM/YYYY"
              />
            }
            extraCaption={<FormattedMessage id="app.data.order" />}
            extraText={<Moment date={withdrawal.createdAt} format="DD/MM/YYYY" />}
          />
          {/* Data limite */}
          <InfoItem
            caption={<FormattedMessage id="app.date.limit" />}
            text={
              <Moment
                date={addDays(withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt, 4)}
                format="DD/MM/YYYY"
              />
            }
          />
          {/* Valor solicitado e Taxas */}
          <InfoItem
            caption={<FormattedMessage id="app.amount.requested" />}
            text={<Number value={withdrawal.amount} currency="LCT" />}
            extraCaption={<FormattedMessage id="app.fee" />}
            extraText={
              <>
                <IconButton
                  onClick={(e) => {
                    this.setState({ edit: true });
                  }}
                >
                  <Edit />
                </IconButton>
                <Number value={withdrawal.fee} currency="LCT" />
              </>
            }
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row">
              {/* Valor a pagar*/}
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography style={{ marginBottom: -10 }} variant="h6" gutterBottom color="primary">
                  <FormattedMessage id="app.amount.payable" />
                </Typography>
                <Typography variant="h6" gutterBottom color="primary">
                  <Number value={withdrawal.amount - withdrawal.fee} currency="LCT" />
                </Typography>
              </Grid>
              {withdrawal.partnerWithdraw == 0 ? (
                withdrawal.partnerwithdrawtype == 0 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem
                            caption={
                              <>
                                <FormattedMessage id="app.estimated.BTC" /> *
                              </>
                            }
                            text={<Number value={withdrawal.valueWithFee / LCTBTCSell} currency="BTC" />}
                          />
                        </Grid>
                        <Grid item>
                          <QuotationUpdater
                            onChange={({ LCTBTCSell }) => {
                              this.setState({ LCTBTCSell });
                            }}
                            size={16}
                            style={{ marginTop: 24 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : withdrawal.partnerwithdrawtype == 1 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem
                            caption={<FormattedMessage id="app.type.usdtTRX.button" />}
                            text={<FormattedMessage id="app.no.price.1" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : withdrawal.partnerwithdrawtype == 2 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem
                            caption={<FormattedMessage id="app.type.usdtETH.button" />}
                            text={<FormattedMessage id="app.no.price.1" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )
              ) : withdrawal.partnerWithdraw == 1 ? (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container direction="row" justify="flex-end">
                      <Grid item>
                        <InfoItem
                          caption={
                            <>
                              <FormattedMessage id="app.estimated.BTC" /> *
                            </>
                          }
                          text={<Number value={withdrawal.valueWithFee / LCTBTCSell} currency="BTC" />}
                        />
                      </Grid>
                      <Grid item>
                        <QuotationUpdater
                          onChange={({ LCTBTCSell }) => {
                            this.setState({ LCTBTCSell });
                          }}
                          size={16}
                          style={{ marginTop: 24 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                withdrawal.partnerWithdraw == 2 && (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <FormattedMessage id="app.no.price" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Grid>
          <InfoItem
            caption={<FormattedMessage id="app.wallet.destination" />}
            text={
              <>
                {withdrawal.address}
                {currentUser.role == Roles.Admin && (
                  <IconButton
                    onClick={(e) => {
                      this.setState({ editWallet: true });
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </>
            }
          />
          {
            //DIEGO valida se o checkbox da exchange Americansbitcoin está marcado
            withdrawal.partnerWithdraw == 1 ? (
              <>
                <Typography gutterBottom color="primary">
                  <b>{<FormattedMessage id="app.warning" />}</b>
                  {": "}
                  {<FormattedMessage id="app.withdraw.partner" />}

                  {" - "}
                  {withdrawal.partnerwithdrawtype == 0 ? (
                    <FormattedMessage id="app.automatic.selling" />
                  ) : (
                    <FormattedMessage id="app.balance.btc" />
                  )}
                </Typography>
              </>
            ) : withdrawal.partnerWithdraw == 0 ? (
              <>
                <Typography gutterBottom color="primary">
                  {[0, 1, 2].includes(withdrawal.partnerwithdrawtype)
                    ? networkNameByPartnerWithdrawType[withdrawal.partnerwithdrawtype]
                    : ""}
                </Typography>
              </>
            ) : (
              withdrawal.partnerWithdraw == 2 && (
                <Typography gutterBottom color="primary">
                  <b>{<FormattedMessage id="app.warning" />}</b>
                  {": "}
                  {<FormattedMessage id="app.withdraw.reaport" />}
                </Typography>
              )
            )
          }
          <Divider style={{ marginBottom: 3 }} />
          {withdrawal.partnerWithdraw == 2 ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 16 }}
              onClick={(e) => this.setState({ flagApprove: true })}
            >
              <FormattedMessage id="app.withdraw.reaport" />
            </Button>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 32,
                  marginBottom: 32,
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    width: "fit-content",
                  }}
                >
                  <QRCode value={withdrawal.address} size={250} />
                </div>
                <br />
                <div>{withdrawal.address}</div>
              </div>
              <Divider style={{ marginBottom: 3 }} />
              <Typography gutterBottom color="textSecondary">
                <b>{<FormattedMessage id="app.important" />}</b> {<FormattedMessage id="app.adminWithdrawal.text2" />}
              </Typography>
              <TextField
                fullWidth
                id="transactionId"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label={<FormattedMessage id="app.contract.waitingDeposit.button2" />}
                onChange={(e) => this.validaTransactionId(e.target.value)}
              />
              {/* Consulta a transação  */}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                disabled={!this.state.btnActive}
                onClick={(e) => this.submitTransactionId(this.state.transactionId)}
              >
                <FormattedMessage id="app.transaction.blockchain.select" />
              </Button>
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            style={{ marginTop: 16 }}
            onClick={(e) => this.setState({ openRejectReason: true })}
          >
            <FormattedMessage id="app.reject" />
          </Button>
        </div>
        {transaction && (
          // Após consultar a transação
          <HelpDialog
            title={
              transaction.messageError.length > 0 ? (
                <FormattedMessage id="app.transaction.problem" />
              ) : (
                <FormattedMessage id="app.approveWithdrawal" />
              )
            }
            onClose={(e) => this.setState({ openTransaction: false })}
            open={this.state.openTransaction}
            action={this.onSubmit}
            onAction={(e) => this.setState({ openTransaction: false })}
            openConfirm={currentUser.role == Roles.Admin || transaction.messageError.length == 0 ? true : false}
          >
            {/* Caso o id da transação sejá diferente de uma hash */}
            {this.state.transactionId.length != 64 ? (
              <InfoItem
                color="primary"
                caption={<FormattedMessage id="app.problems.detected" />}
                text={<FormattedMessage id="app.transaction.notfound" />}
              />
            ) : (
              transaction.messageError.length > 0 && (
                // Caso tenha dado mais de um erro na transação

                <InfoItem
                  color="primary"
                  caption={<FormattedMessage id="app.problems.detected" />}
                  text={transaction.messageError.map((e) => (
                    <Fragment key={e}>
                      <FormattedMessage id={e} />
                      <br />
                    </Fragment>
                  ))}
                />
              )
            )}

            {/* Estimativa em BTC*/}
            <InfoItem
              color="primary"
              caption={<FormattedMessage id="app.estimated.BTC" />}
              text={<Number value={withdrawal.valueWithFee / LCTBTCSell} currency="BTC" />}
            />
            <InfoItem
              caption={<FormattedMessage id="app.confirmed" />}
              text={
                <Moment
                  date={withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt}
                  format="DD/MM/YYYY HH:mm:ss"
                />
              }
            />
            {/* Valor em BRZ */}
            <InfoItem
              caption={<FormattedMessage id="app.camel.amount" />}
              text={<Number value={withdrawal.amount - withdrawal.fee} currency="LCT" />}
            />
            <InfoItem caption={<FormattedMessage id="app.wallet.address" />} text={withdrawal.address} />
            <InfoItem caption={<FormattedMessage id="app.transactionId" />} text={this.state.transactionId} />
            <Divider />
            <br />
            <Typography gutterBottom variant="h6">
              <FormattedMessage id="app.withdraw.information" />
            </Typography>
            {/* BTC enviado */}
            <InfoItem
              color="primary"
              caption={<FormattedMessage id="app.btc.sent" />}
              text={<Number value={transaction.transactionValue} currency="BTC" />}
            />
            {/* Taxa de mineração */}
            <InfoItem
              caption={<FormattedMessage id="app.transaction.miningFee" />}
              text={<Number value={transaction.feeInXbt} currency="BTC" />}
            />
            {/* Data da transação */}
            <InfoItem
              caption={<FormattedMessage id="app.transaction.transactionDate" />}
              text={<Moment date={transaction.received} format="DD/MM/YYYY HH:mm:ss" />}
            />

            <Button
              fullWidth
              variant="outlined"
              href={"https://www.blockchain.com/pt/btc/tx/" + this.state.transactionId}
              target="_blank"
            >
              <FormattedMessage id="app.transaction.blockchain" />
            </Button>
          </HelpDialog>
        )}
        {/* Confirma o saque? */}
        <GenericDialog
          title={<FormattedMessage id="app.withdrawConfirmOrNo" />}
          onCancel={() => this.setState({ flagApprove: false })}
          open={this.state.flagApprove}
          onOk={(e) => this.confirmWithdrawl()}
        ></GenericDialog>
        {/* Rejeita o saque? */}
        <GenericDialog
          title={<FormattedMessage id="app.withdrawRejectOrNo" />}
          onCancel={() => this.setState({ flagRejected: false })}
          open={this.state.flagRejected}
          onOk={() => {
            this.rejectWithdrawal();
          }}
        ></GenericDialog>
        {/* Alterar Carteira */}
        <GenericDialog
          title={<FormattedMessage id="app.wallet.destination.change.sure" />}
          onCancel={() => this.setState({ flagWallet: false, btAddress: false })}
          open={this.state.flagWallet}
          onOk={() => {
            this.setState({
              withdrawal: { ...this.state.withdrawal, address: this.state.newAddress },
              flagWallet: false,
              btAddress: false,
            });
          }}
        ></GenericDialog>
        <FormDialog
          title="app.wallet.destination.change"
          open={this.state.editWallet}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ editWallet: false })}
        >
          <FormUpdateAddress
            withdrawal={withdrawal}
            onActionComplete={(withdrawal) => this.setState({ withdrawal, editWallet: false })}
          />
        </FormDialog>
        <FormDialog
          title="app.rate.update"
          open={this.state.edit}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ edit: false })}
        >
          <FormUpdateFee
            withdrawal={withdrawal}
            onActionComplete={(withdrawal) => this.setState({ withdrawal, edit: false })}
          />
        </FormDialog>
        {/* Motivo da rejeição */}
        <Dialog
          fullWidth={true}
          maxWidth="md"
          style={{ color: "red" }}
          open={this.state.openRejectReason}
          onClose={(e) => this.setState({ openRejectReason: false })}
        >
          <DialogTitle>
            <FormattedMessage id="app.reject.reason.enter" />
          </DialogTitle>
          <Grid item xs={12}>
            <DialogContent>
              <TextField
                fullWidth
                id="transactionId"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label={<FormattedMessage id="app.reason.error" />}
                onChange={(e) => this.validaRejectReason(e.target.value)}
              />
            </DialogContent>
          </Grid>

          <DialogActions>
            <Button disabled={this.state.btnReject} onClick={(e) => this.onRejecteReason(e)} color="primary">
              <FormattedMessage id="app.reject" />
            </Button>

            <Button onClick={(e) => this.setState({ openRejectReason: false })} color="primary">
              <FormattedMessage id="app.close" />
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    );
  }
}

const HelpDialog = ({ open, onClose, title, children, action, onAction, openConfirm }) => (
  <Dialog style={{ color: "red" }} open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {openConfirm && (
        <Button onClick={action} color="primary">
          <FormattedMessage id="app.toApprove" />
        </Button>
      )}

      <Button onClick={onAction} color="primary">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(AdminWIthdrawalPopUp);
