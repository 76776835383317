import { UPDATE_USER, CREATE_USER, USER_ME, CLEAR_ME } from "./types";
import performRequest from "../configAxios";
import { push } from "connected-react-router";
import * as alerts from "./Alert";
import { requestAndDispatch, getDataFromWeb, formatParamsGet } from "../util";
import cookie from "react-cookies";

//KYC
export const sendFileKyc = (type, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getDataFromServer("POST", "/user/kyc/" + type, formData, true);
};
export const getKyc = () => getDataFromServer("GET", `/user/kyc/`, null, true);
export const changeAgent = id => getDataFromServer("PUT", `/user/${id}/changeAgent`, null, true);
export const changeAgentAdmin = (id, agent) => getDataFromServer("PUT", `/user/${id}/changeAgentAdmin`, agent, true);
export const clearAgent = (id) => getDataFromServer("PUT", `/user/clearAgent/${id}`, null, true);

//history
//account
export const freeBalance = () => getDataFromServer("POST", `account/release`, null, true);

export const getConfigurações = () => getDataFromServer("GET", `/config`, null, true);

export const getAccount = () => getDataFromServer("GET", `account/me`, null, true);
export const getAccountById = id => getDataFromServer("GET", `account/${id}`, null, true);
export const getAllStatement = () => getDataFromServer("GET", `statement/me`, null, true);
export const getStatement = (startDate, finalDate) =>
  getDataFromServer("GET", `statement/me?startDate=${startDate}&finalDate=${finalDate}`, null, true);
export const getInfoStatement = (userId, startDate, finalDate) =>
  getDataFromServer("GET", `statement/info?userId=${userId}&startDate=${startDate}&finalDate=${finalDate}`, null, true);
export const getYields = () => getDataFromServer("GET", `account/me/yields`, null, true);

export const getUser = id => requestAndDispatch("default", "GET", "/user/" + id, null, true);
export const getUsers = formprops => {
  let params = formatParamsGet(formprops)
  return getDataFromServer("GET", "/user/" + params, null, true)
};

//-------chamada de usuarios da Colombia-----
export const getUsersES = formprops => {
  let params = formatParamsGet(formprops)
  return getDataFromServer("GET", "/user/" + params, null, true)
};
export const getUser2 = userId => getDataFromServer("GET", "/user/" + userId, null, true);
export const getCustomer = userId => getDataFromServer("GET", `/user/customers/${userId}`, null, true);
export const resetPassword = userId => getDataFromServer("GET", `/user/${userId}/generateNewPassword`, null, true);
export const getAddressInfo = cep => getDataFromWeb("GET", `https://viacep.com.br/ws/${cep}/json/`, null);
export const confirmEmail = token => getDataFromServer("PUT", `/user/activate?token=${token}`, null, true);
export const resendConfirmationEmail = () => getDataFromServer("GET", `/user/confirmationEmail`, null, true);
export const sendEmail = (customerId, params) =>
  getDataFromServer("POST", `/user/${customerId}/sendEmail`, params, true);

export const getDataFromServer = (method, path, params, auth) =>
  new Promise(async (resolve, reject) => {
    try {
      const result = await performRequest(method, path, params, auth);
      const resultData = result.data.data;
      resolve(resultData);
    } catch (e) {
      reject(e);
    }
  });

export const searchUsers = (formProps, origin) => async dispatch => {
  try {
    let str = "";

    str = formProps
      ? "?" +
        Object.keys(formProps)
          .map(function (key) {
            if (formProps[key].trim().length > 0) {
              return key + "=" + formProps[key];
            }
            return null;
          })
          .join("&")
      : "";
    await performRequest("GET", "/user" + str, null, true);
  } catch (e) {
    if (e.response) {
      if (e.response.status == 401) {
        dispatch(push("/logout"));
      } else {
        dispatch(alerts.showErrorMessage(e.response.data.message));
      }
    } else {
      dispatch(alerts.showErrorMessage("app.server.connect.error"));
    }
  }
};

export const getMe = () => async dispatch => {
  try {
    const response = await performRequest("GET", "/user/me", null, true);

    const responseData = response.data.data;

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    cookie.save("lclocale", responseData.locale, {
      path: "/",
      expires
    });

    dispatch({ type: USER_ME, payload: responseData });
  } catch (e) {}
};

export const clearMe = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_ME, payload: {} });
  } catch (e) {}
};

export const updateUser = (id, formProps, callback) => async dispatch => {
  try {
    const response = await performRequest("PUT", `/user/${id}`, formProps, true);
    dispatch({ type: UPDATE_USER, payload: response.data.data });
    dispatch(alerts.showSuccessMessage("app.update"));
    if (callback) callback();
  } catch (e) {}
};

export const createUser = formProps => async dispatch => {
  try {
    const response = await performRequest("POST", `/user`, formProps, true);
    dispatch({ type: CREATE_USER, payload: response.data.data });
    dispatch(alerts.showSuccessMessage("app.create"));
  } catch (e) {}
};
export const updateLanguage = formProps => async dispatch => {
  await performRequest("PUT", `/user/me/language`, formProps, true)
    .then(response => {
      dispatch({ type: USER_ME, payload: response.data.data });
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      cookie.save("lclocale", response.data.data.locale, {
        path: "/",
        expires
      });
      dispatch(alerts.showSuccessMessage("app.update"));
      dispatch(push("/more"));
    })
    .catch(e => {
      dispatch(alerts.showErrorMessage(e.response ? e.response.data.message : e));
    });
};

//  Chamadas do contrato do Agente
export const createContractAgent = (agentId, formProps) => {
  return performRequest("POST", `/contractAgent/${agentId}`, formProps, true);
};

export const editContractAgent = (agentId, formProps) => {
  return performRequest("PUT", `/contractAgent/${agentId}`, formProps, true);
};
export const approveContractAgent = () => {
  return performRequest("GET", `/contractAgent/approve`, null, true);
};
export const restartContractAgent = (agentId, formProps) => {
  return performRequest("PUT", `/contractAgent/${agentId}/restart`, formProps, true);
};
export const getContractAgent = agentId => {
  return performRequest("GET", `/contractAgent/${agentId}`, null, true);
};
