import { Button, CardContent, Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { approveContractAgent } from "../actions";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import ContractAgentDescription from "./components/ContractAgentDescription";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}
class ContractAgentDetail extends Component {
  state = {
    contractConfirmed: false,
    contractDetail: false,
    yesOrNo: false,
    btn: true,
  };

  componentDidMount() {}

  approveContract = () => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, history, currentUser } = this.props;
    approveContractAgent()
      .then((response) => {
        showSuccessMessage("app.contractAgent.confirmed");

        history.push("/contracts");
        window.location.reload(false);
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem title="app.contractAgentId" values={currentUser.id} showBackButton destination="/contracts" />
          <CardPageItem>
            <CardContent>
              {currentUser.contractAgent.status == "PENDING" ? (
                <>
                  <Typography gutterBottom variant="h6">
                    <br />
                    <FormattedHTMLMessage id="app.contractAgent.approve" />
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">
                  <FormattedMessage id="app.contract.details" />
                </Typography>
              )}

              <InfoItem color="primary" caption={<FormattedMessage id="app.name" />} text={currentUser.name} />
              <InfoItem
                color="primary"
                caption={<FormattedMessage id="app.status" />}
                text={<FormattedMessage id={`app.enuns.${currentUser.contractAgent.status}`} />}
              />

              <InfoItem
                color="primary"
                caption={<FormattedMessage id="app.created" />}
                text={<Moment date={currentUser.contractAgent.createdAt} format="DD/MM/YYYY" />}
              />
              {/* <InfoItem
                color="primary"
                caption={<FormattedMessage id="app.goal" />}
                text={<Number value={currentUser.contractAgent.goal} currency="LCT" />}
              /> */}
              <Button fullWidth variant="outlined" onClick={() => this.setState({ contractDetail: true })}>
                <FormattedHTMLMessage id="app.contractAgent.read" />
              </Button>
              <FormDialog
                title="app.contractAgent"
                open={this.state.contractDetail}
                variant="secondary"
                onClose={(e) => this.setState({ contractDetail: false })}
              >
                <CardContent>
                  <ContractAgentDescription currentUser={currentUser} contractAgent={currentUser.contractAgent} />
                  {currentUser.contractAgent.status == "PENDING" && (
                    <>
                      <FormControlLabel
                        label={<FormattedMessage id="app.withdrawTerms" />}
                        control={
                          <Field
                            component={FormCheckbox}
                            name="acceptReceipt"
                            color="primary"
                            disabled={!this.props.clauses}
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ btn: false });
                              } else {
                                this.setState({ btn: true });
                              }
                            }}
                          />
                        }
                      />
                      <Button
                        disabled={this.state.btn}
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16, marginBottom: 16 }}
                        onClick={(e) => {
                          this.approveContract();
                        }}
                      >
                        Ok
                      </Button>
                    </>
                  )}
                </CardContent>
              </FormDialog>
            </CardContent>
          </CardPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    clauses: state.contractAgent.clauses,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "newContractAgent",
  })
)(ContractAgentDetail);
