import React, { Component } from "react";
import { Button, CardContent, Typography } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomInputForm from "../custom/CustomInput";
import performRequest from "../configAxios";
import * as actions from "../actions";
import { createNumberMask } from "redux-form-input-masks";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem, CardPageItem } from "../custom/Page";
import moment from "moment";
import { FormattedMessage } from "react-intl";

class AdminInvestmentYieldForm extends Component {
  onSubmit = formProps => {
    const {
      showErrorMessage,
      showSuccessMessage,
      match: {
        params: { id }
      }
    } = this.props;
    performRequest("POST", `/investment-statement`, { ...formProps, investmentId: id }, true)
      .then(response => {
        showSuccessMessage("app.save");
        this.props.history.push(`/investments/${id}`);
      })
      .catch(e => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  componentDidMount() {
    this.props.initialize({ createdAt: moment().format("YYYY-MM-DD") });
  }

  render() {
    const { handleSubmit } = this.props;

    const percentualMask = createNumberMask({
      suffix: "%",
      decimalPlaces: 2,
      locale: "pt-BR",
      allowNegative: true
    });

    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem
            title="app.investment.inform"
            showBackButton
            destination={"/investments/" + this.props.match.params.id}
          />
        </PageItem>

        <CardPageItem>
          <CardContent>
            <Typography gutterBottom>
              <FormattedMessage id="app.investment.inform.text" />
            </Typography>
            <Typography gutterBottom>
              <FormattedMessage id="app.investment.inform.text2" />
            </Typography>

            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="createdAt"
                label="app.date"
                type="date"
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                component={CustomInputForm}
              />

              <Field
                name="yield"
                label="app.yield"
                InputLabelProps={{
                  shrink: true
                }}
                component={CustomInputForm}
                {...percentualMask}
              />

              <Button fullWidth variant="contained" color="primary" type="submit">
                <FormattedMessage id="app.button.save" />
              </Button>

              <Typography variant="caption" color="textSecondary" gutterBottom style={{ marginTop: 32 }}>
                <FormattedMessage id="app.investment.inform.text3" />
              </Typography>
            </form>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.createdAt) {
    errors.createdAt = "app.date.inform";
  }

  if (!values.yield) {
    errors.yield = "app.yeld.informed";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    user: state.user.user.me
  };
}

export default compose(
  reduxForm({
    form: "yieldForm",
    validate
  }),
  connect(mapStateToProps, actions)
)(AdminInvestmentYieldForm);
