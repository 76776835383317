import { CardActionArea, CardContent, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Number from "../custom/Number";
import { CardPageItem } from "../custom/Page";
import LinearProgress from "@material-ui/core/LinearProgress";
import { diffDatedays } from "../util";
import moment from "moment";

class ContractPageItem extends Component {
  progress = (dateOpen, dateClose) => {
    dateOpen = moment(new Date(dateOpen));
    dateClose = moment(new Date(dateClose));
    let progress = diffDatedays(dateOpen);
    let days = moment.duration(dateClose.diff(dateOpen));
    let total = Math.round(days.asDays());
    return (progress * 100) / total;
  };

  render() {
    const { contract, user } = this.props;
    const c = {
      id: contract.id,
      showAgent: contract.user.id !== user,
      status: contract.status,
      amount: contract.amount,
      phase: contract.phase,
      name: "LCContract C" + contract.id,
      openDate: contract.initialDate ? contract.initialDate : contract.createdAt,
      closeDate: contract.dueDate,
      lastDate: contract.updatedAt,
      investment: contract.status == "INACTIVE" ? contract.previsionAmount : contract.amount,
      profit: contract.yield.val,
      profitPercentage: contract.yield.per,
      splitAgent: contract.splitAgent,
      splitPercent: contract.splitPercent,
      percAgent: contract.type.agentCommission,
      description: contract.type.description,
    };
    return (
      <CardPageItem raised>
        <CardActionArea component={Link} to={c.showAgent ? "/userContract/" + c.id : "/contract/" + c.id}>
          <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flexGrow: 1 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" color="primary">
                  {c.name}
                </Typography>

                <Typography variant="body1" color="primary">
                  <Number value={c.investment} currency="LCT" />
                </Typography>
              </div>

              {c.showAgent && (
                <>
                  <Typography variant="caption" color="textSecondary" component="div" style={{ marginBottom: 16 }}>
                    {c.description}. <FormattedMessage id="app.contract.commission" />:{" "}
                    <Number value={c.percAgent - c.splitPercent * c.percAgent} display="percent"></Number>
                  </Typography>
                </>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="caption" color="textSecondary">
                    {c.status == "ACTIVE" ? (
                      <FormattedMessage
                        id="app.progress"
                        values={{ value: <Number value={this.progress(c.openDate, c.closeDate)} display="percent" /> }}
                      />
                    ) : c.status == "INACTIVE" ? (
                      <FormattedMessage id="app.activationDate" />
                    ) : c.phase == "CANCELED" ? (
                      <FormattedMessage id="app.contract.finishedIn" />
                    ) : c.phase == "REDEEMED" ? (
                      <FormattedMessage id="app.contract.finishedIn" />
                    ) : (
                      <FormattedMessage id="app.contract.finishedAt" />
                    )}
                  </Typography>
                  <Typography>
                    {/* <Moment format="DD/MM/YYYY"> */}
                    {c.status == "ACTIVE" ? (
                      <LinearProgress
                        variant="determinate"
                        style={{ marginTop: 8, height: 10 }}
                        value={this.progress(c.openDate, c.closeDate)}
                      />
                    ) : c.status == "INACTIVE" ? (
                      moment(c.openDate).format("DD/MM/YYYY")
                    ) : c.phase == "CANCELED" ? (
                      moment(c.lastDate).format("DD/MM/YYYY")
                    ) : c.phase == "REDEEMED" ? (
                      moment(c.closeDate).format("DD/MM/YYYY")
                    ) : (
                      moment(c.closeDate).format("DD/MM/YYYY")
                    )}
                    {/* </Moment> */}
                  </Typography>
                </div>
                <div>
                  {c.status == "INACTIVE" ? (
                    <>
                      {c.amount > 0 ? (
                        <Typography variant="caption" color="textSecondary">
                          <FormattedMessage id="app.aport.validating" />
                        </Typography>
                      ) : (
                        <Typography variant="caption" color="textSecondary">
                          <FormattedMessage id={`app.enuns.${c.phase}`} />
                        </Typography>
                      )}
                    </>
                  ) : c.status == "ACTIVE" ? (
                    <>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id="app.contract.increase" />:
                      </Typography>
                      <Typography>
                        <Number value={c.profit} currency="LCT" />
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                      {c.status == "TERMINATED" || c.status == "ARCHIVE" ? (
                        <FormattedMessage id="app.contract.status.finished" />
                      ) : (
                        ""
                      )}
                    </Typography>
                  )}
                </div>
                <div>
                  {c.status == "TERMINATED" ? (
                    <>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id="app.contract.finished.text" />
                      </Typography>
                      <Typography>
                        <FormattedMessage id="app.contract.redemptionRequest" />
                      </Typography>
                    </>
                  ) : c.status == "ACTIVE" ? (
                    <>
                      <Typography variant="caption" color="textSecondary">
                        %:
                      </Typography>
                      <Typography>
                        <Number value={c.profitPercentage} display="percent" />
                      </Typography>
                    </>
                  ) : c.status == "INACTIVE" ? (
                    <>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id="app.inactive" />
                      </Typography>
                    </>
                  ) : (
                    //sempre vai entrar aqui arquivado
                    <>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id={`app.enuns.${c.phase}`} />
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", paddingLeft: 16 }}>
              <ChevronRightIcon color="action" />
            </div>
          </CardContent>
        </CardActionArea>
      </CardPageItem>
    );
  }
}

export default ContractPageItem;
