import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { CardContent, Button, withStyles, Typography, CircularProgress, withTheme } from "@material-ui/core";
import * as actions from "../actions";
import customInput from "../custom/CustomInput";
import FormDialog from "../custom/FormDialog";
import suporte from "../static/suporte.png";
import { Terms } from "./components/Terms";
import Policy from "./components/Policy";
import { FormattedMessage } from "react-intl";
import cookie from "react-cookies";
import platformConfig from "../platformConfig";

class Signin extends Component {
  state = {
    doubt: false,
    terms: false,
    policy: false,
    emailAdmin: platformConfig.links.emailadmin,
    emailAdminUS: platformConfig.links.emailajuda,
    telefoneAdmin: "",
    loading: false,
  };
  onSubmit = (formProps) => {
    this.setState({ loading: true });
    this.props
      .signin(formProps)
      .then((response) => {
        cookie.save("LCAuthToken", response.token, { path: "/" });
        this.props.history.go(0);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("FAILS");
      });
  };

  render() {
    const { handleSubmit, classes } = this.props;
    const { emailAdmin, emailAdminUS } = this.state;

    return (
      <>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 300,
            padding: 16,
          }}
        >
          <div>
            <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
              {platformConfig.id === "digiplata" ? (
                <img src={platformConfig.asset(platformConfig.logo)} alt="logo" width="300" height="120" />
              ) : (
                <img src={platformConfig.asset(platformConfig.logo)} alt="logo" width="200" height="200" />
              )}
            </div>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field name="username" label="app.email" component={customInput} type="email" />
              <Field name="password" label="app.password" type="password" component={customInput} />
              <br />
              <div className={classes.wrapper}>
                {/* O servidor verifica se não é um ataque DE FORÇA BRUTA */}
                {this.state.loading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button color="primary" variant="contained" type="submit" fullWidth={true}>
                    <FormattedMessage id="app.signin.button" defaultMessage="Entrar" />
                  </Button>
                )}
              </div>
            </form>
            <div
              style={{
                marginTop: 32,
              }}
            >
              <Button
                fullWidth
                size="small"
                variant="text"
                style={{ marginTop: 16 }}
                component={Button}
                href={platformConfig.links.termos}
                target="_blank"
              >
                <FormattedMessage id="app.termsAndConditions" />
              </Button>
              <Button
                fullWidth
                size="small"
                variant="text"
                style={{ marginTop: 16 }}
                component={Button}
                href={platformConfig.links.politica}
                target="_blank"
              >
                <FormattedMessage id="app.privacyPolicy" />
              </Button>

              {/* <FormDialog
                title="app.termsAndConditions"
                open={this.state.terms}
                variant="secondary"
                noHeader
                onClose={(e) =>
                  this.setState({
                    terms: false,
                  })
                }
              >
                <Typography component="div">
									<Terms />
								</Typography>
              </FormDialog>
              <FormDialog
                title="app.privacyPolicy"
                open={this.state.policy}
                variant="secondary"
                noHeader
                onClose={(e) =>
                  this.setState({
                    policy: false,
                  })
                }
              >
                <Typography component="div">
                  <Policy />
                </Typography>
              </FormDialog> */}
              <Button
                fullWidth
                size="small"
                variant="text"
                style={{ marginTop: 16 }}
                component={Button}
                onClick={() =>
                  this.setState({
                    doubt: true,
                  })
                }
              >
                <FormattedMessage id="app.signin.support.title" />
              </Button>
              <FormDialog
                title="app.signin.support.title"
                open={this.state.doubt}
                variant="secondary"
                noHeader
                onClose={(e) =>
                  this.setState({
                    doubt: false,
                  })
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography gutterBottom variant="h6" style={{ marginTop: 64, marginBottom: 32 }}>
                    <FormattedMessage id="app.contactUs" values={{ platform: platformConfig.name }} />
                  </Typography>
                  <img src={suporte} width="60" height="60" alt="suporte" />
                  <Typography gutterBottom style={{ marginTop: 32 }}>
                    <FormattedMessage id="app.signin.support.secondText" />
                  </Typography>
                  <Typography gutterBottom>{emailAdmin}</Typography>

                  <Typography gutterBottom style={{ marginTop: 32 }}>
                    <FormattedMessage id="app.signin.support.thirdText" />
                  </Typography>
                  <Typography gutterBottom>{emailAdminUS}</Typography>
                </div>
              </FormDialog>
            </div>
            <Typography style={{ textAlign: "center", color: "#999", marginTop: 32 }} variant="body2">
              <FormattedMessage id="app.version" defaultMessage="Versão" /> 2.6.0
            </Typography>
          </div>
        </CardContent>
      </>
    );
    // ATENÇÃO
    // No caso de erro na build no servidor (minified, etc.), a solução é copiar os arquivos package e package-lock do branch master.
  }
}

const styles = (theme) => ({
  cardHeader: {
    textAlign: "center",
  },
  card: {
    marginTop: "100px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = "app.form.email.invalid";
  }
  if (!values.password) {
    errors.password = "app.password.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    auth: state.auth.authenticated,
    currentUser: state.user.user.me,
  };
}

export default withTheme(
  compose(
    withStyles(styles),
    connect(mapStateToProps, actions),
    reduxForm({
      form: "signin",
      validate,
    })
  )(Signin)
);
