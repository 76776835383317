import { CircularProgress } from "@material-ui/core";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getContracts } from "../actions";
import ContractReportContent from "./components/ContractReportContent";

class ContractReportWaitingValidation extends Component {
  state = {
    loading: true,
    dataList: [],
    title: "",
  };

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search);
    const params = {
      phase: ["WAITING_APORT", "VALIDATING"],
      taxResidence: urlParams.taxResidence,
    };
    getContracts(params).then((dataList) => {
      // Filtro para eliminar contratos "bugados", que possuem amount zerado.
      dataList = dataList.filter((c) => c.amount > 0);
      this.setState({ dataList, loading: false });
    });
    this.setState({
      title: `app.contracts.reportwaitingvalidation.${params.taxResidence}`,
      taxResidence: params.taxResidence,
    });
  }

  render() {
    const { loading, dataList } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <ContractReportContent
        title={this.state.title}
        dataList={dataList}
        desc={"reportwaitingvalidation"}
        situation={true} // == "VALIDATING" ? "Aguardando cliente" : "Aguardando admin"}
        taxResidence={this.state.taxResidence}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(ContractReportWaitingValidation);
