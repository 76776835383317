import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import moment from "moment";
import platformConfig from "../../platformConfig";

class FormSendSelfie extends Component {
  render() {
    const today = moment();

    const IMG1A = platformConfig.asset("/images/kyc/verificaçãoDocOk.jpg");
    const IMG2A = platformConfig.asset("/images/kyc/verificaçãoDocError.jpg");
    const IMG1B = platformConfig.asset("/images/kyc/verificaçãoCNHOk.jpg");
    const IMG2B = platformConfig.asset("/images/kyc/verificaçãoCNHError.jpg");

    return (
      <>
        <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.1" />
        <br />
        <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.2" />
        <br />
        <div style={{ marginBottom: 8, marginTop: 32 }}>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.11" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.12" values={{ name: platformConfig.name }} />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.13" /> {today.format("DD/MM/YYYY")}
        </div>
        <div style={{ alignContent: "center", marginTop: 32 }}>
          {platformConfig.id === "luxcapital" && <img src={IMG1A} alt={1} height={150} style={{ margin: 8 }} />}
          <img src={IMG1B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        {platformConfig.id === "luxcapital" && (
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ color: "green", marginRight: 8 }}>✓</div>
            <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.3" />
          </div>
        )}
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.4" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.5" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.6" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.7" />
        </div>
        <div style={{ alignContent: "center", marginTop: 32 }}>
          {platformConfig.id === "luxcapital" && <img src={IMG2A} alt={1} height={150} style={{ margin: 8 }} />}
          <img src={IMG2B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.8" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.9" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.10" />
        </div>

        <Button
          fullWidth
          color="primary"
          variant="contained"
          style={{ marginBottom: 16, marginTop: 32 }}
          onClick={this.props.onActionComplete}
        >
          <FormattedMessage id="app.documents.idWithPhoto.dialog.button" />
        </Button>
      </>
    );
  }
}
export default FormSendSelfie;
