import { Button, CardContent, CircularProgress, MenuItem, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as actions from "../actions";
import { editPrevisionAmount, getActiveContractType, getContract } from "../actions/Contract";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { PageItem } from "../custom/Page";
import { Roles } from "../util";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class AgentEditContract extends Component {
  _isMounted = true;
  state = {
    contract: {},
    types: [],
    valueAport: 0,
    save: false,
    showback: false,
    loading: true,
    totalValueAport: 0,
  };

  componentDidMount() {
    if (this._isMounted) {
      this.props.getConfig();
      this.loadContract();
      getActiveContractType().then((data) => {
        this.setState({ types: data });
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onSubmit = (formProps) => {
    const { history, showErrorMessage, showSuccessMessage } = this.props;
    editPrevisionAmount(this.props.match.params.id, formProps)
      .then((data) => {
        showSuccessMessage("app.edit.sucesfully");
        this.loadContract();
        if (this.state.showback) {
          history.push("/contract/" + this.props.match.params.id);
        } else {
          history.push("/userContract/" + this.props.match.params.id);
        }
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  loadContract() {
    const { currentUser } = this.props;
    this.setState({ contract: {} });
    getContract(this.props.match.params.id).then((data) => {
      if (currentUser.id == data.user.id) {
        this.setState({ showback: data });
      }
      data.deposits.map((d) => this.setState({ totalValueAport: this.state.totalValueAport + d.amount }));
      this.setState({ contract: data, valueAport: data.amount });
      this.props.change("previsionAmount", data.previsionAmount);
      this.props.change("valueAport", data.amount);
      this.props.change("typeId", data.type.id);
      this.props.change("investmentId", data.investmentId);
      this.props.change("comments", data.comments);
      this.props.change("addendum", data.addendum);
    });
  }

  validateAmount = (value, allValues) => {
    const selectedType = this.state.types.filter((c) => c.id == allValues.typeId)[0];
    if (selectedType == undefined || allValues.typeId == undefined) return undefined;
    return value == undefined ? true : value >= selectedType.minimumAmount ? undefined : "app.editContract.minAport";
  };

  selectedType = this.state.types.filter((c) => c.id == this.props.contractType)[0];

  render() {
    const { contract, showback, types, valueAport, LCTFiat, LCTBTCBuy } = this.state;
    const { handleSubmit, currentUser, config } = this.props;
    if (!contract.id || !config.LCTInputMask) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      const lctMask = config.LCTInputMask((valueAport) => this.setState({ valueAport }));
      return (
        <PageContainer>
          <PageItem>
            {showback ? (
              <HeaderPageItem title="app.editContract" showBackButton destination={"/contract/" + contract.id} />
            ) : (
              <HeaderPageItem title="app.editContract" showBackButton destination={"/userContract/" + contract.id} />
            )}
          </PageItem>
          <PageItem>
            <CardContent>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Typography style={{ marginBottom: 16 }}>
                  <FormattedMessage id="app.editContract.text" />
                </Typography>
                <Field
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  name="typeId"
                  label="app.contractType"
                  component={CustomInputForm}
                >
                  {types.map((contract) => (
                    <MenuItem key={contract.id} value={contract.id}>
                      {contract.description}
                    </MenuItem>
                  ))}
                </Field>

                <Typography style={{ marginBottom: 16 }}>
                  <FormattedMessage id="app.editContract.text2" />.
                </Typography>
                <InfoItem
                  caption={<FormattedMessage id="app.estimatedAmount" />}
                  text={<Number value={contract.previsionAmount} currency="LCT" />}
                />

                <Field
                  fullWidth
                  component={CustomInputForm}
                  name="previsionAmount"
                  label="app.editContract.newAportValue"
                  style={{ marginTop: 16 }}
                  InputLabelProps={{ shrink: true }}
                  {...lctMask}
                  validate={this.validateAmount}
                  errorValues={{
                    min: this.selectedType ? this.selectedType.minimumAmount : 0,
                    coin: this.selectedType ? this.selectedType.minimumAmount : this.props.config.LCTCode,
                  }}
                />
                {contract.phase == "WAITING_APORT" &&
                  contract.amount > 0 &&
                  (currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && (
                    <>
                      <InfoItem
                        caption={<FormattedMessage id="app.totalAports" />}
                        text={<Number value={this.state.totalValueAport} currency="LCT" />}
                      />

                      <Field
                        fullWidth
                        component={CustomInputForm}
                        name="valueAport"
                        label="app.editContract.aportValue.new"
                        style={{ marginTop: 16 }}
                        InputLabelProps={{ shrink: true }}
                        {...lctMask}
                      />
                    </>
                  )}

                {(currentUser.role === Roles.Admin || currentUser.role == Roles.FinanceManager) && (
                  <>
                    <Field
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      name="investmentId"
                      label="app.editContract.investmentId"
                      component={CustomInputForm}
                    >
                      {[
                        { id: 1, desc: "Geral" },
                        { id: 2, desc: "AS Capital" },
                        { id: 3, desc: "Introduce" },
                        { id: 4, desc: "Sem agente" },
                      ].map((inv) => (
                        <MenuItem key={inv.id} value={inv.id}>
                          {inv.desc}
                        </MenuItem>
                      ))}
                    </Field>
                  </>
                )}

                <Field
                  fullWidth
                  multiline
                  component={CustomInputForm}
                  name="comments"
                  label="app.editContract.comments"
                  style={{ marginTop: 16 }}
                  InputLabelProps={{ shrink: true }}
                />

                <Field
                  fullWidth
                  multiline
                  component={CustomInputForm}
                  name="addendum"
                  label="app.editContract.addendum"
                  style={{ marginTop: 16 }}
                  InputLabelProps={{ shrink: true }}
                />

                <Button
                  fullWidth
                  id="bSubmitOrderForm"
                  style={{ marginTop: 16 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                >
                  <FormattedMessage id="app.editContract.button" />
                </Button>
              </form>
            </CardContent>
          </PageItem>
        </PageContainer>
      );
    }
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.previsionAmount) {
    errors.previsionAmount = "app.value.report";
  }
  if (values.previsionAmount <= 0) {
    errors.previsionAmount = "app.value.report";
  }

  if (!values.typeId) {
    errors.typeId = "app.form.contractType.error";
  }

  return errors;
};

const selector = formValueSelector("customerForm");

function mapStateToProps(state) {
  return {
    config: state.config,
    currentUser: state.user.user.me,
    contractType: selector(state, "typeId"),
  };
}
export default compose(
  reduxForm({
    form: "customerForm",
    validate,
  }),
  connect(mapStateToProps, actions)
)(AgentEditContract);
