import { CircularProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Number from "../../custom/Number";
import { DialogManager } from "../../util";
import { HelpDialog } from "./HelpDialog";
import moment from "moment-business-days";
import * as momentTimezone from "moment-timezone";
class ContractWidget extends Component {
  state = {
    date: momentTimezone("22:00:00", "hh:mm:ss"),
    loading: true,
  };
  componentDidMount() {
    this.onTimezone();
  }
  dm = new DialogManager(() => this.forceUpdate());
  onTimezone = () => {
    let data = this.state.date.clone();
    data.tz(this.props.timezone);
    this.setState({
      date: data,
      loading: false,
    });
  };
  render() {
    const { contractTotal, showToday } = this.props;
    if (!contractTotal || contractTotal.history.length == 0 || this.state.loading) return null;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {showToday && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage
                id="app.hourOfDailyIncome"
                values={{ time: this.state.date.format("HH:mm:ss").toString() }}
              />
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              {contractTotal.today.val != 0 ? (
                <>
                  <Typography variant="h5" color="primary">
                    <Number value={contractTotal.today.val} currency="LCT" />
                  </Typography>
                  <Typography variant="h5" color="primary" style={{ marginRight: 16 }}>
                    <Number value={contractTotal.today.per} display="percent" />
                  </Typography>
                </>
              ) : (
                <FormattedMessage
                  id="app.updateTime"
                  values={{ time: this.state.date.format("HH:mm:ss").toString() }}
                />
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12,
            marginTop: 12,
          }}
        >
          <div style={{ marginRight: 16, marginTop: -10 }}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="app.contract.monthlyIncrease" />
            </Typography>
            <Typography variant="h5" color="primary">
              <Number value={contractTotal.total.val} currency="LCT" />
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="app.contract.monthlyEstimated" />
            </Typography>
            <Typography variant="h5" color="textSecondary">
              <Number value={contractTotal.estimated.val} currency="LCT" />
            </Typography>
          </div>
          <div onClick={(e) => this.dm.open("HELP_CHART")} style={{ display: "block" }}>
            <Typography
              variant="h6"
              color="primary"
              style={{
                position: "absolute",
                marginTop: 35,
                marginLeft: 12,
                textAlign: "center",
                width: 100,
              }}
            >
              <Number value={contractTotal.total.per} display="percent" />
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                position: "absolute",
                marginTop: 62,
                marginLeft: 12,
                textAlign: "center",
                width: 100,
              }}
            >
              <FormattedMessage id="app.contract.widget.of" defaultValue="de " />
              <Number value={contractTotal.estimated.per} display="percent" />
            </Typography>
            <CircularProgress
              variant="static"
              value={100}
              size={120}
              color="secondary"
              style={{ position: "absolute" }}
            />
            <CircularProgress
              variant="static"
              value={contractTotal.total.per * (100 / contractTotal.estimated.per)}
              size={120}
            />
          </div>
        </div>

        <HelpDialog
          open={this.dm.isOpen("HELP_CHART")}
          onClose={(e) => this.dm.close()}
          title="app.contract.widget.dialog.title"
        >
          <FormattedMessage id="app.contract.widget.text" />
          <br />
          <br />
          <FormattedMessage id="app.contract.widget.text2" />
          <br />
          <br />
          <FormattedMessage id="app.contract.widget.text3" />
          <b>
            {" "}
            <Number value={contractTotal.estimated.per} display="percent" />
          </b>
          , <FormattedMessage id="app.contract.widget.text4" />
          <b>
            {" "}
            <Number value={contractTotal.total.per} display="percent" />{" "}
          </b>
          .
        </HelpDialog>
      </div>
    );
  }
}

ContractWidget.propTypes = {
  contractTotal: PropTypes.object,
  showToday: PropTypes.bool,
};

export default ContractWidget;
