import Moment from "moment";
import moment from "moment-business-days";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import * as alerts from "../actions/Alert";
import performRequest from "../configAxios";

export const Roles = {
  Admin: "ADMIN", // 1
  Customer: "CUSTOMER", // 2
  Agent: "AGENT", // 3
  Kyc: "KYC", // 4
  BusinessManager: "BUSINESS_MANAGER", // 5
  FinanceManager: "FINANCE_MANAGER", // 6
  AgentsManager: "AGENTS_MANAGER", // 7
  Auditor: "AUDITOR", // 8
};
export const ListRoles = [
  { name: "ADMIN", label: "app.enuns.ADMIN", value: true }, // 1
  { name: "CUSTOMER", label: "app.enuns.CUSTOMER", value: true }, // 2
  { name: "AGENT", label: "app.enuns.AGENT", value: true }, // 3
  { name: "KYC", label: "app.enuns.KYC", value: true }, // 4
  { name: "BUSINESS_MANAGER", label: "app.enuns.BUSINESS_MANAGER", value: true }, // 5
  { name: "FINANCE_MANAGER", label: "app.enuns.FINANCE_MANAGER", value: true }, // 6
  { name: "AGENTS_MANAGER", label: "app.enuns.AGENTS_MANAGER", value: true }, // 7
  { name: "AUDITOR", label: "app.enuns.AUDITOR", value: true }, // 8
];

export const ListContractColumns = [
  { name: "id", label: "app.id", value: true }, // 1
  { name: "customer", label: "app.customer", value: true }, // 1
  { name: "agent", label: "app.agent", value: true }, // 2
  { name: "situation", label: "app.situation", value: true }, // 2
  { name: "custodianLct", label: "app.custodian.lct", value: true }, // 3
  { name: "type", label: "app.type", value: true }, // 5
  { name: "createdAt", label: "app.contractReport.createdAt", value: true }, // 6
  { name: "activation", label: "app.activation", value: true }, // 6
  { name: "rescue", label: "app.rescue", value: true }, // 7
  { name: "durationInMonth", label: "app.durationInMonth", value: true }, // 7
  { name: "limitMonthyPercent", label: "app.limit.monthly.percent", value: true }, // 8
  { name: "limitMonthyLct", label: "app.limit.monthly.lct", value: true }, // 9
  { name: "comissionPercent", label: "app.comission.percent", value: true }, // 11
  { name: "comissionLct", label: "app.comission.lct", value: true }, // 12
  { name: "aportDate", label: "app.aport.date", value: true }, // 14
  { name: "aportValue", label: "app.aport.value", value: true }, // 15
  { name: "aportId", label: "app.aport.id", value: true }, // 16
];
export const ListUserColumns = [
  { name: "id", label: "app.id", value: true }, // 1
  { name: "name", label: "app.name", value: true },
  { name: "txtRole", label: "app.role", value: true },
  { name: "telefone", label: "app.phone", value: true },
  { name: "telefone2", label: "app.phone", value: true },
  { name: "email", label: "app.email", value: true },
  { name: "txtEmailConfirmed", label: "app.email.confirmed", value: true },
  { name: "txtActive", label: "app.apt", value: true },
  { name: "txtCreatedAt", label: "app.dateRegister", value: true },
  { name: "txtActivationDate", label: "app.date.active", value: true },
  { name: "txtDateOfBirth", label: "app.dateOfBirth", value: true },
  { name: "app.agent", label: "app.agent", value: true },
];
export const Enuns = {
  ADDRESS: "app.enuns.ADDRESS",
  ID: "app.enuns.ID",
  SOCIAL_CONTRACT: "app.enuns.SOCIAL_CONTRACT",
  ID_WITH_PHOTO: "app.enuns.ID_WITH_PHOTO",
  ATTACHMENT: "app.enuns.ATTACHMENT",
  APPROVED: "app.enuns.APPROVED",
  PENDING: "app.enuns.PENDING",
  ANALYZING: "app.enuns.ANALYZING",
  CANCEL: "app.enuns.CANCEL",
  REJECTED: "app.enuns.REJECTED",
  ARCHIVE: "app.enuns.TERMINATED",
  ACTIVE: "app.enuns.USER_ACTIVE",
  INACTIVE: "app.inactive",
  TERMINATED: "app.enuns.TERMINATED",
  EXECUTING: "app.enuns.EXECUTING",
  WAITING_WITHDRAW: "app.enuns.WAITING_WITHDRAW",
  WAITING_SIGN: "app.enuns.WAITING_SIGN",
  WAITING_APORT: "app.enuns.WAITING_APORT",
  VALIDATING: "app.enuns.VALIDATING",
  REDEEMED: "app.enuns.REDEEMED",
  WAITING_PAYMENT_REDEEMED: "app.enuns.WAITING_PAYMENT_REDEEMED",
  CANCELED: "app.enuns.CANCEL",
  CONFIRM_WITHDRAW: "app.withdraw.confirm",
  USER_ACTIVE: "app.enuns.ACTIVE",
  USER_INACTIVE: "app.enuns.USER_INACTIVE",
  USER_PENDING: "app.enuns.USER_PENDING",
  REAPORT_CONFIRM: "app.enuns.REAPORT_CONFIRM",
  REAPORT_CONFIRMED: "app.enuns.REAPORT_CONFIRMED",
  REAPORT_SENDED: "app.enuns.REAPORT_SENDED",
  ROLE_ADMIN: "app.enuns.ROLE_ADMIN",
  ROLE_KYC: "app.enuns.ROLE_KYC",
  ROLE_BUSINESS_MANAGER: "app.enuns.BUSINESS_MANAGER",
};

export const Currencies = {
  USD: "app.currency.usd",
  BRL: "app.currency.brl",
  EUR: "app.currency.eur",
};

export const partnerWithdrawTypeByNetwork = {
  "BTC/BTC": 0,
  "USDT/TRX": 1,
  "USDT/ETH": 2,
};

export const networkByPartnerWithdrawType = {
  0: "BTC/BTC",
  1: "USDT/TRX",
  2: "USDT/ETH",
};

export const networkNameByPartnerWithdrawType = {
  0: "BTC - Bitcoin",
  1: "USDT TRC20 - Tron (TRX)",
  2: "USDT ERC20 - Ethereum (ETH)",
};

export const networkNameByNetwork = {
  "BTC/BTC": networkNameByPartnerWithdrawType[partnerWithdrawTypeByNetwork["BTC/BTC"]],
  "USDT/TRX": networkNameByPartnerWithdrawType[partnerWithdrawTypeByNetwork["USDT/TRX"]],
  "USDT/ETH": networkNameByPartnerWithdrawType[partnerWithdrawTypeByNetwork["USDT/ETH"]],
};

export const capitalCaseNormalizer = (value) => {
  return value
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const userDocumentNormalizer = (user) => {
  if (!user) {
    return null;
  }
};

export const formattCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

export const formattCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, ""); //Remove tudo o que não é dígito
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
  return cnpj;
};

const documentFormatters = {
  CPF: formattCPF,
  CNPJ: formattCNPJ,
};

/**
 * Formata o documento de acordo com o tipo informado.
 * @param {String} docNumber
 * @param {String} docType
 * @param {Component} ComponentIfEmpty
 * @returns String com o docNumber formatado de acordo com o docType ou ComponentIfEmpty caso algum parâmetro informado esteja vazio.
 */
// Boa sorte tentanto entender isso! Muahahahaaaa! <-risada malígna
export const documentFormatter = (docNumber, docType, ComponentIfEmpty = <></>) =>
  !docNumber || !docType
    ? ComponentIfEmpty
    : (documentFormatters[docType] ? documentFormatters[docType] : (x) => x)(docNumber);

/**
 * Formata o documento do objeto usuário informado.
 * @param {Object} user
 * @param {String} user.documentId
 * @param {String} user.documentType
 * @param {Component} ComponentIfEmpty
 * @returns String com o documentId formatado de acordo com o documentType ou ComponentIfEmpty caso algum parâmetro informado esteja vazio.
 */
export const userDocumentFormatter = (user, ComponentIfEmpty = <></>) =>
  !user ? null : documentFormatter(user.documentId, user.documentType, ComponentIfEmpty);

export const fullDateFormatter = (dateFormat) => {
  let idLocale = require("moment/locale/pt-br");
  Moment.updateLocale("pt-br", idLocale);
  let date = null;
  dateFormat !== null && (date = Moment(dateFormat).format("DD/MM/YYYY HH:mm:ss"));
  return date;
};

export const dateFormatter = (dateFormat) => {
  let idLocale = require("moment/locale/pt-br");
  Moment.updateLocale("pt-br", idLocale);
  let date = null;
  dateFormat !== null && (date = Moment(dateFormat).format("DD/MM/YYYY"));
  return date;
};

export const diffDatedays = (date, initialDate = moment(new Date())) => {
  const duration = moment.duration(initialDate.diff(date));
  // Mostra a diferença em dias
  return Math.round(duration.asDays());
};

export const timeFormatter = (dateFormat) => {
  let idLocale = require("moment/locale/pt-br");
  Moment.updateLocale("pt-br", idLocale);
  let date = null;
  dateFormat !== null && (date = Moment(dateFormat).format("HH:mm:ss"));
  return date;
};

export const numberFormatter = (value, btc, number) => {
  const formattedValue = Intl.NumberFormat("en", {
    minimumFractionDigits: btc ? 5 : 2,
    maximumFractionDigits: btc ? 5 : 2,
    useGrouping: true,
  }).format(value);
  if (number) return Number(formattedValue);
  return formattedValue;
};

export const formatForMoney = (value, decimal) => {
  var numero = value.toFixed(decimal).split(".");
  numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
};
/*
 * @param {*} money Valor
 * @param {*} symbol simbolo a ser usado
 * @param {*} decimalPlaces quantidade de casas decimais
 */
export const formatMoney = (money, symbol, decimalPlaces = 2) => {
  let value = "";
  if (symbol == "BRL") {
    value = money.toLocaleString("pt-BR", {
      style: "currency",
      currency: symbol,
    });
  } else {
    value = symbol + " " + money.toLocaleString("pt-BR", { minimumFractionDigits: decimalPlaces });
  }

  return value;
};

export const numberNormalizer = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
};

/**
 * @param {*} value texto contendo o número a ser normalizado.
 * @returns o número normalizado, contendo apenas digitos e os carateres "+", "-", ".", "(", ")" e " " (espaço em branco).
 */
export const phoneNormalizer = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d+-.)( ]/g, "");
};

export const cpfNormalizer = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6)}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9)}`;
  }
  return onlyNums;
};

export const cnpjNormalizer = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8)}`;
  }
  if (onlyNums.length <= 14) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
      8,
      12
    )}-${onlyNums.slice(12)}`;
  }
  return onlyNums;
};

/**
 * Executa um consolee.log apenas se estiver em desenvolvimento, evitando logs no ambiente de produção.
 */
export const logDev = (...params) => {
  if (process.env.NODE_ENV !== "production") {
  }
};

/**
 * Atalho para consolee.logError. Executa também no ambiente de produção.
 */
export const logError = (...params) => {};

/**
 * Realiza uma chamada ao servidor para o métodos e url providos e retorna uma promise
 * - Em caso de sucesso, ".then" recebe o objeto retornado pelo servidor.
 * - Em caso de erro, ".catch" recebe uma exceção com nome, message e possivelmente mais dados (se o erro for do servidor).
 * - Se o parâmetro disabledErrorAlert for true, não é mostrado um alerta de erro ao receber erros do servidor.
 */
export const requestAndDispatch =
  (type, method, url, params, auth, disabledErrorAlert, funcName) => async (dispatch) => {
    try {
      logDev("[dispatchRequestPromise] REQUEST: " + funcName, method, url, params);
      const response = await performRequest(method, url, params, auth);
      logDev("[dispatchRequestPromise] RESPONSE: " + funcName, response);
      const data = response.data.data ? response.data.data : response.data;
      try {
        dispatch({ type: type, payload: data });
      } catch (ex2) {
        logError("[dispatchRequestPromise] Erro no dispatch", ex2);
        throw ex2;
      }
      return data;
    } catch (e) {
      e.response.data.name = e.response.data.error;
      logError("[dispatchRequestPromise] ERROR: " + funcName, e.response);
      if (!disabledErrorAlert) dispatch(alerts.showErrorMessage(e.response.data.message));
      throw e.response.data;
    }
  };

/**
 * Esta rotina serve para formatar números que já não estão formatados.
 * Retorna o número em formato de celular do Brasil apenas, que não é adequado para
 * telefones fixos (ainda muito usados por empresas) nem para telefones de outro pais.
 * Inclusive, assume que número NÃO contem o código do pais na frente.
 *
 * Desde Abril/2021 a plataforma não salva apenas os números na base, mas sim,
 * a string completa que o usuário digitar no campo telefone. Por isso, ela vai incluir
 * formatação com espaços, "-" ou "(". Em função disto, esta rotina testa a presença de
 * caracteres "não digito" no número. Caso detecte a presença de algum, retorna
 * a string sem modificação.
 *
 * Temporariamente, para faciliar a identificação, na interface, de números que foram
 * formatados, esta rotina acrecenta um ponto ao final do número formato por ela.
 *
 * @param {*} phoneNumber string com o número a ser formatado
 * @returns retorna o número em formato do brasil (99) 9 9999-9999.
 */
export const phoneFormatter = (phoneNumber) => {
  if (phoneNumber && phoneNumber.length >= 10 && /^\d+$/g.test(phoneNumber))
    return (
      "(" +
      phoneNumber.substr(0, 2) +
      ") " +
      phoneNumber.substr(2, 1) +
      " " +
      phoneNumber.substr(3, 4) +
      "-" +
      phoneNumber.substr(7) +
      " ."
    );
  else return phoneNumber;
};

export const capitalizeFirstLetter = (string) => {
  //verifica se é uma string e deixa a primeira letra em maiscula
  if (!!string) {
    return string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
  }
};

export const getDataFromServer = (method, path, params, auth) =>
  new Promise(async (resolve, reject) => {
    await performRequest(method, path, params, auth)
      .then((response) => resolve(response.data.data))
      .catch((e) => reject(e));
  });

export const getDataFromWeb = (method, url, data) =>
  new Promise(async (resolve, reject) => {
    await fetch(url, { method })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        resolve(response.json());
      })
      .catch((e) => reject(e));
  });

export const downloadBase64 = (base64Data, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = "data:application/pdf;base64," + base64Data;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export class DialogManager {
  openDialog = undefined;

  constructor(onChange) {
    if (onChange) this.onChange = onChange;
  }

  onChange = () => {};

  open = (dialogId) => {
    this.openDialog = dialogId;
    this.onChange(this.openDialog);
  };

  close = () => {
    this.openDialog = undefined;
    this.onChange(this.openDialog);
  };

  isOpen = (dialogId) => {
    return dialogId != null && dialogId != undefined && this.openDialog == dialogId;
  };
}

export const validateAddress = (values, dispatch, props, currentFieldName) => {
  const previousErrors = props.asyncErrors;
  return getDataFromWeb("GET", `https://api.blockcypher.com/v1/btc/main/addrs/${values.address}`, null).catch((e) => {
    throw Object.assign({}, previousErrors, {
      address: "app.walletAddress.invalid",
    });
  });
};

export const validateCriptoAddress = (address) =>
  new Promise(async (resolve, reject) => {
    await getDataFromServer("GET", `withdraw/validadeAddress/${address}`, null, true)
      .then((data) => resolve(data))
      .catch((e) => reject(e.response.data));
  });

/**
 *
 * Valida se o usuário do contrato é o mesmo do id ou se é admin
 */
export const validateContract = (currentUser, contract) => {
  if (currentUser.role == Roles.Admin || currentUser.id == contract.user.id) {
    return true;
  } else {
    return false;
  }
};

export const validateEmail = async (values, dispatch, props, currentFieldName) => {
  const previousErrors = props.asyncErrors;
  return new Promise((resolve, reject) => {
    performRequest("GET", `/user/validateEmail?email=${values.email}`, null)
      .then((response) => {
        if (!response.data.success) {
          return reject(
            Object.assign({}, previousErrors, {
              email: "app.email.inuse",
            })
          );
        }
        if (props.asyncErrors) {
          return reject(Object.assign({}, { ...props.asyncErrors, email: undefined }));
        }
        return resolve();
      })
      .catch((e) => {
        reject(
          Object.assign({}, previousErrors, {
            email: "app.server.connect.error",
          })
        );
      });
  });
};

export const validateDocumentId = async (values, dispatch, props, currentFieldName) => {
  return new Promise((resolve, reject) => {
    if (!values.documentId) return resolve();
    performRequest(
      "GET",
      `/user/validateDocumentId?documentId=${values.documentId}&taxResidence=${values.taxResidence}`,
      null
    )
      .then((response) => {
        if (!response.data.success) {
          return reject(
            Object.assign({}, props.asyncErrors, {
              documentId: `app.documentId.exists`,
            })
          );
        }
        if (props.asyncErrors) {
          return reject(Object.assign({}, { ...props.asyncErrors, documentId: undefined }));
        }
        return resolve();
      })
      .catch((e) => {
        reject(
          Object.assign({}, props.asyncErrors, {
            documentId: "app.server.connect.error",
          })
        );
      });
  });
};

export const validateDocumentIdAdmin = async (values, dispatch, props, currentFieldName) => {
  const previousErrors = props.asyncErrors;
  return await performRequest(
    "GET",
    `/user/validateDocumentIdAdmin?documentId=${values.documentId}&userId=${props.user.id}`,
    null,
    true
  )
    .then((response) => {
      if (!response.data.success) {
        throw Object.assign({}, previousErrors, {
          documentId: "Cpf já utilizado",
        });
      }
    })
    .catch((e) => {
      throw Object.assign({}, previousErrors, {
        documentId: "Cpf já utilizado",
      });
    });
};

export const validateAgentId = async (values, dispatch, props, currentFieldName) => {
  const previousErrors = props.asyncErrors;
  return new Promise((resolve, reject) => {
    if (!values.agentId) return resolve();
    performRequest("GET", `/user/existsAgent/${values.agentId}`, null)
      .then((response) => {
        if (!response.data.success) {
          return reject(
            Object.assign({}, previousErrors, {
              agentId: "app.agent.notFound",
            })
          );
        }
        if (props.asyncErrors) {
          return reject(Object.assign({}, { ...props.asyncErrors, agentId: undefined }));
        }
        return resolve();
      })
      .catch((e) => {
        return reject(
          Object.assign({}, previousErrors, {
            agentId: "app.server.connect.error",
          })
        );
      });
  });
};

function composeAsyncValidators(validatorFns) {
  return async (values, dispatch, props, field) => {
    const validatorFn = validatorFns[field];
    if (!validatorFn) {
      return Promise.resolve();
    }
    return await validatorFn(values, dispatch, props, field);
  };
}

export const asyncValidateRegister = composeAsyncValidators({
  email: validateEmail,
  documentId: validateDocumentId,
  taxResidence: validateDocumentId,
  agentId: validateAgentId,
});

/**
 *
 * @param {*} date  recebe uma data
 * @param {*} day  adiciona a quantidade de  dias para somar
 *  O retorno será a data mais a quantidade de dias
 */
export const addDays = (date, day = 0) => {
  const dateTemp = moment(date);
  dateTemp.hour(0);
  dateTemp.minute(0);
  dateTemp.second(0);
  dateTemp.millisecond(0);
  const newDate = dateTemp.businessAdd(day)._d;
  return newDate;
};

/**
 *
 *
 * @param {*} date  recebe uma data
 * @param {*} day  adiciona a quantidade de  dias para subtrair
 *  O retorno sera a a data menos a quantidade de dias informada
 */
export const subtraiData = (date, day = 0) => {
  const dateTemp = moment(date);
  dateTemp.hour(0);
  dateTemp.minute(0);
  dateTemp.second(0);
  dateTemp.millisecond(0);
  const newDate = dateTemp.businessSubtract(day)._d;
  return newDate;
};

/**
 *
 * @param {*} dateA  Passa a data maior
 * @param {*} dateB  Passa a data menor
 * O retorno sera a diferenca de dias
 */
export const difDate2 = (date1, date2) => {
  const dateA = moment(date1);
  dateA.hour(0);
  dateA.minute(0);
  dateA.second(0);
  dateA.millisecond(0);
  var dateB = moment(date2);
  dateB.hour(0);
  dateB.minute(0);
  dateB.second(0);
  dateB.millisecond(0);
  var dateDiff = moment(dateA).businessDiff(moment(dateB));
  return dateDiff;
};
/**
 *
 * @param {*} date1  Passa a data maior
 * @param {*} dateA  Passa a data menor
 * O retorno sera a diferenca de dias
 */
export const difDate = (date) => {
  var dateA = moment(date, "YYYY-MM-DD");
  dateA.hour(0);
  dateA.minute(0);
  dateA.second(0);
  dateA.millisecond(0);
  var dateB = moment();
  dateB.hour(0);
  dateB.minute(0);
  dateB.second(0);
  dateB.millisecond(0);
  var dateDiff = dateB.businessDiff(dateA);
  return dateDiff;
};
/**
 *
 * @param {*} date1  Passa a data maior
 * @param {*} dateA  Passa a data menor
 * O retorno sera a diferenca de dias
 */
export const difDateSignal = (endDate) => {
  const dateA = moment(endDate);
  dateA.hour(0);
  dateA.minute(0);
  dateA.second(0);
  dateA.millisecond(0);
  const dateB = moment();
  dateB.hour(0);
  dateB.minute(0);
  dateB.second(0);
  dateB.millisecond(0);
  const dateDiff = dateB.businessDiff(dateA);
  return dateA.isBefore(dateB) ? -dateDiff : dateDiff;
};
/**
 *
 * @param {*} date1  Passa a data maior
 * @param {*} dateA  Passa a data menor
 * O retorno sera a diferenca de dias
 */
export const difDateSignal2 = (startDate, endDate) => {
  const dateA = moment(endDate);
  dateA.hour(0);
  dateA.minute(0);
  dateA.second(0);
  dateA.millisecond(0);
  const dateB = moment(startDate);
  dateB.hour(0);
  dateB.minute(0);
  dateB.second(0);
  dateB.millisecond(0);
  const dateDiff = dateB.businessDiff(dateA);
  return dateA.isBefore(dateB) ? -dateDiff : dateDiff;
};
// teste
export const changeColorPendent = (diff) => {
  if (diff == 1 || diff > 4) {
    return "textPrimary";
  }
  if (diff == 2) {
    return "primary";
  }
  if (diff == 3 || diff == 4) {
    return "error";
  }
};

export const orderAscName = (a, b) => {
  var nameA = a.account.user.name.toLowerCase();
  var nameB = b.account.user.name.toLowerCase();
  if (nameA > nameB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  return 0;
};
export const orderDescName = (a, b) => {
  var nameA = a.account.user.name.toLowerCase();
  var nameB = b.account.user.name.toLowerCase();
  if (nameA > nameB) {
    return -1;
  }
  if (nameA < nameB) {
    return 1;
  }
  return 0;
};

export const orderAscDate = (a, b) => {
  var dateA = moment(new Date(a.authorizeDate));
  var dateB = moment(new Date(b.authorizeDate));

  if (moment(dateA).isBefore(dateB)) {
    return 1;
  } else {
    return -1;
  }
};
export const orderDescDate = (a, b) => {
  var dateA = moment(new Date(a.authorizeDate));
  var dateB = moment(new Date(b.authorizeDate));

  if (moment(dateA).isAfter(dateB)) {
    return 1;
  } else {
    return -1;
  }
};

export const formatTextToHTML = (text) => {
  text = text.replace(/\n/g, "<br>");
  return text;
};

// FORMATA O FORMULARIO PARA PARAMETROS GET
export const formatParamsGet = (formProps) => {
  let params = formProps
    ? "?" +
      Object.keys(formProps)
        .map(function (key) {
          if (Array.isArray(formProps[key])) {
            return key + "=" + formProps[key].join(",");
          } else if (formProps[key].trim().length > 0) {
            return key + "=" + formProps[key];
          }
          return null;
        })
        .join("&")
    : "";
  return params;
};

/*
 * Serve para verificar se um objeto esta vazio
 */
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
/*
 * Serve para verificar se um array esta vazio, se nem a posição 0 ele possui.
 */
export const ArrayisEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const resizeImageFile = (file, maxWidth, maxHeight, rotation, quality = 100) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

/**
 * Array que marca em amarelo o menu inferior correspondente a pagina acessada
 */
export const ListRoutes = {
  Admin: [
    { id: 0, path: "/dashboard" },
    { id: 1, path: "/historyWithdrawal" },
    { id: 1, path: "/withdraw" },
    { id: 5, path: "/contractReport" },
    { id: 1, path: "/contracts" },
    { id: 3, path: "/contract" },
    { id: 2, path: "/newInvestment" },
    { id: 2, path: "/investments" },
    { id: 5, path: "/reports" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/customerDocs" },
    { id: 2, path: "/customers" },
    { id: 2, path: "/customer" },
    { id: 1, path: "/newContract" },
    { id: 2, path: "/newContact" },
    { id: 2, path: "/newUser" },
    { id: 3, path: "/users" },
    { id: 3, path: "/user" },
    { id: 3, path: "/accountUser/" },
    { id: 4, path: "/history" },
    { id: 4, path: "/help" },
    { id: 3, path: "/authorize" },
  ],
  Agent: [
    { id: 3, path: "/authorize" },
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 1, path: "/contractAgent/" },
    { id: 1, path: "/contractDetail/" },
    { id: 1, path: "/contractHistory/" },
    { id: 1, path: "/contracts" },
    { id: 1, path: "/contract/" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/customerDocs" },
    { id: 2, path: "/customers" },
    { id: 2, path: "/customer/" },
    { id: 0, path: "/dashboard" },
    { id: 0, path: "/language" },
    { id: 1, path: "/editContract/" },
    { id: 3, path: "/history" },
    { id: 4, path: "/help" },
    { id: 0, path: "/more" },
    { id: 1, path: "/newContract" },
    { id: 2, path: "/userContract/" },
    { id: 3, path: "/withdraw" },
  ],
  AgentsManager: [
    { id: 0, path: "/authorize" },
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 0, path: "/customerDocs" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/dashboard" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 1, path: "/users" },
    { id: 1, path: "/user/" },
  ],
  Auditor: [
    { id: 0, path: "/authorize" },
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 2, path: "/contractReportJustOpened" },
    { id: 2, path: "/contractReportRedeemed" },
    { id: 2, path: "/contractReportTerminated" },
    { id: 2, path: "/contractReport" },
    { id: 0, path: "/customerDocs" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/dashboard" },
    { id: 1, path: "/historyWithdrawal" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 2, path: "/reports" },
    { id: 2, path: "/userReport" },
    { id: 3, path: "/users" },
    { id: 3, path: "/user/" },
  ],
  BusinessManager: [
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 1, path: "/contractReportJustOpened" },
    { id: 1, path: "/contractReportRedeemed" },
    { id: 1, path: "/contractReportTerminated" },
    { id: 1, path: "/contractReport" },
    { id: 0, path: "/customerDocs" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/dashboard" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 1, path: "/reports" },
    { id: 1, path: "/userReport" },
  ],
  Customer: [
    { id: 2, path: "/accountUser/" },
    { id: 3, path: "/authorize" },
    { id: 0, path: "/dashboard" },
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 1, path: "/contractHistory/" },
    { id: 1, path: "/contractChart/" },
    { id: 1, path: "/contractDetail/" },
    { id: 1, path: "/contracts" },
    { id: 1, path: "/contract/" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/customerDocs" },
    { id: 2, path: "/customers" },
    { id: 2, path: "/customer" },
    { id: 4, path: "/help" },
    { id: 3, path: "/history" },
    { id: 2, path: "/investments" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 1, path: "/newContract" },
    { id: 2, path: "/newContact" },
    { id: 2, path: "/newUser" },
    { id: 2, path: "/reaport" },
    { id: 3, path: "/users" },
    { id: 2, path: "/user" },
    { id: 3, path: "/withdraw" },
  ],
  FinanceManager: [
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 2, path: "/contractReportJustOpened" },
    { id: 2, path: "/contractReportRedeemed" },
    { id: 2, path: "/contractReportTerminated" },
    { id: 2, path: "/contractReport" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/customerDocs" },
    { id: 0, path: "/dashboard" },
    { id: 1, path: "/historyWithdrawal" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 2, path: "/reports" },
    { id: 2, path: "/userReport" },
  ],
  Kyc: [
    { id: 0, path: "/dashboard" },
    { id: 0, path: "/changePassword" },
    { id: 0, path: "/confirmEmailForm" },
    { id: 0, path: "/confirmEmail" },
    { id: 0, path: "/customerFormData" },
    { id: 0, path: "/customerDocs" },
    { id: 0, path: "/language" },
    { id: 0, path: "/more" },
    { id: 2, path: "/newUser" },
    { id: 1, path: "/users/" },
    { id: 1, path: "/users" },
    { id: 1, path: "/user" },
  ],
};
