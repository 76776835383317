import React from "react";

// export const Terms = () => (
//   <div>
//     <div>
//       <p class="c4">
//         <span class="c7"></span>
//       </p>
//     </div>
//     <p class="c2">
//       <span>Bem vindo à plataforma da </span>
//       <span class="c6">LUX CAPITAL, LLC</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         Antes de fazer uso dos SERVIÇOS oferecidos pela LUX CAPITAL, LLC, o USUÁRIO concorda em ler atentamente esse
//         TERMO, garantindo sua perfeita compreensão. Ao indicar a opção “Li e aceito” ao final, o USUÁRIO concorda com o
//         previsto neste TERMO, aceitando e vinculando-se a ele.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span>
//         LUX CAPITAL, LLC, localizada na 1309 Coffeen Avenue Suite 1816, Sheridan, Wyoming, 82801, United States, é
//         detentora de todos os direitos sobre a Plataforma por meio da qual serão prestados os serviços na área de
//         tecnologia, arbitragem e trade de criptoativos, hospedada sob o domínio{" "}
//       </span>
//       <span class="c8">
//         <a
//           class="c5"
//           href="https://www.google.com/url?q=https://app.lctechnologybank.com&amp;sa=D&amp;ust=1595520582302000&amp;usg=AOvVaw2YoiWz_3Z4w5tZCjNfECGT"
//         >
//           https://app.lctechnologybank.com
//         </a>
//       </span>
//       <span class="c1">&nbsp; sendo o acesso através de navegador de internet ou aplicativo através de download.</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         É vedada a utilização dos serviços oferecidos pela LUX CAPITAL, LLC aos que possuem domicílio fiscal nos
//         seguintes países Estados Unidos da América, Síria, Coreia do Norte, Irã, Iraque e China, podendo, ainda, serem
//         incluídas outras restrições sem prévio aviso. De igual forma, vedado o uso da plataforma por menores de 18 anos
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">1. ACEITE</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         1.1. O USUÁRIO, ao aceitar este TERMO, concorda que este poderá sofrer alterações ao longo do tempo, sendo de
//         sua inteira responsabilidade a verificação periódica do mesmo, principalmente antes da utilização dos SERVIÇOS,
//         e também concorda com a POLÍTICA DE PRIVACIDADE. Se a LUX CAPITAL, LLC informar ao USUÁRIO sobre alterações
//         significativas realizadas no TERMO, o fará por mera liberalidade.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         1.2. Caso tenha alguma objeção a qualquer disposição deste TERMO, inclusive posterior a seu aceite, o USUÁRIO
//         deverá cessar imediatamente uso da Plataforma e de seus SERVIÇOS.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         1.3. Para utilizar os SERVIÇOS disponíveis na plataforma da LUX CAPITAL, LLC, o USUÁRIO deve ter conhecimento
//         sobre o mercado tecnológico e de criptoativos, considerando o risco inerente. O valor dos criptoativos pode
//         variar no tempo e local e o USUÁRIO tem conhecimento dessa circunstância, aceitando-a por sua conta e risco, de
//         forma consciente e informada.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         1.3.1 Aceitando o presente TERMO, o USUÁRIO está ciente de que os criptoativos apresentam grande volatilidade em
//         relação ao seu preço, existindo a possibilidade de perdas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">2. DA PLATAFORMA LUX CAPITAL, LLC.</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.1. Para utilizar as funcionalidades da Plataforma da LUX CAPITAL, LLC, o USUÁRIO deverá realizar seu cadastro
//         e, em sendo pessoa física deverá ter maioridade civil no país de seu domicílio fiscal e possuir 18 anos
//         completos, ou no caso de pessoa jurídica, deve ser realizado pelo representante legal, devidamente constituído.
//         O USUÁRIO deve aceitar os TERMOS e nossa Política de Privacidade.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.1.1 Caso a LUX CAPITAL, LLC verifique qualquer irregularidade com o cadastro do USUÁRIO, poderá, a seu
//         critério e a qualquer tempo, bloqueá-lo até que todas as irregularidades estejam sanadas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.1.2 O cadastro está disponível para os países no qual a LUX CAPITAL, LLC disponibiliza seus serviços, podendo,
//         a qualquer tempo, alterar as condições de elegibilidade destes.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.1.3 Não poderão utilizar os serviços da LUX CAPITAL, LLC, os residentes nos Estados Unidos da América, Síria,
//         Coreia do Norte, Irã, Iraque e China.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.1.4 A LUX CAPITAL, LLC, se resguarda ao direito de alterar o item 2.1.3, sem necessidade de
//         comunicação/informação a qualquer USUÁRIO, salvo aqueles cujo domicílio fiscal for um dos países listados.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.2 O cadastro será realizado através do preenchimento do formulário on-line disponível na página de cadastro da
//         plataforma, informando os seguintes dados: nome completo em sendo pessoa física ou, nome empresarial sendo
//         pessoa jurídica, data de nascimento da pessoa física, país de domicílio fiscal, e-mail e uma senha.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.3. Após o cadastro efetivado, o USUÁRIO possuirá uma Conta de Acesso na plataforma e ficará PENDENTE para uso
//         dos SERVIÇOS disponíveis. Para estar HABILITADO, o USUÁRIO deverá fornecer as seguintes informações adicionais e
//         cópias digitais dos documentos:
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.3.1. Pessoa Física: Informação adicional &nbsp;de contato, documento de identificação com foto ou documento
//         equivalente, comprovante de endereço, selfie com o documento de identificação fornecido e folha com o texto "LUX
//         CAPITAL" a data.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.3.2. Pessoa Jurídica: Informação adicional de contato, documento de identificação com foto ou documento
//         equivalente do representante legal, comprovante de endereço da sede da empresa, contrato social, selfie com
//         documento de identificação fornecido e folha com o texto "LUX CAPITAL" a data.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.4. Os USUÁRIOS ficarão na situação de PENDENTE até o efetiva validação e comprovação de autenticidade dos
//         documentos enviados, podendo ser requerido o reenvio ou complementação em caso de informação faltantes ou
//         ilegíveis.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.5 Para a realização do cadastro, o USUÁRIO deverá informar dados verdadeiros, os quais serão de sua exclusiva
//         responsabilidade. A LUX CAPITAL, LLC não se responsabiliza por dados falsos inseridos no cadastro.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.6 O fornecimento de informação falsa ou a utilização indevida de dados de terceiros em nome próprio constitui
//         crime. Em qualquer desses casos, a LUX CAPITAL, LLC poderá suspender ou cancelar definitivamente o acesso do
//         USUÁRIO a todos os serviços e funcionalidades da plataforma, sem prejuízo das demais medidas que lhe sejam
//         asseguradas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.7 A LUX CAPITAL, LLC poderá, a seu exclusivo critério, requisitar documentos e informações adicionais para
//         confirmar ou manter o status de USUÁRIO HABILITADO. Caso a LUX CAPITAL, LLC decida exercer essa faculdade em
//         relação a qualquer USUÁRIO, a Conta de Acesso poderá ser suspensa ou definitivamente cancelada caso o USUÁRIO se
//         recuse a prestar as informações adicionais ou a enviar os documentos requeridos.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.8 Cada USUÁRIO poderá manter apenas uma única conta de acesso na plataforma. Caso seja constatada a existência
//         de mais de uma conta relativa a uma mesma pessoa física ou jurídica, uma ou todas essas contas poderão ser
//         suspensas ou canceladas, a critério da LUX CAPITAL, LLC
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.9 A LUX CAPITAL, LLC se reserva ao direito de recusar qualquer solicitação de cadastro, bem como de suspender
//         ou cancelar uma conta de acesso nos casos de: (I) de violação de qualquer das disposições destes Termos de Uso,
//         (II) impossibilidade de verificação da identidade do USUÁRIO ou constatação de falsidade em qualquer das
//         informações por ele fornecidas, (III) prática pelo USUÁRIO de atos fraudulentos ou dolosos ou a adoção de
//         qualquer comportamento que, a critério da LUX CAPITAL, LLC, seja incompatível com os objetivos da Plataforma,
//         com urbanidade com outros USUÁRIOS ou que possam, de qualquer modo, causar danos a terceiros ou à própria
//         plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.10 A LUX CAPITAL, LLC poderá alterar os critérios de elegibilidade de acesso e utilização da Plataforma a
//         qualquer momento, sem que para isso tenha de fazer qualquer tipo de comunicação ou aviso prévio aos USUÁRIOS.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.11 A LUX CAPITAL, LLC manterá os Dados Pessoais, através de seu sistema automatizado, para os usos e
//         finalidades definidas na sua POLÍTICA DE PRIVACIDADE, acessível através de link encontrado na plataforma. Ao
//         acessar a Plataforma, o USUÁRIO aceita qualquer alteração na Política de Privacidade e concorda com o tratamento
//         dos seus Dados Pessoais.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.12 A LUX CAPITAL, LLC é detentora de todos os direitos sobre a Plataforma, podendo atualizar sempre que
//         desejar, ou for necessário, bem como deixar de disponibilizá-la ou mesmo descontinuá-la ao longo do tempo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.13 Em caso de descontinuidade da plataforma, a LUX CAPITAL, LLC informará os USUÁRIOS com antecedência.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         2.14 Toda e qualquer quantidade de criptoativos transferidos à LUX CAPITAL, LLC poderá ser resgatada a qualquer
//         tempo, observando aos prazos e prerrogativas de cada modalidade de custódia.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">3. RESPONSABILIDADES E OBRIGAÇÕES DOS USUÁRIOS</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">3.1. Os USUÁRIOS são responsáveis e se obrigam a:</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.1.1. Utilizar com retidão e de forma ética a Plataforma, respeitando as condições que regem a sua utilização e
//         finalidade.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.1.2. Fornecer dados cadastrais corretos, completos e atualizados, além de informar canal de contato apto a ser
//         acionado pela empresa para o melhor cumprimento dos serviços. Se a qualquer momento for constatado que o USUÁRIO
//         forneceu dados falsos ou não condizentes com a realidade, a LUX CAPITAL, LLC se reserva ao direito de suspender
//         ou cancelar sua Conta de Acesso, sem prejuízo de adotar as medidas que entender cabíveis.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.1.3. Dispor de dispositivos e equipamentos compatíveis, serviço de conexão à Internet com antivírus, firewall
//         habilitados e softwares devidamente atualizados.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.1.4. Manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-la de modo individual e
//         intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de
//         autenticação com quem quer que seja sob pena de ter de arcar com os prejuízos decorrentes de eventual utilização
//         incorreta das informações e recursos contidos na Conta de Acesso.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.2 Não é permitido ao USUÁRIO efetuar cadastro de sua senha em aplicações de terceiros para o fim de realizar
//         consultas automáticas em sua Conta de Acesso da Plataforma. Caso suspeite que o sigilo da sua senha tenha sido
//         comprometido, o USUÁRIO deverá proceder sua troca o mais rápido possível e contatar os canais de atendimento
//         disponíveis na Plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.3 Adotar senha forte. Não utilizar sequências óbvia de números ou de fácil dedução. Em adição, o USUÁRIO não
//         deve reutilizar sua senha em aplicativos, portais e sites de terceiros.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.4 Adotar medidas em seus dispositivos tecnológicos para evitar o acesso físico ou lógico por terceiros não
//         autorizados, tais como utilização de senha e/ou biometria.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.5 Reconhecer que todos os acessos e operações realizadas após sua autenticação digital bem-sucedida são
//         interpretados como sendo de sua responsabilidade de forma incontestável, inclusive aqueles derivados de uso
//         indevido ou divulgação desta para terceiros.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.6 Deixar seus sistemas de anti-spam, filtros similares ou configurações de redirecionamento de mensagens
//         ajustados de modo que não interfiram no recebimento dos comunicados e materiais da Plataforma, não sendo
//         aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos
//         recursos mencionados.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.7 Respeitar todos os direitos de propriedade intelectual de titularidade da LUX CAPITAL, LLC, incluindo todos
//         os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma ali disponível. Da
//         mesma forma, os USUÁRIOS somente poderão reproduzir os conteúdos disponíveis na Plataforma, em especial suas
//         marcas e layout do ambiente, caso tenham sido expressamente autorizados pela empresa neste sentido.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.8 Não acessar áreas de programação da Plataforma, seu banco de dados, códigos fonte ou qualquer outro conjunto
//         de dados disponíveis nestes ambientes.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.9 Não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir,
//         alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor
//         inapropriadamente das funcionalidades da Plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.10 Não utilizar softwares spider ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não
//         tipificado que atue de modo similar.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.11 As funcionalidades que compõem a Plataforma são oferecidas para uso dos serviços disponibilizados, não
//         conferindo ao USUÁRIO nenhum direito sobre o software utilizado pela LUX CAPITAL, LLC ou sobre suas estruturas
//         de informática que sustentam a Plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.12 A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da Plataforma em
//         decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de
//         solução amigável de conflitos, jamais, como reconhecimento de culpa ou de qualquer infração pela LUX CAPITAL,
//         LLC a direito de terceiro.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.13 Na incidência de danos a LUX CAPITAL, LLC ou a terceiros, o responsável se compromete a arcar com todas as
//         obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de sua
//         Conta de Acesso, assumindo o pólo passivo de ação judicial ou procedimento administrativo e requerendo a
//         exclusão da LUX CAPITAL, LLC, devendo arcar totalmente com as despesas e custas processuais atinentes,
//         deixando-a livre de prejuízos e ônus.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.14 O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na suspensão total ou
//         parcial das funcionalidades, ou exclusão da Conta de Acesso, sem aviso prévio, conforme previsto neste Termo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.15 Os USUÁRIOS reconhecem que a plataforma LUX CAPITAL, LLC faz custódia apenas do criptoativo BRZ e que
//         qualquer outro criptoativo transferido a ela, pelos meios que ela permitir, serão imediatamente convertidos para
//         do criptoativo BRZ &nbsp;pela cotação do momento do recebimento.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.15.1 Fica estabelecido, desde já, que a custodiante somente receberá e enviará a seus USUÁRIOS &nbsp;o
//         criptoativo BITCOIN.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.15.2 Os USUÁRIOS entendem ainda que os criptoativos BRZs custodiados pela LUX CAPITAL, LLC não são títulos
//         emitidos ou chancelados por governo, autoridade central, autoridade monetária ou instituições financeiras.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.16 Os USUÁRIOS procederão de acordo com os preceitos éticos e legais previstos na legislação de Lavagem de
//         Dinheiro, a Evasão de Divisas e Antiterrorismo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         3.16.1 O USUÁRIO que desrespeitar o elencado acima e vier a ser responsabilizado pelas autoridades, arcará
//         sozinho com as penalidades decorrentes de ato praticado.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">4. DAS MODALIDADES DE CUSTÓDIA</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         &nbsp;4.1 Os USUÁRIOS da Plataforma da LUX CAPITAL, LLC terão variadas modalidades de custódias disponíveis para
//         contratação. Os LCContracts, assim chamados, serão disponibilizados com duração diferenciada, cujo aceite da
//         opção escolhida se dará no momento da contratação de cada LCContract.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.1 Cada USUÁRIO poderá ter inúmeros LCContracts, não havendo limitação de contratação destes, sendo que cada
//         um deles terá prazos, condições e modalidades de custódia distintas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.2 As modalidades dos LCContracts serão disponibilizados pela Plataforma e poderão, a qualquer tempo, não
//         estarem mais disponíveis para novas contratações.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">4.1.3 As modalidades descontinuadas não atingirão os LCContracts em vigência.</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.4 Nos LCContracts na modalidade ABERTA, será permitido ao USUÁRIO efetuar 01 (um) saque mensal do acréscimo
//         de volume de criptoativos inseridos na Plataforma, com isenção de qualquer tipo de taxa. Em cada saque excedente
//         será cobrada taxa de 3% (três por cento) sobre o volume de criptoativos solicitado.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.5 O acréscimo de volume de criptoativos será alocado diariamente no extrato da conta do USUÁRIO, ficando
//         bloqueado até o próximo DIA DE CRÉDITO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.6 O DIA DE CRÉDITO do USUÁRIO é atribuído a ele no momento da ativação de sua conta de acesso, sendo que o
//         USUÁRIO deverá escolher o dia de vencimento mensal de seus LCContracts.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.1.7 O saque fica liberado no primeiro dia útil subsequente ao DIA DE CRÉDITO, sendo facultado ao USUÁRIO o
//         saque de seu volume de criptoativos a qualquer momento.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         &nbsp;4.2 Nos LCContracts na modalidade FECHADA, os USUÁRIOS só poderão sacar a quantidade de criptoativos
//         custodiados pela LUX CAPITAL, LLC, ao final de seu prazo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.2.1 O acréscimo de volume de criptoativos será alocado diariamente no extrato da conta do USUÁRIO, contudo
//         ficará bloqueado até o término da vigência do LCContract.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.2.2 Ao término da vigência do LCContract, o volume total de criptoativos em custódia com a LUX CAPITAL, LLC
//         será disponibilizado ao USUÁRIO solicitar o saque a qualquer momento, conforme definido neste TERMO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.3 A LUX CAPITAL, LLC poderá a seu critério, independentemente de aviso prévio, rescindir qualquer LCContract
//         ativo que o USUÁRIO possua, se sujeitando às práticas estabelecidas em cada contratação.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         4.3.1 Caso ocorra a rescisão acima referida, o total de criptoativos do USUÁRIO ficará disponível para saque na
//         plataforma do USUÁRIO, nos prazos constantes neste TERMO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">5. DA UTILIZAÇÃO DA PLATAFORMA LUX CAPITAL, LLC</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.1. O USUÁRIO está ciente de que a LUX CAPITAL, LLC atua no desenvolvimento de tecnologia, arbitragem e trade
//         de criptoativos, cuja operação é de risco inerente aos serviços e que não se tem como garantir qualquer tipo de
//         aumento de volume de ativos do USUÁRIO, não obstante, apresenta estatística de variação passada na plataforma
//         para fins de mera informação do USUÁRIO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2. Estando com o cadastro validado e o USUÁRIO na condição de HABILITADO, encontra-se apto a contratar os
//         LCContracts disponíveis na plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2.1. Após a escolha da modalidade de custódia, deverá o USUÁRIO aceitar os termos do LCContract para efetuar a
//         validação dos SERVIÇOS a serem prestados e transferir no mínimo 0,25 BITCOINS para a carteira digital de
//         custódia da LUX CAPITAL, LLC, para validação dos serviços.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2.1.1 O USUÁRIO poderá ter inúmeros LCContracts, contudo o volume mínimo de criptoativos somados deverá ser no
//         mínimo 0,25 BITCOINS.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2.2 O LCContract escolhido pelo USUÁRIO, será ativado em até dois dias úteis (D+2) após a transferência dos
//         criptoativos para a carteira digital da LUX CAPITAL, LLC. Após ativação do LCContract, o USUÁRIO estará na
//         condição de ATIVO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2.3 Esta transferência poderá ser realizada a partir de de carteira externa ou proveniente da utilização de
//         seu saldo constante no extrato.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.2.4 Para contratação de novo LCContract quando houver saldo disponível constante no extrato, o poderá ser
//         feito independentemente do volume ali constante. Caso a soma dos LCContracts &nbsp;supere o volume de 0,25
//         BITCOINS.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.3. Os resultados obtidos nas operações de desenvolvimento de tecnologia, arbitragem e trade de criptoativos,
//         serão computados e alocados diariamente na conta do USUÁRIO na plataforma e o resultado pode ser verificado como
//         saldo a liberar no extrato.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.4 As transferências de criptoativos para a LUX CAPITAL, LLC somente serão aceitos se forem realizadas em
//         BITCOIN, não sendo aceito qualquer outro criptoativo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.5 Os BITCOINS transferidos para a LUX CAPITAL, LLC serão, na data do recebimento, convertidos pela cotação do
//         momento, &nbsp;no criptoavito &nbsp;BRZ &nbsp;pela própria plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.6 O volume de criptoativos custodiados pela LUX CAPITAL, LLC, permanecerá sob sua custódia durante toda
//         vigência do LCContract, não podendo ser resgatado antes do prazo de vigência.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.6.1 Em caso excepcional de resgate antecipado do volume custodiado pela LUX CAPITAL, LLC, o USUÁRIO deve
//         solicitar o cancelamento do LCContract de acordo com o disposto neste TERMO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.7 Quando o USUÁRIO desejar realizar o RESGATE de seu volume de criptoativos, este o fará por sua livre
//         iniciativa na plataforma da LUX CAPITAL, LLC, considerando os critérios estabelecidos nos LCContracts
//         contratados.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         5.8 Após a solicitação de resgate, o USUÁRIO deverá, a seu critério, solicitar o SAQUE na Plataforma, que será
//         realizado no prazo máximo de quatro dias úteis (D+4), devendo ser levado em consideração também os prazos de
//         transações da Blockchain, o que pode aumentar esse prazo, sem responsabilidade da LUX CAPITAL, LLC{" "}
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         &nbsp; &nbsp; &nbsp; &nbsp; 5.8.1 As transferências relativas a saques serão realizadas somente em BITCOIN, cuja
//         cotação será calculada no momento de sua realização.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">6. ENCARGOS OPERACIONAIS</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         6.1. A LUX CAPITAL, LLC não cobra taxas de depósito, custódia ou saques na plataforma, este último quando não
//         exceder a um saque mensal.{" "}
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         6.2. O &nbsp;resultado que será repassado ao USUÁRIO observará o limite previsto em cada LCContract.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         6.2.1 Caso não atinja o limite do LCContract &nbsp;contratado, serão abatidos apenas os custos de operação dos
//         serviços. Outrossim, em superando o limite constante no LCContract, o excedente será revertido a LUX CAPITAL,
//         LLC a título de remuneração pelos serviços prestados, cabendo ao USUÁRIO o limite estabelecido em cada
//         LCContract.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">7. DA VIGÊNCIA E RENOVAÇÃO</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.1 Este termo terá vigência indeterminada, sendo que todas as alterações e aditivos, ficam vinculados ao
//         aceite.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.2 A LUX CAPITAL, LLC permanecerá na custódia dos criptoativos transferidos pelo USUÁRIO, o qual poderá
//         solicitar resgate a qualquer momento, desde que observadas as seguintes disposições:
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.2.1 Na modalidade aberta, será disponibilizado ao USUÁRIO o saque mensal de seu aumento de volume de
//         criptoativos;
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.2.2 Na modalidade fechada, não será disponibilizado ao USUÁRIO o saque mensal de seu aumento de volume de
//         criptoativos.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.3 No caso da solicitação ocorrer antes do término da vigência do LCContract, haverá incidência de multa a
//         título de quebra contratual, conforme os casos a seguir:
//       </span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.3.1 Do início até a metade da prazo do LCContract, será cobrado o percentual de 50% sobre o volume custodiado.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.3.2 Da metade até o final da prazo do LCContract, o percentual da seção 7.3.1 É reduzido proporcionalmente ao
//         tempo restante.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.4 Após completar a vigência do LCContract, o mesmo ficará inativo, cessando assim as operações.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.4.1 Estando o LCContract inativo é permitido ao USUÁRIO, a qualquer tempo, solicitar o resgate integral de
//         seus criptoativos.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.4.2 É de inteira responsabilidade do USUÁRIO, a verificação da vigência de seu LCContract, seu prazo, resgate
//         e saque.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         7.5 Quando da solicitação de RESGATE, o USUÁRIO receberá seu volume de criptoativos em sua conta de USUÁRIO,
//         ficando disponível para saque e/ou contrato de novo LCContract em D+4 (dias úteis).
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">8. DA RESCISÃO OU DESISTÊNCIA DO TERMO DO USUÁRIO</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         8.1. Caso não haja mais interesse pela continuação do presente Termo por parte da LUX CAPITAL, LLC, a mesma deve
//         comunicar ao USUÁRIO com antecedência, sem necessidade de justificativa, pagamento de multa ou taxa, sendo que
//         serão disponibilizados para saque a quantidade de volume de criptoativos existentes em LCContracts ativos na
//         plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         8.2 Não havendo interesse na continuidade do presente Termo por parte do USUÁRIO, o mesmo deverá solicitar o
//         resgate e saque integral do volume de criptoativos existentes em LCContracts ativos na plataforma da LUX
//         CAPITAL, LLC, sem necessidade de justificativa, sujeitando-se as cláusulas penais existentes neste termo e em
//         cada LCContract.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">9. ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.1. A Plataforma e suas funcionalidades são apresentadas aos USUÁRIOS na maneira como estão disponíveis,
//         podendo passar por constantes aprimoramentos e atualizações, obrigando-se a LUX CAPITAL, LLC &nbsp;a:
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         a) preservar a sua funcionalidade, com links não quebrados e utilizando layout que respeita a usabilidade e
//         navegabilidade, sempre que possível;
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         b) exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata
//         percepção das operações realizadas; e
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         c) proteger, por meio da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.2. A LUX CAPITAL, LLC envida seus esforços para a manutenção da disponibilidade contínua e permanente da
//         Plataforma. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de
//         manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de
//         comunicação e acesso à Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.2.1. Se isso ocorrer, a LUX CAPITAL, LLC fará o que estiver ao seu alcance para restabelecer o acesso à
//         Plataforma o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiros, dos
//         quais depende para estar online.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.2.2. Eventuais procedimentos de manutenção que acarretem a indisponibilidade da Plataforma por longos períodos
//         serão informados por meio dos canais oficiais de comunicação, como e-mails, perfis oficiais, telefone de
//         atendimento, dentre outros.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         8.3. O USUÁRIO não possui qualquer direito para exigir a disponibilidade da plataforma da LUX CAPITAL, LLC
//         tampouco poderá pleitear indenização ou reparação de danos no caso da Plataforma permanecer fora do ar,
//         independente da motivação.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.3.1. No caso de indisponibilidade da Plataforma o USUÁRIO poderá entrar em contato com o canal oficial de
//         comunicação indicados neste Termo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">9.4. A LUX CAPITAL, LLC não se responsabiliza:</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.1. Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos que ocorrerem nos dispositivos e
//         equipamentos dos USUÁRIOS e sejam resultantes direta ou indiretamente do uso regular da Plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.2. Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando
//         a ataque de hackers em computadores dos USUÁRIOS, falhas no sistema, no servidor ou na conexão à Internet,
//         inclusive por ações de softwares que possam de algum modo, danificar ativos físicos ou lógicos ou a conexão dos
//         USUÁRIOS em decorrência do acesso, utilização ou navegação na Plataforma, bem como a transferência de dados,
//         arquivos, imagens, textos, áudios ou vídeos contidos neste.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.3. Pela navegação dos USUÁRIOS nos links externos disponibilizados na Plataforma, sendo seus deveres a
//         leitura dos Termos e Condições de Uso e Política de Privacidade do site acessado e agir conforme o determinado.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.4. &nbsp;Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados
//         disponibilizados em tais links, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela
//         visita de tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos
//         antes de tomar alguma decisão ou praticar algum ato.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.5 Toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas
//         causas possam ser atribuídas, de forma direta ou indireta, ao fornecimento, utilização ou desempenho da
//         plataforma;
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">9.4.6 Eventos da natureza ou de força-maior;</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">9.4.7 Uso perigoso e/ou inadequado da plataforma;</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         9.4.8 Fatores de ordem técnica que impossibilitem a transferência de dados e/ou o download de informações da
//         plataforma, neste incluído problemas de internet,
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">9.4.9 Atos de má fé do usuário.</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">10. DOS ENCARGOS E IMPOSTOS</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         10.1. As partes convencionam que caberá ao USUÁRIO a responsabilidade exclusiva pela informação, preenchimento
//         tributário e o recolhimento de todos os tributos incidentes sobre os criptoativos que possuir, de acordo com seu
//         domicílio fiscal.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         10.1.1 A LUX CAPITAL, LLC se exime de quaisquer tributações, multas ou qualquer tipo de imposição do poder
//         público, sobre a não declaração/informação da quantidade de criptoativos que o USUÁRIO possui, consoante a norma
//         de domicílio fiscal do USUÁRIO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">11. DA PRIVACIDADE</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         11.1. A LUX CAPITAL, LLC possui documento próprio, denominado Política de Privacidade, que regula o tratamento
//         dos dados coletados na Plataforma, sendo parte integrante e inseparável dos presentes Termos e Condições de Uso
//         e pode ser acessada pelo link encontrado na plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         11.2. Caso alguma disposição da Política de Privacidade conflite com qualquer outra do presente documento,
//         deverá prevalecer o descrito na norma mais específica.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">12. SUPORTE TÉCNICO </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         12.1. A prestação do serviço de suporte técnico se limita a esclarecimentos acerca do funcionamento da
//         plataforma da LUX CAPITAL, LLC, pressupondo o conhecimento por parte do USUÁRIO do uso do computador e
//         dispositivo móvel e suas funções, bem como do sistema operacional sob o qual o sistema será utilizado.
//         Pressupõe-se ainda a configuração adequada do computador e seu bom estado de funcionamento.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         12.2. O suporte técnico limita-se ao atendimento ao USUÁRIO nos canais de atendimento mencionados na plataforma,
//         sendo que a LUX CAPITAL, LLC se compromete a fornecer uma resposta em até 24 (vinte e quatro) horas úteis,
//         exceto em situações excepcionais, contadas do chamado realizado pelo USUÁRIO. &nbsp;
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         12.3. O Suporte Técnico fornecido pela LUX CAPITAL, LLC não abrange a conexão com a internet, rede interna e
//         computadores/dispositivos móveis do USUÁRIO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         12.4. O Suporte da LUX CAPITAL, LLC também não inclui assessoria ou consultoria financeira, econômica ou
//         tributária, sendo que o USUÁRIO será totalmente responsável pelas decisões que tomar para uso da plataforma.
//         &nbsp;
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">13. PROPRIEDADE INTELECTUAL </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         13.1. Todos os direitos de propriedade intelectual como marca, nome empresarial ou nome de domínio, bem como os
//         logos, marcas, insígnias, conteúdo das telas relativas às funcionalidades da Plataforma e o conjunto de
//         programas, bancos de dados, redes e arquivos que permitem que o USUÁRIO acesse e use sua conta referente a LUX
//         CAPITAL, LLC pertencem à empresa, sendo concedido ao USUÁRIO apenas o direito de uso da funcionalidade nela
//         disponibilizado. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         13.2. A Plataforma poderá ser aperfeiçoada, podendo inclusive ocorrer suspensão temporária do serviço em virtude
//         disso, O USUÁRIO desde já, concorda com modificações, implementação de novos recursos, ferramentas, melhorias ou
//         correções na plataforma.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">14. DISPOSIÇÕES GERAIS</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.1. Ao navegar pela Plataforma e utilizar suas funcionalidades, o USUÁRIO aceita guiar-se pelos Termos e
//         Condições de Uso e pela Política de Privacidade que se encontram vigentes na data de acesso. Por isso, é
//         recomendável que o USUÁRIO se mantenha atualizado.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.2 A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não
//         constituirá novação das obrigações aqui estipuladas e, tampouco, impedirá ou inibirá a exigibilidade das mesmas
//         a qualquer tempo.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.3 Caso alguma disposição destes Termos e Condições de Uso ou da Política de Privacidade publicadas na
//         Plataforma seja julgada inaplicável ou sem efeito, o restante de ambos os documentos continuam a viger, sem a
//         necessidade de medida judicial que declare tal assertiva.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.4 O USUÁRIO reconhece que toda comunicação realizada pela LUX CAPITAL, LLC ao e-mail do USUÁRIO cadastrado na
//         plataforma, é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos serviços
//         objeto deste contrato, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado,
//         ressalvadas as disposições expressamente diversas previstas nestes Termos e Condições de Uso.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.5 O USUÁRIO tem o direito de, a qualquer momento, solicitar o encerramento da sua conta na LUX CAPITAL, LLC{" "}
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.6 O encerramento da conta do USUÁRIO só será possível se não houver registro de fundos, isto é, se não
//         houver, no momento do cancelamento, quaisquer valores do criptoativo BRZ &nbsp;e/ou LCContracts em aberto,
//         cabendo ao USUÁRIO a realização do saque.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.8 Após o encerramento da conta do USUÁRIO, realizado por ele mesmo ou pela LUX CAPITAL, LLC o USUÁRIO
//         reconhece que poderá não mais ter acesso e/ou resgatar informações sobre transações realizadas através da
//         plataforma, não tendo a LUX CAPITAL, LLC qualquer dever de armazenamento de informações, tampouco de repassar
//         essas informações ao USUÁRIO.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.9 Em caso de suspeita de fraude ou qualquer outra atividade ilícita, a LUX CAPITAL, LLC poderá, além de
//         recorrer às medidas legais cabíveis, no momento efetivo da rescisão, reter eventuais fundos armazenados, até a
//         conclusão das respectivas investigações.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.10 Mesmo em caso de encerramento, todas as taxas pagas e cobranças feitas antes do encerramento não são
//         reembolsáveis.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.11 O encerramento do cadastro não afetará direito e obrigação anteriores. As obrigações de quaisquer das
//         partes contraídas continuarão em pleno vigor e efeito até que sejam sanadas.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.12 A incapacidade ou a mera liberalidade em não fazer cumprir quaisquer disposições deste TERMO não constitui
//         renúncia de direitos, podendo ser exigido o seu cumprimento em qualquer momento.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">
//         14.13 Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação da sede da LUX CAPITAL,
//         LLC, para dirimir qualquer litígio ou controvérsia envolvendo o presente documento.
//       </span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <p class="c2">
//       <span class="c1">Texto atualizado em 23/07/2020</span>
//     </p>
//     <p class="c0">
//       <span class="c1"></span>
//     </p>
//     <div>
//       <p class="c4">
//         <span class="c9"></span>
//       </p>
//     </div>
//   </div>
// );

export const country = [
  //  { value: "África do Sul", input: "África do Sul" },
  //  { value: "Albânia", input: "Albânia" },
  //  { value: "Alemanha", input: "Alemanha" },
  //  { value: "Andorra", input: "Andorra" },
  //  { value: "Angola", input: "Angola" },
  //  { value: "Anguilla", input: "Anguilla" },
  //  { value: "Antigua", input: "Antigua" },
  //  { value: "Arábia Saudita", input: "Arábia Saudita" },
  { value: "Argentina", input: "Argentina" },
  //  { value: "Armênia", input: "Armênia" },
  //  { value: "Aruba", input: "Aruba" },
  { value: "Austrália", input: "Austrália" },
  //  { value: "Áustria", input: "Áustria" },
  //  { value: "Azerbaijão", input: "Azerbaijão" },
  //  { value: "Bahamas", input: "Bahamas" },
  //  { value: "Bahrein", input: "Bahrein" },
  //  { value: "Bangladesh", input: "Bangladesh" },
  //  { value: "Barbados", input: "Barbados" },
  //  { value: "Bélgica", input: "Bélgica" },
  //  { value: "Benin", input: "Benin" },
  //  { value: "Bermudas", input: "Bermudas" },
  //  { value: "Botsuana", input: "Botsuana" },
  { value: "Brasil", input: "Brasil" },
  //  { value: "Brunei", input: "Brunei" },
  //  { value: "Bulgária", input: "Bulgária" },
  //  { value: "Burkina Fasso", input: "Burkina Fasso" },
  //  { value: "botão", input: "botão" },
  //  { value: "Cabo Verde", input: "Cabo Verde" },
  //  { value: "Camarões", input: "Camarões" },
  //  { value: "Camboja", input: "Camboja" },
  //  { value: "Canadá", input: "Canadá" },
  //  { value: "Cazaquistão", input: "Cazaquistão" },
  //  { value: "Chade", input: "Chade" },
  //  { value: "Chile", input: "Chile" },
  //  { value: "China", input: "China" },
  //  { value: "Cidade do Vaticano", input: "Cidade do Vaticano" },
  { value: "Colômbia", input: "Colômbia" },
  //  { value: "Congo", input: "Congo" },
  //  { value: "Coréia do Sul", input: "Coréia do Sul" },
  //  { value: "Costa do Marfim", input: "Costa do Marfim" },
  //  { value: "Costa Rica", input: "Costa Rica" },
  //  { value: "Croácia", input: "Croácia" },
  //  { value: "Dinamarca", input: "Dinamarca" },
  //  { value: "Djibuti", input: "Djibuti" },
  //  { value: "Dominica", input: "Dominica" },
  //  { value: "EUA", input: "EUA" },
  //  { value: "Egito", input: "Egito" },
  //  { value: "El Salvador", input: "El Salvador" },
  //  { value: "Emirados Árabes", input: "Emirados Árabes" },
  //  { value: "Equador", input: "Equador" },
  //  { value: "Eritréia", input: "Eritréia" },
  //  { value: "Escócia", input: "Escócia" },
  //  { value: "Eslováquia", input: "Eslováquia" },
  //  { value: "Eslovênia", input: "Eslovênia" },
  //  { value: "Espanha", input: "Espanha" },
  //  { value: "Estônia", input: "Estônia" },
  //  { value: "Etiópia", input: "Etiópia" },
  //  { value: "Fiji", input: "Fiji" },
  //  { value: "Filipinas", input: "Filipinas" },
  //  { value: "Finlândia", input: "Finlândia" },
  //  { value: "França", input: "França" },
  //  { value: "Gabão", input: "Gabão" },
  //  { value: "Gâmbia", input: "Gâmbia" },
  //  { value: "Gana", input: "Gana" },
  //  { value: "Geórgia", input: "Geórgia" },
  //  { value: "Gibraltar", input: "Gibraltar" },
  //  { value: "Granada", input: "Granada" },
  //  { value: "Grécia", input: "Grécia" },
  //  { value: "Guadalupe", input: "Guadalupe" },
  //  { value: "Guam", input: "Guam" },
  //  { value: "Guatemala", input: "Guatemala" },
  //  { value: "Guiana", input: "Guiana" },
  //  { value: "Guiana Francesa", input: "Guiana Francesa" },
  //  { value: "Guiné-bissau", input: "Guiné-bissau" },
  //  { value: "Haiti", input: "Haiti" },
  //  { value: "Holanda", input: "Holanda" },
  //  { value: "Honduras", input: "Honduras" },
  //  { value: "Hong Kong", input: "Hong Kong" },
  //  { value: "Hungria", input: "Hungria" },
  //  { value: "Iêmen", input: "Iêmen" },
  //  { value: "Ilhas Cayman", input: "Ilhas Cayman" },
  //  { value: "Ilhas Cook", input: "Ilhas Cook" },
  //  { value: "Ilhas Curaçao", input: "Ilhas Curaçao" },
  //  { value: "Ilhas Marshall", input: "Ilhas Marshall" },
  //  { value: "Ilhas Turks & Caicos", input: "Ilhas Turks & Caicos" },
  //  { value: "Ilhas Virgens (brit.)", input: "Ilhas Virgens (brit.)" },
  //  { value: "Ilhas Virgens(amer.)", input: "Ilhas Virgens(amer.)" },
  //  { value: "Ilhas Wallis e Futuna", input: "Ilhas Wallis e Futuna" },
  //  { value: "Índia", input: "Índia" },
  //  { value: "Indonésia", input: "Indonésia" },
  //  { value: "Inglaterra", input: "Inglaterra" },
  //  { value: "Irlanda", input: "Irlanda" },
  //  { value: "Islândia", input: "Islândia" },
  //  { value: "Israel", input: "Israel" },
  //  { value: "Itália", input: "Itália" },
  //  { value: "Jamaica", input: "Jamaica" },
  //  { value: "Japão", input: "Japão" },
  //  { value: "Jordânia", input: "Jordânia" },
  //  { value: "Kuwait", input: "Kuwait" },
  //  { value: "Latvia", input: "Latvia" },
  //  { value: "Líbano", input: "Líbano" },
  //  { value: "Liechtenstein", input: "Liechtenstein" },
  //  { value: "Lituânia", input: "Lituânia" },
  //  { value: "Luxemburgo", input: "Luxemburgo" },
  //  { value: "Macau", input: "Macau" },
  //  { value: "Macedônia", input: "Macedônia" },
  //  { value: "Madagascar", input: "Madagascar" },
  //  { value: "Malásia", input: "Malásia" },
  //  { value: "Malaui", input: "Malaui" },
  //  { value: "Mali", input: "Mali" },
  //  { value: "Malta", input: "Malta" },
  //  { value: "Marrocos", input: "Marrocos" },
  //  { value: "Martinica", input: "Martinica" },
  //  { value: "Mauritânia", input: "Mauritânia" },
  //  { value: "Mauritius", input: "Mauritius" },
  //  { value: "México", input: "México" },
  //  { value: "Moldova", input: "Moldova" },
  //  { value: "Mônaco", input: "Mônaco" },
  //  { value: "Montserrat", input: "Montserrat" },
  //  { value: "Nepal", input: "Nepal" },
  //  { value: "Nicarágua", input: "Nicarágua" },
  //  { value: "Niger", input: "Niger" },
  //  { value: "Nigéria", input: "Nigéria" },
  //  { value: "Noruega", input: "Noruega" },
  //  { value: "Nova Caledônia", input: "Nova Caledônia" },
  //  { value: "Nova Zelândia", input: "Nova Zelândia" },
  //  { value: "Omã", input: "Omã" },
  //  { value: "Palau", input: "Palau" },
  //  { value: "Panamá", input: "Panamá" },
  //  { value: "Papua-nova Guiné", input: "Papua-nova Guiné" },
  //  { value: "Paquistão", input: "Paquistão" },
  //  { value: "Peru", input: "Peru" },
  //  { value: "Polinésia Francesa", input: "Polinésia Francesa" },
  //  { value: "Polônia", input: "Polônia" },
  //  { value: "Porto Rico", input: "Porto Rico" },
  //  { value: "Portugal", input: "Portugal" },
  //  { value: "Qatar", input: "Qatar" },
  //  { value: "Quênia", input: "Quênia" },
  //  { value: "Rep. Dominicana", input: "Rep. Domiicana" },
  //  { value: "Rep. Tcheca", input: "Rep. Tcheca" },
  //  { value: "Reunion", input: "Reunion" },
  //  { value: "Romênia", input: "Romênia" },
  //  { value: "Ruanda", input: "Ruanda" },
  //  { value: "Rússia", input: "Rússia" },
  //  { value: "Saipan", input: "Saipan" },
  //  { value: "Samoa Americana", input: "Samoa Americana" },
  //  { value: "Senegal", input: "Senegal" },
  //  { value: "Serra Leone", input: "Serra Leone" },
  //  { value: "Seychelles", input: "Seychelles" },
  //  { value: "Singapura", input: "Singapura" },
  //  { value: "Síria", input: "Síria" },
  //  { value: "Sri Lanka", input: "Sri Lanka" },
  //  { value: "St. Kitts & Nevis", input: "St. Kitts & Nevis" },
  //  { value: "St. Lúcia", input: "St. Lúcia" },
  //  { value: "St. Vincent", input: "St. Vincent" },
  //  { value: "Sudão", input: "Sudão" },
  //  { value: "Suécia", input: "Suécia" },
  //  { value: "Suiça", input: "Suiça" },
  //  { value: "Suriname", input: "Suriname" },
  //  { value: "Tailândia", input: "Tailândia" },
  //  { value: "Taiwan", input: "Taiwan" },
  //  { value: "Tanzânia", input: "Tanzânia" },
  //  { value: "Togo", input: "Togo" },
  //  { value: "Trinidad & Tobago", input: "Trinidad & Tobago" },
  //  { value: "Tunísia", input: "Tunísia" },
  //  { value: "Turquia", input: "Turquia" },
  //  { value: "Ucrânia", input: "Ucrânia" },
  //  { value: "Uganda", input: "Uganda" },
  { value: "Uruguai", input: "Uruguai" },
  //  { value: "Venezuela", input: "Venezuela" },
  //  { value: "Vietnã", input: "Vietnã" },
  //  { value: "Zaire", input: "Zaire" },
  //  { value: "Zâmbia", input: "Zâmbia" },
  //  { value: "Zimbábue", input: "Zimbábue" },
];
