import React from "react";
import { createCustomTheme } from "../theme/createCustomTheme";
import Helmet from "react-helmet";

const platformConfig = process.env.REACT_APP_PLATFORM_ID
  ? require(`./${process.env.REACT_APP_PLATFORM_ID}.js`).default
  : null;

export default platformConfig;

export function createPlatformConfig(customConfig) {
  let assetsURL = customConfig.assetsURL;
  if (assetsURL.endsWith("/")) assetsURL = assetsURL.substr(0, assetsURL.length - 1);
  return {
    ...customConfig,
    assetsURL,
    asset: (fileName) => `${assetsURL}/${fileName}`,
    theme: createCustomTheme(customConfig.palette),
    metaTags: generateMetaTags(
      assetsURL,
      customConfig.color,
      customConfig.name,
      customConfig.title,
      customConfig.description,
      customConfig.canonicalURL
    ),
  };
}

export function generateMetaTags(
  platformAssetsURL,
  platformColor,
  platformName,
  platformTitle,
  platformDescription,
  platformCanonicalURL
) {
  const asset = (fileName) => `${platformAssetsURL}/${fileName}`;
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="google" content="notranslate" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="stylesheet" type="text/css" href={asset("fonts/fonts.css")} />

      <title>{platformTitle}</title>
      <meta name="description" content={platformDescription} />
      <meta name="keywords" content={platformName} />
      <meta property="og:title" content={platformTitle} />
      <meta property="og:description" content={platformDescription} />
      <meta property="og:site_name" content={platformName} />
      <meta property="og:url" content={platformCanonicalURL} />
      <meta property="og:site" content={platformCanonicalURL} />
      <meta property="og:image" content={asset("assets/img/share.jpg")} />
      <meta property="og:type" content="website" />
      <meta name="author" content={platformName} />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={platformName} />
      <meta name="application-name" content={platformName} />
      <meta name="theme-color" content={platformColor} />
      <meta name="msapplication-TileColor" content={platformColor} />
      <meta name="msapplication-TileImage" content={asset("favicon/mstile-150x150.png")} />
      <link rel="manifest" href={asset("manifest.json")} />

      <link rel="shortcut icon" href={asset("favicon/favicon.ico")} />
      <link rel="apple-touch-icon" sizes="180x180" href={asset("favicon/apple-touch-icon.png")} />
      <link rel="icon" type="image/png" sizes="32x32" href={asset("favicon/favicon-32x32.png")} />
      <link rel="icon" type="image/png" sizes="16x16" href={asset("favicon/favicon-16x16.png")} />
      <link rel="apple-touch-startup-image" href={asset("favicon/splash.png")} />
    </Helmet>
  );
}
