import { formatParamsGet, getDataFromServer } from "../util";

// Lista de contratos
export const getCustomerContracts = (userId, history = false) =>
  getDataFromServer("GET", "/contract?userId=" + userId + "&history=" + history, null, true);
export const getAllCustomerContracts = (userId) => getDataFromServer("GET", "/contract?userId=" + userId, null, true);
export const getPendingContracts = (phaseId) => getDataFromServer("GET", "/contract?status=INACTIVE", null, true);

/**
 * Obtém uma lista de contratos do servidor.
 * @param {Object} params
 * @param {String} params.userId Se informado, retorna apenas os contratos do usuário.
 * @param {Boolean} params.history Utilizado em conjunto com userId. true, retorna apenas contratos arquivados.
 * @param {("ACTIVE"|"INACTIVE"|"ARCHIVE"|"TERMINATED")} params.status Se informado, retorna apenas contratos com este status.
 * @param {("WAITING_SIGN"|"WAITING_APORT"|"VALIDATING"|"EXECUTING"|"WAITING_WITHDRAW"|"REDEEMED"|"CANCELED")} params.phase Se informado, retorna apenas contratos nesta fase.
 * @param {("EMPTY"|"WITHOUT_PAY"|"PAYMENT_GENERATED"|"PAID")} params.statusRedeemPaid Se informado, retorna apenas contratos com este status de resgate.
 * @param {String} params.taxresidence Se informado, retorna apenas contratos de usuários deste domicílio fiscal. Nome do país.
 * @param {String} params.startDueDate Se informado, retorna apenas contratos que terminam a partir desta data (formato "YYYY-MM-DD").
 * @param {String} params.finalDueDate Se informado, retorna apenas contratos que terminam até esta data (formato "YYYY-MM-DD").
 * @param {String} params.startInitialDate Se informado, retorna apenas contratos que iniciam a partir desta data (formato "YYYY-MM-DD").
 * @param {String} params.finalInitialDate Se informado, retorna apenas contratos que iniciam até esta data (formato "YYYY-MM-DD").
 * @returns {Promise} Promise que retorna a lista de contratos.
 *
 * Observação: parãmetros não documentados que o backend aceita:
 * - name
 * - documentId
 * - contractId
 * - consultantId
 * - typeId
 */
export const getContracts = (params) => {
  const strParams = formatParamsGet(params);
  return getDataFromServer("GET", "/contract" + strParams, null, true);
};

// ---------- chamada de relatórios de contratos  para Brasil

export const getJustOpenContracts2 = (phaseId) =>
  getDataFromServer("GET", "/contract?phase=WAITING_APORT&taxresidence=Brasil", null, true);
export const getValidationContracts = (phaseId) =>
  getDataFromServer("GET", "/contract?phase=VALIDATING&taxresidence=Brasil", null, true);
export const getAtiveContracts = (phaseId) =>
  getDataFromServer("GET", "/contract?status=ACTIVE&taxresidence=Brasil", null, true);
export const getTerminatedContracts = (phaseId) =>
  getDataFromServer("GET", "/contract?status=TERMINATED&taxresidence=Brasil", null, true);
export const getRedeemContracts = (phaseId) =>
  getDataFromServer("GET", "/contract?phase=WAITING_WITHDRAW&taxresidence=Brasil", null, true);
export const getStatusRedeemContracts = (statusRedeemPaid) =>
  getDataFromServer("GET", "/contract?statusRedeemPaid=PAYMENT_GENERATED&taxresidence=Brasil", null, true);

// ---------- chamada de relatórios de contratos  para Colombia

// export const getJustOpenContracts2ES = (phaseId) =>
//   getDataFromServer("GET", "/contract?phase=WAITING_APORT&taxresidence=Colômbia", null, true);
// export const getValidationContractsES = (phaseId) =>
//   getDataFromServer("GET", "/contract?phase=VALIDATING&taxresidence=Colômbia", null, true);
// export const getAtiveContractsES = (phaseId) =>
//   getDataFromServer("GET", "/contract?status=ACTIVE&taxresidence=Colômbia", null, true);
// export const getRedeemContractsES = (phaseId) =>
//   getDataFromServer("GET", "/contract?phase=WAITING_WITHDRAW&taxresidence=Colômbia", null, true);
// export const getStatusRedeemContractsES = (statusRedeemPaid) =>
//   getDataFromServer("GET", "/contract?statusRedeemPaid=PAYMENT_GENERATED&taxresidence=Colômbia", null, true);
// export const getJustOpenContractsES = (phaseId) =>
//   getDataFromServer("GET", "/contract?phase=WAITING_SIGN&taxresidence=Colômbia", null, true);
//-------------------------------------------------------------

export const getExpiredContracts = (startDueDate, finalDueDate) =>
  getDataFromServer("GET", "/contract?startDueDate=" + startDueDate + "&finalDueDate=" + finalDueDate, null, true);
// Informações do contrato
export const createContractType = (params) => getDataFromServer("POST", "/type", params, true);
export const editContractType = (id, params) => getDataFromServer("PUT", "/type/" + id, params, true);
export const deleteContractType = (id) => getDataFromServer("DELETE", "/type/" + id, null, true);
export const getContract = (id) => getDataFromServer("GET", "/contract/" + id, null, true);
export const getContractType = () => getDataFromServer("GET", "/type", null, true);
export const getActiveContractType = () => getDataFromServer("GET", "/type/active", null, true);
export const getContractHistory = (id) => getDataFromServer("GET", "/contract/" + id + "/history", null, true);
export const getContractMonthHistory = (id, month, year) =>
  getDataFromServer("GET", "/contract/" + id + "/month/" + month + "/year/" + year, null, true);
export const getContractTotal = (month, year) =>
  getDataFromServer("GET", "/contract/total/month/" + month + "/year/" + year, null, true);

// Estado do contrato
export const newContract = (params) => getDataFromServer("POST", "/contract", params, true);
export const generateContractPDF = (id) => getDataFromServer("GET", "/contract/" + id + "/generate", null, true);
export const sendContractPDF = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getDataFromServer("POST", "/contract/" + id + "/upload", formData, true);
};
export const generateContractReceipt = (id) =>
  getDataFromServer("GET", "/contract/" + id + "/generateReceipt", null, true);
export const sendContractReceiptPDF = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getDataFromServer("POST", "/contract/" + id + "/uploadReceipt", formData, true);
};
export const sendTransactionById = (id, params) =>
  getDataFromServer("POST", "/withdraw/" + id + "/transaction", params, true);
export const sendContractTransaction = (id, params) =>
  getDataFromServer("POST", "contract/" + id + "/deposit", params, true);
export const setContractToWaitingAport = (id, params) =>
  getDataFromServer("PUT", "/contract/" + id + "/phase/aport", params, true);
export const setContractToWithdrawComplete = (id) =>
  getDataFromServer("PUT", "/contract/" + id + "/phase/withdrawComplete", false, true);
export const executeContract = (id, params) =>
  getDataFromServer("PUT", "/contract/" + id + "/phase/execute", params, true);
export const terminateContract = (id, params) =>
  getDataFromServer("PUT", "/contract/" + id + "/terminate", params, true);
export const archiveContract = (id) => getDataFromServer("PUT", "/contract/" + id + "/archive", null, true);
export const cancelContract = (id) => getDataFromServer("PUT", "/contract/" + id + "/cancel", null, true);
export const downloadContract = (id) => getDataFromServer("GET", "/contract/" + id + "/downloadContract", null, true);
export const downloadReceipt = (id) => getDataFromServer("GET", "/contract/" + id + "/downloadReceipt", null, true);
export const editPrevisionAmount = (id, params) => getDataFromServer("PUT", "/contract/" + id, params, true);
export const validateContract = (id) => getDataFromServer("PUT", "/contract/" + id + "/validate", null, true);
export const awaitingValidationContract = (id) =>
  getDataFromServer("PUT", "/contract/" + id + "/awaitingValidation", null, true);

export const contractRedeem = (id) => getDataFromServer("PUT", "/contract/" + id + "/redeem", null, true);
export const contractAddAgent = (id, params) => getDataFromServer("PUT", "/contract/" + id + "/addFifty", params, true);
export const contractEditAgent = (id, params) =>
  getDataFromServer("PUT", "/contract/" + id + "/editFifty", params, true);
export const contractRemoveAgent = (id) => getDataFromServer("PUT", "/contract/" + id + "/removeAgent", null, true);
