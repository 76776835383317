import { createPlatformConfig } from ".";

const roxoAzul = "#5E00D0";
const roxoClaro = "#917EBE";
const roxoVermelho = "#AB0CE8";

export default createPlatformConfig({
  id: "digiplata",
  name: "DigiPlata",
  title: "DigiPlata",
  description: "¡Crédito digital para lo que quieras!",
  canonicalURL: "https://digiplata.co/",
  assetsURL: "https://assets.digiplata.co/",
  locale: "pt-br",
  timeZone: "America/Bogota",
  dateTimeFormat: "DD/MM/YYYY HH:mm",
  color: "#2f0068",
  logo: "logo/logo.svg",
  versao: "0.0.1",

  links: {
    feed: "https://digiplata.co/", //site da digiplata não tem feed
    emailadmin: "contacto@digiplata.co",
    emailajuda: "contacto@digiplata.co",
    blog: "https://digiplata.co/",
    site: "https://digiplata.co/",
    termos: "https://digiplata.co/",
    politica: "https://digiplata.co/",
  },

  palette: {
    type: "light",
    common: { black: "#000", white: "#fff" },
    background: {
      paper: "#fff",
      default: "#eee",
    },
    primary: {
      light: roxoVermelho,
      main: roxoVermelho,
      dark: roxoVermelho,
      contrastText: "#fff",
      _comment_main_hex: "#fff",
      _comment_dark_hex: "#fff",
    },
    secondary: {
      light: "#eee",
      main: "#fff",
      dark: "#ddd",
      contrastText: "#000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#000",
      disabled: "#555",
      hint: "rgba(180, 180, 180, 1)",
    },
  },
});
