import { getDataFromServer } from "../util";

// Saques
export const getWithdraw = () => getDataFromServer("GET", "/withdraw/me", null, true);
export const getInfoWithdraw = (userId) => getDataFromServer("GET", `/withdraw/userInfo?userId=${userId}`, null, true);
export const getWithdrawById = (id) => getDataFromServer("GET", "withdraw/" + id, null, true);
export const getMyPendingWithdrawals = (id) => getDataFromServer("GET", "/withdraw/me/pending", null, true);
export const getPendingWithdrawals = (id) => getDataFromServer("GET", "/withdraw?status=PENDING", null, true);
export const getPendingWithdrawalsInfo = (userId) =>
	getDataFromServer("GET", `/withdraw?userId=${userId}&status=PENDING`, null, true);
export const getWaitingAuthWithdrawals = async (id) =>
	await getDataFromServer("GET", "/withdraw?status=WAITING_AUTH", null, true);
export const getWithdrawHistory = async () =>
	await getDataFromServer("GET", "/withdraw?history=true&status=APPROVED,REJECTED,CANCELED", null, true);
export const requestWithdrawal = (params) => getDataFromServer("POST", "/withdraw", params, true);
export const generateWithdrawalReceipt = (id) =>
	getDataFromServer("GET", "/withdrawal/" + id + "/generateReceipt", null, true);
export const sendWithdrawalReceipt = (id, file) => {
	const formData = new FormData();
	formData.append("file", file);
	return getDataFromServer("POST", "/withdrawal/" + id + "/uploadReceipt", formData, true);
};
export const cancelWithdrawal = (id) => getDataFromServer("PUT", "/withdraw/" + id + "/cancel", null, true);
export const approveWithdrawal = (id, formProps) =>
	getDataFromServer("PUT", "/withdraw/" + id + "/approve", formProps, true);
export const rejectWithdrawal = (id, formProps) =>
	getDataFromServer("PUT", "/withdraw/" + id + "/reject", formProps, true);
export const authorizeWithdrawal = (token) =>
	getDataFromServer("PUT", `/withdraw/authorize?token=${token}`, null, true);
export const resendEmail = (id) => getDataFromServer("PUT", `/withdraw/${id}/resendEmail`, null, true);
export const updateWithdrawalFee = (id, formProps) => getDataFromServer("PUT", `/withdraw/${id}/fee`, formProps, true);
export const updateWithdrawalAddress = (id, formProps) =>
	getDataFromServer("PUT", `/withdraw/${id}/address`, formProps, true);
export const superAuthorizeWithdrawal = (idWithdraw) =>
	getDataFromServer("PUT", `/withdraw/authorize/force?idWithdraw=${idWithdraw}`, null, true);
