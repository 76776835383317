import {
  AppBar,
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withTheme,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import React, { Component } from "react";
import AnimatedNumber from "react-animated-number";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as rssParser from "react-native-rss-parser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { getContractTotal, getCustomerContracts, getKyc, getTerminatedContracts } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import ValueInfoItem from "../custom/ValueInfoItem";
import { DialogManager } from "../util";
import ContractWidget from "./components/ContractWidget";
import { HelpDialog } from "./components/HelpDialog";
import platformConfig from "../platformConfig";

class CustomerDashboard extends Component {
  state = {
    progress: 0,
    loading: true,
    contractsTerminated: [],
    openPopupContract: false,
    contractAttention: null,
    total: {
      amount: 0,
      balance: 0,
      totalBalance: 0,
      monthObjective: 1,
    },
    badges: {
      cor: "",
      qtd: 0,
    },
    currentYieldVal: 0,
    estimatedYieldVal: 0,
    doc: 0,
    pj: this.props.currentUser.isPj,
    rss: undefined,
  };

  progressInterval = null;
  progressStartInterval = null;

  dm = new DialogManager(() => this.forceUpdate());

  loadKyc = () => {
    getKyc().then((data) => {
      this.setState({ userKyc: data });
    });
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const currentMonth = moment();
    this.showPopupContract();

    this.loadKyc();
    getTerminatedContracts().then((data) => {
      const contractsTerminated = data.filter((c) => c.user.id == currentUser.id);
      this.setState({ contractsTerminated, loading: false });
    });

    getContractTotal(currentMonth.month() + 1, currentMonth.year()).then((data) => {
      this.setState({ contractTotal: data });
    });
    if (platformConfig.id === "luxcapital") {
      fetch(platformConfig.links.feed)
        .then((response) => response.text())
        .then((responseData) => rssParser.parse(responseData))
        .then((rss) => {
          this.setState({ rss });
        });
    }
  }
  showPopupContract = () => {
    getCustomerContracts(this.props.currentUser.id, false).then((data) => {
      const c = data.filter((c) => c.phase == "WAITING_SIGN" || c.phase == "VALIDATING");
      if (c.length > 0) {
        this.setState({ openPopupContract: true, contractAttention: c[0].id });
      } else {
        this.setState({ openPopupContract: false });
      }
    });
  };
  componentWillUnmount() {
    clearTimeout(this.progressStartInterval);
    clearInterval(this.progressInterval);
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { contractTotal, userKyc, pj, loading, contractAttention } = this.state;
    let badges = 0;
    badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout" color="inherit">
              <ExitToAppIcon style={{ transform: "rotate(180deg)" }} />
            </IconButton>
            <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more" color="inherit">
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: `8px ${palette.primary.main} solid` }}
              style={{}}
            />
          </AppBar>
        </HeaderPageItem>

        <PageItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Dialog open={this.state.openPopupContract}>
            <DialogTitle>
              <Typography variant="h6" color="primary">
                <FormattedHTMLMessage id="app.warning" />
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Typography variant="subtitle2">
                <FormattedHTMLMessage id="app.contract.waitingAssign.id" values={{ id: contractAttention }} />
              </Typography>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                component={Link}
                to={"/contract/" + contractAttention}
              >
                <FormattedHTMLMessage id="app.contract.view" />
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ openPopupContract: false })} color="primary">
                <FormattedMessage id="app.contract.later" />
              </Button>
            </DialogActions>
          </Dialog>
          <CardContent
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography gutterBottom variant="h6" style={{ marginBottom: 0 }}>
              {currentUser.name}
            </Typography>
          </CardContent>

          {currentUser.status == "PENDING" ? (
            <CardPageItem
              style={{
                marginTop: 32,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" color="primary">
                  <FormattedHTMLMessage id="app.dashboard.user.pending" />
                </Typography>
              </CardContent>
            </CardPageItem>
          ) : currentUser.status == "INACTIVE" ? (
            <CardPageItem
              style={{
                marginTop: 32,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" color="primary">
                  <FormattedHTMLMessage id="app.dashboard.user.inactive" />
                </Typography>
              </CardContent>
            </CardPageItem>
          ) : (
            <>
              <CardContent>
                <Typography gutterBottom variant="h5">
                  <Number value={currentUser.openContracts} /> <FormattedMessage id="app.openContracts" />
                </Typography>

                <AnimatedNumber
                  initialValue={currentUser.openContractsAmount - 0.01}
                  value={currentUser.openContractsAmount}
                  duration={1000}
                  stepPrecision={8}
                  formatValue={(n) => (
                    <ValueInfoItem
                      caption={<FormattedMessage id="app.custodian.total" />}
                      value={n}
                      center
                      variant="h4"
                      color="primary"
                      currency="LCT"
                    />
                  )}
                  style={{ marginTop: 32 }}
                  onClick={(e) => this.dm.open("HELP_TOTAL")}
                />

                <ValueInfoItem
                  caption={<FormattedMessage id="app.totalIncrease" />}
                  value={currentUser.totalYield}
                  center
                  variant="h4"
                  //color="primary"
                  currency="LCT"
                />
              </CardContent>

              <CardPageItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ContractWidget contractTotal={contractTotal} />
              </CardPageItem>
            </>
          )}
        </PageItem>
        <HelpDialog
          open={this.dm.isOpen("HELP_TOTAL")}
          onClose={(e) => this.dm.close()}
          title="app.dashboard.total.title"
        >
          <FormattedMessage id="app.dashboard.total" />
        </HelpDialog>
        {this.state.contractsTerminated && this.state.contractsTerminated.length > 0 && (
          <>
            <CardContent style={{ paddingBottom: 0 }}>
              <Typography variant="body1">
                <FormattedMessage id="app.dashboard.expiredContracts" />
              </Typography>
            </CardContent>
            {this.state.contractsTerminated.map((c) => (
              <CardPageItem key={c.id} raised>
                <CardActionArea component={Link} to={"/contract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {"LCContract C" + c.id}
                        </Typography>

                        <Typography variant="body1" color="primary">
                          <Number value={c.amount} currency="LCT" />
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textSecondary">
                            {c.status !== "TERMINATED" ? (
                              <FormattedMessage id="app.activationDate" />
                            ) : (
                              <FormattedMessage id="app.contract.finishedIn" />
                            )}
                            :
                          </Typography>
                          <Typography>
                            <Moment format="DD/MM/YYYY">{c.status !== "TERMINATED" ? c.createdAt : c.updatedat}</Moment>
                          </Typography>
                        </div>

                        {c.status !== "TERMINATED" ? (
                          <>
                            <div>
                              <Typography variant="caption" color="textSecondary">
                                <FormattedMessage id="app.contract.increase" />:
                              </Typography>
                              <Typography>
                                <Number value={c.yield.val} currency="LCT" />
                              </Typography>
                            </div>

                            <div>
                              <Typography variant="caption" color="textSecondary">
                                %
                              </Typography>
                              <Typography>
                                <Number value={c.yield.per} display="percent" />
                              </Typography>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <FormattedMessage id="app.contract.finished.text" />
                            </Typography>
                            <Typography>
                              <FormattedMessage id="app.contract.redemptionRequest" />
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              </CardPageItem>
            ))}
          </>
        )}
        {this.state.rss && (
          <CardPageItem raised>
            <CardContent>
              <Typography gutterBottom variant="body1" style={{ textAlign: "center" }}>
                Últimas notícias
              </Typography>
              <List dense>
                {this.state.rss.items.slice(0, 5).map((item) => (
                  <ListItem button component="a" href={item.links[0].url} key={item.id}>
                    <ListItemText primary={item.title} secondary={<Moment fromNow date={item.published} />} />
                    <ListItemSecondaryAction>
                      <ChevronRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </CardPageItem>
        )}
        <PageItem style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="app.dashboard.text1" />
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <FormattedMessage id="app.dashboard.text2" />
          </Typography>
          <Button variant="outlined" color="primary" href={platformConfig.links.blog}>
            <FormattedMessage id="app.access.blog" />
          </Button>
        </PageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, actions)(CustomerDashboard));
