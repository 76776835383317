import { Button, Typography } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { updateWithdrawalAddress } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";
import customSelect from "../../custom/CustomSelect";
import { networkNameByPartnerWithdrawType } from "../../util";

class FormUpdateAddress extends Component {
  onSubmit = (formProps) => {
    const {
      onActionComplete,
      showErrorMessage,
      showSuccessMessage,
      withdrawal: { id },
    } = this.props;
    updateWithdrawalAddress(id, formProps)
      .then((response) => {
        showSuccessMessage("app.update");
        onActionComplete(response);
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  componentDidMount() {
    const { initialize, withdrawal } = this.props;
    initialize({
      address: withdrawal.address,
      partnerWithdraw: withdrawal.partnerWithdraw,
      partnerWithdrawType: withdrawal.partnerwithdrawtype,
    });
  }

  render() {
    const { handleSubmit, withdrawal } = this.props;
    const network = [
      { input: networkNameByPartnerWithdrawType[0], value: 0 },
      { input: networkNameByPartnerWithdrawType[1], value: 1 },
      { input: networkNameByPartnerWithdrawType[2], value: 2 },
    ];
    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {withdrawal.partnerWithdraw == 1 && (
            <Typography>
              Atenção: Informe um endereço da rede <b>BTC</b>
            </Typography>
          )}
          <Field
            name="address"
            label="app.wallet.address"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            autoFocus
          />
          {withdrawal.partnerWithdraw == 0 && (
            <Field name="partnerWithdrawType" label="app.type.network" component={customSelect} values={network} />
          )}

          <Button
            id="bSubmitOrderForm"
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={this.props.submitting || this.props.invalid || this.props.pristine}
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </Fragment>
    );
  }
}
const validate = (values, props) => {
  const errors = {};
  if (!values.address) {
    errors.address = "app.form.address.error";
  }
  return errors;
};
export default compose(
  reduxForm({
    form: "FormUpdateAddress",
    validate,
  }),
  connect(null, actions)
)(FormUpdateAddress);
