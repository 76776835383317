import {
  AppBar,
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withTheme,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as rssParser from "react-native-rss-parser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAccount, getCurrentOperations, getKyc } from "../actions";
import {
  getAtiveContracts,
  getContractTotal,
  getExpiredContracts,
  getPendingContracts,
  getTerminatedContracts,
} from "../actions/Contract";
import { getTotalValue } from "../actions/Payment";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import bad from "../static/bad.png";
import { DialogManager } from "../util";
import ContractWidget from "./components/ContractWidget";
import platformConfig from "../platformConfig";

class Dashboard extends Component {
  state = {
    progress: 0,
    customers: [],
    loading: true,
    limitMin: 1000,
    contracts: [],
    payments: [],
    totalValueMonth: 0,
    contractsActive: [],
    contractsActiveFinal: [],
    contractsInactive: [],
    contractsTerminated: [],
    newContracts: [],
    signedContracts: [],
    fundedContracts: [],
    closedContracts: [],
    total: {
      amount: 0,
      balance: 0,
      totalBalance: 0,
      monthObjective: 1,
    },
    account: {},
    currentYieldVal: 0,
    estimatedYieldVal: 0,
    currentYieldPer: 0,
    estimatedYieldPer: 0,
    bonus: 0,
    doc: 0,
    operations: {},
    pj: this.props.currentUser.isPj,
    rss: undefined,
  };

  progressInterval = null;
  dm = new DialogManager(() => this.forceUpdate());

  getCustomers = () => {
    performRequest("GET", `user/customers?status=ACTIVE`, null, true).then((response) => {
      this.setState({ customers: response.data.data, loading: false });
    });
  };
  loadKyc = () => {
    getKyc().then((data) => {
      this.setState({ userKyc: data });
    });
  };
  loadOp = () => {
    getCurrentOperations().then((data) => {
      this.setState({ operations: data, op: false });
    });
  };
  componentDidMount() {
    this.loadAccount();
    this.loadKyc();
    this.loadOp();

    const { currentUser } = this.props;
    if (!currentUser.id) {
      this.props.getMe().then((r) => this.getCustomers());
    } else {
      this.getCustomers();
    }
    getPendingContracts().then((data) => {
      const contractsInactive = data.filter((c) => c.status == "INACTIVE" && c.user.id !== currentUser.id);
      const newContracts = data.filter((c) => c.phase == "WAITING_SIGN" && !c.fileUrl && c.user.id !== currentUser.id);
      const newContractsSigned = data.filter(
        (c) => c.phase == "WAITING_SIGN" && c.fileUrl && c.user.id !== currentUser.id
      );
      const signedContracts = data.filter(
        (c) =>
          c.phase == "WAITING_APORT" && c.amount < c.previsionAmount && !c.receiptUrl && c.user.id !== currentUser.id
      );
      const aportedContracts = data.filter(
        (c) =>
          c.phase == "WAITING_APORT" && c.amount > c.previsionAmount && !c.receiptUrl && c.user.id !== currentUser.id
      );
      const receiptContracts = data.filter(
        (c) => c.phase == "WAITING_APORT" && c.receiptUrl && c.user.id !== currentUser.id
      );
      const fundedContracts = data.filter((c) => c.phase == "VALIDATING" && c.user.id !== currentUser.id);
      // Disparar a carga de dados dos usuários para cada contrato nos arrays acima.
      this.setState({
        signedContracts,
        fundedContracts,
        newContracts,
        contractsInactive,
        newContractsSigned,
        aportedContracts,
        receiptContracts,
      });
      if (platformConfig.id === "luxcapital") {
        fetch(platformConfig.links.feed)
          .then((response) => response.text())
          .then((responseData) => rssParser.parse(responseData))
          .then((rss) => {
            this.setState({ rss });
          });
      }
    });

    getTerminatedContracts().then((data) => {
      const contractsTerminated = data.filter((c) => c.user.id == currentUser.id);
      this.setState({ contractsTerminated });
    });

    const currentMonth = moment();

    getExpiredContracts(moment().format("YYYY-MM-DD"), moment().add(30, "day").format("YYYY-MM-DD")).then((data) => {
      const contractsActiveFinal = data.filter((c) => c.user.id !== currentUser.id && c.status == "ACTIVE");
      this.setState({ contractsActiveFinal });
    });
    getAtiveContracts().then((data) => {
      const contractsActive = data.filter((c) => c.user.id !== currentUser.id);
      this.setState({ contractsActive, loading: false });
    });

    getContractTotal(currentMonth.month() + 1, currentMonth.year()).then((data) => {
      this.setState({ contractTotal: data });
    });
  }

  loadAccount() {
    getAccount().then((data) => {
      this.setState({ account: data });
    });

    let month = moment().format("YYYY-MM-01");
    getTotalValue(month, moment(month).endOf("month").format("YYYY-MM-DD")).then((data) => {
      this.setState({ totalValueMonth: data });
    });
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };
  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { customers, limitMin, loading, contractsActive, contractTotal, totalValueMonth, userKyc, pj, operations } =
      this.state;
    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);
    let totalRend = 0;
    this.state.customers.map((cus) => {
      return (totalRend += cus.openContracts);
    });
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout">
              <ExitToAppIcon htmlColor="#23323C" style={{ transform: "rotate(180deg)" }} />
            </IconButton>
            <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more">
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon htmlColor="#23323C" />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: `8px ${palette.primary.main} solid` }}
              style={{}}
            />
          </AppBar>
        </HeaderPageItem>

        <PageItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            {currentUser.name}
          </Typography>

          <CardPageItem style={{ width: "100%", textAlign: "center" }}>
            {customers.length > 0 ? (
              <>
                {contractsActive.length > 0 ? (
                  <>
                    <CardContent>
                      <div
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        onClick={(e) => this.dm.open("HELP_TOTAL")}
                      >
                        <Typography variant="caption" color="textSecondary">
                          <FormattedMessage id="app.agent.dashboard.text1" />
                        </Typography>

                        <Typography variant="h3" color="primary">
                          <Number value={totalValueMonth.val ? totalValueMonth.val : 0} currency="LCT" />
                        </Typography>
                      </div>
                    </CardContent>

                    <div
                      style={{
                        marginRight: 16,
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    >
                      <Typography variant="subtitle2" color="textPrimary">
                        <Number value={customers.length} /> <FormattedMessage id="app.customers" />
                      </Typography>

                      <Typography variant="subtitle2" color="textPrimary">
                        <Number value={totalRend} /> <FormattedMessage id="app.openContracts" />
                      </Typography>
                      <Typography variant="subtitle2" color="textPrimary">
                        <Number value={contractsActive.length} /> <FormattedMessage id="app.lccontracts.active" />
                      </Typography>
                    </div>
                  </>
                ) : (
                  <CardPageItem
                    style={{
                      marginTop: 32,
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" color="primary">
                        <FormattedMessage id="app.agent.dashboard.text2" />
                      </Typography>
                      <img style={{ marginTop: 32 }} src={bad} width="48" height="48" alt=" " />
                    </CardContent>
                  </CardPageItem>
                )}
              </>
            ) : (
              <CardPageItem
                style={{
                  marginTop: 32,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" color="primary">
                    <FormattedMessage id="app.agent.dashboard.text3" />
                  </Typography>
                  <img style={{ marginTop: 32 }} src={bad} width="48" height="48" alt=" " />
                </CardContent>
              </CardPageItem>
            )}

            {currentUser.status == "PENDING" ? (
              <CardPageItem
                style={{
                  marginTop: 32,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" color="primary"></Typography>
                </CardContent>
              </CardPageItem>
            ) : (
              currentUser.status == "ACTIVE" && (
                <CardPageItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ContractWidget contractTotal={contractTotal} />
                </CardPageItem>
              )
            )}
          </CardPageItem>
        </PageItem>
        {operations.capacity && operations.current && (
          <CardPageItem style={{ backgroudColor: "red" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <InfoItem
                  caption={<FormattedMessage id="app.deposit.limit" />}
                  text={<Number value={operations.capacity - operations.current} currency="LCT" />}
                />
              </div>
            </CardContent>
          </CardPageItem>
        )}
        <PageItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        />

        <HelpDialog
          open={this.dm.isOpen("HELP_TOTAL")}
          onClose={(e) => this.dm.close()}
          title={<FormattedMessage id="app.agent.dashboard.helpTotal.title" />}
        >
          <FormattedMessage id="app.agent.dashboard.helpTotal" />
        </HelpDialog>
        <HelpDialog
          open={this.dm.isOpen("HELP_SALDO")}
          onClose={(e) => this.dm.close()}
          title={<FormattedMessage id="app.camel.balance" />}
        >
          <FormattedMessage id="app.agent.dashboard.helpTotal" values={{ limitMin }} />
        </HelpDialog>
        {/* Refatorar- transformar em um component só */}
        {this.state.contractsTerminated && this.state.contractsTerminated.length > 0 && (
          <>
            <CardContent style={{ paddingBottom: 0 }}>
              <Typography variant="body1">
                <FormattedMessage id="app.dashboard.expiredContracts" />
              </Typography>
            </CardContent>
            {this.state.contractsTerminated.map((c) => (
              <CardPageItem key={c.id} raised>
                <CardActionArea component={Link} to={"/contract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {"LCContract C" + c.id}
                        </Typography>

                        <Typography variant="body1" color="primary">
                          <Number value={c.amount} currency="LCT" />
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textSecondary">
                            {c.status !== "TERMINATED" ? (
                              <FormattedMessage id="app.activationDate" />
                            ) : (
                              <FormattedMessage id="app.contract.finishedIn" />
                            )}
                            :
                          </Typography>
                          <Typography>
                            <Moment format="DD/MM/YYYY">{c.status !== "TERMINATED" ? c.createdAt : c.updatedat}</Moment>
                          </Typography>
                        </div>

                        {c.status !== "TERMINATED" ? (
                          <>
                            <div>
                              <Typography variant="caption" color="textSecondary">
                                <FormattedMessage id="app.contract.increase" defaultMessage="Acréscimo" />:
                              </Typography>
                              <Typography>
                                <Number value={c.yield.val} currency="LCT" />
                              </Typography>
                            </div>

                            <div>
                              <Typography variant="caption" color="textSecondary">
                                %
                              </Typography>
                              <Typography>
                                <Number value={c.yield.per} display="percent" />
                              </Typography>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <FormattedMessage id="app.contract.finished.text" />
                            </Typography>
                            <Typography>
                              <FormattedMessage id="app.contract.redemptionRequest" />
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              </CardPageItem>
            ))}
          </>
        )}
        {this.state.contractsInactive && this.state.contractsInactive.length > 0 && (
          <CardPageItem raised>
            <CardContent>
              <Typography variant="body1" color="textPrimary">
                <FormattedMessage id="app.agent.dashboard.pendingContracts" />
              </Typography>
            </CardContent>
            {this.state.newContracts &&
              this.state.newContracts.map((c) => (
                <CardActionArea key={c.id} component={Link} to={"/userContract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div key={c.id} style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {c.user.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {"LCContract C" + c.id}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textPrimary">
                            <FormattedMessage id="app.enuns.WAITING_SIGN" values={{ id: c.id }} />
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <Moment date={c.createdAt} />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              ))}
            {this.state.signedContracts &&
              this.state.signedContracts.map((c) => (
                <CardActionArea key={c.id} component={Link} to={"/userContract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div key={c.id} style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {c.user.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {"LCContract C" + c.id}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {c.amount > 0 ? (
                            <Typography variant="caption" color="textPrimary">
                              <FormattedMessage id="app.aport.validating" />
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="textPrimary">
                              <FormattedMessage id={`app.enuns.${c.phase}`} />
                            </Typography>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <Moment date={c.createdAt} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              ))}

            {this.state.fundedContracts &&
              this.state.fundedContracts.map((c) => (
                <CardActionArea key={c.id} component={Link} to={"/userContract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div key={c.id} style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {c.user.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {"LCContract C" + c.id}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textPrimary">
                            <FormattedMessage id="app.enuns.VALIDATING" />
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <Moment date={c.createdAt} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              ))}
          </CardPageItem>
        )}
        <CardPageItem raised>
          {this.state.contractsActiveFinal && this.state.contractsActiveFinal.length > 0 && (
            <>
              <CardContent>
                <Typography variant="body1" color="textPrimary">
                  <FormattedMessage id="app.agent.dashboard.finishingContracts" />
                </Typography>
              </CardContent>
              {this.state.contractsActiveFinal.map((c) => (
                <CardActionArea key={c.id} component={Link} to={"/userContract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div key={c.id} style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {c.user.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {"LCContract C" + c.id}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textPrimary">
                            <FormattedMessage id="app.agent.dashboard.contract.dueDate" />{" "}
                            <Moment date={c.dueDate} format="DD/MM/YYYY" />
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <Moment date={c.createdAt} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              ))}
            </>
          )}
        </CardPageItem>

        {this.state.rss && (
          <CardPageItem raised>
            <CardContent>
              <Typography gutterBottom variant="body1" style={{ textAlign: "center" }}>
                Últimas notícias
              </Typography>
              <List dense>
                {this.state.rss.items.slice(0, 5).map((item) => (
                  <ListItem button component="a" href={item.links[0].url} key={item.id}>
                    <ListItemText primary={item.title} secondary={<Moment fromNow date={item.published} />} />
                    <ListItemSecondaryAction>
                      <ChevronRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </CardPageItem>
        )}

        <PageItem style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="app.dashboard.text1" />
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <FormattedMessage id="app.dashboard.text2" />
          </Typography>
          <Button variant="outlined" color="primary" href={platformConfig.links.blog}>
            <FormattedMessage id="app.access.blog" />
          </Button>
        </PageItem>
      </PageContainer>
    );
  }
}
const HelpDialog = ({ open, onClose, title, children }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

const validate = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = "app.type.error";
  }
  if (!values.active) {
    errors.active = "app.status.error";
  }
  if (!values.startDate) {
    errors.startDate = "app.startPeriod.mustbe.selected";
  }
  if (!values.finalDate) {
    errors.finalDate = "app.endPeriod.mustbe.selected";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(
  compose(
    connect(mapStateToProps, actions),
    reduxForm({
      form: "dashboardForm",
      enableReinitialize: true,
      validate,
    })
  )(Dashboard)
);
