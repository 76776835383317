import { Button, CardActions, CardContent, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createTextMask } from "redux-form-input-masks";
import * as actions from "../actions";
import performRequest from "../configAxios";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { phoneNormalizer } from "../util";
import platformConfig from "../platformConfig";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class CustomerFormData extends Component {
  state = {
    isWhatsDisabled: false,
    isWhats2Disabled: false,
    telefone: null,
    isWhats: this.props.user.isWhats == null ? false : true,
    telefone2: null,
    isWhats2: this.props.user.isWhats2 == null ? false : true,
  };

  componentDidMount() {
    const { initialize, initialValues } = this.props;
    initialize(initialValues);
  }

  onSubmit = (formProps) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    if (!formProps) {
      showErrorMessage("app.contact.phone");
    } else {
      performRequest("PUT", `/user/me`, formProps, true)
        .then((response) => {
          showSuccessMessage("app.edit.sucesfully");
          this.props.history.push("/more");
          this.props.getMe();
        })
        .catch((e) => {
          if (e.response) {
            showErrorMessage(e.response.data.message);
          } else {
            showErrorMessage(e);
          }
        });
    }
  };

  check = () => {
    if (this.state.isWhats != null) this.setState({ isWhats: !this.state.isWhats });
  };
  check2 = () => {
    if (this.state.isWhats2 != null) this.setState({ isWhats2: !this.state.isWhats2 });
  };
  render() {
    const { handleSubmit } = this.props;
    const { email, telefone, telefone2 } = this.state;

    const phoneOnChange = (fieldName, name, value) => {
      // Esta rotina opera sobre o nome de campo recebido em fieldName, que será "formIsWhats" ou "formIsWhats2".
      // Determina se o campo deve ficar desabilitado (com base no valor ser vazio).
      let disabled = value == "";
      // Muda a variável no estado (é o nome do campo + "Disabled").
      this.setState({ [fieldName + "Disabled"]: disabled });
      // Muda o valor do campo no Redux Forms.
      if (disabled) {
        this.props.change(fieldName, false);
        this.setState({ [fieldName]: false });
      }
      this.setState({ [name]: value });
      this.props.change(name, value);
    };

    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.editContact.title" showBackButton destination="/more" />
        </PageItem>
        <CardPageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="email"
                label="app.email"
                type="email"
                value={email}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                component={CustomInputForm}
              />
              <Typography gutterBottom color="textSecondary" variant="caption" style={{ marginBottom: 8 }}>
                <FormattedMessage id="app.editContactInfo.obs" values={{ contato: platformConfig.links.emailajuda }} />
              </Typography>
              <Field
                name="telefone"
                label="app.phone"
                value={telefone}
                component={CustomInputForm}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => phoneOnChange("isWhats", "telefone", e.value)}
                normalize={phoneNormalizer}
              />
              <FormControlLabel
                disabled={this.state.formIsWhatsDisabled}
                label={<FormattedMessage id="app.whatsapp" />}
                control={<Field component={FormCheckbox} name="isWhats" color="primary" onChange={this.check} />}
              />
              <Field
                name="telefone2"
                label="app.phone"
                value={telefone2}
                component={CustomInputForm}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => phoneOnChange("isWhats2", "telefone2", e.value)}
                normalize={phoneNormalizer}
              />
              <FormControlLabel
                disabled={this.state.formIsWhats2Disabled}
                label={<FormattedMessage id="app.whatsapp" />}
                control={<Field component={FormCheckbox} name="isWhats2" color="primary" onChange={this.check2} />}
              />

              <CardActions>
                <Button
                  fullWidth
                  id="bSubmitOrderForm"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.props.submitting || this.props.invalid}
                >
                  <FormattedMessage id="app.button.save" />
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.telefone && !values.telefone2) {
    errors.telefone = "app.contact.phone";
  }
  if (!values.telefone && !values.telefone2) {
    errors.telefone2 = "app.contact.phone";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    user: state.user.user.me,
    initialValues: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "CustomerFormData",

    validate,
  }),
  connect(mapStateToProps, actions)
)(CustomerFormData);
