import {
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { default as ChevronRight, default as ChevronRightIcon } from "@material-ui/icons/ChevronRight";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import * as actions from "../actions";
import {
  awaitingValidationContract,
  cancelContract,
  getContract,
  sendContractTransaction,
  terminateContract,
  validateContract,
} from "../actions/Contract";
import { getRedemptionPayment } from "../actions/Payment";
import CustomInputForm from "../custom/CustomInput";
import customSelect from "../custom/CustomSelect";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Roles, validateCriptoAddress, networkNameByNetwork } from "../util";
import ContractDetail from "./components/ContractDetail";
import FormFifty from "./components/FormFifty";
import FormPayment from "./components/FormPayment";
import { QuestionDialog } from "./components/QuestionDialog";
import { TerminateContractDialog } from "./components/TerminateContractDialog";

class AdminContract extends Component {
  state = {
    contract: {},
    sendingFile: false,
    step: false,
    newPrevisionAmount: { previsionAmount: 4500 },
    btn: true,
    terminate: false,
    cancel: false,
    verifyCancel: false,
    modal: false,
    loading: true,
    sendingTransaction: false,
    transactionIdSent: null,
    forceSubmitTransaction: false,
    disableBt: true,
    popupAportForm: null,
  };

  componentDidMount() {
    this.loadContract();
  }

  loadContract() {
    this.setState({ contract: {} });
    getContract(this.props.match.params.id).then((data) => {
      if (data.status == "ARCHIVE") {
        getRedemptionPayment(data.id).then((payment) => {
          this.setState({ payment });
        });
      }
      this.setState({ contract: data, loading: false });
    });
  }

  validateTxBeforeSubmitAport = (formProps) => {
    const { forceSubmitTransaction } = this.state;
    return new Promise((resolve, reject) => {
      this.setState({
        sendingTransaction: true,
      });
      const networkOnly = formProps.network.split("/")[1];
      validateCriptoAddress(formProps.transactionId)
        .then((data) => {
          const networks = data
            .filter((item) => item.type === "tx" && item.network === networkOnly)
            .map((item) => item.network);
          if (networks.length === 0 && !forceSubmitTransaction) {
            reject("NETWORK_ERROR");
          } else {
            resolve();
          }
        })
        .catch((error) => {
          if (!forceSubmitTransaction) {
            reject(error.message);
          } else {
            resolve();
          }
        });
    });
  };

  onAportSubmitOk = () => {
    this.props.reset();
    this.setState({
      popupAportForm: false,
      sendingTransaction: false,
      transactionIdSent: null,
      forceSubmitTransaction: false,
      transactionIdSent: null,
    });
    this.loadContract();
  };

  onAportSubmitError = (transactionIdSent) => {
    this.setState({
      sendingTransaction: false,
      forceSubmitTransaction: false,
      transactionIdSent,
    });
  };

  onSubmitAport = (formProps) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    this.validateTxBeforeSubmitAport(formProps)
      .then(() => {
        sendContractTransaction(this.state.contract.id, formProps)
          .then((data) => {
            showSuccessMessage("app.editContract.aportValue");
            this.onAportSubmitOk();
          })
          .catch((error) => {
            showErrorMessage(error.response.data.message);
            this.onAportSubmitError(null);
          });
      })
      .catch((errorMessage) => {
        if (errorMessage == "TRANSACTION_ERROR") {
          showErrorMessage("Transação inválida\nNão foi encontrada em rede alguma.");
        } else if (errorMessage == "NETWORK_ERROR") {
          showErrorMessage("Transação inválida\nNão encontrada na rede indicada.");
        } else {
          showErrorMessage("Erro de conexão ao validar.\nNão foi possível validar a transação.");
        }
        this.onAportSubmitError(formProps.transactionId);
      });
  };

  terminate = (params) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    terminateContract(this.state.contract.id, params)
      .then((data) => {
        showSuccessMessage("app.contract.finished.successfully");
        this.setState({ contract: data, terminate: false });
      })
      .catch((e) => {
        showErrorMessage(e.message);
      });
  };

  cancel = () => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    cancelContract(this.props.match.params.id)
      .then((data) => {
        showSuccessMessage("app.terminated.successfully");
        this.loadContract();
        this.setState({ cancel: false });
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  goEdit = () => {
    const { history } = this.props;
    const { contract } = this.state;
    history.push("/editContract/" + contract.id);
  };

  validateContract() {
    const { contract } = this.state;
    const { showErrorMessage, showSuccessMessage } = this.props;
    validateContract(contract.id)
      .then((data) => {
        showSuccessMessage("app.contract.validated");
        this.loadContract();
      })
      .catch((error) => {
        showErrorMessage(error.response.data.message);
      });
  }

  awaitingValidationReturnedContract() {
    const { contract } = this.state;
    const { showErrorMessage, showSuccessMessage } = this.props;
    awaitingValidationContract(contract.id)
      .then((data) => {
        showSuccessMessage("app.awaitingValidation.returned");
        this.loadContract();
      })
      .catch((error) => {
        showErrorMessage(error.response.data.message);
      });
  }

  render() {
    const { handleSubmit, currentUser } = this.props;
    const { contract, payment, modal, popupAportForm, sendingTransaction, transactionIdSent } = this.state;
    const numberMask = createNumberMask({
      decimalPlaces: 2,
      locale: "pt-BR",

      onChange: (v) => {
        if (v < contract.amount) {
          this.setState({ btn: true });
        } else {
          this.setState({
            btn: false,
            newPrevisionAmount: { previsionAmount: v },
          });
        }
      },
    });
    if (this.state.loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    }

    const explorerURL = {
      "USDT/TRX": "https://tronscan.org/#/transaction/",
      "USDT/ETH": "https://etherscan.io/tx/",
      "BTC/BTC": "https://www.blockchain.com/pt/btc/tx/",
      "NONE/NONE": "https://tokenview.com/en/search/",
    };
    return (
      <PageContainer>
        <HeaderPageItem
          title="app.manageContract"
          showBackButton
          destination={
            "/user/" + (contract.user ? contract.user.id : <CircularProgress style={{ marginTop: "25%" }} />)
          }
          onMore={(e) => this.setState({ menuAnchor: e.target })}
          values={contract.id}
        >
          <Menu
            onClose={(e) => this.setState({ menuAnchor: undefined })}
            open={!!this.state.menuAnchor}
            anchorEl={this.state.menuAnchor}
          >
            <MenuItem
              disabled={contract.phase !== "EXECUTING"}
              component={Link}
              to={"/contract/" + contract.id}
              onClick={(e) => this.setState({ menuAnchor: undefined })}
            >
              <FormattedMessage id="app.contractDetails" />
            </MenuItem>

            {/* 
                    O menu de editar está sempre disponível porque lá dentro edita-se as observações.
                    O form encarrega-se de impedir mudanças impróprias como, por exemplo, tipo ou valor se o contrato já está ativo.
                    Condição original era:
                    disabled={contract.status !== "INACTIVE" || contract.phase == "VALIDATING"}
            */}
            {(currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && (
              <MenuItem
                component={Link}
                to={"/editContract/" + contract.id}
                onClick={(e) => {
                  this.setState({ menuAnchor: undefined });
                }}
              >
                <FormattedMessage id="app.editContract" />
              </MenuItem>
            )}

            {currentUser.role == Roles.Admin && (
              <>
                <MenuItem
                  disabled={contract.phase !== "EXECUTING"}
                  onClick={(e) => {
                    this.setState({ terminate: true });
                    this.setState({ menuAnchor: undefined });
                  }}
                >
                  <FormattedMessage id="app.finishContract" />
                </MenuItem>

                {contract.phase == "VALIDATING" && (
                  <MenuItem
                    onClick={(e) => {
                      this.awaitingValidationReturnedContract();
                      this.setState({ menuAnchor: undefined });
                    }}
                  >
                    <FormattedMessage id="app.awaitingValidation.return" />
                  </MenuItem>
                )}

                <MenuItem
                  disabled={contract.status !== "INACTIVE"}
                  onClick={(e) => {
                    this.setState({ verifyCancel: true });
                    this.setState({ menuAnchor: undefined });
                  }}
                >
                  <FormattedMessage id="app.cancelContract" />
                </MenuItem>
                <MenuItem
                  disabled={contract.status == "ARCHIVE"}
                  onClick={(e) => {
                    this.setState({ step: true, menuAnchor: undefined });
                  }}
                >
                  {!contract.splitAgent ? (
                    <FormattedMessage id="app.addOrRemove.Fifty" />
                  ) : (
                    <FormattedMessage id="app.editOrRemove.Fifty" />
                  )}
                </MenuItem>
              </>
            )}
          </Menu>
        </HeaderPageItem>
        <FormDialog
          title={!contract.splitAgent ? "app.addOrRemove.Fifty" : "app.editOrRemove.Fifty"}
          open={this.state.step}
          variant="secondary"
          onClose={(e) => this.setState({ step: false })}
        >
          <FormFifty
            contract={contract}
            onActionComplete={(e) => {
              this.loadContract();
              this.setState({ step: false });
            }}
          />
        </FormDialog>
        <TerminateContractDialog
          title={"app.finishContract"}
          onCancel={() => {
            this.setState({ terminate: false });
          }}
          open={this.state.terminate}
          onOk={(choose) => () => this.terminate(choose)}
        >
          <FormattedMessage id="app.finishContract.text" />
        </TerminateContractDialog>

        <QuestionDialog
          title={<FormattedMessage id="app.cancelContract" />}
          onCancel={() => this.setState({ cancel: true, verifyCancel: false })}
          open={this.state.verifyCancel}
          onOk={() => {
            this.goEdit();
          }}
        >
          <>
            <InfoItem
              caption={<FormattedMessage id="app.estimatedAmount" />}
              text={<Number value={contract.previsionAmount} currency="LCT" />}
            />
            <InfoItem
              caption={<FormattedMessage id="app.aport.value" />}
              text={<Number value={contract.amount} currency="LCT" />}
            />
            <FormattedMessage id="app.cancelContract.text1" />
            <br />
            <br />
            <FormattedMessage id="app.cancelContract.text2" />
            <br />
            <br />
            <FormattedMessage id="app.cancelContract.text3" />
          </>
        </QuestionDialog>

        <QuestionDialog
          title={<FormattedMessage id="app.cancelContract" />}
          onCancel={() => this.setState({ cancel: false })}
          open={this.state.cancel}
          onOk={() => {
            this.cancel();
          }}
        >
          <FormattedMessage id="app.cancelContract.text" />
        </QuestionDialog>

        <CardPageItem>
          <CardContent>
            {Object.entries(contract).length > 0 ? (
              contract.status == "ACTIVE" ? (
                <>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                </>
              ) : contract.status == "INACTIVE" ? (
                <>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  {Object.entries(contract.deposits).length > 0 && (
                    <>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id="app.aports" />
                      </Typography>

                      <List dense>
                        {contract.deposits.map((d) => (
                          <ListItem
                            key={d.id}
                            component="a"
                            button
                            href={explorerURL[d.network] + d.transactionId}
                            target="_blank"
                          >
                            <ListItemText
                              primary={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Moment date={d.createdAt} format="DD/MM/YYYY" /> <Typography>{d.network}</Typography>
                                  <Number value={d.amount} currency="LCT" />
                                </div>
                              }
                            />
                            <ChevronRight color="action" />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  {contract.phase == "WAITING_APORT" && (
                    <>
                      <Button
                        id="bShowAportPopup"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16, marginBottom: 16 }}
                        onClick={(e) => this.setState({ popupAportForm: true })}
                      >
                        <FormattedMessage id="app.type.network.2" />
                      </Button>
                      <FormDialog
                        open={popupAportForm}
                        title="app.type.network.2"
                        variant="secondary"
                        noHeader
                        onClose={(e) => this.setState({ popupAportForm: false })}
                      >
                        {sendingTransaction ? (
                          <CircularProgress style={{ marginTop: "25%" }} />
                        ) : (
                          <form onSubmit={handleSubmit(this.onSubmitAport)} style={{ marginTop: 16 }}>
                            <Field
                              name="network"
                              label="app.type.network"
                              component={customSelect}
                              values={[
                                { value: "BTC/BTC", input: networkNameByNetwork["BTC/BTC"] },
                                { value: "USDT/TRX", input: networkNameByNetwork["USDT/TRX"] },
                                { value: "USDT/ETH", input: networkNameByNetwork["USDT/ETH"] },
                                { value: "NONE/NONE", input: "Nenhuma" },
                              ]}
                              InputLabelProps={{ shrink: true }}
                            />
                            <Field
                              name="transactionId"
                              fullWidth
                              component={CustomInputForm}
                              label={"app.aport.transaction"}
                              style={{ marginTop: 16 }}
                              InputLabelProps={{ shrink: true }}
                            />
                            <Field
                              name="amount"
                              fullWidth
                              component={CustomInputForm}
                              {...numberMask}
                              label={"app.aport.value"}
                              style={{ marginTop: 16 }}
                              InputLabelProps={{ shrink: true }}
                            />
                            <Button
                              id="bSubmitAport"
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="large"
                              type="submit"
                              style={{ marginTop: 16 }}
                              disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                            >
                              <FormattedMessage id="app.sendTransaction" />
                            </Button>
                            <div style={{ marginTop: 48 }}>
                              {transactionIdSent && (
                                <>
                                  <Typography gutterBottom>
                                    Verifique a transação manualmente.
                                    <br />
                                    Se a rede estiver incorreta, basta corrigir selecionando outra acima.
                                    <br />
                                    Como último recurso, utilize o botão para forçar o envio.
                                    <br />
                                  </Typography>
                                  <Button
                                    id="bManuallyVerifyTransaction"
                                    component="a"
                                    target="_blank"
                                    fullWidth
                                    variant="outlined"
                                    href={"https://tokenview.com/en/search/" + transactionIdSent}
                                  >
                                    <FormattedMessage id="Verificar transação manualmente" />
                                  </Button>
                                  <Button
                                    id="bForceSubmitOrderForm"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    style={{ marginTop: 16 }}
                                    disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                                    onClick={(e) => this.setState({ forceSubmitTransaction: true })}
                                  >
                                    <FormattedMessage id="Forçar envio da transação" />
                                  </Button>
                                </>
                              )}
                            </div>
                          </form>
                        )}
                      </FormDialog>
                      {Object.entries(contract.deposits).length > 0 && (
                        <>
                          <Divider style={{ marginBottom: 24 }} />
                          <Typography
                            gutterBottom
                            variant="body1"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "red",
                            }}
                          >
                            <FormattedMessage id="app.type.network.1" />
                          </Typography>
                          <Button
                            id="bValidateContract"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            disabled={contract.amount <= 1}
                            onClick={() => this.validateContract()}
                          >
                            <FormattedMessage id="app.lccontract.validate" />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                  {payment && (
                    <>
                      <CardPageItem raised style={{ marginTop: "10px" }}>
                        <CardContent>
                          <Typography variant="caption" color="primary">
                            <FormattedMessage id="app.payments" />
                          </Typography>
                          <CardActionArea
                            disabled={payment.payed}
                            key={payment.id}
                            onClick={(e) => this.setState({ modal: true })}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div key={payment.id} style={{ flexGrow: 1 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography color="primary">{payment.id}</Typography>
                                    <Typography color="textSecondary">
                                      (
                                      {payment.payed ? (
                                        <FormattedMessage id="app.paid" />
                                      ) : (
                                        <FormattedMessage id="app.paid.not" />
                                      )}
                                      )
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography color="textSecondary" variant="caption">
                                      <FormattedMessage id="app.fine" />
                                    </Typography>
                                    <Typography color="textSecondary">
                                      <Number value={payment.fine} currency="LCT" />
                                    </Typography>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography color="textSecondary">
                                      <Number value={payment.value} currency="LCT" />
                                    </Typography>

                                    <Typography color="textSecondary">
                                      <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: 16,
                                }}
                              >
                                <ChevronRightIcon color="action" />
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </CardContent>
                      </CardPageItem>
                      <FormDialog
                        title="app.payment.edit"
                        open={modal}
                        variant="secondary"
                        noHeader
                        onClose={(e) => {
                          this.setState({ modal: false });
                        }}
                      >
                        <FormPayment
                          type={payment}
                          onActionComplete={() => {
                            this.setState({ modal: false });
                            this.loadContract();
                          }}
                        />
                      </FormDialog>
                    </>
                  )}
                </>
              )
            ) : (
              <></>
            )}
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  } else if (values.transactionId.trim().length == 0) {
    errors.transactionId = "app.aport.invalid";
  }
  if (!values.network) {
    errors.network = "app.transaction.report";
  }
  if (!values.amount) {
    errors.amount = "app.transaction.report";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "transForm",
    validate,
  }),
  connect(mapStateToProps, actions)
)(AdminContract);
