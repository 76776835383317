import React, { Component } from "react";
import { cnpjNormalizer, cpfNormalizer } from "../../util";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
class ContractAgentDescriptionBR extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { currentUser, contractAgent } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p>
            <span>CONTRATO DE PRESTA&Ccedil;&Atilde;O DE SERVI&Ccedil;O AUT&Ocirc;NOMO</span>
          </p>
          <ol>
            <li>
              <p>DAS PARTES</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 1&ordf;: Lux Capital, LLC., pessoa jur&iacute;dica de direito privado com sede sito
                    1309 Coffeen Avenue, Suite 1816, Sheridan Wyoming 82801, United States, inscrita sob n&ordm;
                    2020-927450, doravante denominada CONTRATANTE,
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 2&ordf;: e de outro lado, {currentUser.name}{" "}
                    {currentUser.type == "NATURAL_PERSON" ? (
                      <FormattedMessage id="app.naturalPerson" />
                    ) : (
                      <FormattedMessage id="app.company" />
                    )}{" "}
                    {currentUser.documentType}{" "}
                    {currentUser.type == "NATURAL_PERSON"
                      ? currentUser.taxResidence == "Brasil"
                        ? cpfNormalizer(currentUser.documentId)
                        : currentUser.documentId
                      : currentUser.taxResidence == "Brasil"
                      ? cnpjNormalizer(currentUser.documentId)
                      : currentUser.documentId}
                    , residente na{" "}
                    {currentUser.address +
                      " - " +
                      currentUser.city +
                      "/" +
                      currentUser.state +
                      " - " +
                      currentUser.country}
                    , neste instrumento denominada CONTRATADA, decidem por firmar contrato de presta&ccedil;&atilde;o de
                    servi&ccedil;os aut&ocirc;nomos regidos pelas cl&aacute;usulas abaixo acordadas:
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>DO OBJETO</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 3&ordf;: &Eacute; objeto do presente contrato a presta&ccedil;&atilde;o de
                    servi&ccedil;os a ser realizado pela CONTRATADA em prol da CONTRATANTE, exercida pela
                    divulga&ccedil;&atilde;o e apresenta&ccedil;&atilde;o &agrave; clientes, demonstrando os
                    servi&ccedil;os que est&atilde;o dispon&iacute;veis pela CONTRATANTE, dentro da sua capacidade
                    empresarial nos termos jur&iacute;dicos por ela disposta, tratando-se exclusivamente de
                    promo&ccedil;&atilde;o de tornar conhecido e de como fazer uso dos servi&ccedil;os oferecidos pela
                    CONTRATANTE.
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 4&ordf;: A presta&ccedil;&atilde;o do servi&ccedil;o objeto deste instrumento
                    ser&aacute; executada na regi&atilde;o determinada pela CONTRATADA, a qual deve ser informada
                    &agrave; CONTRATANTE quanto a escolha do local de atua&ccedil;&atilde;o;{" "}
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>DO PAGAMENTO</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 5&ordf;: Pelo cumprimento do objeto deste instrumento, identificado na
                    Cl&aacute;usula 3&ordf;, a CONTRATADA receber&aacute;, mensalmente, uma porcentagem do volume
                    custodiado de cada LCContract ativo sob sua responsabilidade.
                    <br />
                    <br />
                    <ol>
                      <li>
                        Par&aacute;grafo Primeiro: o volume custodiado de um LCContract corresponde ao valor aportado
                        pelo cliente no momento da abertura do mesmo, conforme descrito nos TERMOS E
                        CONDI&Ccedil;&Otilde;ES DE USO.
                      </li>
                      <li>
                        Par&aacute;grafo Segundo: O valor aportado pelo cliente em um LCContract &eacute; oriundo de
                        decis&atilde;o pessoal, aut&ocirc;noma e individual dele, que assim deseja aderir aos
                        servi&ccedil;os disponibilizados pela CONTRATANTE.
                      </li>
                      <li>
                        Par&aacute;grafo Terceiro: um LCContract &eacute; considerado ativo no per&iacute;odo entre sua
                        data de ativa&ccedil;&atilde;o e data de t&eacute;rmino, ou seja, o per&iacute;odo durante o
                        qual est&aacute; gerando acr&eacute;scimo de volume para o cliente.
                        <br />O valor da porcentagem varia de acordo com o tipo de LCContract, onde contratos de maior
                        dura&ccedil;&atilde;o e fechados (vide os TERMOS E CONDI&Ccedil;&Otilde;ES DE USO para detalhes)
                        geram um valor maior. De forma geral, as porcentagens variam entre 1% (um por cento) e 1,5% (um
                        e meio por cento);
                      </li>
                    </ol>
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 6&ordf;: Os pagamentos referentes &agrave; presta&ccedil;&atilde;o de servi&ccedil;o
                    realizados ser&atilde;o efetuados por meio de cr&eacute;dito na conta da CONTRATADA na plataforma.
                    Os valores referentes ao comissionamento da presta&ccedil;&atilde;o de servi&ccedil;o ser&atilde;o
                    pagos na stable coin determinada conforme o pa&iacute;s do cadastro da CONTRATADA.
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 7&ordf;: O pagamento do comissionamento ocorrer&aacute; no dia de anivers&aacute;rio
                    do LCContract ao qual se refere. <br />
                    <br />
                    <ol>
                      <li>
                        Par&aacute;grafo &Uacute;nico: O dia de anivers&aacute;rio de um LCContract &eacute; o dia em
                        que ele foi ativado, contabilizando um anivers&aacute;rio a cada m&ecirc;s.{" "}
                      </li>
                    </ol>
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 8&ordf;: O saque dos valores da conta na plataforma dever&aacute; ser feito conforme
                    regras v&aacute;lidas para todos usu&aacute;rios da mesma, conforme descrito nos TERMOS E
                    CONDI&Ccedil;&Otilde;ES DE USO. <br />
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATANTE</p>
              <ol>
                <li>
                  CL&Aacute;USULA 9&ordf;: Fica obrigada a contratante a: <br />
                  a) fornecer informa&ccedil;&atilde;o, esclarecimentos e capacita&ccedil;&atilde;o para o bom
                  desenvolvimento das atividades objeto deste ato contratual;
                  <br />
                  b) fornecer suporte t&eacute;cnico necess&aacute;rio para o seguimento da atividade objeto deste
                  contrato;
                  <br />
                  c) fiscalizar a atividade da CONTRATADA e propiciar que a mesma desenvolva &agrave;s atividades de
                  acordo com as normativas e orienta&ccedil;&otilde;es proporcionada da CONTRATANTE;
                  <br />
                  d) realizar o pagamento dos servi&ccedil;os prestados observado o disposto nas cl&aacute;usulas
                  5&ordf; a 7&ordf; do presente termo.
                  <br />
                  e) avisar previamente a CONTRATADA das reuni&otilde;es, cursos ou treinamentos a serem realizados pela
                  CONTRATANTE ou por quem esta, exclusivamente, indicar apta(o) para tal, com anteced&ecirc;ncia de 5
                  dias.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATADA</p>
              <ol>
                <li>
                  CL&Aacute;USULA 10&ordf;: Fica obrigada a contratada &agrave;:
                  <br />
                  a) realizar a presta&ccedil;&atilde;o de servi&ccedil;o objeto deste contrato de acordo com as normas,
                  informa&ccedil;&otilde;es e capacita&ccedil;&atilde;o proporcionada pela CONTRATANTE, ou por quem
                  esta, exclusivamente, indicar apta(o) para tal;
                  <br />
                  b) exercer, de maneira personal&iacute;ssima, a presta&ccedil;&atilde;o de servi&ccedil;o objeto deste
                  contrato;
                  <br />
                  c) prestar de maneira clara e objetiva, informa&ccedil;&otilde;es e orienta&ccedil;&otilde;es aos
                  clientes sobre a funcionalidade e servi&ccedil;os da empresa CONTRATANTE;
                  <br />
                  d) fornecer, informar, preencher adequadamente &agrave; plataforma os dados dos respectivos clientes
                  &agrave; empresa CONTRATANTE.
                  <br />
                  e) fornecer &agrave; CONTRATANTE, sempre que solicitado, no prazo de 48 horas, as
                  informa&ccedil;&otilde;es referente &agrave; presta&ccedil;&atilde;o de servi&ccedil;os deste
                  contrato.
                  <br />
                  f) participar das reuni&otilde;es, cursos ou treinamentos proporcionados pela empresa CONTRATANTE,
                  previamente agendados, ou por quem esta, exclusivamente, indicar apta(o) para tal.
                  <br />
                  g) arcar com os custos de deslocamento, estadias, transportes, seguros ou quaisquer outras despesas
                  necess&aacute;rias para o atendimento aos seus clientes, bem como ao comparecimento &agrave;s
                  reuni&otilde;es, cursos e treinamentos previamente agendados pela CONTRATANTE, ou por quem esta,
                  exclusivamente, indicar apta(o) para tal.
                  <br />
                  h) responder adequadamente a declara&ccedil;&atilde;o dos tributos fiscais dos valores recebidos em
                  prol da presta&ccedil;&atilde;o de servi&ccedil;o efetuada.
                </li>
              </ol>
            </li>
            <li>
              <p>DA VIG&Ecirc;NCIA CONTRATUAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 11&ordf;: O presente contrato de presta&ccedil;&atilde;o de servi&ccedil;o passa a
                  vigorar na data de sua assinatura, passando a ser vigente por prazo indeterminado.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>DA RESCIS&Atilde;O CONTRATUAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 12&ordf;: A rescis&atilde;o imotivada do contrato &eacute; facultada por ambas as
                  partes e pode ser solicitada com aviso pr&eacute;vio de 30 (trinta) dias, por meio de
                  notifica&ccedil;&atilde;o escrita, salvo quando interrup&ccedil;&otilde;es n&atilde;o
                  sist&ecirc;micas, governamentais ou de &oacute;rg&atilde;os regulamentadores e fiscalizadores, que por
                  ventura possam vir a alterar o modus operandi do mercado em quest&atilde;o.
                </li>
                <li>
                  CL&Aacute;USULA 13&ordf;: O contrato poder&aacute; ainda ser rescindido de pleno direito e sem
                  pr&eacute;via notifica&ccedil;&atilde;o, por justo motivo, este entendido como:
                  <br />
                  <br />
                  <ol>
                    <li>
                      Par&aacute;grafo Primeiro: Unilateralmente, pela parte inocente, na hip&oacute;tese de
                      descumprimento de qualquer das obriga&ccedil;&otilde;es assumidas neste contrato, sem
                      preju&iacute;zo da indeniza&ccedil;&atilde;o, pela parte infratora &agrave; inocente, pelas
                      eventuais perdas e danos a que a parte infratora der causa;
                    </li>
                    <li>
                      Par&aacute;grafo Segundo: No caso de pedido, decreta&ccedil;&atilde;o ou homologa&ccedil;&atilde;o
                      de recupera&ccedil;&atilde;o judicial ou extrajudicial, fal&ecirc;ncia, liquida&ccedil;&atilde;o,
                      dissolu&ccedil;&atilde;o ou inequ&iacute;voca situa&ccedil;&atilde;o de insolv&ecirc;ncia da outra
                      parte;{" "}
                    </li>
                    <li>
                      Par&aacute;grafo Terceiro: Comprovada a ocorr&ecirc;ncia de caso fortuito ou for&ccedil;a maior.
                    </li>
                    <li>
                      Par&aacute;grafo Quarto: Em quaisquer dessas hip&oacute;teses de rescis&atilde;o contratual,
                      &eacute; garantido &agrave;s partes o acerto de contas pelos servi&ccedil;os j&aacute; prestados,
                      at&eacute; a &uacute;ltima data efetiva de servi&ccedil;os prestados.{" "}
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>DA RESPONSABILIDADE TRABALHISTA</p>
              <ol>
                <li>
                  CL&Aacute;USULA 14&ordf;: Fica estipulado neste, que n&atilde;o se estabelece, por for&ccedil;a do
                  presente contrato, qualquer v&iacute;nculo de natureza empregat&iacute;cia ou de responsabilidade
                  entre o CONTRATANTE e CONTRATADA para a execu&ccedil;&atilde;o dos servi&ccedil;os, sendo a CONTRATADA
                  &uacute;nica e exclusiva respons&aacute;vel por todas as obriga&ccedil;&otilde;es e encargos
                  decorrentes da legisla&ccedil;&atilde;o vigente, seja a trabalhista, previdenci&aacute;ria ou qualquer
                  outra, obrigando-se, assim a CONTRATADA ao cumprimento das disposi&ccedil;&otilde;es legais no que se
                  refere &agrave; remunera&ccedil;&atilde;o da m&atilde;o de obra que utilizar e a outras
                  obriga&ccedil;&otilde;es e encargos de qualquer natureza. <br />
                  <br />
                  {contractAgent.status == "PENDING" ? (
                    <>
                      <Checkbox
                        checked={this.state.checked1}
                        onChange={(e) => {
                          this.setState({ checked1: e.target.checked });
                        }}
                        color="primary"
                      />
                      <span style={{ color: "red" }}>CIENTE</span>
                    </>
                  ) : (
                    " "
                  )}
                </li>
                <li>
                  CL&Aacute;USULA 15&ordf;: A CONTRATANTE n&atilde;o se responsabiliza por quaisquer d&iacute;vidas
                  trabalhistas, tribut&aacute;rias ou quaisquer outras que possua a CONTRATADA. Estas s&atilde;o de sua
                  exclusiva e intransfer&iacute;vel responsabilidade;
                </li>
                <li>
                  CL&Aacute;USULA 16&ordf;: Eventuais condena&ccedil;&otilde;es trabalhistas da CONTRATADA advindas da
                  rela&ccedil;&atilde;o jur&iacute;dica aqui estabelecida entre as partes, poder&atilde;o ser
                  descontadas do pagamento mensal devido &agrave; CONTRATADA. Caso o valor das condena&ccedil;&otilde;es
                  supere as import&acirc;ncias devidas pela, a CONTRATADA ressarcir&aacute; a CONTRATANTE no valor da
                  diferen&ccedil;a, podendo ainda as verbas serem descontadas de pagamentos futuros.
                  <br />
                  <br />
                  {contractAgent.status == "PENDING" ? (
                    <>
                      {" "}
                      <Checkbox
                        checked={this.state.checked2}
                        onChange={(e) => {
                          this.setState({ checked2: e.target.checked });
                        }}
                        color="primary"
                      />
                      <span style={{ color: "red" }}>CIENTE</span>
                    </>
                  ) : (
                    " "
                  )}
                </li>
              </ol>
            </li>
            <li>
              <p>DA RESPONSABILIDADE TRIBUT&Aacute;RIA</p>
              <ol>
                <li>
                  CL&Aacute;USULA 17&ordf;: Caso a CONTRATANTE seja obrigado a pagar ou indenizar import&acirc;ncia
                  referente a tributos n&atilde;o pagos pela CONTRATADA, ficar&aacute; este &uacute;ltimo obrigado a
                  ressarcir todos valores despendidos, incluindo eventuais custas processuais e honor&aacute;rios
                  advocat&iacute;cios, no prazo de at&eacute; 5 dias do efetivo pagamento.
                </li>
              </ol>
            </li>
            <li>
              <p>DA RESPONSABILIDADE CIVIL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 18&ordf;: A CONTRATADO dever&aacute; reparar imediatamente qualquer erro, perda ou
                  dano resultante do descumprimento e/ou do cumprimento das obriga&ccedil;&otilde;es contratuais em
                  desconformidade com o presente contrato, obrigando-se a ressarcir a CONTRATANTE ou terceiros
                  prejudicados{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>DA CONFIDENCIALIDADE</p>
              <ol>
                <li>
                  CL&Aacute;USULA 19&ordf;: As partes contratantes reconhecem que o v&iacute;nculo estipulado neste
                  contrato &eacute; baseado em rela&ccedil;&atilde;o de fidelidade e boa-f&eacute; rec&iacute;proca e
                  ficam a partir cientes que, no exerc&iacute;cio de suas fun&ccedil;&otilde;es, poder&atilde;o ter
                  acesso a informa&ccedil;&otilde;es e materiais que n&atilde;o s&atilde;o de conhecimento do
                  p&uacute;blico em geral e que a parte reveladora considera ser de sua propriedade exclusiva e de
                  car&aacute;ter confidencial e, em caso de solicita&ccedil;&atilde;o de algum material informativo ou
                  confidencial relacionado com as atividades mencionadas neste contrato, fica com exclusiva
                  responsabilidade de fornecer, a seu crit&eacute;rio, a empresa CONTRATANTE, eximindo perante &agrave;s
                  autoridades e demais &oacute;rg&atilde;os governamentais a responsabilidade da CONTRATADA em fornecer
                  qualquer material ou dado informativo, tendo em vista se tratar de assunto de confidencialidade.
                </li>
                <li>
                  CL&Aacute;USULA 20&ordf;: &Eacute; vedado a qualquer das partes usar em benef&iacute;cio
                  pr&oacute;prio ou de terceiros quaisquer informa&ccedil;&otilde;es privilegiadas para o
                  desenvolvimento de atividade igual ou similar &agrave; desenvolvida pela outra parte, pelo
                  per&iacute;odo igual ou maior de 5 (cinco) anos, quando da resolu&ccedil;&atilde;o do contrato.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 21&ordf;: A obriga&ccedil;&atilde;o de sigilo estabelecida nessa cl&aacute;usula
                  perdurar&aacute; ap&oacute;s a extin&ccedil;&atilde;o deste contrato, pelo per&iacute;odo de 10 (dez)
                  anos.
                </li>
                <li>
                  CL&Aacute;USULA 22&ordf;: Todas as tratativas, negocia&ccedil;&otilde;es, contratos, manuais,
                  circulares, notifica&ccedil;&otilde;es, treinamentos, reuni&otilde;es, certid&otilde;es, documentos
                  cont&aacute;beis, ou quaisquer informa&ccedil;&otilde;es a respeito da atividade desenvolvida pela
                  CONTRATANTE s&atilde;o estritamente confidenciais, n&atilde;o podendo ser divulgados por qualquer
                  meio, m&iacute;dia ou sob qualquer justificativa, com exce&ccedil;&atilde;o das previstas em Lei, sob
                  pena de aplica&ccedil;&atilde;o de multa contratual, estipulada no presente instrumento.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 23&ordf;: O presente contrato e seus anexos, se houver, tornam-se tamb&eacute;m
                  informa&ccedil;&atilde;o confidencial e por isso a sua exist&ecirc;ncia e seu conte&uacute;do
                  n&atilde;o poder&atilde;o ser revelados a terceiros, sen&atilde;o mediante autoriza&ccedil;&atilde;o
                  expressa da CONTRATANTE.
                </li>
                <li>
                  CL&Aacute;USULA 24&ordf;: Em caso de d&uacute;vida sobre a confidencialidade de determinada
                  informa&ccedil;&atilde;o, a CONTRATADA dever&aacute; mant&ecirc;-la em absoluto sigilo at&eacute; que
                  o CONTRATANTE se manifeste expressamente a respeito.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 25&ordf;: A n&atilde;o observ&acirc;ncia das cl&aacute;usulas de confidencialidade
                  importar&aacute; em responsabilidade c&iacute;vel e criminal pelas partes, as quais poder&atilde;o ser
                  dirimidas e apuradas em Ju&iacute;zo.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>DO JU&Iacute;ZO ARBITRAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 26&ordf;: Este contrato &eacute; aplic&aacute;vel de acordo com as leis de
                  jurisdi&ccedil;&atilde;o dos pa&iacute;ses onde essa transa&ccedil;&atilde;o &eacute; realizada e
                  qualquer disputa dever&aacute; ser resolvida sob as regras da ICC Brasil para arbitragem, a menos que
                  a parte lesada tome medidas legais em um tribunal de jurisdi&ccedil;&atilde;o.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 27&ordf;: Os contratantes reconhecem e concordam que qualquer diverg&ecirc;ncia
                  disputa na aplica&ccedil;&atilde;o deste o acordo ser&aacute; resolvido, primeiramente amigavelmente,
                  salvo se n&atilde;o for poss&iacute;vel, o procedimento de arbitragem deve ser seguido.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 28&ordf;: Adicionalmente as partes podem definir a sede da arbitragem, o idioma, o
                  n&uacute;mero de &aacute;rbitros, a forma de custeio, dentre outros.
                </li>
              </ol>
            </li>
            <li>
              <p>DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</p>
              <ol>
                <li>
                  CL&Aacute;USULA 29&ordf;: A toler&acirc;ncia por qualquer uma das partes, no que tange ao cumprimento
                  das obriga&ccedil;&otilde;es da outra parte, n&atilde;o ser&aacute; considerada nova&ccedil;&atilde;o
                  ou perd&atilde;o, permanecendo as cl&aacute;usulas deste contrato em pleno vigor, da forma aqui
                  prevista.
                </li>
                <li>
                  CL&Aacute;USULA 30&ordf;: O presente contrato e os direitos e obriga&ccedil;&otilde;es dele
                  decorrentes n&atilde;o poder&atilde;o ser cedidos, transferidos ou sub-rogados por quaisquer das
                  partes sem o pr&eacute;vio consentimento por escrito das outras partes;
                </li>
              </ol>
            </li>
          </ol>
          <p>
            E por estarem assim justas e contratadas, as partes assinam o presente instrumento em duas vias de igual
            teor e forma, perante as testemunhas tamb&eacute;m abaixo assinadas, juntamente com profissional advogado
            identificado juntamente com sua credencial.
          </p>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    // currentUser: state.user.user.me
  };
}
//export default ContractAgentDescription;
export default compose(connect(mapStateToProps, actions))(ContractAgentDescriptionBR);
