import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { CustomInput, CustomSelect } from "../../custom";
import * as actions from "../../actions";
import performRequest from "../../configAxios";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Roles } from "../../util";
import InputFile from "../../custom/InputFile";
// import IMG2B from "../../static/cnhError.jpg";
// import IMG1B from "../../static/cnhOk.jpg";
// import IMG2A from "../../static/rgError.jpg";
// import IMG1A from "../../static/rgOk.jpg";
import platformConfig from "../../platformConfig";

class FormSendDoc extends Component {
  render() {
    const IMG1A = platformConfig.asset("/images/kyc/rgOk.jpg");
    const IMG2A = platformConfig.asset("/images/kyc/rgError.jpg");
    const IMG1B = platformConfig.asset("/images/kyc/cnhOk.jpg");
    const IMG2B = platformConfig.asset("/images/kyc/cnhError.jpg");

    return (
      <>
        <FormattedHTMLMessage id="app.documents.doc.dialog.1" />
        <br />

        <div style={{ alignContent: "center", marginTop: 32 }}>
          <img src={IMG1A} alt={1} height={150} style={{ margin: 8 }} />
          <img src={IMG1B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        {platformConfig.id === "luxcapital" ? (
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ color: "green", marginRight: 8 }}>✓</div>
            <FormattedHTMLMessage id="app.documents.doc.dialog.2" />
          </div>
        ) : (
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ color: "green", marginRight: 8 }}>✓</div>
            <FormattedHTMLMessage id="app.documents.doc.dialog.2.1" />
          </div>
        )}
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.doc.dialog.4" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.doc.dialog.3" />
        </div>

        <div style={{ alignContent: "center", marginTop: 32 }}>
          <img src={IMG2A} alt={1} height={150} style={{ margin: 8 }} />
          <img src={IMG2B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.doc.dialog.5" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.doc.dialog.6" />
        </div>
        {platformConfig.id === "digiplata" ? (
          <>
            {" "}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 32 }}>
              <Button
                color={this.props.disabledbtn1 ? "inherit" : "primary"}
                style={{ margin: 4 }}
                variant="contained"
                onClick={this.props.onActionSendFront}
              >
                <FormattedMessage id="app.documents.idWithPhoto.dialog.front" />
              </Button>

              <Button
                color={this.props.disabledbtn2 ? "inherit" : "primary"}
                style={{ margin: 4 }}
                variant="contained"
                onClick={this.props.onActionSendBack}
              >
                <FormattedMessage id="app.documents.idWithPhoto.dialog.back" />
              </Button>
            </div>
            <Button
              fullWidth
              disabled={this.props.disabled}
              color="primary"
              variant="contained"
              style={{ marginBottom: 16, marginTop: 32 }}
              onClick={this.props.onActionComplete}
            >
              <FormattedMessage id="app.ok" />
            </Button>
          </>
        ) : (
          <Button
            fullWidth
            // disabled={this.props.disabled}
            color="primary"
            variant="contained"
            style={{ marginBottom: 16, marginTop: 32 }}
            onClick={this.props.onActionComplete}
          >
            <FormattedMessage id="app.documents.idWithPhoto.dialog.button" />
          </Button>
        )}
      </>
    );
  }
}
export default FormSendDoc;
