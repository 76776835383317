import { AppBar, Badge, CardContent, IconButton, Typography, withTheme } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getKyc } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { FormattedMessage } from "react-intl";

class AgentsManagerDashboard extends Component {
  state = {
    pj: this.props.currentUser.isPj,
  };

  loadKyc = () => {
    getKyc().then((data) => {
      const userKyc = data;
      this.setState({ userKyc });
    });
  };
  componentDidMount() {
    this.loadKyc();
  }
  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { userKyc, pj } = this.state;
    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);

    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout" color="inherit">
              <ExitToAppIcon style={{ transform: "rotate(180deg)" }} />
            </IconButton>
            <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more" color="inherit">
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: `8px ${palette.primary.main} solid` }}
              style={{}}
            />
          </AppBar>
        </HeaderPageItem>

        <CardPageItem>
          <CardContent>
            <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
              {currentUser.name}
              <br />
              (<FormattedMessage id="app.enuns.AGENTS_MANAGER" />)
            </Typography>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, null)(AgentsManagerDashboard));
