import { Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { contractAddAgent, contractEditAgent, contractRemoveAgent, getContract } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";
import NumberComp from "../../custom/Number";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class FormFifty extends Component {
  state = {
    contract: {},
    splitPercent: 0,
  };

  addFifty = (formProps) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    formProps.splitPercent /= 100;
    contractAddAgent(this.props.contract.id, formProps)
      .then((data) => {
        showSuccessMessage("app.successfully.added");
        this.props.onActionComplete();
      })
      .catch((e) => {
        //app.agent.notFound
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  editFifty = (formProps) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    formProps.splitPercent /= 100;
    contractEditAgent(this.props.contract.id, formProps)
      .then((data) => {
        showSuccessMessage("app.edit.sucesfully");
        this.props.onActionComplete();
      })
      .catch((e) => {
        //app.agent.notFound
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  remove = () => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    contractRemoveAgent(this.props.contract.id)
      .then((data) => {
        showSuccessMessage("app.successfully.removed");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  loadContract() {
    this.setState({ contract: {} });
    getContract(this.props.contract.id).then((data) => {
      this.setState({ contract: data });
      if (data.splitAgent) {
        const splitPercent = Number(data.splitPercent) * 100;
        this.setState({ splitPercent });
        this.props.change("splitAgentId", data.splitAgent.id);
        this.props.change("splitPercent", splitPercent);
        this.props.change("fixedAgent", data.fixedAgent);
      }
    });
  }
  componentDidMount() {
    this.loadContract();
  }

  render() {
    const { handleSubmit, currentUser, contract } = this.props;
    if (!contract || !contract.id) return null;

    const contractCommission = contract.type.agentCommission / 100;

    const splitPercent = this.state.splitPercent / 100;
    const splitCommission = contractCommission * splitPercent;
    const splitValue = contract.amount * splitCommission;

    const agentPercent = 1 - splitPercent;
    const agentCommission = contractCommission - splitCommission;
    const agentValue = contract.amount * agentCommission;

    return (
      <Fragment>
        <Typography gutterBottom>
          <FormattedMessage id="app.fifty.text" />
        </Typography>
        <form onSubmit={handleSubmit(contract.splitAgent ? this.editFifty : this.addFifty)}>
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
            <Field
              component={CustomInputForm}
              name="splitPercent"
              label="app.fiftyPercent"
              type="Number"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0, max: 100, style: { minWidth: 180 } }}
              onChange={(e) => this.setState({ splitPercent: e.target.value })}
              disabled={contract.fixedAgent && currentUser.role != "ADMIN" && currentUser.role != "COMERCIAL"}
            />
            <Field
              component={CustomInputForm}
              name="splitAgentId"
              label="app.agentId"
              type="Number"
              InputLabelProps={{ shrink: true }}
              disabled={contract.fixedAgent && currentUser.role != "ADMIN" && currentUser.role != "COMERCIAL"}
            />
            {(currentUser.role == "ADMIN" || currentUser.role == "COMERCIAL") && (
              <FormControlLabel
                label={<FormattedMessage id="app.isFixed" />}
                control={<Field component={FormCheckbox} name="fixedAgent" color="primary" />}
              />
            )}
          </div>

          <table cellPadding="0">
            <tbody>
              <tr>
                <td colSpan="2">
                  <Typography style={{ marginTop: 16 }}>
                    <b>LCContract C{contract.id}</b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Volume custodiado:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={contract.amount} currency="LCT" />
                  </Typography>
                </td>
              </tr>
              <tr>
                <td width="180">
                  <Typography>Comissão base:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={contractCommission} display="percentage" fractionDigits={3} />
                  </Typography>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <Typography style={{ marginTop: 16 }}>
                    <b>Comissão do Split</b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Porcentagem efetiva:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={splitCommission} display="percentage" fractionDigits={3} /> (
                    <NumberComp value={splitPercent} display="percentage" /> da base)
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Valor efetivo:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={splitValue} currency="LCT" />
                  </Typography>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <Typography style={{ marginTop: 16 }}>
                    <b>Comissão do agente</b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Porcentagem efetiva:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={agentCommission} display="percentage" fractionDigits={3} /> (
                    <NumberComp value={agentPercent} display="percentage" /> da base)
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Valor efetivo:</Typography>
                </td>
                <td>
                  <Typography>
                    <NumberComp value={agentValue} currency="LCT" />
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

          {contract.fixedAgent && currentUser.role != "ADMIN" && currentUser.role != "COMERCIAL" ? (
            <>
              <br />
              <Typography>
                Não é possível editar ou remover o split porque foi <b>fixado</b> pelo administrador.
              </Typography>
            </>
          ) : (
            <>
              {!contract.splitAgent ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                  style={{ marginTop: 16 }}
                >
                  <FormattedMessage id="app.add" />
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                  style={{ marginTop: 16 }}
                >
                  <FormattedMessage id="app.edit" />
                </Button>
              )}
              <Button
                fullWidth
                variant="outlined"
                color="default"
                disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                onClick={this.remove}
                style={{ marginTop: 16 }}
              >
                <FormattedMessage id="app.remove" />
              </Button>
            </>
          )}
        </form>
      </Fragment>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.splitAgentId) {
    errors.splitAgentId = "app.agentId.empty";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "addFifty",
    validate,
  }),
  connect(null, actions)
)(FormFifty);
