import {
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  withTheme,
} from "@material-ui/core";
import moment from "moment";
import QRCode from "qrcode.react";
import React, { Component, Fragment } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { getConfigurações } from "../actions";
import {
  downloadContract,
  generateContractPDF,
  generateContractReceipt,
  getContract,
  sendContractPDF,
  sendContractReceiptPDF,
  sendContractTransaction,
} from "../actions/Contract";
import CustomInputForm from "../custom/CustomInput";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { downloadBase64, validateContract } from "../util";
import CustomerAccept from "./CustomerAccept";
import * as momentTimezone from "moment-timezone";
import platformConfig from "../platformConfig";
import ContractDepositPopup from "./ContractDepositPopup";

class CustomerInactiveContract extends Component {
  state = {
    contract: {},
    transaction: null,
    menuAnchor: undefined,
    showQRCode: false,
    aportTime: false,
    showTransactionForm: false,
    sendingTransaction: false,
    loading: true,
    sendingFile: false,
    receipt: false,
    accept: false,
    config: {},
    disableBt: false,
    dataOpen: momentTimezone(),
    dataClose: momentTimezone(),
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const { contract, config } = this.state;
    this.loadContract();
    if (
      Object.entries(contract).length == 0 ||
      Object.entries(config).length == 0 ||
      validateContract(currentUser, contract) == false
    ) {
      this.setState({ loading: false });
    }
  }

  loadContract() {
    this.setState({ contract: {}, loading: true });
    getContract(this.props.match.params.id).then((contracts) => {
      getConfigurações().then((config) => {
        let dataOpen = momentTimezone(config.startTime, "HH:mm:ss");
        dataOpen.tz(this.props.currentUser.timezone);
        let dataClose = momentTimezone(config.finishTime, "HH:mm:ss");
        dataClose.tz(this.props.currentUser.timezone);
        this.setState({
          contract: contracts,
          config: config,
          dataOpen: dataOpen,
          dataClose: dataClose,
          loading: false,
        });
      });
    });
  }

  confirm = (formProps) => {
    const { showSuccessMessage } = this.props;
    showSuccessMessage("app.send.success");
    this.props.history.push("/agentDataCustomer/" + this.state.contract.user);
  };

  showQRCode = (e) => {
    this.setState({ showQRCode: true });
  };

  render() {
    const { currentUser, handleSubmit } = this.props;
    const { contract, transaction, showTransactionForm, showQRCode } = this.state;
    if (this.state.loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else if (contract.status == "CANCELED") {
      return (
        <PageContainer>
          <HeaderPageItem
            title="app.lccontract"
            showBackButton
            destination={"/contracts"}
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              {currentUser.role == "AGENT" && (
                <MenuItem
                  disabled={currentUser.role == "CUSTOMER"}
                  component={Link}
                  to={"/editContract/" + contract.id}
                  onClick={(e) => this.setState({ menuAnchor: undefined })}
                >
                  <FormattedMessage id="app.editContract" />
                </MenuItem>
              )}
            </Menu>
          </HeaderPageItem>
        </PageContainer>
      );
    } else if (contract.status == "ARCHIVE") {
      return (
        <PageContainer>
          <HeaderPageItem
            title="app.lccontract"
            showBackButton
            destination={"/contracts"}
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              {currentUser.role == "AGENT" && (
                <MenuItem
                  disabled={currentUser.role == "CUSTOMER"}
                  component={Link}
                  to={"/editContract/" + contract.id}
                  onClick={(e) => this.setState({ menuAnchor: undefined })}
                >
                  <FormattedMessage id="app.editContract" />
                </MenuItem>
              )}
            </Menu>
          </HeaderPageItem>
        </PageContainer>
      );
    } else if (contract.phase == "WAITING_SIGN") {
      return (
        <PageContainer>
          <HeaderPageItem
            title="app.lccontract"
            showBackButton
            destination={"/contracts"}
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              {currentUser.role == "AGENT" && (
                <MenuItem
                  disabled={currentUser.role == "CUSTOMER"}
                  component={Link}
                  to={"/editContract/" + contract.id}
                  onClick={(e) => this.setState({ menuAnchor: undefined })}
                >
                  <FormattedMessage id="app.editContract" />
                </MenuItem>
              )}
            </Menu>
          </HeaderPageItem>

          <CardPageItem>
            <CardContent>
              <>
                {/* Preciso saber se isso ainda existe no sistema. No caso 
                Aqui exibiria apenas um texto "Enviando arquivo..." e nada mais */}
                {this.state.sendingFile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{ marginBottom: 8 }} />
                    <Typography>
                      <FormattedMessage id="app.file.sending" />
                      ...
                    </Typography>
                  </div>
                ) : (
                  <>
                    <input
                      ref={(r) => (this.inputPDF = r)}
                      id="inputPDF"
                      style={{ display: "none" }}
                      accept={this.docFileMask}
                      type="file"
                      onChange={this.sendSigned}
                    />
                    {contract.fileUrl ? (
                      <>
                        <Typography gutterBottom color="primary" variant="h6">
                          <FormattedMessage id="app.lccontract.submitted.successfully" />.
                        </Typography>
                        <Typography gutterBottom style={{ marginBottom: 32 }}>
                          <FormattedMessage id="app.qrcode.text3" />
                        </Typography>

                        <Typography gutterBottom>
                          <FormattedMessage id="app.lccontract.waitingApproval.administration" />
                        </Typography>
                        <Typography gutterBottom variant="caption" color="textSecondary">
                          <FormattedMessage id="app.lccontract.text1" />
                        </Typography>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginTop: 16, marginRight: 8 }}
                          onClick={this.downloadContract}
                        >
                          <FormattedMessage id="app.file.viewUpload" />
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginTop: 16 }}
                          onClick={(e) => this.inputPDF.click()}
                        >
                          <FormattedMessage id="app.resend" />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography gutterBottom color="primary" variant="h6">
                          <FormattedMessage id="app.contract.waitingAssign.text1" />
                        </Typography>
                        <Typography gutterBottom variant="body1" style={{ marginBottom: 32 }}>
                          <FormattedMessage id="app.contract.waitingAssign.text2" />
                        </Typography>
                        <Typography gutterBottom>
                          <FormattedMessage id="app.contract.waitingAssign.text3" />
                        </Typography>

                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 16 }}
                          onClick={() => this.setState({ accept: true })}
                        >
                          <FormattedMessage id="app.readContract" />
                        </Button>
                        <FormDialog
                          title="app.lccontracts"
                          open={this.state.accept}
                          variant="secondary"
                          noHeader
                          onClose={(e) => this.setState({ accept: false })}
                        >
                          <CustomerAccept
                            acceptContract
                            contractId={contract.id}
                            onOk={(e) => {
                              this.loadContract();
                            }}
                          />
                        </FormDialog>
                      </>
                    )}
                  </>
                )}
              </>
            </CardContent>
          </CardPageItem>
        </PageContainer>
      );
    } else if (showTransactionForm) {
      return (
        <ContractDepositPopup
          contractId={contract.id}
          onClose={() => {
            this.setState({ showTransactionForm: false });
            this.loadContract();
          }}
        />
      );
    } else if (contract.phase == "WAITING_APORT") {
      const amountLeft = contract.previsionAmount - contract.amount;
      const now = moment();
      now.tz(this.props.currentUser.timezone);
      const isWeekend = [0, 6].includes(now.weekday());
      let showAport = now.isBefore(this.state.dataOpen) || now.isAfter(this.state.dataClose) || isWeekend;

      return (
        <PageContainer>
          <HeaderPageItem
            title={"app.lccontract"}
            showBackButton
            destination={"/contracts"}
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              {currentUser.role == "AGENT" && (
                <MenuItem
                  disabled={currentUser.role == "CUSTOMER"}
                  component={Link}
                  to={"/editContract/" + contract.id}
                  onClick={(e) => this.setState({ menuAnchor: undefined })}
                >
                  <FormattedMessage id="app.editContract" />
                </MenuItem>
              )}
            </Menu>
          </HeaderPageItem>

          {contract.amount == 0 ? (
            <Fragment>
              <CardPageItem>
                <CardContent>
                  <Typography gutterBottom color="primary" variant="h6">
                    <FormattedMessage id="app.contract.waitingDeposit.text1" />
                  </Typography>
                  <Typography gutterBottom variant="body1" style={{ marginBottom: 32 }}>
                    <FormattedMessage id="app.contract.waitingAssign.text2" />
                  </Typography>

                  <InfoItem
                    caption={<FormattedMessage id="app.estimatedAmount" />}
                    text={<Number value={contract.previsionAmount} currency="LCT" />}
                  />

                  <Typography gutterBottom>
                    <FormattedMessage id="app.contract.waitingDeposit.text3" />
                  </Typography>

                  <Button
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 16 }}
                    onClick={(e) => this.setState({ showTransactionForm: true })}
                    disabled={showAport}
                  >
                    <FormattedMessage id="app.contract.waitingDeposit.button2" />
                  </Button>
                  {!showAport ? (
                    <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                      <Typography>
                        <FormattedMessage id="app.contract.waitingDeposit.text8" />
                      </Typography>
                      <>&nbsp;</>
                      <Typography variant="h6" color="primary" style={{ marginBottom: -4 }}>
                        <FormattedMessage
                          id="app.contract.waitingDeposit.text9"
                          values={{
                            startTime: this.state.dataOpen.format("HH:mm:ss").toString(),
                            finishTime: this.state.dataClose.format("HH:mm:ss").toString(),
                          }}
                        />
                      </Typography>
                    </Grid>
                  ) : (
                    <div>
                      <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                        <Typography variant="h6" color="primary" style={{ marginTop: 16, marginBottom: -4 }}>
                          <FormattedMessage
                            id="app.contract.waitingDeposit.text4"
                            values={{
                              startTime: this.state.dataOpen.format("HH:mm:ss").toString(),
                              finishTime: this.state.dataClose.format("HH:mm:ss").toString(),
                            }}
                          />
                        </Typography>
                        <>&nbsp;</>
                        <Typography variant="subtitle1" color="primary" style={{ marginBottom: -2 }}>
                          <FormattedMessage id="app.contract.waitingDeposit.text5" />
                        </Typography>
                      </Grid>
                    </div>
                  )}
                  <Typography gutterBottom style={{ marginTop: 16 }} color="primary">
                    <FormattedHTMLMessage
                      id="app.contract.waitingDeposit.text6"
                      values={{ company: platformConfig.name }}
                    />
                  </Typography>
                </CardContent>
              </CardPageItem>
            </Fragment>
          ) : (
            contract.amount > 0 && (
              <Fragment>
                <CardPageItem>
                  <CardContent>
                    <Typography gutterBottom color="primary" variant="h6">
                      <FormattedMessage id="app.aport.validating.your" />
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      <FormattedMessage id="app.aport.validating.your.text1" />
                    </Typography>
                  </CardContent>
                </CardPageItem>
              </Fragment>
            )
          )}
        </PageContainer>
      );
    } else if (contract.phase == "VALIDATING") {
      return (
        <Fragment>
          <PageContainer>
            <CardPageItem>
              <HeaderPageItem
                title={"app.lccontract"}
                showBackButton
                destination={"/contracts"}
                onMore={(e) => this.setState({ menuAnchor: e.target })}
                values={contract.id}
              >
                <Menu
                  onClose={(e) => this.setState({ menuAnchor: undefined })}
                  open={!!this.state.menuAnchor}
                  anchorEl={this.state.menuAnchor}
                >
                  <MenuItem
                    component={Link}
                    to={"/contractDetail/" + contract.id}
                    onClick={(e) => this.setState({ menuAnchor: undefined })}
                  >
                    <FormattedMessage id="app.contractDetails" />
                  </MenuItem>
                  {currentUser.role == "AGENT" && (
                    <MenuItem
                      disabled={currentUser.role == "CUSTOMER" || contract.phase == "VALIDATING"}
                      component={Link}
                      to={"/editContract/" + contract.id}
                      onClick={(e) => this.setState({ menuAnchor: undefined })}
                    >
                      <FormattedMessage id="app.editContract" />
                    </MenuItem>
                  )}
                </Menu>
              </HeaderPageItem>
              <CardContent>
                <Typography gutterBottom color="primary" variant="h6">
                  <FormattedMessage id="app.accept.contractUpdate.text" />
                </Typography>
                <Typography gutterBottom>
                  <FormattedMessage id="app.accept.contractUpdate.text2" />
                </Typography>
                <Typography gutterBottom>
                  <FormattedMessage id="app.accept.contractUpdate.text3" />
                </Typography>
                <Typography gutterBottom>
                  <FormattedMessage id="app.receipt.text4" />
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16 }}
                  onClick={() => this.setState({ receipt: true })}
                >
                  <FormattedMessage id="app.accept.contractUpdate.button" />
                </Button>
                <FormDialog
                  title="app.contracts"
                  open={this.state.receipt}
                  variant="secondary"
                  noHeader
                  onClose={(e) => this.setState({ receipt: false })}
                >
                  <CustomerAccept
                    acceptReceipt
                    contractId={contract.id}
                    onOk={(e) => {
                      this.props.history.go(0);
                    }}
                  />
                </FormDialog>
              </CardContent>
            </CardPageItem>
          </PageContainer>
        </Fragment>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    config: state.config,
  };
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  } else {
    if (values.transactionId.length > 64 || values.transactionId.length < 34) {
      errors.transactionId = "app.transactionId.invalid";
    }
  }

  return errors;
};

export default compose(
  withRouter,
  withTheme,
  reduxForm({ form: "transForm", validate }),
  connect(mapStateToProps, actions)
)(CustomerInactiveContract);
