import {
  Button,
  CardContent,
  CircularProgress,
  IconButton,
  InputBase,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { changeAgentAdmin, clearAgent } from "../../actions";
import performRequest from "../../configAxios";
import AbtcAvatar from "../../custom/Avatar";
import FormDialog from "../../custom/FormDialog";
import wpp from "../../static/whatsapp.png";
import { capitalCaseNormalizer, documentFormatter, userDocumentFormatter } from "../../util";
import { phoneFormatter, Roles } from "../../util/index";
import platformConfig from "../../platformConfig";

class UserDetail extends Component {
  state = {
    loading: true,
    user: {},
    currentUser: {},
    edit: false,
    openDialogClearAgent: false,
    agents: [],
  };

  componentDidMount() {
    this.loadData();
    this.setState({
      user: this.props.user,
      currentUser: this.props.currentUser,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.loadData();
    }
  }

  getAgents = () => {
    const { user } = this.state;
    performRequest("GET", `user?role=AGENT`, null, true)
      .then((response) => {
        const agents = response.data.data.filter((a) => a.id != user.id);
        this.setState({ agents, loading: false });
      })
      .catch();
  };
  searchUsers = (name) => {
    if (name.trim().length > 0) {
      performRequest("GET", `user?role=AGENT&name=${name}`, null, true)
        .then((response) => {
          this.setState({ agents: response.data.data });
        })
        .catch();
    } else {
      this.getAgents();
    }
  };
  change = (id, ag) => {
    const agId = {
      consultantId: ag,
    };
    changeAgentAdmin(id, agId).then((data) => {
      this.setState({ user: data });
    });
  };
  clearAgent = () => {
    const { user } = this.state;
    clearAgent(user.id).then((data) => {
      this.loadData();
      this.setState({ user: data, openDialogClearAgent: false });
    });
  };
  loadData = () => {
    const { currentUser, user } = this.props;
    //const { user } = this.state;
    performRequest("GET", `/user/${user}`, null, true)
      .then((response) => {
        let user = response.data.data;
        if (user.role == Roles.Agent && user.contractAgent == null) {
          this.setState({ loading: false, user: user, contractAgentOpen: true });
          this.getAgents();
        } else if (currentUser.role == Roles.Customer || currentUser.role == Roles.FinanceManager) {
          this.setState({ loading: false, user: user });
        } else {
          this.setState({ loading: false, user: user });
          this.getAgents();
        }
      })
      .catch((e) => {
        this.props.history.push("/notfound");
      });
  };
  userDocumentType = (document, personType) => {
    if (document == "NIT" && personType == "NATURAL_PERSON") {
      return <FormattedMessage id={`app.cedula`} />;
    } else {
      return <FormattedMessage id={`app.enuns.${document}`} />;
    }
  };
  render() {
    const { agents, loading, user, currentUser } = this.state;
    const role = {
      agent: currentUser.role == Roles.Agent,
      admin: currentUser.role == Roles.Admin,
      client: currentUser.role == Roles.Customer,
    };

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <AbtcAvatar imageUrl={user.photoUrl} disableLink size={120} />
        </div>
        {/* nome */}

        {!user.apt ? (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              {user.id}
              {" - "}
              <FormattedMessage id={"app.enuns." + user.role} />
              <span style={{ color: "red" }}>
                {" - "}
                <FormattedMessage id="app.inapt" />
              </span>
            </Typography>
            <Typography gutterBottom>{user.name}</Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              {user.id}
              {" - "}
              <FormattedMessage id={"app.enuns." + user.role} />
              <span style={{ color: "green" }}>
                {" - "}
                <FormattedMessage id="app.apt" />
              </span>
            </Typography>

            <Typography>{user.name}</Typography>
          </>
        )}
        {/* telefones */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.phone" />
        </Typography>
        <Typography gutterBottom component="div">
          {!user.telefone && !user.telefone2 ? (
            <FormattedMessage id="app.empty" />
          ) : (
            <>
              {user.telefone && (
                <div>
                  {phoneFormatter(user.telefone)}{" "}
                  {user.isWhats && (
                    <a href={"https://wa.me/55" + user.telefone} target="_blank" rel="noopener noreferrer">
                      <img src={wpp} width="14" height="14" alt=" " />
                    </a>
                  )}
                </div>
              )}
              {user.telefone2 && (
                <div>
                  {phoneFormatter(user.telefone2)}{" "}
                  {user.isWhats2 && (
                    <a href={"https://wa.me/55" + user.telefone2} target="_blank" rel="noopener noreferrer">
                      <img src={wpp} width="14" height="14" alt=" " />
                    </a>
                  )}
                </div>
              )}
            </>
          )}
        </Typography>
        {/* email */}
        {!user.emailConfirmed ? (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.email" />{" "}
              <span style={{ color: "red" }}>
                <FormattedMessage id="app.not.confirmed" />
              </span>
            </Typography>
            <Typography gutterBottom>{user.email}</Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.email" />
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: -4,
              }}
            >
              <Typography>
                {user.email}
                <IconButton size="small" disabled style={{ color: "green" }}>
                  <DoneIcon />
                </IconButton>
              </Typography>
            </div>
          </>
        )}

        {/* domicilio fiscal */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.taxResidence" />
        </Typography>
        <Typography gutterBottom>{user.taxResidence}</Typography>
        {/* documentos */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          {this.userDocumentType(user.documentType, user.type)}
          {/* <FormattedMessage id={`app.enuns.${user.documentType}`} /> */}
        </Typography>
        <Typography gutterBottom>{userDocumentFormatter(user, <FormattedMessage id="app.empty" />)}</Typography>
        {user.isPj && (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.legalRepresentativeName" />
            </Typography>
            <Typography gutterBottom>
              {user.legalRepresentativeName ? user.legalRepresentativeName : <FormattedMessage id="app.empty" />}
            </Typography>
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.legalRepresentativeDocumentId" />
            </Typography>
            <Typography gutterBottom>
              {documentFormatter(user.legalRepresentativeId, "CPF", <FormattedMessage id="app.empty" />)}
            </Typography>
          </>
        )}
        {!user.isPj && (
          <>
            {/* data de nascimento */}
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.dateOfBirth" />
            </Typography>
            <Typography gutterBottom>
              {user.dateOfBirth ? moment(user.dateOfBirth).format("DD/MM/YYYY") : <FormattedMessage id="app.empty" />}
            </Typography>
          </>
        )}
        {/* endereço */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.address" />
        </Typography>
        <Typography gutterBottom>
          {user.address ? user.address : "N/D"}, {user.city ? user.city : "N/D"} - {user.state ? user.state : "N/D"} -{" "}
          {user.country ? user.country : "N/D"}
        </Typography>
        {/* observações */}
        {user.observations && (currentUser.role == Roles.Admin || currentUser.role == Roles.Kyc) && (
          <>
            <Typography gutterBottom color="textSecondary" variant="caption">
              <FormattedMessage id="app.obs" />
            </Typography>
            <Typography gutterBottom>{user.observations}</Typography>
          </>
        )}
        {/* data de cadastro */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.dateRegister" />
        </Typography>
        <Typography gutterBottom>{moment(user.createdAt).format("DD/MM/YYYY")}</Typography>
        {/* idioma */}
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.language" />
        </Typography>
        <Typography gutterBottom>
          {user.locale == "br" ? (
            <FormattedMessage id="app.language.br" />
          ) : user.locale == "es" ? (
            <FormattedMessage id="app.language.es" />
          ) : (
            <FormattedMessage id="app.language.en" />
          )}
        </Typography>
        {/* status do contrato de agente */}
        {platformConfig.id !== "digiplata" && (
          <>
            {user.role == Roles.Agent &&
              (user.contractAgent ? (
                user.contractAgent.signatureDate ? (
                  <>
                    <Typography gutterBottom color="textSecondary" variant="caption">
                      <FormattedHTMLMessage id="app.contractAgent" />
                    </Typography>
                    <Typography gutterBottom>
                      <FormattedMessage id={`app.enuns.${user.contractAgent.status}`} />{" "}
                      <FormattedMessage id="app.contractAgent.signed" />
                      {moment(user.contractAgent.signatureDate).format("DD/MM/YYYY")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography gutterBottom color="textSecondary" variant="caption">
                      <FormattedHTMLMessage id="app.contractAgent" />
                    </Typography>
                    <Typography gutterBottom>
                      <FormattedMessage id={`app.enuns.${user.contractAgent.status}`} />{" "}
                    </Typography>
                  </>
                )
              ) : (
                <>
                  <Typography gutterBottom color="textSecondary" variant="caption">
                    <FormattedHTMLMessage id="app.contractAgent" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.none" />
                  </Typography>
                </>
              ))}

            {user.consultant ? (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography gutterBottom color="textSecondary" variant="caption">
                    {user.consultant.id} {" - "}
                    <FormattedMessage id="app.agent.assigned" />
                  </Typography>
                  <div color="textSecondary" style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      style={{ alignSelf: "center", textDecoration: "none" }}
                      component={
                        currentUser.role == Roles.Admin ||
                        currentUser.role == Roles.BusinessManager ||
                        currentUser.role == Roles.Auditor ||
                        currentUser.role == Roles.AgentsManager
                          ? Link
                          : null
                      }
                      to={`/user/${user.consultant.id}`}
                    >
                      {user.consultant.name}
                    </Typography>
                    <div>
                      {(currentUser.role == Roles.Admin || currentUser.role == Roles.BusinessManager) && (
                        <Tooltip title={<FormattedMessage id="app.changeAgentOrAgentManager" />}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              this.setState({ edit: true });
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {currentUser.role == Roles.Admin && (
                        <Tooltip title={<FormattedMessage id="app.agentOrAgentManager.clear" />}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              this.setState({ openDialogClearAgent: true });
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {((currentUser.role == Roles.Admin && user.role != Roles.Admin) ||
                  currentUser.role == Roles.BusinessManager) && (
                  <Button
                    disabled={currentUser.role == Roles.Kyc}
                    style={{ marginTop: 16 }}
                    fullWidth
                    variant="outlined"
                    onClick={(e) => {
                      this.setState({ edit: true });
                    }}
                  >
                    <FormattedMessage id="app.addAgent" />
                  </Button>
                )}
              </>
            )}
          </>
        )}

        <FormDialog
          title="app.changeAgent"
          open={this.state.edit}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ edit: false })}
        >
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.changeAgent.text" />
          </Typography>
          <IconButton aria-label="Search">
            <SearchIcon />
          </IconButton>
          <FormattedMessage id="app.searchAgent">
            {(placeholder) => (
              <InputBase placeholder={placeholder} onChange={(e) => this.searchUsers(e.target.value)} />
            )}
          </FormattedMessage>

          {agents.map((c) => {
            if (user.id != c.id) {
              return (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={c.id}
                  onClick={(e) => {
                    this.change(user.id, c.id);
                    this.setState({ edit: false });
                  }}
                >
                  <ListItemAvatar>
                    <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "div" }}
                    secondaryTypographyProps={{ component: "div" }}
                    primary={
                      <div
                        color="textSecondary"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{capitalCaseNormalizer(c.name)}</span>
                        <span> {"Id: " + c.id}</span>
                      </div>
                    }
                    secondary={
                      <div>
                        <div
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{c.email}</span>
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              );
            }
          })}
        </FormDialog>
        <Dialog open={this.state.openDialogClearAgent}>
          <DialogTitle>
            <FormattedMessage id="app.agentOrAgentManager.clear.text" />
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ marginTop: 16 }}
              variant="outlined"
              onClick={(e) => {
                this.clearAgent();
              }}
            >
              <FormattedMessage id="app.clear" />
            </Button>
            <Button
              style={{ marginTop: 16 }}
              variant="outlined"
              onClick={(e) => {
                this.setState({ openDialogClearAgent: false });
              }}
            >
              <FormattedMessage id="app.back" />
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    );
  }
}

export default UserDetail;
