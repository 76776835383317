import { Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import FormDialog from "../../custom/FormDialog";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import LCContract from "./LCContract";
import platformConfig from "../../platformConfig";

class ContractDetail extends Component {
  state = {
    lccontract: false,
  };

  render() {
    const { contract, user } = this.props;
    const role = {
      agent: user.role == "AGENT",
      admin: user.role == "ADMIN",
      client: user.role == "CUSTOMER",
    };
    const c = {
      status: contract.status,
      phase: contract.phase,
      canceledBy: contract.canceledBy,
      modal: contract.type.type,
      description: contract.type.description,
      duration: contract.type.durationInMonth,
      prevision: contract.previsionAmount,
      volume: contract.amount,
      limit: contract.type.maxMonthlyYield,
      datecreate: contract.createdAt,
      dateopen: contract.initialDate,
      dateclose: contract.dueDate,
      lastDate: contract.updatedAt,
      split: contract.splitAgent,
      percAgent: contract.type.agentCommission,
      customer: this.props.contract.user.name,
      customerId: this.props.contract.user.id,
      agent: { id: this.props.contract.user.consultantId, name: this.props.contract.user.consultantName },
      comments: contract.comments,
      addendum: contract.addendum,
      investmentId: contract.investmentId,
    };
    const segment = ["N/A", "Geral", "AS Capital", "Introduce", "Sem agente"];
    return (
      <>
        {c.phase && (
          <InfoItem color="primary" caption="LCContract" text={<FormattedMessage id={`app.enuns.${c.phase}`} />} />
        )}
        {c.status && <InfoItem caption="Status" text={<FormattedMessage id={`app.enuns.${c.status}`} />} />}
        {c.canceledBy && (
          <>
            <InfoItem
              color="primary"
              caption={<FormattedMessage id="app.contract.finishedBy" />}
              text={
                c.canceledBy == "CUSTOMER" ? (
                  <FormattedMessage id="app.contract.canceledBy.customer" />
                ) : (
                  <FormattedMessage id={platformConfig.name} />
                )
              }
            />
            <InfoItem
              color="primary"
              caption={<FormattedMessage id="app.contract.finishedAt" />}
              text={<Moment date={c.lastDate} format="DD/MM/YYYY" />}
            />
          </>
        )}

        <InfoItem caption={<FormattedMessage id="app.customer" />} text={c.customer + " (" + c.customerId + ")"} />
        {c.volume ? (
          <InfoItem
            caption={<FormattedMessage id="app.aport.value" />}
            text={<Number value={c.volume} currency="LCT" />}
          />
        ) : (
          <InfoItem
            caption={<FormattedMessage id="app.estimatedAmount" />}
            text={<Number value={c.prevision} currency="LCT" />}
          />
        )}
        <InfoItem caption={<FormattedMessage id="app.description" />} text={c.description} />

        {(role.agent || role.admin) && c.percAgent && (
          <>
            <InfoItem
              caption={<FormattedMessage id="app.contract.effectiveCommission" />}
              text={
                <>
                  <Number value={(1 - contract.splitPercent) * c.percAgent} display="percent" /> (
                  <Number value={(((1 - contract.splitPercent) * c.percAgent) / 100) * c.volume} currency="LCT" />)
                  {role.admin ? " - " + c.agent.name + " (" + c.agent.id + ")" : ""}
                </>
              }
            />
          </>
        )}
        {role.admin && (
          <>
            {c.split && (
              <InfoItem
                caption="Split"
                text={
                  <>
                    <Number value={contract.splitPercent * c.percAgent} display="percent" /> (
                    <Number value={((contract.splitPercent * c.percAgent) / 100) * c.volume} currency="LCT" />){" - "}
                    {c.split.name} ({c.split.id}){role.admin && contract.fixedAgent ? " - FIXO" : ""}
                  </>
                }
              />
            )}
            <InfoItem
              caption={<FormattedMessage id="app.contract.baseCommission" />}
              text={
                <>
                  <Number value={c.percAgent} display="percent" /> (
                  <Number value={(c.percAgent / 100) * c.volume} currency="LCT" />)
                </>
              }
            />
          </>
        )}

        {c.datecreate && (
          <InfoItem
            caption={<FormattedMessage id="app.createdAt" />}
            text={<Moment date={c.datecreate} format="DD/MM/YYYY" />}
          />
        )}
        {c.dateopen && (
          <InfoItem
            caption={<FormattedMessage id="app.activationDate" />}
            text={<Moment date={c.dateopen} format="DD/MM/YYYY" />}
          />
        )}
        {c.dateclose && (
          <InfoItem
            caption={<FormattedMessage id="app.dueDate" />}
            text={<Moment date={c.dateclose} format="DD/MM/YYYY" />}
          />
        )}

        {role.admin && (
          <>
            <InfoItem
              caption={<FormattedMessage id="app.editContract.investmentId" />}
              text={segment[c.investmentId]}
            />
            <InfoItem caption={<FormattedMessage id="app.editContract.comments" />} text={c.comments ?? "-"} />
          </>
        )}

        <Button fullWidth variant="outlined" onClick={() => this.setState({ lccontract: true })}>
          <FormattedMessage id="app.contract.show" />
        </Button>

        <FormDialog
          title="LCContract"
          open={this.state.lccontract}
          variant="secondary"
          onClose={(e) => this.setState({ lccontract: false })}
        >
          <Typography gutterBottom component="div">
            <LCContract
              amount={c.volume > 0 ? c.volume : c.prevision}
              duration={c.duration}
              type={c.modal}
              max={c.duration * c.limit}
              lang={user.locale}
              addendum={c.addendum}
            />
          </Typography>
        </FormDialog>
      </>
    );
  }
}

export default ContractDetail;
