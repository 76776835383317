import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

export const LCTDisplayModes = {
  Original: 0,
  Converted: 1,
};

let LCTDisplayMode = LCTDisplayModes.Original;
let LCTRate = {
  BRL: 0,
  USD: 0,
  EUR: 0,
};
let LCTConvertedCurrency = "BRL";

let loadingConfig = false;

export const getLCTDisplayMode = () => {
  return LCTDisplayMode;
};

export const setLCTDisplayMode = (mode) => {
  LCTDisplayMode = mode;
};

export const setLCTConvertedCurrency = (currency) => {
  LCTConvertedCurrency = currency;
};

export const setLCTRate = (data) => {
  LCTRate = data.LCTFiat;
};

/**
 * @augments {Component<{}>}
 * Exemplos de uso:
 *       <Number value={15.258}/>
 *       -> 15,26
 *
 *       <Number value={15.258} currency="BRL" display="symbol"/>
 *       -> R$ 15,26
 *
 *       <Number value={15.258} currency="BRZ" display="code"/>
 *       -> 15,25800000 BTC
 *
 *       <Number value={15.258} display="percent" fractionDigits={1}/>
 *       -> 15,3%
 *
 *       <Number value={-15.258} color="auto" bold/>
 *       -> -15,26 (em vermelho e negrito)
 *
 * Observação: LCT é um código coringa, quando usado, este componente
 * troca ele pelo código obtido no objeto config.
 * Para isso, caso o config não esteja carregado, isso é feito com uma chamada a getConfig.
 * Nesse caso, durante a chamanda o Number retorna apenas "...", indicando que não é
 * possível mostrar o valor ainda.
 */
class Number extends Component {
  static propTypes = {
    /**
     * Valor a ser mostrado.
     *
     * Obrigatório.
     */
    value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),

    /**
     * Localização: default: localização do user logado.
     */
    locale: PropTypes.string,

    /**
     * Controla prefixo e sufixo.
     * - "symbol": prefixo é o símbolo da moeda. Exemplo: "R$ 99,99" Só faz efeito se currency for informado.
     * - "code": sufixo é o código da moeda. Exemplo: "99,99 BRL" Só faz efeito se currency for informado.
     * - "percent": sufixo é o símbolo "%".
     * - "percentage": normaliza para porcentagem, onde 1.00 signifca 100%. Value é multiplicado por 100 e sufixo é o símbolo "%".
     *
     * O default é como segue:
     * - Se currency foi informado, default é "symbol"
     * - Se não foi informado currency, default é "none".
     */
    display: PropTypes.oneOf(["none", "symbol", "code", "percent", "percentage"]),

    /**
     * Moeda a ser usada na formatação. Interfere no símbolo e código e nas casas decimais.
     */
    currency: PropTypes.string,

    /**
     * Indica que o número deve ser mostrado em negrito. Default: false.
     */
    bold: PropTypes.bool,

    /**
     * Indica que o número deve usar agrupamento de milhares. Default: true.
     */
    grouping: PropTypes.bool,

    /**
     * Controla a apresentação do sinal. Default: "auto".
     * - "auto": mostra o sinal quando o número é negativo (+10 = 10, -10 = -10).
     * - "show": mostra sempre o sinal (+10 = +10, -10 = -10).
     * - "hide": nunca mostra o sinal (+10 = 10, -10 = 10).
     */
    sign: PropTypes.oneOf(["auto", "show", "hide"]),

    /**
     * Cor do texto. default: "inherit" (usa a cor do container).
     * A palavra chave "auto" torna a cor dinâmica conforme a regra:
     * - vermelho, se o número for negativo;
     * - verde se o número for zero ou positivo.
     */
    color: PropTypes.string,

    /**
     * Key para uso em grupos (map, etc).
     */
    key: PropTypes.any,

    /**
     * Id da currency que vem da api.
     */
    currencyId: PropTypes.number,

    /**
     * Quantidade de casas decimais.
     */
    fractionDigits: PropTypes.number,
  };

  render = () => {
    let {
      value,
      locale,
      currency,
      display,
      fractionDigits,
      bold,
      color,
      sign,
      grouping,
      key,
      currencyId,
      config,
      getConfig,
    } = this.props;

    if (currency == "LCT") {
      if (!config.LCTCode) {
        if (!loadingConfig) {
          loadingConfig = true;
          getConfig();
          return <>....</>;
        } else {
          return <>..</>;
        }
      }
      currency = config.LCTCode;
    } else if ((currency == "BRZ" || currencyId == 2) && LCTDisplayMode == LCTDisplayModes.Converted) {
      currency = LCTConvertedCurrency;
      value *= LCTRate;
    }

    // console.log("Number -> getConfig", currencyId, currency);
    if (!display) {
      if (currency == "BRL" || currencyId == 3 || currency == "EUR") {
        display = "symbol";
      } else {
        display = "code";
      }
    }

    locale = locale ? locale : "pt-BR";
    if (currencyId) {
      currency = currencies.filter((c) => c.id == currencyId)[0];
    } else if (currency) {
      currency = currencies.filter((c) => c.code.toUpperCase() == currency.toUpperCase())[0];
    }

    color = color ? color : "inherit";
    sign = sign ? sign : "auto";
    let prefix = "";
    let sufix = "";
    if (display == "percent" || display == "percentage") {
      fractionDigits = fractionDigits ? fractionDigits : 2;
      prefix = "";
      sufix = "%";
    } else if (currency) {
      fractionDigits = fractionDigits ? fractionDigits : currency.decimalPlaces;
      if (display == "code") {
        sufix = " " + currency.code;
      } else if (display !== "none") {
        prefix = currency.symbol + " ";
      }
    }
    let style = {
      fontFamily: "NumberFont",
      display: "inline-block",
      fontSize: "110%",
      color:
        color == "auto"
          ? value >= 0
            ? "green"
            : "red"
          : color == "positive"
          ? "green"
          : color == "negative"
          ? "red"
          : color,
      ...this.props.style,
    };
    if (bold) style.fontWeight = "bold";
    if (value > 0 && sign == "show") {
      prefix += "+";
    } else if (sign == "hide") {
      value = Math.abs(value);
    }
    if (display == "percentage") {
      value *= 100;
    }
    let formatedValue = Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      useGrouping: grouping,
    }).format(value);

    return (
      <span key={key} style={style}>
        <span style={{ fontSize: "50%" }}>{prefix}</span>
        {formatedValue}
        <span style={{ fontSize: "50%" }}>{sufix}</span>
      </span>
    );
  };
}

function mapStateToProps(state) {
  return {
    user: state.user.user.me,
    config: state.config,
  };
}

export default connect(mapStateToProps, actions)(Number);

const currencies = [
  {
    code: "BRL",
    decimalPlaces: 2,
    generateWallet: true,
    id: 3,
    isCrypto: false,
    namePlural: "Reais",
    nameSingular: "Real",
    symbol: "R$",
  },
  {
    code: "BTC",
    decimalPlaces: 8,
    generateWallet: false,
    id: 1,
    isCrypto: true,
    namePlural: "Bitcoins",
    nameSingular: "Bitcoin",
    symbol: "Ƀ",
  },
  {
    code: "BRZ",
    decimalPlaces: 2,
    generateWallet: false,
    id: 2,
    isCrypto: true,
    namePlural: "Brazilian Digital Token",
    nameSingular: "Brazilian Digital Tokens",
    symbol: "brz",
  },
  {
    code: "USD",
    decimalPlaces: 2,
    generateWallet: false,
    id: 5,
    isCrypto: false,
    namePlural: "Dolars",
    nameSingular: "Dolar",
    symbol: "USD",
  },
  {
    code: "USDT",
    decimalPlaces: 2,
    generateWallet: false,
    id: 5,
    isCrypto: false,
    namePlural: "Tethers",
    nameSingular: "Tether",
    symbol: "USDt",
  },
  {
    code: "EUR",
    decimalPlaces: 2,
    generateWallet: false,
    id: 9,
    isCrypto: false,
    namePlural: "Euros",
    nameSingular: "Euro",
    symbol: "€",
  },
  {
    code: "COPD",
    decimalPlaces: 2,
    generateWallet: false,
    id: 10,
    isCrypto: true,
    namePlural: "Peso Colombiano Digilate",
    nameSingular: "Pesos Colombianos Digilates",
    symbol: "COPd",
  },
];
