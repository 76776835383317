import React, { Component } from "react";
import { cnpjNormalizer, cpfNormalizer } from "../../util";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
class ContractAgentDescriptionES extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { currentUser, contractAgent } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p>
            <span>CONTRATO DE PRESTACI&Oacute;N DE SERVICIOS AUT&Oacute;NOMOS</span>
          </p>
          <ol>
            <li>
              <p>LAS PARTES</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 1: Lux Capital, LLC., persona jur&iacute;dica de derecho privado domiciliada en 1309
                    Coffeen Avenue, Suite 1816, Sheridan Wyoming 82801, Estados Unidos, registrada bajo el no.
                    2020-927450, en adelante &ldquo;LA CONTRATANTE&rdquo;,{" "}
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 2: y, por otro lado, {currentUser.name}{" "}
                    {currentUser.type == "NATURAL_PERSON" ? (
                      <FormattedMessage id="app.naturalPerson" />
                    ) : (
                      <FormattedMessage id="app.company" />
                    )}{" "}
                    {currentUser.documentType}{" "}
                    {currentUser.type == "NATURAL_PERSON"
                      ? currentUser.taxResidence == "Brasil"
                        ? cpfNormalizer(currentUser.documentId)
                        : currentUser.documentId
                      : currentUser.taxResidence == "Brasil"
                      ? cnpjNormalizer(currentUser.documentId)
                      : currentUser.documentId}
                    , domiciliado en{" "}
                    {currentUser.address +
                      " - " +
                      currentUser.city +
                      "/" +
                      currentUser.state +
                      " - " +
                      currentUser.country}
                    , en adelante &ldquo;EL CONTRATISTA&rdquo;, deciden celebrar un contrato de prestaci&oacute;n de
                    servicios aut&oacute;nomos, regido por las cl&aacute;usulas a continuaci&oacute;n:{" "}
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>EL OBJETO</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 3: El objeto del presente contrato es la prestaci&oacute;n de servicios que debe
                    realizar la CONTRATISTA a favor de la CONTRATANTE, ejercida por la divulgaci&oacute;n y
                    presentaci&oacute;n a los clientes de los servicios disponibles la CONTRATANTE, dentro de su
                    capacidad empresarial, en los t&eacute;rminos legales que se le proporcionar&aacute;,
                    trat&aacute;ndose exclusivamente de promoci&oacute;n para hacer conocer y c&oacute;mo utilizar los
                    servicios ofrecidos por la CONTRATANTE.
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 4: La prestaci&oacute;n del servicio objeto de este instrumento se realizar&aacute;
                    en la regi&oacute;n determinada por la CONTRATISTA, que deber&aacute; ser informada a la CONTRATISTA
                    sobre la elecci&oacute;n del lugar de actuaci&oacute;n;{" "}
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>EL PAGO</p>
              <ol>
                <li>
                  <span>
                    CL&Aacute;USULA 5: Para el cumplimiento del objeto de este instrumento, identificado en la
                    Cl&aacute;usula 3, la CONTRATISTA recibir&aacute;, mensualmente, un porcentaje del importe
                    custodiado de cada LCContract activo bajo su responsabilidad.
                    <br />
                    <br />
                    <ol>
                      <li>
                        P&aacute;rrafo uno: El importe custodiado de un LCContract corresponde al importe del cliente en
                        el momento de la apertura del contrato, como se describe en los T&Eacute;RMINOS Y CONDICIONES DE
                        USO.
                      </li>
                      <li>
                        P&aacute;rrafo dos: El importe aportado por el cliente en un LCContract proviene de una
                        decisi&oacute;n personal, aut&oacute;noma e individual del cliente, que desea anotarse a los
                        servicios prestados por la CONTRATISTA.
                      </li>
                      <li>
                        P&aacute;rrafo tres: Un LCContract se considera activo en el per&iacute;odo entre su fecha de
                        activaci&oacute;n y su fecha de finalizaci&oacute;n, es decir, el per&iacute;odo mientras se
                        est&aacute; generando nuevos importes del cliente.
                        <br />
                        El valor porcentual var&iacute;a en funci&oacute;n del tipo de LCContract. Los contratos
                        m&aacute;s largos y cerrados (consulte los T&Eacute;RMINOS Y CONDICIONES DE USO para m&aacute;s
                        detalles) generan un valor m&aacute;s alto. En general, los porcentajes oscilan entre el 1% (uno
                        por ciento) y el 1,5 por ciento (uno y medio por ciento);
                      </li>
                    </ol>
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 6: Los pagos referentes a la prestaci&oacute;n de los servicios realizados se
                    realizar&aacute;n a trav&eacute;s del cr&eacute;dito en la cuenta de la CONTRATISTA en la
                    plataforma. Los montos relacionados con la prestaci&oacute;n del servicio se pagar&aacute;n en la
                    moneda determinada de acuerdo con el pa&iacute;s del registro de la CONTRATISTA.
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 7: El pago de la comisi&oacute;n tendr&aacute; lugar en el d&iacute;a del
                    aniversario de dicho LCContract. <br />
                    <br />
                    <ol>
                      <li>
                        P&aacute;rrafo &uacute;nico: El aniversario de un LCContract es el d&iacute;a en que se
                        activ&oacute;, cumpliendo un aniversario cada mes.{" "}
                      </li>
                    </ol>
                  </span>
                </li>
                <li>
                  <span>
                    CL&Aacute;USULA 8: El retiro de las sumas de la cuenta en la plataforma debe realizarse de acuerdo
                    con las normas v&aacute;lidas para todos los, como se describe en los T&Eacute;RMINOS Y CONDICIONES
                    DE USO. <br />
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <p>LAS OBLIGACIONES LA CONTRATANTE</p>
              <ol>
                <li>
                  CL&Aacute;USULA 9: La CONTRATANTE est&aacute; obligada a: <br />
                  a) Proveer informaci&oacute;n, aclaraci&oacute;n y capacitaci&oacute;n para el correcto desarrollo de
                  las actividades sujetas a este acto contratual;
                  <br />
                  b) Proveer el apoyo t&eacute;cnico necesario para el seguimiento de la actividad sujeta a este
                  contrato;
                  <br />
                  c) Supervisar la actividad de la CONTRATISTA y hacer que desarrolle las actividades de conformidad con
                  las regulaciones y orientaciones proporcionadas por la CONTRATANTE;
                  <br />
                  d) Realizar el pago de los servicios prestados de conformidad con las disposiciones de las
                  cl&aacute;usulas 5 a 7 de este acuerdo.
                  <br />
                  e) Notificar a la CONTRATISTA sobre las reuniones, cursos o entrenamientos realizados por la
                  CONTRATANTE o por quien, exclusivamente, pueda hacerlo, con antelaci&oacute;n de 5 d&iacute;as.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>LAS OBLIGACIONES DEL CONTRATISTA</p>
              <ol>
                <li>
                  CL&Aacute;USULA 10: Queda obligado a:
                  <br />
                  a) realizar la prestaci&oacute;n de servicio objeto de este contrato de conformidad con las normas,
                  informaciones y capacitaci&oacute;n proporcionadas por la CONTRATANTE o por quien, exclusivamente,
                  est&eacute; capacitada para ello;
                  <br />
                  b) ejercer, de manera personal&iacute;sima, la prestaci&oacute;n del servicio objeto de este contrato;
                  <br />
                  c) proporcionar de forma clara y objetiva informaciones y orientaci&oacute;n a los clientes sobre la
                  funcionalidad y los servicios de la empresa CONTRATANTE;
                  <br />
                  d) facilitar, informar y completar adecuadamente la plataforma con los datos de los respectivos
                  clientes a la empresa CONTRATANTE.
                  <br />
                  e) proporcionar a la CONTRATANTE, siempre que lo solicite, en un plazo de 48 horas, la
                  informaci&oacute;n referente a la prestaci&oacute;n de servicios de este contrato.
                  <br />
                  f) participar en las reuniones, cursos o entrenamientos impartidos por la empresa CONTRATANTE,
                  previamente programados, o por quien, exclusivamente, esta lo indique.
                  <br />
                  g) asumir los gastos de viaje, alojamiento, transporte, seguros o cualquier otro gasto necesario para
                  la atenci&oacute;n a sus clientes, as&iacute; como la asistencia a reuniones, cursos y entrenamientos
                  previamente programados por la CONTRATANTE o por quienes, exclusivamente, lo indiquen.
                  <br />
                  h) responder adecuadamente la declaraci&oacute;n de impuestos de las sumas recibidas por la
                  prestaci&oacute;n del servicio realizado.
                </li>
              </ol>
            </li>
            <li>
              <p>EL PLAZO CONTRACTUAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 11: Este contrato de servicio entra en vigor en la fecha de su firma, y estar&aacute;
                  en vigor por un plazo indefinido.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>LA RESCISI&Oacute;N CONTRACTUAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 12: La rescisi&oacute;n sin motivo del contrato es facultada a ambas las partes y
                  puede solicitarse con 30 (treinta) d&iacute;as de antelaci&oacute;n, mediante notificaci&oacute;n
                  escrita, excepto cuando haya interrupciones no sist&eacute;micas, gubernamentales o de &oacute;rganos
                  reguladores y de inspecci&oacute;n que puedan cambiar el modus operandi del mercado.
                </li>
                <li>
                  CL&Aacute;USULA 13: El contrato tambi&eacute;n puede rescindirse en su totalidad y sin
                  notificaci&oacute;n previa, por una buena raz&oacute;n, entendida como: <br />
                  <br />
                  <ol>
                    <li>
                      P&aacute;rrafo uno: Unilateralmente, por la parte inocente, en caso de incumplimiento de
                      cualquiera de las obligaciones asumidas en este contrato, sin perjuicio de la
                      indemnizaci&oacute;n, por parte de la parte infractora a los inocentes, por cualquier
                      p&eacute;rdida y da&ntilde;o causado por la parte infractora;{" "}
                    </li>
                    <li>
                      P&aacute;rrafo dos: En el caso de una solicitud, decreto o aprobaci&oacute;n de la
                      recuperaci&oacute;n judicial o extrajudicial, quiebra, liquidaci&oacute;n, disoluci&oacute;n o
                      situaci&oacute;n de insolvencia inequ&iacute;voca de la otra parte;
                    </li>
                    <li>P&aacute;rrafo tres: Si comprobado que sucedi&oacute; algo fortuito o de fuerza mayor.</li>
                    <li>
                      P&aacute;rrafo cuatro: En cualquiera de estos casos de rescisi&oacute;n contractual, se garantiza
                      a las partes el acierto de cuentas correcto de los servicios ya prestados hasta la &uacute;ltima
                      fecha efectiva de los servicios prestados.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>RESPONSABILIDAD LABORAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 14: En este sentido, se estipula que, en virtud de este contrato, no se establece
                  ning&uacute;n v&iacute;nculo laboral o de responsabilidad entre la CONTRATANTE y la CONTRATISTA para
                  el desempe&ntilde;o de los servicios. La CONTRATISTA es la &uacute;nica y exclusiva responsable por
                  todas las acciones y cargos derivados de la legislaci&oacute;n vigente, ya sean laborales, de
                  seguridad social o de cualquier otro, oblig&aacute;ndose as&iacute; la CONTRATISTA a cumplir con las
                  disposiciones legales respecto a la remuneraci&oacute;n del trabajo que utiliza y otras obligaciones y
                  cargos de cualquier naturaleza. <br />
                  <br />
                  {contractAgent.status == "PENDING" ? (
                    <>
                      <Checkbox
                        checked={this.state.checked1}
                        onChange={(e) => {
                          this.setState({ checked1: e.target.checked });
                        }}
                        color="primary"
                      />
                      <span style={{ color: "red" }}>CIENTE</span>
                    </>
                  ) : (
                    " "
                  )}
                </li>
                <li>
                  CL&Aacute;USULA 15: La CONTRATANTE no es responsable de ninguna deuda laboral, fiscal o cualquier otra
                  deuda que tenga la Compa&ntilde;&iacute;a. Estas son su responsabilidad &uacute;nica e intransferible;
                </li>
                <li>
                  CL&Aacute;USULA 16: Eventuales condenas laborales de la CONTRATANTE, resultantes de la relaci&oacute;n
                  legal establecida en el presente documento entre las partes, podr&aacute;n ser descontadas del pago
                  mensual adeudado a la CONTRATISTA. Si el valor de las condenas excede las sumas adeudadas por la
                  CONTRATISTA, la CONTRATISTA pagar&aacute; a la CONTRATANTE la diferencia. Los montos tambi&eacute;n
                  pueden ser descontados de pagos futuros. <br />
                  <br />
                  {contractAgent.status == "PENDING" ? (
                    <>
                      {" "}
                      <Checkbox
                        checked={this.state.checked2}
                        onChange={(e) => {
                          this.setState({ checked2: e.target.checked });
                        }}
                        color="primary"
                      />
                      <span style={{ color: "red" }}>CIENTE</span>
                    </>
                  ) : (
                    " "
                  )}
                </li>
              </ol>
            </li>
            <li>
              <p>RESPONSABILIDAD TRIBUTARIA</p>
              <ol>
                <li>
                  CL&Aacute;USULA 17: Si a la CONTRATANTE se le obligan pagar o indemnizar sumas relacionadas a
                  impuestos no pagados por la CONTRATISTA, este &uacute;ltimo estar&aacute; obligado a reembolsar todas
                  las cantidades pagadas, incluidos los costos procesales y honorarios de abogados, dentro de los 5
                  d&iacute;as posteriores al pago.
                </li>
              </ol>
            </li>
            <li>
              <p>RESPONSABILIDAD CIVIL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 18: La CONTRATISTA deber&eacute; reparar inmediatamente cualquier error,
                  p&eacute;rdida o da&ntilde;o resultante del incumplimiento y/o el cumplimiento de las obligaciones
                  contractuales en desconformidad con el presente contrato, oblig&aacute;ndose a indemnizar a la
                  CONTRATANTE o a terceros perjudicados.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>CONFIDENCIALIDAD</p>
              <ol>
                <li>
                  CL&Aacute;USULA 19: Las partes contratantes reconocen que el v&iacute;nculo estipulado en este acuerdo
                  se basa en una relaci&oacute;n de fidelidad y buena fe mutua. Son conscientes de que, en el
                  desempe&ntilde;o de sus funciones, pueden tener acceso a informaci&oacute;n y materiales que no son
                  conocidos por el p&uacute;blico en general y que la parte reveladora considera de su propiedad
                  exclusiva y confidencial. Asimismo, en caso de solicitud de cualquier informaci&oacute;n o material
                  confidencial relacionado con las actividades mencionadas en este acuerdo, es el &uacute;nico
                  responsable de proporcionar, a sudiscreci&oacute;n, a la empresa CONTRATANTE, eximiendo ante las
                  autoridades y otros organismos gubernamentales la responsabilidad de la CONTRATANTE de proveer
                  cualquier material o datos de informaci&oacute;n, teniendo en cuenta el tema de la confidencialidad.
                </li>
                <li>
                  CL&Aacute;USULA 20: Se proh&iacute;be a las partes utilizar en beneficio propio o de terceros
                  cualquier informaci&oacute;n privilegiada para el desarrollo de una actividad igual o similar a la
                  desarrollada por la otra parte por un per&iacute;odo igual o superior a cinco (5) a&ntilde;os desde el
                  cierre el contrato.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 21: La obligaci&oacute;n de sigilo establecida en esta cl&aacute;usula durar&aacute;
                  despu&eacute;s de la extinci&oacute;n de este contrato por un per&iacute;odo de diez (10)
                  a&ntilde;os.CLÁUSULA 22ª: A obrigação de sigilo estabelecida nessa cláusula perdurará após a extinção
                  deste contrato, pelo período de 10 (dez) anos.
                </li>
                <li>
                  CL&Aacute;USULA 22: Todas las tratativas, negociaciones, contratos, manuales, circulares,
                  notificaciones, entrenamientos, reuniones, certificados, documentos contables o cualquier
                  informaci&oacute;n sobre la actividad desarrollada por la CONTRATANTE son estrictamente confidenciales
                  y no pueden ser divulgados por ning&uacute;n medio, medio de comunicaci&oacute;n o bajo ninguna
                  justificaci&oacute;n, excepto seg&uacute;n lo dispuesto por la ley, bajo la penalidad de la
                  aplicaci&oacute;n de una multa contractual estipulada en este instrumento.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 23: El presente contrato y sus anexos, en su caso, tambi&eacute;n se convierten en
                  informaci&oacute;n confidencial y, por lo tanto, su existencia y su contenido no pueden ser revelados
                  a terceros, sino mediante autorizaci&oacute;n expresa de la CONTRATANTE.
                </li>
                <li>
                  CL&Aacute;USULA 24: En caso de duda sobre la confidencialidad de una determinada informaci&oacute;n,
                  la CONTRATISTA la mantendr&aacute; en absoluto sigilo hasta que la CONTRATANTE se manifieste
                  expresamente sobre el tema.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 25: El incumplimiento de las cl&aacute;usulas de confidencialidad resultar&aacute; en
                  responsabilidad civil y penal por las partes, que podr&aacute; resolverse y apurarse en los
                  tribunales.{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>EL JUICIO ARBITRAL</p>
              <ol>
                <li>
                  CL&Aacute;USULA 26: Este acuerdo es aplicable de conformidad con las leyes jurisdiccionales de los
                  pa&iacute;ses donde se lleva a cabo esta transacci&oacute;n y cualquier disputa se resolver&aacute;
                  bajo las reglas de arbitraje de ICC Brasil, a menos que la parte perjudicada tome acciones legales en
                  un tribunal de jurisdicci&oacute;n.
                </li>
                <li>
                  CL&Aacute;USULA 27: Los contratistas reconocen y acuerdan que cualquier disputa en la
                  aplicaci&oacute;n de este acuerdo se resolver&aacute;, en primer lugar, amistosamente. Solo si no es
                  posible, el procedimiento de arbitraje debe ser llevado a cabo.{" "}
                </li>
                <li>
                  CL&Aacute;USULA 28: Adem&aacute;s de ello, las partes pueden definir el lugar del arbitraje, el
                  idioma, el n&uacute;mero de &aacute;rbitros y la forma de costo entre otros.
                </li>
              </ol>
            </li>
            <li>
              <p>DISPOSICIONES GENERALES</p>
              <ol>
                <li>
                  CL&Aacute;USULA 29: La tolerancia de cualquiera de las partes respecto al cumplimiento de las
                  obligaciones de la otra parte no se considerar&aacute; una novaci&oacute;n o indulto. Las
                  disposiciones de este contrato siguen en pleno vigor, como se establece en el presente documento.
                </li>
                <li>
                  CL&Aacute;USULA 30: El presente contrato, los derechos y obligaciones que surjan del mismo no
                  podr&aacute;n ser cedidos, transferidos o subrogados por ninguna de las partes sin el consentimiento
                  previo por escrito de las dem&aacute;s partes;
                </li>
              </ol>
            </li>
          </ol>
          <p>
            Y, debido a que por lo tanto son justos y contratados, las partes firman este instrumento de dos copias de
            igual contenido y forma ante los testigos que tambi&eacute;n subscriben, junto a un profesional abogado
            acreditado con su credencial.
          </p>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    // currentUser: state.user.user.me
  };
}
//export default ContractAgentDescription;
export default compose(connect(mapStateToProps, actions))(ContractAgentDescriptionES);
