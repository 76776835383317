import React, { Component } from "react";
import { CardContent, Typography, Button, IconButton, CardActions, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withRouter } from "react-router-dom";
import { getInvestmentMonthYield } from "../actions/Investments";
import { Chart, ArgumentAxis, ValueAxis, LineSeries } from "@devexpress/dx-react-chart-material-ui";
import ValueInfoItem from "../custom/ValueInfoItem";
import moment from "moment";
import Moment from "react-moment";
import performRequest from "../configAxios";
import { ValueScale } from "@devexpress/dx-react-chart";
import SimplexNoise from "simplex-noise";
import { FormattedMessage } from "react-intl";

class AdminInvestmentDetail extends Component {
  state = { filling: false };
  today = moment();

  loadInvestmentData = (id, month, year) => {
    getInvestmentMonthYield(id, month, year)
      .then(response => {
        this.setState({ month: response });
      })
      .catch(e => {
        this.setState({ month: null });
      });
  };

  changeDate(add) {
    this.today = add ? this.today.add(1, "M") : this.today.subtract(1, "M");
    this.loadInvestmentData(this.props.match.params.id, this.today.month() + 1, this.today.year());
  }

  componentDidMount() {
    this.loadInvestmentData(this.props.match.params.id, this.today.month() + 1, this.today.year());
  }

  fillInvestment_old() {
    this.setState({ filling: true });
    var seed = 7500;
    const simpleRandom = () => {
      var x = Math.sin(seed++) * 100000;
      return x - Math.floor(x);
    };
    const date = moment("2019-03-01");
    let prevYield = 0.1;
    const next = () => {
      let sign = prevYield > 0.15 ? -1 : prevYield < 0.02 ? 1 : Math.random() < 0.5 ? 1 : -1;
      const rnd = simpleRandom();
      prevYield += ((rnd * 200) / 10000) * sign;
      if (prevYield < 0) {
        prevYield = 0;
      } else if (prevYield > 0.25) {
        prevYield = 0.25;
      }
      if (date.isBefore(moment().startOf("day"))) {
        const params = {
          investmentId: this.props.match.params.id,
          createdAt: date.format("YYYY-MM-DD"),
          yield: prevYield
        };
        performRequest("POST", "/investment-statement", params, true);
        date.add(1, "day");
        setTimeout(next, 300);
      } else {
        this.setState({ filling: false });
        this.props.history.go(0);
      }
    };
    next();
  }

  fillInvestment() {
    this.setState({ filling: true });
    const noise = new SimplexNoise("lcbank");
    const date = moment("2018-07-15");
    const noiseStep = 0.1;
    let noiseIndex = 0;
    const next = () => {
      if (date.isBefore(moment().startOf("day"))) {
        const params = {
          investmentId: this.props.match.params.id,
          createdAt: date.format("YYYY-MM-DD"),
          yield: ((1 + noise.noise2D(noiseIndex, 12)) / 2) * 0.8
        };
        performRequest("POST", "/investment-statement", params, true).then(data => {
          date.add(1, "day");
          noiseIndex += noiseStep;
          setTimeout(next, 50);
        });
      } else {
        this.setState({ filling: false });
        this.props.history.go(0);
      }
    };
    next();
  }

  fillWeeklyYield() {
    this.setState({ filling: true });
    const date = moment().add(-1, "year");
    const next = () => {
      if (date.isBefore(moment().startOf("day"))) {
        const params = {
          createdAt: date.format("YYYY-MM-DD")
        };
        performRequest("POST", "/investment-statement/generate", params, true).then(data => {
          date.add(1, "week");
          setTimeout(next, 50);
        });
      } else {
        this.setState({ filling: false });
        this.props.history.go(0);
      }
    };
    next();
  }

  fillDailyYield() {
    this.setState({ filling: true });
    const date = moment().add(-1, "year");
    const next = () => {
      if (date.isBefore(moment().startOf("day"))) {
        const params = {
          createdAt: date.format("YYYY-MM-DD")
        };
        performRequest("POST", "/contract-statement/generate", params, true).then(data => {
          date.add(1, "day");
          setTimeout(next, 50);
        });
      } else {
        this.setState({ filling: false });
        this.props.history.go(0);
      }
    };
    next();
  }

  fillNextDailyYield() {
    this.setState({ filling: true });
    const date = moment();
    const limit = moment().add(7, "day");
    const next = () => {
      if (date.isBefore(limit)) {
        const params = {
          createdAt: date.format("YYYY-MM-DD")
        };
        performRequest("POST", "/contract-statement/generate", params, true).then(data => {
          date.add(1, "day");
          setTimeout(next, 50);
        });
      } else {
        this.setState({ filling: false });
        this.props.history.go(0);
      }
    };
    next();
  }

  render() {
    const { month } = this.state;
    if (!month) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem
            title="app.investment.title"
            showBackButton
            destination="/investments"
            values={this.props.match.params.id}
          />

          <CardPageItem>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <IconButton onClick={e => this.changeDate()}>
                <ChevronLeftIcon />
              </IconButton>

              <Typography variant="h6">
                <FormattedMessage id="app.investment.resume" /> <Moment format="MMMM/YYYY" date={month.month} />
              </Typography>

              <IconButton onClick={e => this.changeDate(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </CardPageItem>

          <CardPageItem>
            <CardContent>
              {!month ? (
                <CircularProgress style={{ marginTop: "25%" }} />
              ) : (
                <>
                  {month.isCurrent ? (
                    <>
                      <ValueInfoItem caption={<FormattedMessage id="app.investment.resume" />} percent={month.today} />
                      <ValueInfoItem
                        caption={
                          <FormattedMessage id="app.investment.untilToday" values={{ day: month.history.length }} />
                        }
                        percent={month.total}
                      />
                      <ValueInfoItem
                        caption={<FormattedMessage id="app.investment.estimatedYield" values={{ day: 30 }} />}
                        percent={month.estimated}
                      />
                    </>
                  ) : (
                    <>
                      <ValueInfoItem
                        caption={<FormattedMessage id="app.investment.dailyAvg" />}
                        percent={month.dailyAvg}
                      />
                      <ValueInfoItem
                        caption={<FormattedMessage id="app.investment.monthlyYield" values={{ day: 30 }} />}
                        percent={month.total}
                      />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </CardPageItem>

          <CardPageItem>
            <CardContent>
              {!month ? (
                <CircularProgress style={{ marginTop: "25%" }} />
              ) : (
                <>
                  <div style={{ backgroundColor: "rgba(30, 30, 30, 1)" }}>
                    <Chart
                      data={month.history.map(d => ({
                        ...d,
                        day: d.createdAt.substr(8)
                      }))}
                      height={250}
                    >
                      <ValueScale name="per" />
                      <ArgumentAxis />
                      <ValueAxis scaleName="per" />
                      <LineSeries scaleName="per" valueField="yield" argumentField="day" />
                    </Chart>
                  </div>
                </>
              )}
            </CardContent>
            <CardActions style={{ justifyContent: "center" }}>
              <Button variant="outlined" component={Link} to={"/investments/" + this.props.match.params.id + "/yield"}>
                <FormattedMessage id="app.investment.inform" />
              </Button>
            </CardActions>
          </CardPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default withRouter(connect(mapStateToProps, actions)(AdminInvestmentDetail));
