import React, { Component } from "react";
import { withStyles, Dialog, Grid, Card } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

const styles = theme => ({
  customDialog: {
    paddingRight: "0px!important",
    background: "none"
  },
  customDialogPaper: {
    background: theme.palette.background.default
  },
  customDialogModal: {
    background: "none"
  }
});

/**
 * Componente container da página, oferece opção de ser página normal ou de detalhe, que no mobile abre em tela cheia.
 */ class PageContainer extends Component {
  render() {
    const { removeSpacing, detail, children, classes, width, theme, full } = this.props;
    const fullScreen = !isWidthUp("lg", width);

    if (detail) {
      return (
        <DetailPage fullScreen={fullScreen} classes={classes} theme={theme}>
          <PageContainerBase full={full} detail theme={theme}>
            {children}
          </PageContainerBase>
        </DetailPage>
      );
    } else {
      return (
        <PageContainerBase full={full} removeSpacing={removeSpacing} theme={theme}>
          {children}
        </PageContainerBase>
      );
    }
  }
}

export default withStyles(styles)(withWidth({ withTheme: true })(PageContainer));

/**
 * Container do grid da página, cria uma coluna de 700px auto-centralizada.
 */
function PageContainerBase({ full, detail, removeSpacing, theme, children }) {
  let maxWidth = theme.props.maxPageWidth;
  if (full) maxWidth = "100%";
  return (
    <Grid
      container
      spacing={detail || removeSpacing ? 0 : 1}
      style={{
        width: "100%",
        maxWidth: maxWidth,
        alignSelf: "center",
        paddingBottom: theme.props.bottomBarHeight
      }}
      className="PageContainer"
    >
      {children}
    </Grid>
  );
}
/**
 * Item da página, ajusta-se à coluna e controla o espaçamento entre itens.
 */
export function PageItem({ double, children, ...rest }) {
  return (
    <Grid item xs={12} md={double ? 6 : 12} className="PageItem" {...rest}>
      {children}
    </Grid>
  );
}

/**
 * Item da página préconfigruado como card, ajusta-se à coluna e controla o espaçamento entre itens.
 */
export function CardPageItem({ raised, double, children, ...rest }) {
  return (
    <Grid item xs={12} md={double ? 6 : 12} className="PageItem" {...rest}>
      <Card elevation={raised ? 1 : 0} style={{ height: "100%" }}>
        {children}
        <div />
      </Card>
    </Grid>
  );
}

function DetailPage({ fullScreen, classes, theme, children }) {
  return (
    <>
      {fullScreen ? (
        <Dialog
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
          open
          className={classes.customDialog}
          classes={{ paper: classes.customDialogPaper }}
        >
          {children}
        </Dialog>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
