import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import moment from "moment-business-days";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderPageItem from "../../custom/HeaderPageItem";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../../custom/Page";
import messages_br from "../../translations/br.json";
import messages_en from "../../translations/en.json";
import messages_es from "../../translations/es.json";
//import { getConfig2, getAtiveContracts, getStatusRedeemContracts, getRedeemContracts } from "../actions";
import { ListContractColumns, Roles } from "../../util";
import TableList from "./../components/TableList";

const ROLES_FOR_FULL_REPORT = [Roles.Admin, Roles.FinanceManager, Roles.Auditor];
const ROLES_FOR_BASIC_REPORT = [...ROLES_FOR_FULL_REPORT, Roles.BusinessManager];
const UNIT_FONT_SIZE = "60%";
const DARK_FONT_COLOR = "#999";

class CustomerFilter {
  value = "";
  caption = "app.customer";
  options = [];

  apply = (contracts) => contracts.filter((c) => this.value == "" || c.customerId == this.value);
}

class AgentFilter {
  value = "";
  caption = "app.agent";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || c.agentId == this.value);
}

class TypeFilter {
  value = "";
  caption = "app.type";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || c.type == this.value);
}
class MonthyearFilter {
  value = "";
  caption = "app.monthYear";
  options = [];
  apply = (contracts) =>
    contracts.filter((c) => this.value == "" || moment(c.rescueDate).format("MM-YYYY") == this.value);
}

class FilterSet {
  filters = [];
  constructor(filters) {
    this.filters = filters;
  }
  apply = (f) => this.filters.reduce((f, filter) => filter.apply(f), f);
}

class ContractReportContent extends Component {
  state = {
    loading: true,
    dataList: this.props.dataList,
    title: this.props.title,
    desc: this.props.desc,
    columns: [],
    options: [],
    openDialogFilter: false,
    openDialogColumns: false,
    ListColumns: ListContractColumns,
    listAllColumns: true,
  };

  filterSets = new FilterSet([new CustomerFilter(), new AgentFilter(), new TypeFilter(), new MonthyearFilter()]);

  componentDidMount() {
    this.setState({ loading: true });
    this.setState({ listAllColumns: true });
    this.listLCcontracts(this.props.dataList);
    this.listFilters(this.props.dataList);
    this.setState({ loading: false });
  }

  totalCustodian = () => {
    var total = 0;
    this.state.dataList.forEach((a) => {
      total += a.valCustodyLCT;
    });
    return total;
  };

  totalMonthlyLimit = (money = "") => {
    var total = 0;

    this.state.dataList.forEach((a) => {
      total += this.convertPorcentToLCT(a.monthlyLimit, a.valCustodyLCT, money);
    });
    return total;
  };

  totalComission = (money = "") => {
    return this.state.dataList.reduce(
      (acc, a) => acc + this.convertPorcentToLCT(a.commission, a.valCustodyLCT, money),
      0
    );
  };

  newColumn = (title, field, type, align, rolesAllowed, render) => ({
    title,
    field,
    type,
    cellStyle: {
      textAlign: align,
      fontSize: 12,
      position: "sticky",
    },
    headerStyle: {
      textAlignLast: align,
      fontSize: 12,
    },
    render,
    hidden: !rolesAllowed.includes(this.props.currentUser.role),
  });

  newColumnText = (title, field, rolesAllowed, render) =>
    this.newColumn(title, field, "string", "left", rolesAllowed, render);

  newColumnBRL = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        <Number
          value={rowData[field]}
          currency={this.props.taxResidence == "Colômbia" ? "COPD" : this.props.taxResidence == "Brasil" ? "BRL" : ""}
        />
      </>
    ));

  newColumnLCT = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        <Number
          value={rowData[field]}
          currency={this.props.taxResidence == "Colômbia" ? "COPD" : this.props.taxResidence == "Brasil" ? "BRZ" : ""}
        />
      </>
    ));

  newColumnPer = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        {rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>%</span>
      </>
    ));

  newColumnDate = (title, field, rolesAllowed) => this.newColumn(title, field, "date", "left", rolesAllowed);

  /*
     Função que que cria uma nova lista de customers e agents 
     eliminando os repetidos para ser utilizada nos filtros
  */

  listFilters = (listData) => {
    const {
      currentUser: { locale },
    } = this.props;
    const messages = {
      en: messages_en,
      br: messages_br,
      es: messages_es,
    };
    let valid = true;
    let listAgents = listData;
    let listCustomers = listData;
    let listType = [
      { label: messages[locale]["app.all"], value: "" },
      { label: messages[locale]["app.enuns.OPEN"], value: "OPEN" },
      { label: messages[locale]["app.enuns.CLOSED"], value: "CLOSED" },
    ];
    let listMonthyear = listData;

    //                  AGENTS
    //Ordena em ordem alfabetica os nomes dos agentes
    listAgents = listAgents.sort((a, b) => {
      return a.user.consultantName < b.user.consultantName ? -1 : a.user.consultantName > b.user.consultantName ? 1 : 0;
    });
    //Reduz a lista apenas para id e nome do agente e descarta os repetidos
    listAgents = listAgents.reduce(
      (prevElem, elem) => {
        valid = true;
        prevElem.map((e) => {
          if (e.value == elem.user.consultantId) {
            valid = false;
          }
        });
        if (valid) {
          return [
            ...prevElem,
            {
              label: elem.user.consultantName,
              value: elem.user.consultantId + "",
            },
          ];
        } else {
          return [...prevElem];
        }
      },
      [
        {
          label: messages[locale]["app.all"],
          value: "",
        },
      ]
    );
    valid = true;
    //                  CUSTOMER
    //Ordena em ordem alfabetica os nomes dos customers
    listCustomers = listCustomers.sort((a, b) => {
      return a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0;
    });
    //Reduz a lista apenas para id e nome do customer e descarta os repetidos
    listCustomers = listCustomers.reduce(
      (prevElem, elem) => {
        valid = true;
        prevElem.map((e) => {
          if (e.value == elem.user.id) {
            valid = false;
          }
        });
        if (valid) {
          return [
            ...prevElem,
            {
              label: elem.user.name,
              value: elem.user.id + "",
            },
          ];
        } else {
          return [...prevElem];
        }
      },
      [
        {
          label: messages[locale]["app.all"],
          value: "",
        },
      ]
    );

    //                  MOUNTH/YEAR
    //Ordena em ordem alfabetica as datas de resgate dos customers
    listMonthyear = listMonthyear.sort((a, b) => {
      var dateA = moment(new Date(a.dueDate));
      var dateB = moment(new Date(b.dueDate));
      if (moment(dateA).isAfter(dateB)) {
        return 1;
      } else {
        return -1;
      }
    });
    //Reduz a lista apenas para id e data resgate do customer e descarta os repetidos
    listMonthyear = listMonthyear.reduce(
      (prevElem, elem) => {
        valid = true;
        prevElem.map((e) => {
          if (e.label == moment(elem.dueDate).format("MM-YYYY")) {
            valid = false;
          }
        });
        if (valid) {
          var date = moment(elem.dueDate).format("MM-YYYY");
          return [
            ...prevElem,
            {
              label: date,
              value: date,
            },
          ];
        } else {
          return [...prevElem];
        }
      },
      [
        {
          label: messages[locale]["app.all"],
          value: "",
        },
      ]
    );

    //Seta a lista de customers, agentes, types e Month/Year ordenados e sem repetições no filtro
    this.filterSets.filters[0].options = listCustomers;
    this.filterSets.filters[1].options = listAgents;
    this.filterSets.filters[2].options = listType;
    this.filterSets.filters[3].options = listMonthyear;
  };

  //copiado branch do diego EscolherColunas

  columnsFiltered = () => {
    let columns = [];
    this.state.ListColumns.map((column) => {
      if (column.name == "id" && column.value == true) {
        columns = columns.concat([
          this.newColumn("Id", "id", "numeric", "left", ROLES_FOR_BASIC_REPORT, (rowData) => (
            <Link href={"/userContract/" + rowData.id} target="_blank">
              {"C " + rowData.id}
            </Link>
          )),
        ]);
      } else if (column.name == "customer" && column.value == true) {
        columns = columns.concat([
          this.newColumn("app.customer", "customer", "string", "left", ROLES_FOR_BASIC_REPORT, (rowData) => (
            <>
              <Link href={"/user/" + rowData.customerId} target="_blank">
                {rowData.customer}{" "}
              </Link>
              <span style={{ color: DARK_FONT_COLOR }}>{rowData.customerId}</span>
            </>
          )),
        ]);
      } else if (column.name == "agent" && column.value == true) {
        columns = columns.concat([
          this.newColumnText("app.agent", "agent", ROLES_FOR_BASIC_REPORT, (rowData) => (
            <Link href={"/user/" + rowData.agentId} target="_blank">
              {rowData.agent}{" "}
            </Link>
          )),
        ]);
      } else if (column.name == "situation" && column.value == true) {
        columns = columns.concat([this.newColumn("app.situation", "phase", "string", "left", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "custodianLct" && column.value == true) {
        columns = columns.concat([this.newColumnLCT("app.custodian.lct", "valCustodyLCT", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "cusodianBrl" && column.value == true) {
        columns = columns.concat([this.newColumnBRL("app.custodian.brl", "valCustodyRS", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "type" && column.value == true) {
        columns = columns.concat([this.newColumn("app.type", "type", "string", "left", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "createdAt" && column.value == true) {
        columns = columns.concat([
          this.newColumnDate("app.contractReport.createdAt", "createdAt", ROLES_FOR_BASIC_REPORT),
        ]);
      } else if (column.name == "activation" && column.value == true) {
        columns = columns.concat([this.newColumnDate("app.activation", "activatedDate", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "rescue" && column.value == true) {
        columns = columns.concat([this.newColumnDate("app.rescue", "rescueDate", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "durationInMonth" && column.value == true) {
        columns = columns.concat([
          this.newColumn("app.durationInMonth", "durationMonths", "numeric", "right", ROLES_FOR_BASIC_REPORT),
        ]);
      } else if (column.name == "limitMonthyPercent" && column.value == true) {
        columns = columns.concat([
          this.newColumnPer("app.limit.monthly.percent", "monthlyLimit", ROLES_FOR_FULL_REPORT),
        ]);
      } else if (column.name == "limitMonthyLct" && column.value == true) {
        columns = columns.concat([
          this.newColumnLCT("app.limit.monthly.lct", "monthlyLimitLCT", ROLES_FOR_FULL_REPORT),
        ]);
      } else if (column.name == "limitMonthyBrl" && column.value == true) {
        columns = columns.concat([this.newColumnLCT("app.limit.monthly.brl", "limitMonthyBrl", ROLES_FOR_FULL_REPORT)]);
      } else if (column.name == "comissionPercent" && column.value == true) {
        columns = columns.concat([this.newColumnPer("app.comission.percent", "commission", ROLES_FOR_FULL_REPORT)]);
      } else if (column.name == "comissionLct" && column.value == true) {
        columns = columns.concat([this.newColumnLCT("app.comission.lct", "commissionLCT", ROLES_FOR_FULL_REPORT)]);
      } else if (column.name == "comissionBrl" && column.value == true) {
        columns = columns.concat([this.newColumnBRL("app.comission.brl", "commissionRS", ROLES_FOR_FULL_REPORT)]);
      } else if (column.name == "aportDate" && column.value == true) {
        columns = columns.concat([this.newColumnDate("app.aport.date", "aportDate", ROLES_FOR_BASIC_REPORT)]);
      } else if (column.name == "aportId" && column.value == true) {
        columns = columns.concat([
          this.newColumn("app.aport.id", "transactionId", "string", "right", ROLES_FOR_BASIC_REPORT),
        ]);
      }
    });
    return columns;
  };

  listLCcontracts = (data) => {
    // const columns = [
    //   this.newColumn("Id", "id", "numeric", "left", ROLES_FOR_BASIC_REPORT, rowData => (
    //     <Link href={"/userContract/" + rowData.id} target="_blank">
    //       {"C " + rowData.id}
    //     </Link>
    //   )),
    //   this.newColumn("app.customer", "customer", "string", "left", ROLES_FOR_BASIC_REPORT, rowData => (
    //     <>
    //       <Link href={"/user/" + rowData.customerId} target="_blank">
    //         {rowData.customer}{" "}
    //       </Link>
    //       <span style={{ color: DARK_FONT_COLOR }}>{rowData.customerId}</span>
    //     </>
    //   )),
    //   this.newColumnText("app.agent", "agent", ROLES_FOR_BASIC_REPORT, rowData => (
    //     <Link href={"/user/" + rowData.agentId} target="_blank">
    //       {rowData.agent}{" "}
    //     </Link>
    //   )),
    //   this.newColumn("app.situation", "phase", "string", "left", ROLES_FOR_BASIC_REPORT),
    //   this.newColumnLCT("app.custodian.lct", "valCustodyLCT", ROLES_FOR_BASIC_REPORT),
    //   this.newColumnBRL("app.custodian.brl", "valCustodyRS", ROLES_FOR_BASIC_REPORT),
    //   this.newColumn("app.type", "type", "string", "center", ROLES_FOR_BASIC_REPORT),
    //   this.newColumnDate("app.activation", "activatedDate", ROLES_FOR_BASIC_REPORT),
    //   this.newColumnDate("app.rescue", "rescueDate", ROLES_FOR_BASIC_REPORT),
    //   this.newColumn("app.durationInMonth", "durationMonths", "numeric", "center", ROLES_FOR_BASIC_REPORT),
    //   this.newColumnPer("app.limit.monthly.percent", "monthlyLimit", ROLES_FOR_FULL_REPORT),
    //   this.newColumnLCT("app.limit.monthly.lct", "monthlyLimitLCT", ROLES_FOR_FULL_REPORT),
    //   this.newColumnBRL("app.limit.monthly.brl", "monthlyLimitRS", ROLES_FOR_FULL_REPORT),
    //   this.newColumnPer("app.comission.percent", "commission", ROLES_FOR_FULL_REPORT),
    //   this.newColumnLCT("app.comission.lct", "commissionLCT", ROLES_FOR_FULL_REPORT),
    //   this.newColumnBRL("app.comission.brl", "commissionRS", ROLES_FOR_FULL_REPORT),
    //   this.newColumnDate("app.aport.date", "aportDate", ROLES_FOR_BASIC_REPORT),
    //   this.newColumn("app.aport.value", "valAport", "numeric", "right", ROLES_FOR_BASIC_REPORT, rowData => (
    //     <>
    //       <Number value={rowData.valAport / this.state.LCTBTCSell} currency="BTC" />
    //     </>
    //   )),
    //   this.newColumn("app.aport.id", "transactionId", "string", "right", ROLES_FOR_BASIC_REPORT)
    // ];

    const {
      theme: { palette },
    } = this.props;

    let dataList = data.map((a) => ({
      id: a.id,
      customer: a.user.name,
      customerId: a.user.id,
      agent: a.user.consultantName, // Nome do agente
      agentId: a.user.consultantId,
      valCustodyLCT: a.amount ? a.amount : a.previsionAmount, //Valor custodiado BRZ
      valCustodyRS: this.convertMoneyToReal(a.amount ? a.amount : a.previsionAmount), //Valor custodiado em reais
      createdAt: a.createdAt ? moment(a.createdAt).toDate() : "-", // DataAbertura
      activatedDate: a.initialDate ? moment(a.initialDate).toDate() : "-", // DataAtivação
      rescueDate: a.dueDate ? moment(a.dueDate).toDate() : "-", // data fim contrato
      type: a.type.type, //tipo de contrato
      phase: this.props.situation ? (
        a.phase == "VALIDATING" ? (
          "Aguardando cliente"
        ) : (
          "Aguardando admin"
        )
      ) : (
        <FormattedMessage id={`app.enuns.${a.phase}`} />
      ), //fase do contrato
      durationMonths: a.type.durationInMonth, // duração em meses
      monthlyLimit: a.type.maxMonthlyYield, // limite mensal percentual de acrescimo mensal maximo
      monthlyLimitLCT: this.convertPorcentToLCT(a.type.maxMonthlyYield, a.amount), // limite mensal em BRZ
      monthlyLimitRS: this.convertPorcentToLCT(a.type.maxMonthlyYield, a.amount, "BRL"), // limite mensal em reais
      commission: a.type.agentCommission, // Comissão do agente
      commissionLCT: this.convertPorcentToLCT(a.type.agentCommission, a.amount), // Comissão do agente
      commissionRS: this.convertPorcentToLCT(a.type.agentCommission, a.amount, "BRL"), // Comissão do agente
      aportDate: a.amount ? this.lastAport(a.deposits, "createdAt") : "-", //Retorna a data do ultimo aport
      valAport: a.amount ? this.lastAport(a.deposits, "amount") : 0, // Valor do aport
      transactionId: a.amount ? this.lastAport(a.deposits, "transactionId") : "-", // retorna o ultimo transactionId dos aportes
    }));
    const options = {
      exportButton: true,
      search: false,
      filtering: false,
      paging: false,
      exportDelimiter: "\t",
      padding: "dense",
      headerStyle: {
        fontSize: 12,
        color: this.props.theme.palette.primary.main,
        textAlignLast: "start",
        whiteSpace: "nowrap",
      },
      rowStyle: (rowData, index) => {
        if (index % 2) {
          return { whiteSpace: "nowrap", backgroundColor: palette.secondary.light };
        } else {
          return { whiteSpace: "nowrap", backgroundColor: palette.secondary.dark };
        }
      },
    };
    this.setState({
      columns: this.columnsFiltered(),
      dataList: this.filterSets.apply(dataList),
      options,
    });
  };

  convertMoneyToReal = (money) => {
    return money;
  };

  convertPorcentToLCT = (porcent, value, money = null) => {
    let total = (value / 100) * porcent;
    return total;
  };

  lastAport = (aports, item) => {
    let aport = aports[aports.length - 1];
    return aport[item];
  };

  onChangeColumn = (event) => {
    let all = true;
    this.setState({
      ListColumns: this.state.ListColumns.map((column) => {
        if (column.name == event.target.name) {
          column.value = event.target.checked;
        }
        if (!column.value) {
          all = false;
        }
        return column;
      }),
      listAllColumns: all,
    });
  };
  onChangeAllColumns = (event) => {
    this.setState({
      ListColumns: this.state.ListColumns.map((column) => {
        column.value = event.target.checked;
        return column;
      }),
      listAllColumns: event.target.checked,
    });
  };

  render() {
    const { currentUser, title } = this.props;
    const { loading } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <>
        <PageContainer full>
          <HeaderPageItem
            title={title}
            showBackButton
            destination="/reports"
            //onMore={e => this.setState({ openDialogFilter: true })}
            onMore={(e) => this.setState({ menu: e.target })}
          >
            <Menu
              keepMounted
              anchorEl={this.state.menu}
              open={Boolean(this.state.menu)}
              onClose={() => {
                this.setState({ menu: null });
              }}
              component="li"
            >
              <MenuItem button onClick={() => this.setState({ openDialogFilter: true })}>
                <FormattedMessage id="app.report.filter" />
              </MenuItem>
              <MenuItem button onClick={() => this.setState({ openDialogColumns: true, menu: null })}>
                <FormattedMessage id="app.column.filter" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          <CardPageItem raised double={currentUser.role != Roles.BusinessManager}>
            <CardContent>
              <Typography gutterBottom color="primary" variant="h6">
                <FormattedMessage id="app.contracts" />
              </Typography>
              <Divider />
              <InfoItem
                caption={<FormattedMessage id={`app.contracts.${this.state.desc}`} />}
                text={<Number value={this.state.dataList.length} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.total" />
                  </div>
                }
                extraText={
                  <Number
                    value={this.totalCustodian()}
                    currency={
                      this.props.taxResidence == "Colômbia" ? "COPD" : this.props.taxResidence == "Brasil" ? "BRZ" : ""
                    }
                  />
                }
              />
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.yield" />}
                text={
                  <Number
                    value={this.totalMonthlyLimit()}
                    currency={
                      this.props.taxResidence == "Colômbia" ? "COPD" : this.props.taxResidence == "Brasil" ? "BRZ" : ""
                    }
                  />
                }
              />
            </CardContent>
          </CardPageItem>

          {currentUser.role != Roles.BusinessManager && (
            <CardPageItem raised double>
              <CardContent>
                <Typography gutterBottom color="primary" variant="h6">
                  <FormattedMessage id="app.cost.monthly" />
                </Typography>
                <Divider />
                <>
                  <Divider />
                  <InfoItem
                    caption={<FormattedMessage id="app.contract.commission" />}
                    text={
                      <Number
                        value={this.totalComission()}
                        currency={
                          this.props.taxResidence == "Colômbia"
                            ? "COPD"
                            : this.props.taxResidence == "Brasil"
                            ? "BRZ"
                            : ""
                        }
                      />
                    }
                  />

                  <Divider />
                  <InfoItem
                    caption={<FormattedMessage id="app.cost.monthly.total" />}
                    text={
                      <Number
                        value={this.totalComission() + this.totalMonthlyLimit()}
                        currency={
                          this.props.taxResidence == "Colômbia"
                            ? "COPD"
                            : this.props.taxResidence == "Brasil"
                            ? "BRZ"
                            : ""
                        }
                      />
                    }
                  />
                </>
              </CardContent>
            </CardPageItem>
          )}

          <PageItem>
            <TableList
              title={this.state.title}
              columns={this.state.columns}
              data={this.state.dataList}
              options={this.state.options}
              user={this.props.currentUser}
            />
          </PageItem>
        </PageContainer>

        <FilterDialog
          title={<FormattedMessage id="app.filter" />}
          open={this.state.openDialogFilter}
          onClose={() => this.setState({ openDialogFilter: false })}
          onAction={(filterSet) => {
            this.filterSets = filterSet;
            this.setState({ openDialogFilter: false });
            this.componentDidMount();
          }}
          onChange={() => this.forceUpdate()}
          filterSet={this.filterSets}
        />

        <Dialog open={this.state.openDialogColumns} fullWidth>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="app.column.filter" />
              </FormLabel>
              <FormGroup></FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.ListAllColumns} onChange={this.onChangeAllColumns} color="primary" />
                }
                label={<FormattedMessage id="app.all" />}
              />
              <Divider />
              <FormGroup>
                {this.state.ListColumns.map((column) => (
                  <FormControlLabel
                    key={column.name}
                    control={
                      <Checkbox
                        checked={column.value}
                        onChange={this.onChangeColumn}
                        name={column.name}
                        color="primary"
                      />
                    }
                    label={<FormattedMessage id={column.label} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Button
                onClick={() => {
                  this.componentDidMount();
                  this.setState({ openDialogColumns: false });
                }}
                color="primary"
              >
                <FormattedMessage id="app.confirm" />
              </Button>
              <Button onClick={() => this.setState({ openDialogColumns: false })} color="primary">
                <FormattedMessage id="app.cancel" />
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const FilterDialog = ({ open, onClose, title, children, onChange, onAction, filterSet }) => (
  // Componente que mostra um diálogo de edição de conjunto de filtros (FilterSet).
  // Basicamente, mostra controles do tipo select para cada filtro do conjunto.
  // Recebe o conjunto via props e devolve o conjunto modificado em onAction.
  <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {filterSet.filters.map((filter) => (
        <Fragment key={filter.caption}>
          <TextField
            select
            label={<FormattedMessage id={filter.caption} />}
            value={filter.value}
            onChange={(e) => {
              filter.value = e.target.value;
              onChange(filter);
            }}
            style={{ width: "100%", paddingTop: "5%" }}
          >
            {filter.options.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Fragment>
      ))}
    </DialogContent>

    <DialogActions>
      <Button type="submit" onClick={(e) => onAction(filterSet)} color="primary">
        <FormattedMessage id="app.filter" />
      </Button>
      <Button onClick={onClose} color="default">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(withTheme(ContractReportContent));
