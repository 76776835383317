import React, { Component } from "react";
import {
  CardContent,
  Button,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import CustomInputForm from "../custom/CustomInput";
import PageContainer, { CardPageItem } from "../custom/Page";
import { phoneFormatter } from "../util/index";
import HeaderPageItem from "../custom/HeaderPageItem";
import AbtcAvatar from "../custom/Avatar";
import { getContract, requestWithdrawal } from "../actions";
import { FormattedMessage } from "react-intl";

class CustomerFormWithdrawFinal extends Component {
  state = {
    contract: {},
    save: false,
    consultant: null,
    saldoTotal: null
  };

  componentDidMount() {
    getContract(this.props.match.params.id).then(data => {
      this.setState({ contract: data, saldoTotal: data.amount + data.balance });

      this.props.change("amount", data.amount + data.balance);
    });
  }

  onSubmit = formProps => {
    formProps.amount = this.state.saldoTotal;

    const { showErrorMessage, showSuccessMessage } = this.props;
    requestWithdrawal(this.state.contract.id, formProps)
      .then(response => {
        showSuccessMessage("app.create");
        this.setState({ save: true });
        this.props.history.push("/contract/" + this.state.contract.id);
      })
      .catch(e => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;
    const { consultant, contract, saldoTotal } = this.state;

    return (
      <PageContainer>
        <HeaderPageItem
          title="app.withdraw"
          showBackButton
          destination={"/contract/" + contract.id}
        />
        <CardPageItem>
          <CardContent>
            <Typography
              gutterBottom
              variant="body1"
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              mensagem do alessandro saldo: {saldoTotal}
            </Typography>
          </CardContent>
          <CardContent>
            <form id="newSaque" onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                fullWidth
                component={CustomInputForm}
                type="number"
                name="amount"
                label="app.withdraw.amount"
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <Field
                fullWidth
                component={CustomInputForm}
                name="address"
                label="app.wallet.address"
                InputLabelProps={{ shrink: true }}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 16, marginBottom: 16 }}
                disabled={
                  this.props.pristine ||
                  this.props.submitting ||
                  this.props.invalid
                }
              >
                <FormattedMessage id="app.withdrawal.request.submit" />
              </Button>
            </form>

            {consultant && (
              <>
                <Typography variant="caption" color="textPrimary">
                  <FormattedMessage id="app.contract.waitingAssign.text2" />
                </Typography>
                <Card>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <AbtcAvatar
                          imageUrl={consultant.photoUrl}
                          disableLink
                          size={60}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={consultant.name}
                        secondary={
                          <>
                            {phoneFormatter(consultant.telefone)}
                            <br />
                            {consultant.email}
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </Card>
              </>
            )}
          </CardContent>
        </CardPageItem>
        <CardPageItem />
      </PageContainer>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "app.value.report";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "newSaque",
    enableReinitialize: true,
    validate
  })
)(CustomerFormWithdrawFinal);
