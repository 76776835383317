import { withTheme } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Description from "@material-ui/icons/Description";
import Help from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import LocalAtmSharpIcon from "@material-ui/icons/LocalAtmSharp";
import People from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../actions";
import { Roles, ListRoutes } from "../util";
import { FormattedMessage } from "react-intl";
import platformConfig from "../platformConfig";
import { Typography } from "@material-ui/core";

class SimpleBottomNavigation extends React.Component {
  routes = [];

  state = {
    value: 0,
  };

  constructor(props) {
    super(props);

    switch (this.props.currentUser.role) {
      case Roles.Admin:
        this.routes = ListRoutes.Admin;
        break;
      case Roles.Agent:
        this.routes = ListRoutes.Agent;
        break;
      case Roles.AgentsManager:
        this.routes = ListRoutes.AgentsManager;
        break;
      case Roles.BusinessManager:
        this.routes = ListRoutes.BusinessManager;
        break;
      case Roles.Customer:
        this.routes = ListRoutes.Customer;
        break;
      case Roles.FinanceManager:
        this.routes = ListRoutes.FinanceManager;
        break;
      case Roles.Kyc:
        this.routes = ListRoutes.Kyc;
        break;
      case Roles.Auditor:
        this.routes = ListRoutes.Auditor;
        break;
      default:
        this.routes = ListRoutes.Customer;
    }
  }

  componentDidMount() {
    this.selectByRoute();
  }

  componentDidUpdate(oldProps, prevState) {
    this.selectByRoute(prevState);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  selectByRoute = (prevState) => {
    const { history } = this.props;
    const route = this.routes.find((r) => history.location.pathname.startsWith(r.path));
    const routeId = route ? route.id : 0;
    if (prevState && prevState.value == routeId) return;
    this.setState({ value: routeId });
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { value } = this.state;
    if (platformConfig.id === "digiplata" && currentUser.role == Roles.Customer) {
      return null;
    } else {
      return currentUser.role == Roles.Agent ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.contracts" />}
            component={Link}
            to="/contracts"
            icon={<Description />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.customers" />}
            component={Link}
            to="/customers"
            icon={<People />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.account" />}
            component={Link}
            to="/history"
            icon={<ReceiptIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.help" />}
            component={Link}
            to="/help"
            icon={<Help />}
          />
        </BottomNavigation>
      ) : currentUser.role == Roles.Admin ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.withdrawals" />}
            component={Link}
            to="/historyWithdrawal"
            icon={<LocalAtmSharpIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.investment" />}
            component={Link}
            to="/investments"
            icon={<AttachMoney />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.users" />}
            component={Link}
            to="/users"
            icon={<People />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.account" />}
            component={Link}
            to="/history"
            icon={<ReceiptIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.reports" />}
            component={Link}
            to="/reports"
            icon={<MenuBookIcon />}
          />
        </BottomNavigation>
      ) : currentUser.role == Roles.Auditor ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.withdrawals" />}
            component={Link}
            to="/historyWithdrawal"
            icon={<LocalAtmSharpIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.reports" />}
            component={Link}
            to="/reports"
            icon={<MenuBookIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.users" />}
            component={Link}
            to="/users"
            icon={<People />}
          />
        </BottomNavigation>
      ) : currentUser.role == Roles.Customer ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.contracts" />}
            component={Link}
            to="/contracts"
            icon={<Description />}
          />
          {platformConfig.id !== "digiplata" && (
            <BottomNavigationAction
              label={<FormattedMessage id="app.navigationBar.hire" />}
              component={Link}
              to="/newContact"
              icon={<AttachMoney />}
            />
          )}
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.account" />}
            component={Link}
            to="/history"
            icon={<ReceiptIcon />}
          />
          {platformConfig.id !== "digiplata" && (
            <BottomNavigationAction
              label={<FormattedMessage id="app.navigationBar.help" />}
              component={Link}
              to="/help"
              icon={<Help />}
            />
          )}
        </BottomNavigation>
      ) : currentUser.role == Roles.FinanceManager ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.withdrawals" />}
            component={Link}
            to="/historyWithdrawal"
            icon={<LocalAtmSharpIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.users" />}
            component={Link}
            to="/users"
            icon={<People />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.reports" />}
            component={Link}
            to="/reports"
            icon={<MenuBookIcon />}
          />
        </BottomNavigation>
      ) : currentUser.role == Roles.BusinessManager ? (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.reports" />}
            component={Link}
            to="/reports"
            icon={<MenuBookIcon />}
          />
        </BottomNavigation>
      ) : (
        <BottomNavigation value={value} onChange={this.handleChange} showLabels style={{ zIndex: 200 }}>
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.home" />}
            component={Link}
            to="/dashboard"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="app.navigationBar.users" />}
            component={Link}
            to="/users"
            icon={<People />}
          />
        </BottomNavigation>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(withRouter(connect(mapStateToProps, actions)(SimpleBottomNavigation)));
