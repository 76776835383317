import React, { Component, Fragment } from "react";
import { Button, Typography, CircularProgress, CardContent } from "@material-ui/core";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { createContractAgent, editContractAgent, getConfig, restartContractAgent } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";
import { CustomView, isIOS } from "react-device-detect";
import customInput, { CustomDateOfBirthPicker } from "../../custom/CustomInput";
import FormDialog from "../../custom/FormDialog";
import ContractAgentDescription from "./ContractAgentDescription";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormContractAgent extends Component {
  state = {
    editContract: false, // False = novo contrato, True = editar contrato
    contractDetail: false,
  };

  componentDidMount() {
    const { initialize, customer, getConfig } = this.props;
    getConfig();
    if (customer.contractAgent) {
      initialize({
        id: customer.contractAgent.id,
        goal: customer.contractAgent.goal,
      });
      this.setState({ editContract: true });
    }
  }

  onSubmit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    if (!customer.contractAgent) {
      this.onSave(formProps);
    } else if (customer.contractAgent.status == "PENDING" || customer.contractAgent.status == "ACTIVE") {
      this.onEdit(formProps);
    } else {
      this.onRestart(formProps);
    }
  };

  onSave = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    createContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.created");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  onEdit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    editContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.edited");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  onRestart = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;

    restartContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.restarted");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { handleSubmit, customer, config } = this.props;
    if (!customer || !config.LCTInputMask) return <CircularProgress style={{ marginTop: "25%" }} />;
    const lctMask = config.LCTInputMask();
    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Typography gutterBottom color="textSecondary" variant="caption">
            <FormattedMessage id="app.name" />
          </Typography>

          <Typography gutterBottom>
            {customer.name}
            {" - "}
            <FormattedMessage id={`app.enuns.${customer.role}`} />
            {" ID: " + customer.id}
          </Typography>
          {customer.contractAgent && (
            <div>
              <Typography gutterBottom color="textSecondary" variant="caption">
                <FormattedMessage id="app.status" />
              </Typography>
              <Typography gutterBottom>
                <FormattedMessage id={`app.enuns.${customer.contractAgent.status}`} />
              </Typography>
              <Button variant="outlined" onClick={() => this.setState({ contractDetail: true })}>
                <FormattedHTMLMessage id="app.contractAgent.read" />
              </Button>
              <FormDialog
                title="app.contractAgent"
                open={this.state.contractDetail}
                variant="secondary"
                onClose={(e) => this.setState({ contractDetail: false })}
              >
                <CardContent>
                  <ContractAgentDescription currentUser={customer} contractAgent={customer.contractAgent} />
                </CardContent>
              </FormDialog>
              <Button
                style={{ marginLeft: 10 }}
                variant="outlined"
                onClick={() => this.onRestart({ id: customer.contractAgent.id, goal: customer.contractAgent.goal })}
              >
                <FormattedHTMLMessage id="app.contractAgent.restart" />
              </Button>
            </div>
          )}
          <Field
            name="goal"
            label="app.goal"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            autoFocus
            {...lctMask}
          />

          {!customer.contractAgent ? (
            <Button
              id="bSubmitOrderForm"
              style={{ marginTop: 10 }}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={this.props.submitting || this.props.invalid || this.props.pristine}
            >
              <FormattedMessage id="app.button.save" />
            </Button>
          ) : (
            <div>
              <Button
                id="bSubmitOrderForm"
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={
                  customer.contractAgent.status == "TERMINATED"
                    ? this.props.submitting || this.props.invalid
                    : this.props.submitting || this.props.invalid || this.props.pristine
                }
              >
                {customer.contractAgent.status == "TERMINATED" ? (
                  <FormattedHTMLMessage id="app.contractAgent.restart" />
                ) : (
                  <FormattedMessage id="app.edit" />
                )}
              </Button>
              {customer.contractAgent.status == "TERMINATED" && (
                <div>
                  <br />
                  <FormattedHTMLMessage color="primary" id="app.contractAgent.terminated.text1" />
                </div>
              )}
            </div>
          )}
        </form>
      </Fragment>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.goal) {
    errors.goal = "app.goal.inform";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default compose(
  reduxForm({
    form: "FormUpdateAddress",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormContractAgent);
