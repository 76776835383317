import React, { Component } from "react";
import {
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { getKyc, sendFileKyc } from "../actions/User";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FormDialog from "../custom/FormDialog";
import { badgesKyc } from "../custom/BadgesKyc";
import { FormattedMessage } from "react-intl";
import FormAttachment from "./components/FormAttachment";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import FormSendDoc from "./components/FormSendDoc";
import FormSendAddress from "./components/FormSendAddress";
import FormSendSocialContract from "./components/FormSendSocialContract";
import FormSendSelfie from "./components/FormSendSelfie";
import { resizeImageFile } from "../util";
import platformConfig from "../platformConfig";

const placeholder_kyc = {
  ID: "0",
  ADDRESS: "1",
  SOCIAL_CONTRACT: "2",
  ID_WITH_PHOTO: "3",
  ATTACHMENT: "4",
};

class CustomerDocs extends Component {
  state = {
    consultant: null,
    loading: true,
    idOpen: false,
    socialContractOpen: false,
    addressOpen: false,
    attachmentOpen: false,
    userKyc: [],
    ID: false,
    ADDRESS: false,
    SOCIAL_CONTRACT: false,
    ID_WITH_PHOTO: false,
    ATTACHMENT: false,
    selfie: false,
    pj: this.props.currentUser.isPj,
    dialogImageOpen: false,
    photoRotation: 0,
    type: "",
    name: "",
    img: null,
    openButtonDoc: true,
    disabledbtn1: false,
    disabledbtn2: false,
    buttonDoc1: false,
    buttonDoc2: false,
  };

  loadKyc = () => {
    getKyc().then((data) => {
      this.setState({ userKyc: data, loading: false });
    });
  };
  docFileMask = "image/*,application/pdf";
  componentDidMount() {
    this.loadKyc();
  }
  load = () => {
    this.loadKyc();
    this.setState({ attachmentOpen: false });
  };
  openButtons = () => {
    if (this.state.buttonDoc1 && this.state.buttonDoc2) {
      this.setState({ openButtonDoc: false });
    }
    if (this.state.buttonDoc1) {
      this.setState({ disabledbtn1: true });
    }
    if (this.state.buttonDoc2) {
      this.setState({ disabledbtn2: true });
    }
  };
  sendImage = (event) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    resizeImageFile(this.state.photoFile, 2000, 2000, this.state.photoRotation, 70).then((blob) => {
      sendFileKyc(this.state.type, blob)
        .then((data) => {
          showSuccessMessage("app.send.success");
          this.loadKyc();
          this.openButtons();
          this.setState({ [this.state.name]: false, dialogImageOpen: false, photoRotation: 0 });
        })
        .catch((error) => {
          showErrorMessage(error.response ? error.response.data.message : error);
          this.loadKyc();
          this.setState({ [this.state.name]: false });
        });
    });
  };
  handleSetKyc = (type, event, name) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ [name]: true });
      let files = event.target.files[0];
      if (files.type.startsWith("image/")) {
        //this.sendSelfie(type, files, name);
        this.setState({
          photoFile: event.target.files[0],
          type: type,
          name: name,
          dialogImageOpen: true,
        });
      } else if (files.type.startsWith("application/pdf")) {
        sendFileKyc(type, files)
          .then((data) => {
            showSuccessMessage("app.send.success");
            this.loadKyc();
            this.openButtons();
            this.setState({ [name]: false });
          })
          .catch((error) => {
            showErrorMessage(error.response ? error.response.data.message : error);
            this.loadKyc();
            this.setState({ [name]: false });
          });
      } else {
        this.setState({ [name]: false });
        showErrorMessage(<FormattedMessage id="app.upload.error2" />);
      }
    }
  };
  photoRotationChange = (dir) => {
    let rot = Math.abs(this.state.photoRotation + 90 * dir);
    if (rot === 360) {
      rot = 0;
    }
    this.setState({
      photoRotation: rot,
    });
  };
  setEditorRef = (editor) => (this.editor = editor);
  render() {
    const { currentUser, consultant } = this.props;
    const { userKyc, pj, photoFile } = this.state;
    const badges = badgesKyc(pj, userKyc);
    const { docAd, docId, docSelfie, docSc, docAnx } = badges;
    const photoObj = photoFile ? URL.createObjectURL(photoFile) : null;

    const colorAd =
      docAd.status == "APPROVED"
        ? platformConfig.id === "digiplata"
          ? "black"
          : "white"
        : docAd.status == "PENDING" || docAd.status == "ANALYZING"
        ? "yellow"
        : "red";
    const colorSc =
      docSc.status == "APPROVED" || docSelfie.status == "INVALID"
        ? platformConfig.id === "digiplata"
          ? "black"
          : "white"
        : docSc.status == "PENDING" || docSc.status == "ANALYZING"
        ? "yellow"
        : "red";
    const colorId =
      docId.status == "APPROVED" || docSelfie.status == "INVALID"
        ? platformConfig.id === "digiplata"
          ? "black"
          : "white"
        : docId.status == "PENDING" || docId.status == "ANALYZING"
        ? "yellow"
        : "red";
    const colorSelfie =
      docSelfie.status == "APPROVED" || docSelfie.status == "INVALID"
        ? platformConfig.id === "digiplata"
          ? "black"
          : "white"
        : docSelfie.status == "PENDING" || docSelfie.status == "ANALYZING"
        ? "yellow"
        : "red";
    const colorAt =
      docAnx.status == "REJECTED"
        ? "red"
        : docAnx.status == "PENDING" || docAnx.status == "ANALYZING"
        ? "yellow"
        : platformConfig.id === "digiplata"
        ? "black"
        : "white";

    if (this.state.loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <>
          <PageContainer>
            <HeaderPageItem title="app.documents.title" showBackButton destination={"/more"} />

            <CardPageItem>
              <CardContent>
                {badges.cor == "error" ? (
                  <Typography gutterBottom variant="subtitle1">
                    <FormattedMessage id="app.documents.badge.error.title" />
                  </Typography>
                ) : badges.cor == "primary" ? (
                  <Typography gutterBottom variant="subtitle1">
                    <FormattedMessage id="app.documents.badge.primary.title" />
                  </Typography>
                ) : !consultant && currentUser.role !== "AGENT" ? (
                  <Typography gutterBottom variant="body1">
                    <FormattedMessage id="app.documents.noAgent.title" />
                  </Typography>
                ) : (
                  <Typography gutterBottom variant="body1">
                    <FormattedMessage id="app.documents.update.title" />
                  </Typography>
                )}
              </CardContent>
              <CardContent>
                <List>
                  {!pj ? (
                    <>
                      <ListItem
                        button={docAd.status !== "ANALYZING" && !this.state.ADDRESS}
                        onClick={(e) => this.setState({ addressOpen: true })}
                      >
                        <ListItemText
                          primary={
                            <span style={{ color: colorAd }}>
                              <FormattedMessage id="app.enuns.ADDRESS" defaultValue="Comprovante de endereço" />
                            </span>
                          }
                          secondary={
                            docAd.status == "APPROVED" ? (
                              <FormattedMessage id="app.documents.address.status.approved" />
                            ) : docAd.status == "PENDING" ? (
                              <FormattedMessage id="app.documents.address.status.pending" />
                            ) : docAd.status == "ANALYZING" ? (
                              <FormattedMessage id="app.documents.address.status.analyzing" />
                            ) : docAd.status == "REJECTED" ? (
                              <FormattedMessage
                                id="app.documents.address.status.rejected"
                                values={{ reason: docAd.reason }}
                              />
                            ) : (
                              <FormattedMessage id="app.documents.address.send" />
                            )
                          }
                        />
                        <ListItemSecondaryAction>
                          {this.state.ADDRESS ? (
                            <CircularProgress size={32} />
                          ) : (
                            <IconButton
                              disabled={docAd.status == "ANALYZING"}
                              onClick={(e) => this.setState({ addressOpen: true })}
                            >
                              <ChevronRight status={docAd.status} />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <input
                        ref={(r) => (this.contained_button_file_1 = r)}
                        id="contained-button-file-1"
                        style={{ display: "none" }}
                        disabled={docAd.status == "ANALYZING" || this.state.ADDRESS}
                        accept={this.docFileMask}
                        type="file"
                        onChange={(e) => {
                          if (!this.state.ADDRESS) this.handleSetKyc(placeholder_kyc.ADDRESS, e, "ADDRESS");
                        }}
                      />
                      <label htmlFor="contained-button-file-1">
                        <FormDialog
                          title="app.addDocument"
                          open={this.state.addressOpen}
                          variant="secondary"
                          noHeader
                          onClose={(e) => this.setState({ addressOpen: false })}
                        >
                          <FormSendAddress
                            onActionComplete={(e) => {
                              this.contained_button_file_1.click();
                              this.setState({
                                addressOpen: false,
                                photoRotation: 0,
                                photoFile: null,
                              });
                            }}
                          />
                        </FormDialog>
                      </label>
                    </>
                  ) : (
                    <>
                      <ListItem
                        button={docSc.status !== "ANALYZING" && !this.state.SOCIAL_CONTRACT}
                        onClick={(e) => this.setState({ socialContractOpen: true })}
                      >
                        <ListItemText
                          primary={
                            <span style={{ color: colorSc }}>
                              <FormattedMessage id={"app.documents.registrationIncorporationCertificate"} />
                            </span>
                          }
                          secondary={
                            docSc.status == "APPROVED" ? (
                              <FormattedMessage id="app.documents.idWithPhoto.status.approved" />
                            ) : docSc.status == "PENDING" ? (
                              <FormattedMessage id="app.documents.address.status.pending" />
                            ) : docSc.status == "ANALYZING" ? (
                              <FormattedMessage id="app.documents.idWithPhoto.status.analyzing" />
                            ) : docSc.status == "REJECTED" ? (
                              <FormattedMessage
                                id="app.documents.idWithPhoto.status.rejected"
                                values={{ reason: docSc.reason }}
                              />
                            ) : (
                              <FormattedMessage id="app.documents.registrationIncorporationCertificate.send" />
                            )
                          }
                        />
                        <ListItemSecondaryAction>
                          {this.state.SOCIAL_CONTRACT ? (
                            <CircularProgress size={32} />
                          ) : (
                            <IconButton
                              disabled={docSc.status == "ANALYZING"}
                              onClick={(e) => this.setState({ socialContractOpen: true })}
                              //onClick={e => this.contained_button_file_2.click()}
                            >
                              <ChevronRight status={docSc.status} />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <input
                        ref={(r) => (this.contained_button_file_2 = r)}
                        id="contained-button-file-2"
                        style={{ display: "none" }}
                        disabled={docSc.status == "ANALYZING" || this.state.SOCIAL_CONTRACT}
                        accept={this.docFileMask}
                        type="file"
                        onChange={(e) => {
                          if (!this.state.SOCIAL_CONTRACT)
                            this.handleSetKyc(placeholder_kyc.SOCIAL_CONTRACT, e, "SOCIAL_CONTRACT");
                        }}
                      />
                      <label htmlFor="contained-button-file-2">
                        <FormDialog
                          title="app.addDocument"
                          open={this.state.socialContractOpen}
                          variant="secondary"
                          noHeader
                          onClose={(e) => this.setState({ socialContractOpen: false })}
                        >
                          <FormSendSocialContract
                            onActionComplete={(e) => {
                              this.contained_button_file_2.click();
                              this.setState({
                                socialContractOpen: false,
                                photoRotation: 0,
                                photoFile: null,
                              });
                            }}
                          />
                        </FormDialog>
                      </label>
                    </>
                  )}
                  {pj && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{
                        marginTop: 32,
                        marginBottom: 32,
                        marginLeft: 12,
                      }}
                    >
                      <FormattedMessage id="app.documents.socialContract.subtitle" />
                    </Typography>
                  )}

                  {/* Adicionar documento */}
                  <ListItem
                    button={docId.status !== "ANALYZING" && !this.state.ID}
                    onClick={(e) => this.setState({ idOpen: true })}
                  >
                    <ListItemText
                      primary={
                        <span style={{ color: colorId }}>
                          <FormattedMessage id="app.documents.identification.photo" />
                        </span>
                      }
                      secondary={
                        docId.status == "APPROVED" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.approved" />
                        ) : docId.status == "PENDING" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.pending" />
                        ) : docId.status == "ANALYZING" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.analyzing" />
                        ) : docId.status == "REJECTED" ? (
                          <FormattedMessage
                            id="app.documents.idWithPhoto.status.rejected"
                            values={{ reason: docId.reason }}
                          />
                        ) : (
                          <FormattedMessage id={"app.documents.identification.photo.send"} />
                        )
                      }
                    />
                    <ListItemSecondaryAction>
                      {this.state.ID ? (
                        <CircularProgress size={32} />
                      ) : (
                        <IconButton
                          disabled={docId.status == "ANALYZING"}
                          onClick={(e) => this.setState({ idOpen: true })}
                          // onClick={e => this.contained_button_file_0.click()}
                        >
                          <ChevronRight status={docId.status} />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <input
                    ref={(r) => (this.contained_button_file_0 = r)}
                    id="image"
                    style={{ display: "none" }}
                    disabled={platformConfig.id === "luxcapital" && (docId.status == "ANALYZING" || this.state.ID)}
                    accept={this.docFileMask}
                    type="file"
                    onChange={(e) => {
                      if (!this.state.ID) this.handleSetKyc(placeholder_kyc.ID, e, "ID");
                    }}
                  />
                  <label htmlFor="image">
                    <FormDialog
                      title="app.addDocument"
                      open={this.state.idOpen}
                      variant="secondary"
                      noHeader
                      onClose={(e) => this.setState({ idOpen: false })}
                    >
                      {platformConfig.id === "digiplata" ? (
                        <FormSendDoc
                          onActionSendFront={(e) => {
                            this.contained_button_file_0.click();
                            this.setState({ photoRotation: 0, photoFile: null, buttonDoc1: true });
                          }}
                          onActionSendBack={(e) => {
                            this.contained_button_file_0.click();
                            this.setState({ photoRotation: 0, photoFile: null, buttonDoc2: true });
                          }}
                          onActionComplete={(e) => {
                            this.setState({ idOpen: false, openButtonDoc: true, buttonDoc1: false, buttonDoc2: false });
                          }}
                          disabled={this.state.openButtonDoc}
                          disabledbtn1={this.state.disabledbtn1}
                          disabledbtn2={this.state.disabledbtn2}
                        />
                      ) : (
                        <FormSendDoc
                          onActionComplete={(e) => {
                            this.contained_button_file_0.click();
                            this.setState({ photoRotation: 0, photoFile: null, idOpen: false });
                          }}
                        />
                      )}
                    </FormDialog>
                  </label>

                  {/* Adicionar selfie */}
                  <ListItem
                    button={docSelfie.status !== "ANALYZING" && !this.state.ID_WITH_PHOTO}
                    onClick={(e) => this.setState({ selfie: true })}
                  >
                    <ListItemText
                      primary={
                        <span style={{ color: colorSelfie }}>
                          <FormattedMessage id="app.documents.photo.holding" />
                        </span>
                      }
                      secondary={
                        docSelfie.status == "APPROVED" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.approved" />
                        ) : docSelfie.status == "PENDING" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.pending" />
                        ) : docSelfie.status == "ANALYZING" ? (
                          <FormattedMessage id="app.documents.idWithPhoto.status.analyzing" />
                        ) : docSelfie.status == "REJECTED" ? (
                          <FormattedMessage
                            id="app.documents.idWithPhoto.status.rejected"
                            values={{ reason: docSelfie.reason }}
                          />
                        ) : (
                          <FormattedMessage id="app.documents.idWithPhoto.status.empty" />
                        )
                      }
                    />
                    <ListItemSecondaryAction>
                      {this.state.ID_WITH_PHOTO ? (
                        <CircularProgress size={32} />
                      ) : (
                        <>
                          <IconButton
                            disabled={docSelfie.status == "ANALYZING"}
                            onClick={(e) => this.setState({ selfie: true })}
                          >
                            <ChevronRight status={docSelfie.status} />
                          </IconButton>
                        </>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <input
                    ref={(r) => (this.contained_button_file_3 = r)}
                    id="contained-button-file-3"
                    style={{ display: "none" }}
                    disabled={docSelfie.status == "ANALYZING" || this.state.ID_WITH_PHOTO}
                    accept={this.docFileMask}
                    type="file"
                    onChange={(e) => {
                      if (!this.state.ID_WITH_PHOTO)
                        this.handleSetKyc(placeholder_kyc.ID_WITH_PHOTO, e, "ID_WITH_PHOTO");
                    }}
                  />
                  <label htmlFor="contained-button-file-3">
                    <FormDialog
                      title="app.enuns.ID_WITH_PHOTO"
                      open={this.state.selfie}
                      variant="secondary"
                      noHeader
                      onClose={(e) => this.setState({ selfie: false })}
                    >
                      <FormSendSelfie
                        onActionComplete={(e) => {
                          this.contained_button_file_3.click();
                          this.setState({ selfie: false, photoRotation: 0, photoFile: null });
                        }}
                      />
                    </FormDialog>
                  </label>

                  {/* Adicionar anexo */}
                  <ListItem
                    button
                    onClick={(e) => {
                      this.setState({ attachmentOpen: true });
                    }}
                  >
                    <ListItemText
                      primary={
                        <span style={{ color: colorAt }}>
                          <FormattedMessage id="app.documents.anexo.add" />
                        </span>
                      }
                      secondary={
                        docAnx.status == "APPROVED" ? (
                          <FormattedMessage id="app.documents.anexo.status.approved" />
                        ) : docAnx.status == "ANALYZING" ? (
                          <FormattedMessage id="app.documents.anexo.status.analyzing" />
                        ) : docAnx.status == "PENDING" ? (
                          <FormattedMessage id="app.documents.anexo.status.pending" />
                        ) : docAnx.status == "REJECTED" ? (
                          <FormattedMessage
                            id="app.documents.anexo.status.rejected"
                            values={{ reason: docAnx.reason }}
                          />
                        ) : (
                          <FormattedMessage id="app.documents.anexo.status.empty" />
                        )
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={(e) => {
                          this.setState({ attachmentOpen: true });
                        }}
                      >
                        <ChevronRight status={docAnx.status} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <FormDialog
                    title="app.addDocument"
                    open={this.state.attachmentOpen}
                    variant="secondary"
                    noHeader
                    onClose={(e) => this.setState({ attachmentOpen: false })}
                  >
                    <FormAttachment onActionComplete={this.load} user={currentUser} />
                  </FormDialog>
                </List>
              </CardContent>
            </CardPageItem>
            <FormDialog
              title="app.changeAvatar"
              variant="secondary"
              open={this.state.dialogImageOpen}
              onClose={() =>
                this.setState({
                  dialogImageOpen: false,
                  ID: false,
                  ADDRESS: false,
                  SOCIAL_CONTRACT: false,
                  ID_WITH_PHOTO: false,
                  ATTACHMENT: false,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {photoFile ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 16,
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => this.photoRotationChange(1)}
                        style={{ marginRight: 16 }}
                      >
                        <RotateRightIcon />
                        Girar
                      </Button>
                      <Button variant="contained" color="primary" onClick={this.sendImage}>
                        <FormattedMessage id="app.button.save" />
                      </Button>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 0,
                        paddingTop: "100%",
                        backgroundColor: "black",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#222222",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={photoObj}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            transform: "rotate(" + this.state.photoRotation + "deg)",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <CircularProgress color="primary" style={{ marginTop: "25%" }} />
                )}
              </div>
            </FormDialog>
          </PageContainer>
        </>
      );
    }
  }
}
const validate = (values) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "app.value.report";
  }
  if (values.amount < 0.25) {
    errors.amount = "app.value.minimum.btc";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    consultant: state.user.user.me.consultant,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "newAport",
    enableReinitialize: true,
    validate,
  })
)(CustomerDocs);
