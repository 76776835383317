import { Button, CardContent, CircularProgress, Menu, MenuItem, Typography, withTheme } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getContract, getConfigurações, contractRedeem, getUser2 } from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { HelpDialog } from "./components/HelpDialog";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { validateContract } from "../util";
import ImgExSaquePT from "../static/TelaSaqueReaportePT.png";
import ImgExSaqueEN from "../static/TelaSaqueReaporteEN.png";

class CustomerReaportContract extends Component {
  state = {
    contract: {},
    customer: {},
    menuAnchor: undefined,
    step: false,
    saldoTotal: null,
    config: {},
  };

  loadContract = () => {
    getContract(this.props.match.params.id).then((data) => {
      getUser2(data.user.id).then((c) => {
        this.setState({
          customer: c,
          contract: data,
          saldoTotal: data.amount + data.yield.val,
        });
      });
    });
  };

  componentDidMount() {
    this.loadContract();
    getConfigurações().then((data) => {
      this.setState({ config: data });
    });
  }

  onOk = () => {
    this.props.history.push("/contracts");
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { contract, config, customer } = this.state;
    if (!contract.id || config.redemptionDelay < 0) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else if (!validateContract(currentUser, contract)) {
      return null;
    } else {
      const agentBack = currentUser.id !== contract.user.id;

      return (
        <PageContainer>
          <HeaderPageItem
            title={"Reaporte"}
            showBackButton
            destination={"/contract/" + contract.id}
            values={contract.id}
          ></HeaderPageItem>
          <CardPageItem>
            <CardContent>
              <Typography gutterBottom variant="h6" color="primary">
                <FormattedHTMLMessage id="app.redemption.text1" />
              </Typography>
              <Typography gutterBottom>
                <FormattedHTMLMessage id="app.redemption.text2" />
              </Typography>
            </CardContent>
            <CardContent>
              <Typography gutterBottom style={{ marginBottom: 16 }}>
                <span style={{ color: palette.primary.main }}>1. </span>
                <FormattedHTMLMessage id="app.redemption.text3" values={{ value: config.redemptionDelay }} />
              </Typography>
              <Typography gutterBottom style={{ marginBottom: 16 }}>
                <span style={{ color: palette.primary.main }}>2. </span>
                <FormattedHTMLMessage id="app.redemption.text4" />
              </Typography>
              <Typography gutterBottom style={{ marginBottom: 16 }}>
                <span style={{ color: palette.primary.main }}>3. </span>
                <FormattedHTMLMessage id="app.redemption.text5" />
              </Typography>
              {customer.locale == "en" ? (
                <img src={ImgExSaqueEN} width="100%" style={{ marginTop: 16, marginBottom: 16 }} />
              ) : (
                <img src={ImgExSaquePT} width="100%" style={{ marginTop: 16, marginBottom: 16 }} />
              )}
              <Typography gutterBottom style={{ marginBottom: 16 }}>
                <span style={{ color: palette.primary.main }}>4. </span>
                <FormattedHTMLMessage id="app.redemption.text6" />
              </Typography>
              <Typography gutterBottom style={{ marginBottom: 16 }}>
                <span style={{ color: palette.primary.main }}>5. </span>
                <FormattedHTMLMessage id="app.redemption.text7" />
              </Typography>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                onClick={() => {
                  contractRedeem(contract.id);
                  this.setState({ step: true });
                }}
              >
                <FormattedHTMLMessage id="app.redemption.button" />
              </Button>
            </CardContent>
            <HelpDialog
              open={this.state.step}
              onClose={() => {
                this.onOk();
                this.setState({ step: false });
              }}
              title="app.contract.finished.dialog"
            >
              <FormattedMessage id="app.contract.finished.dialog.text" values={{ days: config.redemptionDelay }} />
            </HelpDialog>
          </CardPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  }

  return errors;
};

export default compose(
  withRouter,
  withTheme,
  reduxForm({ form: "transForm", validate }),
  connect(mapStateToProps, actions)
)(CustomerReaportContract);
