import {
	CardContent,
	FormControl,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { Roles } from "../util";
import { country } from "./components/Terms";

class MenuReports extends Component {
	state = {
		taxResidence: this.props.currentUser.taxResidence,
	};

	ReportListItem = ({ captionId, path, taxResidence }) => {
		return (
			<ListItem button component={Link} to={path + "?taxResidence=" + taxResidence}>
				<ListItemText primary={<FormattedMessage id={captionId} />} />
				<ChevronRight color="action" />
			</ListItem>
		);
	};

	ReportMenuList = ({ taxResidence }) => {
		return (
			<List>
				<this.ReportListItem
					captionId="app.contracts.justopened"
					path="/contractReportJustOpened"
					taxResidence={taxResidence}
				/>
				<this.ReportListItem
					captionId="app.contracts.reportwaitingvalidation"
					path="/contractReportWaitingValidation"
					taxResidence={taxResidence}
				/>
				<this.ReportListItem
					captionId="app.lccontracts.active"
					path="/contractReport"
					taxResidence={taxResidence}
				/>
				<this.ReportListItem
					captionId="app.contracts.terminated"
					path="/contractReportTerminated"
					taxResidence={taxResidence}
				/>
				<this.ReportListItem
					captionId="app.contracts.redeemed"
					path="/contractReportRedeemed"
					taxResidence={taxResidence}
				/>
			</List>
		);
	};

	ReportMenu = ({ taxResidence }) => {
		return (
			<CardPageItem>
				<CardContent>
					<this.ReportMenuList taxResidence={taxResidence} />
				</CardContent>
			</CardPageItem>
		);
	};

	render() {
		const { currentUser } = this.props;
		const { taxResidence } = this.state;
		return (
			<PageContainer>
				<HeaderPageItem title="app.reports" showBackButton destination="/dashboard" />
				<CardPageItem raised>
					<CardContent>
						<Typography variant="h6">
							<FormattedMessage id="app.user.report" />
						</Typography>
						<List>
							<this.ReportListItem captionId="app.user.report" path="/userReport" taxResidence="" />
						</List>
					</CardContent>
				</CardPageItem>

				<CardPageItem raised>
					<CardContent>
						<Typography variant="h6">
							<FormattedMessage id="app.report.lccontracts" />
						</Typography>
						{(currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && (
							<>
								<Typography gutterBottom>
									<FormattedMessage id="app.report.lccontracts.selectTaxResidence" />
								</Typography>
								<FormControl variant="outlined" fullWidth>
									<Select
										value={taxResidence}
										onChange={(e) => this.setState({ taxResidence: e.target.value })}
									>
										{country.map((ctry) => (
											<MenuItem key={ctry.value} value={ctry.value}>
												{ctry.input}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</>
						)}
						<this.ReportMenuList taxResidence={taxResidence} />
					</CardContent>
				</CardPageItem>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.user.me,
	};
}

export default compose(connect(mapStateToProps, actions))(MenuReports);

/*
<ListItem button onClick={this.contractReport(null,null,"PAYMENT_GENERATED","Brasil")}>
	<ListItemText primary={<FormattedMessage id="teste redeem" />} />
	<ChevronRight color="action" />
</ListItem>
*/
