import React, { Component } from "react";
import {
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Avatar
} from "@material-ui/core";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import performRequest from "../configAxios";
import * as actions from "../actions";
import wppWhite from "../static/whatsappWhite.png";
import rent from "../static/rentabilidade.png";
import liq from "../static/liquidez.png";
import tempoReal from "../static/tempoReal.png";
import auditoria from "../static/auditoria.png";
import agentes from "../static/agentes.png";
import PageContainer, { CardPageItem } from "../custom/Page";
import { phoneFormatter } from "../util/index";
import HeaderPageItem from "../custom/HeaderPageItem";
import AbtcAvatar from "../custom/Avatar";
import { getActiveContractType } from "../actions/Contract";
import { getKyc, sendFileKyc } from "../actions/User";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import FixedPageItem from "../custom/FixedPageItem";
import Phone from "@material-ui/icons/Phone";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import CustomerDocs from "./CustomerDocs";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
class CustomerNewContract extends Component {
  state = {
    types: [],
    maxRend: [],
    minAport: [],
    max: 0,
    min: 0,
    save: false,
    consultant: null,
    userKyc: [],
    ID: false,
    ADDRESS: false,
    SOCIAL_CONTRACT: false,
    ID_WITH_PHOTO: false,
    ATTACHMENT: false
  };

  loadKyc = () => {
    getKyc().then(data => {
      this.setState({ userKyc: data });
    });
  };
  docFileMask = ".pdf,.png,.jpeg,.jpg";
  componentDidMount() {
    getActiveContractType().then(data => {
      this.setState({ types: data });
      const minAport = data.map(c => c.minimumAmount);
      const maxRend = data.map(c => c.maxMonthlyYield);

      let max = maxRend[0];
      for (let i = 1; i < maxRend.length; i++) {
        if (maxRend[i] > max) max = maxRend[i];
      }
      let min = minAport[0];
      for (let i = 1; i < minAport.length; i++) {
        if (minAport[i] < max) min = minAport[i];
      }

      this.setState({ minAport, maxRend, max: max, min: min });
    });
    this.loadKyc();
  }

  handleSetKyc = (type, event, name) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ [name]: true });

      const files = event.target.files[0];
      event.target.value = "";
      sendFileKyc(type, files)
        .then(data => {
          this.loadKyc();
          this.setState({ [name]: false });
        })
        .catch(error => {
          this.loadKyc();
          this.setState({ [name]: false });
        });
    }
  };

  onSubmit = formProps => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    performRequest("POST", "/user/me/contact", formProps, true)
      .then(response => {
        showSuccessMessage("app.create");
        this.setState({ save: true });
      })
      .catch(e => {
        if (e.response) {
          showErrorMessage("app.send.unable");
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { currentUser, consultant } = this.props;
    if (!currentUser.apt) {
      return <CustomerDocs />;
    } else if (currentUser.apt && !consultant) {
      return (
        <PageContainer>
          <HeaderPageItem title="app.navigationBar.hire" />
          <CardPageItem>
            <CardContent>
              <Typography gutterBottom variant="body1">
                <FormattedMessage id="app.documents.approved" />.
              </Typography>
              <Typography gutterBottom variant="body1">
                <b>
                  <FormattedMessage id="app.agent.noHave" />!
                </b>
              </Typography>
            </CardContent>
          </CardPageItem>
        </PageContainer>
      );
    } else {
      return (
        <PageContainer>
          <HeaderPageItem title="app.navigationBar.hire" />
          <CardPageItem>
            <CardContent>
              <div
                style={{
                  marginBottom: 16,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{
                    marginBottom: 16,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  <FormattedMessage id="app.invest.text1" />
                </Typography>
                <Typography>
                  <FormattedMessage id="app.invest.text2" />
                </Typography>
              </div>
            </CardContent>
            <AutoPlaySwipeableViews interval={8000} style={{ marginBottom: 8 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 16,
                    textAlign: "center"
                  }}
                >
                  <Avatar src={rent} style={{ width: 160, height: 160 }} />
                  <Typography
                    color="primary"
                    style={{ marginTop: 16 }}
                    gutterBottom
                    variant="h6"
                  >
                    <FormattedMessage id="app.invest.profitability.title" />
                  </Typography>

                  <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    <FormattedHTMLMessage id="app.invest.profitability.text1" />
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 16,
                    textAlign: "center"
                  }}
                >
                  <Avatar src={liq} style={{ width: 160, height: 160 }} />
                  <Typography
                    color="primary"
                    style={{ marginTop: 16 }}
                    gutterBottom
                    variant="h6"
                  >
                    <FormattedMessage id="app.invest.liquidity.title" />
                  </Typography>

                  <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    <FormattedHTMLMessage id="app.invest.liquidity.text1" />
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 16,
                    textAlign: "center"
                  }}
                >
                  <Avatar src={tempoReal} style={{ width: 160, height: 160 }} />
                  <Typography
                    color="primary"
                    style={{ marginTop: 16 }}
                    gutterBottom
                    variant="h6"
                  >
                    <FormattedMessage id="app.invest.real.title" />
                  </Typography>

                  <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    <FormattedHTMLMessage id="app.invest.real.text1" />
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 16,
                    textAlign: "center"
                  }}
                >
                  <Avatar src={auditoria} style={{ width: 160, height: 160 }} />
                  <Typography
                    color="primary"
                    style={{ marginTop: 16 }}
                    gutterBottom
                    variant="h6"
                  >
                    <FormattedMessage id="app.invest.audit.title" />
                  </Typography>

                  <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    <FormattedHTMLMessage id="app.invest.audit.text1" />
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 16,
                    textAlign: "center"
                  }}
                >
                  <Avatar src={agentes} style={{ width: 160, height: 160 }} />
                  <Typography
                    color="primary"
                    style={{ marginTop: 16 }}
                    gutterBottom
                    variant="h6"
                  >
                    <FormattedMessage id="app.invest.agent.title" />
                  </Typography>

                  <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    <FormattedHTMLMessage id="app.invest.agent.text1" />
                  </Typography>
                </div>
              </CardContent>
            </AutoPlaySwipeableViews>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Typography>
                <FormattedHTMLMessage id="app.invest.agent.text2" />
              </Typography>
            </CardContent>
          </CardPageItem>

          <FixedPageItem position="bottom" height={90}>
            {consultant && (
              <>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <AbtcAvatar
                        imageUrl={consultant.photoUrl}
                        disableLink
                        size={50}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography gutterBottom variant="body1">
                          <FormattedMessage id="app.agent.talk" />
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" gutterBottom>
                          {consultant.name.substring(
                            0,
                            consultant.name.indexOf(" ")
                          )}{" "}
                          {consultant.telefone && consultant.isWhats
                            ? phoneFormatter(consultant.telefone)
                            : consultant.telefone2 && consultant.isWhats2
                            ? phoneFormatter(consultant.telefone2)
                            : phoneFormatter(consultant.telefone)}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton href={"tel:" + consultant.telefone}>
                        <Phone />
                      </IconButton>
                      {(consultant.isWhats || consultant.isWhats2) && (
                        <IconButton
                          href={
                            consultant.isWhats
                              ? "https://wa.me/55" + consultant.telefone
                              : "https://wa.me/55" + consultant.telefone2
                          }
                          target="_blank"
                        >
                          <img src={wppWhite} width="30" height="30" alt=" " />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </>
            )}
          </FixedPageItem>
        </PageContainer>
      );
    }
  }
}
const validate = values => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "app.value.report";
  }
  if (values.amount < 0.25) {
    errors.amount = "app.value.minimum.btc";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    consultant: state.user.user.me.consultant
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "newAport",
    enableReinitialize: true,
    validate
  })
)(CustomerNewContract);
