import { Button, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../../actions";
import { withTheme } from "@material-ui/styles";
import Moment from "react-moment";
import { getWithdrawById, superAuthorizeWithdrawal } from "../../actions/Withdrawal";
import performRequest from "../../configAxios";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import { addDays } from "../../util";
import { GenericDialog } from "./GenericDialog";

class DetailHistoryWithdrawalPopUp extends Component {
  state = {
    withdraw: null,
    loading: true,
    edit: false,
    navWithdrawall: false,
    userWithdraw: null,
    wId: this.props.selectWithdrawal.id,
    authorizeOPen: false,
  };

  componentDidMount() {
    const { wId } = this.state;

    getWithdrawById(wId)
      .then((data) => {
        this.setState({
          withdraw: data,
          loading: false,
        });
        if (this.state.withdraw.loggedUserId) {
          this.getUserByID(this.state.withdraw.loggedUserId);
        }
      })

      .catch((e) => {
        this.props.history.push("/404");
      });
  }

  getUserByID = (id) => {
    performRequest("GET", `/user/${id}`, null, true)
      .then((response) => {
        this.setState({ userWithdraw: response.data.data });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  onAuthorize = () => {
    const { withdraw } = this.state;
    const { showSuccessMessage, showErrorMessage, history } = this.props;

    superAuthorizeWithdrawal(withdraw.id)
      .then((response) => {
        this.setState({ authorizeOPen: false });
        showSuccessMessage("app.authorization.completed");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  render() {
    const { withdraw, loading, userWithdraw, sellBTCUSDt, wId } = this.state;

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <CardContent>
        {/* Status */}
        <Typography variant="h6" color="primary">
          <FormattedMessage id={`app.enuns.${withdraw.status}`} />
        </Typography>
        {withdraw.rejectionReason && (
          /* Motivo da Rejeição */
          <InfoItem caption={<FormattedMessage id="app.reason.rejected" />} text={withdraw.rejectionReason} />
        )}
        {/* Responsavel */}
        {userWithdraw && (
          <InfoItem
            caption={<FormattedMessage id="app.responsible" />}
            text={userWithdraw.name + " (ID: " + userWithdraw.id + ")"}
          />
        )}
        {/* cliente */}
        <InfoItem
          caption={<FormattedMessage id="app.contract.canceledBy.customer" />}
          text={withdraw.account.user.name}
        />

        {withdraw.status != "CANCELED" && withdraw.status != "WAITING_AUTH" ? (
          // Data autorização e data de pedido
          <InfoItem
            caption={<FormattedMessage id="app.confirmed" />}
            text={
              <Moment date={withdraw.authorizeDate ? withdraw.authorizeDate : withdraw.createdAt} format="DD/MM/YYYY" />
            }
            extraCaption={<FormattedMessage id="app.data.order" />}
            extraText={<Moment date={withdraw.createdAt} format="DD/MM/YYYY" />}
          />
        ) : (
          // Data pedido
          <InfoItem
            caption=""
            text=""
            extraCaption={<FormattedMessage id="app.data.order" />}
            extraText={<Moment date={withdraw.createdAt} format="DD/MM/YYYY" />}
          />
        )}
        {withdraw.status == "REJECTED" ? (
          // Data rejeição e data de taxa
          <Fragment>
            <InfoItem
              caption={<FormattedMessage id="app.rejected" />}
              text={<Moment date={withdraw.updatedAt} format="DD/MM/YYYY" />}
            />
            {/* Valor solicitado taca */}
            <InfoItem
              caption={<FormattedMessage id="app.amount.requested" />}
              text={<Number value={withdraw.amount} currency="LCT" />}
              extraCaption={<FormattedMessage id="app.fee" />}
              extraText={<Number value={withdraw.fee} currency="LCT" />}
            />
          </Fragment>
        ) : withdraw.status == "CANCELED" ? (
          <Fragment>
            {/* Valor solicitado e  taxa */}
            <InfoItem
              caption={<FormattedMessage id="app.amount.requested" />}
              text={<Number value={withdraw.amount} currency="LCT" />}
              extraCaption={<FormattedMessage id="app.fee" />}
              extraText={<Number value={withdraw.fee} currency="LCT" />}
            />
            {/* Data cancelamento  e cotação efetiva*/}
            <InfoItem
              caption={<FormattedMessage id="app.enuns.CANCELED" />}
              text={<Moment date={withdraw.updatedAt} format="DD/MM/YYYY" />}
            />
          </Fragment>
        ) : withdraw.status == "WAITING_AUTH" ? (
          <Fragment>
            {/* Valor solicitado e taxa */}
            <InfoItem
              caption={<FormattedMessage id="app.amount.requested" />}
              text={<Number value={withdraw.amount} currency="LCT" />}
              extraCaption={<FormattedMessage id="app.fee" />}
              extraText={<Number value={withdraw.fee} currency="LCT" />}
            />
          </Fragment>
        ) : (
          withdraw.status == "APPROVED" && (
            // data de aprovação e Data limite
            <Fragment>
              <InfoItem
                caption={<FormattedMessage id="app.enuns.APPROVED" />}
                text={<Moment date={withdraw.withdrawDate} format="DD/MM/YYYY" />}
                extraCaption={<FormattedMessage id="app.limit" />}
                extraText={
                  <Moment
                    date={addDays(withdraw.authorizeDate ? withdraw.authorizeDate : withdraw.createdAt, 4)}
                    format="DD/MM/YYYY"
                  />
                }
              />
              {/* valor solicitado e  taxa */}

              <InfoItem
                caption={<FormattedMessage id="app.amount.requested" />}
                text={<Number value={withdraw.amount} currency="LCT" />}
                extraCaption={<FormattedMessage id="app.fee" />}
                extraText={<Number value={withdraw.fee} currency="LCT" />}
              />
              {/* Valor Pago e cotação efetiva */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" justify="flex-start">
                  {/* Valor  pago*/}
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography style={{ marginBottom: -10 }} variant="h6" gutterBottom color="primary">
                      <FormattedMessage id="app.amount.paid" />
                    </Typography>
                    <Typography variant="h6" gutterBottom color="primary">
                      <Number value={withdraw.amount - withdraw.fee} currency="LCT" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* BTC enviado e Estimativa em BTC */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row">
                  {/* BTC enviado*/}
                  {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container direction="row" justify="flex-start">
                      <Grid item>
                        <Fragment>
                          <InfoItem
                            caption={<FormattedMessage id="app.btc.sent" />}
                            text={<Number value={withdraw.btc} currency="BTC" />}
                          />
                        </Fragment>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* Cotação efetiva */}
                  {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container direction="row" justify="flex-end">
                      <Grid item>
                        <InfoItem
                          caption=""
                          text=""
                          extraCaption={<FormattedMessage id="app.price.effective" />}
                          extraText={
                            <Number
                              value={withdraw.btc ? (withdraw.amount - withdraw.fee) / withdraw.btc : 0}
                              currency="LCT"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Fragment>
          )
        )}
        {/* Carteira */}
        <InfoItem caption={<FormattedMessage id="app.wallet.destination" />} text={withdraw.address} />
        {
          //DIEGO valida se o checkbox da exchange Americansbitcoin está marcado
          withdraw.partnerWithdraw == 1 ? (
            withdraw.account.user.taxResidence == "Brasil" ? (
              <>
                <Typography gutterBottom color="primary">
                  <b>{<FormattedMessage id="app.warning" />}</b>
                  {": "}
                  {<FormattedMessage id="app.withdraw.partner" />}

                  {" - "}
                  {withdraw.partnerwithdrawtype == 0 ? (
                    <FormattedMessage id="app.automatic.selling" />
                  ) : (
                    <FormattedMessage id="app.balance.btc" />
                  )}
                </Typography>
              </>
            ) : (
              <>
                <Typography gutterBottom color="primary">
                  <b>- DigiPlata -</b>
                </Typography>
              </>
            )
          ) : (
            withdraw.partnerWithdraw == 2 && (
              <Typography gutterBottom color="primary">
                <b>{<FormattedMessage id="app.warning" />}</b>
                {": "}
                {<FormattedMessage id="app.withdraw.reaport" />}
              </Typography>
            )
          )
        }
        {withdraw.transactionId && (
          <>
            <InfoItem caption={<FormattedMessage id="app.transactionId" />} text={withdraw.transactionId} />

            <Button
              fullWidth
              variant="outlined"
              href={"https://www.blockchain.com/pt/btc/tx/" + withdraw.transactionId}
              target="_blank"
            >
              <FormattedMessage id="app.transaction.blockchain" />
            </Button>
          </>
        )}
        {withdraw.status == "WAITING_AUTH" && (
          <>
            <Typography style={{ marginTop: 32 }}>
              <FormattedMessage id="app.withdrawConfirmAdminObs" />
            </Typography>
            <Button
              small
              variant="outlined"
              style={{ marginTop: 16, marginBottom: 16 }}
              onClick={() => this.setState({ authorizeOPen: true })}
            >
              <FormattedMessage id="app.confirm.manual" />
            </Button>
            <GenericDialog
              title={<FormattedMessage id="app.withdrawConfirmOrNoAdmin" />}
              onCancel={() => this.setState({ authorizeOPen: false })}
              open={this.state.authorizeOPen}
              onOk={() => {
                this.onAuthorize();
              }}
            ></GenericDialog>
          </>
        )}
      </CardContent>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    config: state.config,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "dashboardForm",
    enableReinitialize: true,
  })
)(withTheme(DetailHistoryWithdrawalPopUp));
