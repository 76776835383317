import React, { Component } from "react";
import ContractAgentDescriptionBR from "./ContractAgentDescriptionBR";
import ContractAgentDescriptionEN from "./ContractAgentDescriptionEN";
import ContractAgentDescriptionES from "./ContractAgentDescriptionES";
class ContractAgentDescription extends Component {
  render() {
    let { currentUser, contractAgent } = this.props;
    if (currentUser.locale === "br") {
      return <ContractAgentDescriptionBR currentUser={currentUser} contractAgent={contractAgent} />;
    } else if (currentUser.locale === "en") {
      return <ContractAgentDescriptionEN currentUser={currentUser} contractAgent={contractAgent} />;
    } else if (currentUser.locale === "es") {
      return <ContractAgentDescriptionES currentUser={currentUser} contractAgent={contractAgent} />;
    }
  }
}
export default ContractAgentDescription;
