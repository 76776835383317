import { Button, CardContent, CircularProgress, Divider, Typography, withTheme } from "@material-ui/core";
import QRCode from "qrcode.react";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { sendContractTransaction } from "../actions/Contract";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { validateCriptoAddress, networkNameByNetwork } from "../util";

class ContractDepositPopup extends Component {
  state = {
    network: null,
    loading: true,
    sendingTransaction: false,
  };

  componentDidMount() {
    this.setState({ loading: false });
  }

  submitTransactionId = (formProps) => {
    const { contractId, showErrorMessage, showSuccessMessage } = this.props;
    const { network } = this.state;
    this.setState({ transaction: null, sendingTransaction: true });
    formProps.network = network;
    formProps.amount = 1;
    const networkOnly = network.split("/")[1];
    validateCriptoAddress(formProps.transactionId)
      .then((data) => {
        const networks = data
          .filter((item) => item.type === "tx" && item.network === networkOnly)
          .map((item) => item.network);
        if (networks.length === 0) {
          showErrorMessage("app.address.invalid");
          this.setState({ transaction: null, sendingTransaction: false });
        } else {
          showSuccessMessage(<FormattedHTMLMessage id="app.address.valid" values={{ values: networks }} />);
          setTimeout(() => {
            sendContractTransaction(contractId, formProps)
              .then((transaction) => {
                this.setState({ transaction, sendingTransaction: false });
                showSuccessMessage("app.send.success");
              })
              .catch((e) => {
                this.setState({ sendingTransaction: false });
                if (e.response) {
                  if (e.response.data.message === "After hours") {
                    showErrorMessage("app.send.error.afterhours");
                  } else {
                    showErrorMessage(e.response.data.message);
                  }
                } else {
                  showErrorMessage(e);
                }
              });
          }, 2000);
        }
      })
      .catch((e) => {
        if (e.message == "TRANSACTION_ERROR") {
          showErrorMessage("app.address.invalid");
        } else {
          showErrorMessage(<FormattedHTMLMessage id="app.address.invalid.1" />);
        }
        this.setState({ transaction: null, sendingTransaction: false });
      });
  };

  StepLoading = () => (
    <CardPageItem>
      <CardContent></CardContent>
    </CardPageItem>
  );

  StepSelectNetwrok = () => (
    <CardPageItem>
      <CardContent>
        <Typography gutterBottom variant="h6" color="primary">
          <FormattedMessage id="app.withdraw.text0" />
        </Typography>
        <Typography gutterBottom variant="body1" style={{ marginBottom: 16 }}>
          <FormattedHTMLMessage id="app.qrcode.text3" />
        </Typography>
        {this.props.currentUser.taxResidence == "Brasil" && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16, marginBottom: 16 }}
            onClick={(e) => this.setState({ network: "BTC/BTC" })}
            style={{ marginBottom: 16 }}
          >
            {networkNameByNetwork["BTC/BTC"]}
          </Button>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          onClick={(e) => this.setState({ network: "USDT/ETH" })}
          style={{ marginBottom: 16 }}
        >
          {networkNameByNetwork["USDT/ETH"]}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          onClick={(e) => this.setState({ network: "USDT/TRX" })}
          style={{ marginBottom: 16 }}
        >
          {networkNameByNetwork["USDT/TRX"]}
        </Button>
      </CardContent>
    </CardPageItem>
  );

  BlockSendingTransaction = () => (
    <>
      <CircularProgress style={{ marginBottom: 8 }} />
      <Typography>
        <FormattedMessage id="app.transaction.validating" />
      </Typography>
    </>
  );

  BlockTransactionOk = () => {
    const { onClose } = this.props;
    return (
      <>
        <Typography variant="h6" color="primary" style={{ marginBottom: 16 }}>
          <FormattedMessage id="app.transaction.success" />
        </Typography>
        <Button fullWidth variant="contained" color="primary" onClick={(e) => onClose()}>
          Ok
        </Button>
      </>
    );
  };

  BlockTransactionForm = () => {
    const { handleSubmit } = this.props;
    return (
      <>
        <Typography gutterBottom>
          <FormattedMessage id="app.informTransfer.text1" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.informTransfer.text2" />
        </Typography>
        <form onSubmit={handleSubmit(this.submitTransactionId)}>
          <Field
            fullWidth
            component={CustomInputForm}
            name="transactionId"
            label="app.transactionId"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            fullWidth
            id="bSubmitOrderForm"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={this.props.pristine || this.props.submitting || this.props.invalid || this.state.disableBt}
          >
            <FormattedMessage id="app.sendTransaction" />
          </Button>
        </form>
      </>
    );
  };

  StepTransactionForm = () => {
    const { config } = this.props;
    const { sendingTransaction, network, transaction } = this.state;
    let depositAddress = "";
    if (network === "BTC/BTC") {
      depositAddress = config.address;
    } else if (network === "USDT/TRX") {
      depositAddress = config.addressUsdtTrx;
    } else if (network === "USDT/ETH") {
      depositAddress = config.addressUsdtEth;
    }
    return (
      <CardPageItem>
        <CardContent>
          <Typography>
            {network === "BTC/BTC" ? (
              <FormattedMessage id="app.qrcode.text1" />
            ) : (
              <>
                <FormattedMessage id="app.qrcode.text1.1" />
                <br />
                <FormattedHTMLMessage id="app.qrcode.text1.2" />
                <br />
                <b>{networkNameByNetwork[network]}</b>
              </>
            )}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 32,
              marginBottom: 32,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: 10,
                width: "fit-content",
              }}
            >
              <QRCode value={depositAddress} size={200} />
            </div>
          </div>
          <InfoItem caption={<FormattedMessage id="app.address" />} text={depositAddress} center />
          <InfoItem caption="NETWORK" text={networkNameByNetwork[network]} center />
          <Divider style={{ margin: 16 }} />

          {sendingTransaction ? (
            <this.BlockSendingTransaction />
          ) : transaction ? (
            <this.BlockTransactionOk />
          ) : (
            <this.BlockTransactionForm />
          )}
        </CardContent>
      </CardPageItem>
    );
  };

  render() {
    const { contractId, onClose } = this.props;
    const { loading, network } = this.state;
    return (
      <PageContainer>
        <HeaderPageItem title="app.lccontract" values={contractId} showBackButton onBack={onClose} />
        {loading ? <this.StepLoading /> : network === null ? <this.StepSelectNetwrok /> : <this.StepTransactionForm />}
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    config: state.config,
  };
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  } else {
    if (values.transactionId.length < 32 || values.transactionId.length > 96) {
      errors.transactionId = "app.transactionId.invalid";
    }
  }

  return errors;
};

export default compose(
  withRouter,
  withTheme,
  reduxForm({ form: "transForm", validate }),
  connect(mapStateToProps, actions)
)(ContractDepositPopup);
