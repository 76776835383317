import React, { Component } from "react";

class Policy extends Component {
  render() {
    return (
      <>
        <div style={{ textAlign: "justify" }}>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Vamos conhecer as diretrizes a respeito de como coletamos informa&ccedil;&otilde;es e dados dos nossos
              USU&Aacute;RIOS e visitantes e como os protegemos. O objetivo desse documento &eacute; ajud&aacute;-lo a
              decidir conscientemente sobre o compartilhamento de informa&ccedil;&otilde;es conosco e a
              utiliza&ccedil;&atilde;o da plataforma.{" "}
            </span>
          </p>
          <p className="c9">
            <span className="c13">
              O USU&Aacute;RIO, ao aceitar esta POL&Iacute;TICA DE PRIVACIDADE, concorda que esta poder&aacute; sofrer
              altera&ccedil;&otilde;es ao longo do tempo, sendo de sua inteira responsabilidade a
              verifica&ccedil;&atilde;o peri&oacute;dica da mesma, principalmente antes da utiliza&ccedil;&atilde;o dos
              SERVI&Ccedil;OS. Se a{" "}
            </span>
            <span className="c8 c16">LUX CAPITAL, LLC</span>
            <span className="c13">
              &nbsp;informar ao USU&Aacute;RIO sobre altera&ccedil;&otilde;es significativas realizadas na
              POL&Iacute;TICA DE PRIVACIDADE, o far&aacute; por mera liberalidade.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Os conceitos e express&otilde;es utilizados nesta Pol&iacute;tica de Privacidade s&atilde;o os mesmos dos
              Termos de Uso.{" "}
            </span>
          </p>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="bold c0">ACEITE </span>
          </p>
          <p className="c1">
            <span className="c2">
              Ao manifestar o &ldquo;Aceite&rdquo; nos Termos de Uso, do qual esta Pol&iacute;tica de Privacidade
              &eacute; parte integrante, o USU&Aacute;RIO declara expressamente que leu e entendeu todos os direitos e
              obriga&ccedil;&otilde;es aqui determinados, concorda e se sujeita a todas as disposi&ccedil;&otilde;es do
              presente instrumento.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c3">Esta Pol&iacute;tica de Privacidade abrange o tratamento dado pela </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              &nbsp;&agrave;s informa&ccedil;&otilde;es capazes de identificar os USU&Aacute;RIOS, bem como seus dados
              quando estes visitam e se cadastram junto &agrave; Plataforma, fornecendo informa&ccedil;&otilde;es que
              ser&atilde;o mantidas pela empresa pelo per&iacute;odo de 5 (cinco) anos.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c3">Ademais, a </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              , a fim de implementar transpar&ecirc;ncia e controles de acesso adicionais, sendo direitos concedidos aos
              USU&Aacute;RIOS, t&atilde;o somente ser&atilde;o:{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              1) Direito de acesso &ndash; o direito a ser informado e solicitar o acesso aos dados pessoais que
              processamos sobre o USU&Aacute;RIO;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c3">2) Direito de retifica&ccedil;&atilde;o &ndash; o direito a solicitar que a </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              &nbsp;altere ou atualize os dados pessoais do USU&Aacute;RIO sempre que estiverem incorretos e/ou
              incompletos;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              3) Direito &agrave; elimina&ccedil;&atilde;o da informa&ccedil;&atilde;o &ndash; o direito a solicitar-nos
              para apagar os seus dados pessoais;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              4) Direito a restringir &ndash; o direito de pedir que tempor&aacute;ria ou permanentemente interrompamos
              o tratamento de todos ou alguns dos seus dados pessoais;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              5) Direito de oposi&ccedil;&atilde;o &ndash; o direito de, a qualquer momento, se opor ao tratamento dos
              seus dados pessoais por motivos relacionados com sua situa&ccedil;&atilde;o em particular; e o direito de
              oposi&ccedil;&atilde;o a que os seus dados pessoais sejam tratados para fins de marketing direto;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              6) Direito &agrave; portabilidade de dados &ndash; o direito de solicitar uma c&oacute;pia dos seus dados
              pessoais em formato eletr&ocirc;nico;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              7) Direito a n&atilde;o estar sujeito a decis&otilde;es automatizadas &ndash; o direito de n&atilde;o
              estar sujeito a uma decis&atilde;o baseada apenas numa decis&atilde;o automatizada, incluindo a
              elabora&ccedil;&atilde;o de perfis, onde a decis&atilde;o poder&aacute; ter um efeito jur&iacute;dico para
              si ou produzir um efeito igualmente significativo.{" "}
            </span>
          </p>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="bold c0">INFORMA&Ccedil;&Otilde;ES COLETADAS </span>
          </p>
          <p className="c1">
            <span className="c2">Nossa plataforma coleta informa&ccedil;&otilde;es pessoais dos USU&Aacute;RIOS: </span>
          </p>
          <ol className="c11 lst-kix_list_1-0 start" start="1">
            <li className="c1 c4">
              <span className="c3 c7">
                Ao realizar o cadastramento na PLATAFORMA, processando os seguintes dados que podem ser fornecidos pelos
                USU&Aacute;RIOS: nome completo, data de nascimento,{" "}
              </span>
              <span className="c13">pa&iacute;s de domic&iacute;lio fiscal, e-mail</span>
              <span className="c3 c7">, telefone, </span>
              <span className="c3">
                endere&ccedil;o completo (incluindo cidade, estado, pa&iacute;s), n&uacute;meros de documentos de
                identifica&ccedil;&atilde;o. No caso de PJ, todos dados contidos no contrato social da mesma.
              </span>
            </li>
            <li className="c1 c4">
              <span className="c2">
                No momento do preenchimento de qualquer formul&aacute;rio de contato, quando s&atilde;o processados
                todos os dados nele inseridos pelos USU&Aacute;RIOS.{" "}
              </span>
            </li>
            <li className="c1 c4">
              <span className="c2">
                Quando do envio volunt&aacute;rio de informa&ccedil;&otilde;es por e-mail pelos USU&Aacute;RIOS, dados
                que tamb&eacute;m se limitam a essas informa&ccedil;&otilde;es.
              </span>
            </li>
            <li className="c1 c4">
              <span className="c3 c7">Ao realizar qualquer acesso &agrave; plataforma da </span>
              <span className="c0">LUX CAPITAL, LLC</span>
              <span className="c3 c7">
                , por meio de cookies e outras tecnologias, quando ser&atilde;o processados dados de URL, o
                endere&ccedil;o IP do USU&Aacute;RIO, a data de sua visita, telas visitadas na p&aacute;gina{" "}
              </span>
              <span className="c0">LUX CAPITAL, LLC</span>
              <span className="c0 c7">,</span>
              <span className="c3 c7">
                &nbsp;padr&otilde;es de intera&ccedil;&atilde;o (como a&ccedil;&otilde;es de tela,{" "}
              </span>
              <span className="c3">cliques,</span>
              <span className="c2">
                &nbsp;toques) at&eacute; mesmo como forma de confirmar as escolhas e a&ccedil;&otilde;es do
                pr&oacute;prio USU&Aacute;RIO.{" "}
              </span>
            </li>
          </ol>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="bold c0">FINALIDADE DOS DADOS TRATADOS </span>
          </p>
          <p className="c1">
            <span className="c2">
              O USU&Aacute;RIO concorda e autoriza o uso dos dados tratados para os seguintes fins:
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados coletados conforme o descrito no item
              &ldquo;1.&rdquo; acima &ndash; para an&aacute;lise obrigat&oacute;ria de cliente (pol&iacute;ticas KYC e
              AML) e possibilitar o acesso aos servi&ccedil;os disponibilizados na plataforma;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados coletados conforme o descrito nos itens
              &ldquo;1 e 2&rdquo; acima &ndash; para envio de informativos e newsletters;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados coletados conforme o descrito no item
              &ldquo;3&rdquo; acima &ndash; para responder &agrave;s solicita&ccedil;&otilde;es dos usu&aacute;rios;{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados coletados conforme o descrito no item
              &ldquo;1&rdquo; e &ldquo;4&rdquo; acima &ndash; para pesquisa e an&aacute;lise, para aprimorar nossa
              tecnologia, para registro de acesso, aprimoramento das ferramentas de seguran&ccedil;a, para a
              identifica&ccedil;&atilde;o do USU&Aacute;RIO e servi&ccedil;os.{" "}
            </span>
          </p>
          <p className="c1 c6">
            <span className="bold c0"></span>
          </p>
          <p className="c1">
            <span className="c0">I - DADOS PESSOAIS COLETADOS</span>
          </p>
          <p className="c1">
            <span className="c3">1.1. Ao acessar a Plataforma atrav&eacute;s de computador, celular,&nbsp;</span>
            <span className="c3 c8">tablet</span>
            <span className="c3">
              &nbsp;ou qualquer outro dispositivo de acesso, o Usu&aacute;rio consente que os dados pessoais abaixo
              mencionados sejam coletados, armazenados, utilizados e tratados pela{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC,</span>
            <span className="c2">&nbsp;nos termos desta Pol&iacute;tica de Privacidade.</span>
          </p>
          <p className="c1">
            <span className="c3">1.2. A </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c3">
              &nbsp;poder&aacute; coletar o endere&ccedil;o IP e informa&ccedil;&otilde;es do Dispositivo de Acesso, a
              saber: identificador, nome e tipo de dispositivo, sistema operacional, localiza&ccedil;&atilde;o,
              informa&ccedil;&otilde;es da rede m&oacute;vel e&nbsp;
            </span>
            <span className="c3 c8">wi-fi</span>
            <span className="c3">&nbsp;e informa&ccedil;&otilde;es padr&atilde;o do log da web, navegador (</span>
            <span className="c3 c8">browser</span>
            <span className="c2">
              ) utilizado, tr&aacute;fego para/da Plataforma e as p&aacute;ginas acessadas dentro da Plataforma.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              1.3 Adicionalmente, tamb&eacute;m ser&atilde;o coletadas as informa&ccedil;&otilde;es de todas as
              atividades realizadas na Plataforma pelo Usu&aacute;rio.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c3">1.4. </span>
            <span className="c0">
              &nbsp;O Usu&aacute;rio autoriza a LUX CAPITAL, LLC a compartilhar os dados coletados e tratados nos termos
              da presente Pol&iacute;tica de Privacidade com outras empresas parceiras da LUX CAPITAL, LLC,&nbsp;
              servi&ccedil;os de verifica&ccedil;&atilde;o de identidade, seja documental, &iacute;ris, digitais, imagem
              ou voz, provedores de servi&ccedil;os contratados pela LUX CAPITAL, LLC, institui&ccedil;&otilde;es
              financeiras parceiras da LUX CAPITAL, LLC, poss&iacute;veis investidores da LUX CAPITAL, LLC e autoridades
              governamentais mediante solicita&ccedil;&atilde;o legal. Tais entidades poder&atilde;o reter as
              informa&ccedil;&otilde;es e dados pessoais do Usu&aacute;rio para as suas atividades e ser&atilde;o
              integralmente respons&aacute;veis pela guarda, armazenamento e uso dos dados.
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              1.5. Salvo no caso de solicita&ccedil;&atilde;o legal, os dados protegidos pela presente Pol&iacute;tica
              de Privacidade somente ser&atilde;o compartilhados ap&oacute;s a assinatura de acordo de confidencialidade
              exigindo que os terceiros que tenham acesso aos dados do Usu&aacute;rio ajam com o mesmo grau de
              dilig&ecirc;ncia e prote&ccedil;&atilde;o que a pr&oacute;pria{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">.</span>
          </p>
          <p className="c1">
            <span className="c3">
              1.6. Em caso de suspens&atilde;o, encerramento do uso da Plataforma e seus Servi&ccedil;os ou
              rescis&atilde;o dos Termos e Condi&ccedil;&otilde;es de Uso da Plataforma, seja pelo Usu&aacute;rio ou
              pela{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c3">
              , o Usu&aacute;rio est&aacute; ciente de que seu cadastro ser&aacute; cancelado, podendo seus dados serem
              armazenados e mantidos pela{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c3">
              &nbsp;por cinco anos. Ap&oacute;s esse prazo, todos os dados do Usu&aacute;rio ser&atilde;o
              definitivamente exclu&iacute;dos da base de dados da{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC.</span>
          </p>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c0">II. FORMA DE COLETA DOS DADOS E O USO DE COOKIES OU DE FERRAMENTAS SIMILARES</span>
          </p>
          <p className="c1">
            <span className="c3">
              2.1. O Usu&aacute;rio est&aacute; ciente e concorda que os dados acima mencionados ser&atilde;o coletados
              mediante o preenchimento de cadastro na Plataforma{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC,</span>
            <span className="c3">
              &nbsp;o&nbsp;acesso&nbsp;do Usu&aacute;rio na Plataforma, a utiliza&ccedil;&atilde;o de&nbsp;
            </span>
            <span className="c3 c8">cookies</span>
            <span className="c2">&nbsp;ou outras tecnologias similares para coletar dados sobre o Usu&aacute;rio.</span>
          </p>
          <p className="c1">
            <span className="c3">2.2. </span>
            <span className="c3 c8">Cookies</span>
            <span className="c3">
              &nbsp;s&atilde;o pequenos arquivos de texto enviados ao computador ou outro Dispositivo de Acesso do
              Usu&aacute;rio de forma a permitir apenas que a{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">&nbsp;personalize a experi&ecirc;ncia na Plataforma.</span>
          </p>
          <p className="c1">
            <span className="c3">&nbsp;2.3. A maioria dos navegadores da&nbsp;</span>
            <span className="c3 c8">Web</span>
            <span className="c3">&nbsp;est&aacute; configurada para aceitar os&nbsp;</span>
            <span className="c3 c8">cookies</span>
            <span className="c3">
              . O Usu&aacute;rio poder&aacute;, a qualquer momento, bloquear, excluir ou desativar o uso dos&nbsp;
            </span>
            <span className="c3 c8">cookies</span>
            <span className="c2">
              &nbsp;caso seu navegador permita, mas deve estar ciente de que esta escolha poder&aacute; interferir no
              uso e na funcionalidade da Plataforma e de seus Servi&ccedil;os.
            </span>
          </p>
          <p className="c1">
            <span className="c3">2.4. O Usu&aacute;rio poder&aacute; encontrar&nbsp;</span>
            <span className="c3 c8">cookies</span>
            <span className="c3">
              &nbsp;ou outros mecanismos similares em plataformas, aplicativos ou propagandas de terceiros que&nbsp;
            </span>
            <span className="c0">
              n&atilde;o s&atilde;o controlados pela LUX CAPITAL, LLC e n&atilde;o est&atilde;o sujeitos a presente
              Pol&iacute;tica de Privacidade.
            </span>
          </p>
          <p className="c1 c6">
            <span className="bold c0"></span>
          </p>
          <p className="c1">
            <span className="c0">III. USO DOS DADOS COLETADOS</span>
          </p>
          <p className="c1">
            <span className="c3">3.1. Os dados coletados pela </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">&nbsp;poder&atilde;o ser utilizados para:</span>
          </p>
          <p className="c1">
            <span className="c2">
              (i) prevenir e investigar atividades potencialmente proibidas ou ilegais e/ou viola&ccedil;&otilde;es de
              Termos e Condi&ccedil;&otilde;es de Uso da Plataforma;
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              (ii) identificar poss&iacute;vel viola&ccedil;&atilde;o de contas por terceiros n&atilde;o-autorizados,
              tais como&nbsp;
            </span>
            <span className="c3 c8">hackers</span>
            <span className="c2">
              &nbsp;e fraudadores em geral, por softwares mal-intencionados ou atividade que possa prejudicar
              financeiramente ou de qualquer outra forma os Usu&aacute;rios;
            </span>
          </p>
          <p className="c1">
            <span className="c2">(iii) fornecer um melhor Servi&ccedil;o e suporte ao Usu&aacute;rio;</span>
          </p>
          <p className="c1">
            <span className="c2">
              (iv) processar transa&ccedil;&otilde;es e enviar notifica&ccedil;&otilde;es sobre Transa&ccedil;&otilde;es
              dos Usu&aacute;rios;
            </span>
          </p>
          <p className="c1">
            <span className="c2">(v) resolver disputas e solucionar problemas;</span>
          </p>
          <p className="c1">
            <span className="c3">
              (vi) personalizar, medir e melhorar a Plataforma e seus Servi&ccedil;os, bem como o conte&uacute;do
              e&nbsp;
            </span>
            <span className="c3 c8">layout</span>
            <span className="c2">&nbsp;da plataforma e aplicativo, sempre em prol do Usu&aacute;rio;</span>
          </p>
          <p className="c1">
            <span className="c2">
              (vii) prover avisos de atualiza&ccedil;&atilde;o dos Servi&ccedil;os e ofertas promocionais;
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              (viii) verificar a identidade do Usu&aacute;rio quando solicita a abertura de conta e a
              realiza&ccedil;&atilde;o de transa&ccedil;&otilde;es pela Plataforma, comparando os dados coletados pela{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              &nbsp;em todas as intera&ccedil;&otilde;es do Usu&aacute;rio de forma a verificar poss&iacute;veis
              tentativas de uso n&atilde;o-autorizado da conta do Usu&aacute;rio; e
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              (ix) cumprir outras obriga&ccedil;&otilde;es legais e regulat&oacute;rias impostas &agrave; Plataforma.
            </span>
          </p>
          <p className="c1 c6">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c0">IV. PROTE&Ccedil;&Atilde;O E ARMAZENAMENTO DOS DADOS DO USU&Aacute;RIO</span>
          </p>
          <p className="c1">
            <span className="c3">
              4.1. Todos os dados disponibilizados pelo Usu&aacute;rio ou coletados atrav&eacute;s do seu acesso
              &agrave; Plataforma ser&atilde;o protegidos nos termos desta Pol&iacute;tica de Privacidade enquanto forem
              pass&iacute;veis de associa&ccedil;&atilde;o e identifica&ccedil;&atilde;o do indiv&iacute;duo.&nbsp;
            </span>
            <span className="c0">
              Esta Pol&iacute;tica de Privacidade n&atilde;o se aplica aos dados anonimizados, j&aacute; que tais dados
              n&atilde;o identificam o Usu&aacute;rio.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              4.2. O Usu&aacute;rio ter&aacute; sua conta na Plataforma protegida por uma senha pessoal e
              intransfer&iacute;vel, de forma a garantir sua seguran&ccedil;a e privacidade. A senha dever&aacute; ser
              escolhida pelo Usu&aacute;rio de forma adequada, cabendo ao Usu&aacute;rio (i) impedir o acesso de
              terceiros ao seu computador ou outros dispositivos durante a utiliza&ccedil;&atilde;o da Plataforma; e
              (ii) desconectar-se da Plataforma quando terminado o acesso, al&eacute;m das proibi&ccedil;&otilde;es
              /responsabilidades estipuladas no termo de uso.
            </span>
          </p>
          <p className="c1">
            <span className="c3">4.3. A </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c3">
              &nbsp;nunca solicitar&aacute; a senha para acesso &agrave; Plataforma do Usu&aacute;rio por qualquer meio
              diferente da pr&oacute;pria Plataforma em si. Em caso de suspeita de acesso indevido por terceiros
              &agrave; sua conta pessoal na Plataforma, de uso indevido do seu&nbsp;e-mail de acesso, senha,&nbsp;ou de
              qualquer atividade n&atilde;o reconhecida, o Usu&aacute;rio dever&aacute;, imediatamente, (i) solicitar
              altera&ccedil;&atilde;o de sua senha na Plataforma; e (ii) avisar a{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC,</span>
            <span className="c3">
              &nbsp;atrav&eacute;s dos canais de atendimento disponibilizados na plataforma, para que a{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">&nbsp;possa tomar as devidas provid&ecirc;ncias.</span>
          </p>
          <p className="c1">
            <span className="c3">
              4.4. A transmiss&atilde;o de informa&ccedil;&otilde;es pela internet pode n&atilde;o ser completamente
              segura dada a possibilidade de acesso ou uso n&atilde;o autorizado, falha do hardware ou software e outros
              fatores que podem comprometer a seguran&ccedil;a das informa&ccedil;&otilde;es do Usu&aacute;rio a
              qualquer tempo. Para prevenir o acesso n&atilde;o autorizado aos dados dos Usu&aacute;rios, a{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c3">
              &nbsp;utiliza solu&ccedil;&otilde;es de seguran&ccedil;a para monitorar e prevenir invas&otilde;es aos
              servidores da{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              . Ainda, toda a comunica&ccedil;&atilde;o realizada atrav&eacute;s da Plataforma &eacute; criptografada e
              os dados sens&iacute;veis s&atilde;o armazenados em bancos de dados protegidos pelas tecnologias mais
              atuais de prote&ccedil;&atilde;o.
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              4.5. O Usu&aacute;rio reconhece ser o exclusivo respons&aacute;vel pela transmiss&atilde;o de suas
              informa&ccedil;&otilde;es pessoais na Plataforma. A{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">
              &nbsp;n&atilde;o se responsabiliza por falhas na seguran&ccedil;a ocorridas em raz&atilde;o do Dispositivo
              de Acesso utilizado pelo Usu&aacute;rio para acessar a Plataforma, bem como por danos ou problemas
              decorrentes da demora, interrup&ccedil;&atilde;o ou bloqueio nas transmiss&otilde;es de dados em virtude
              de oscila&ccedil;&otilde;es na conex&atilde;o do Usu&aacute;rio com a internet.
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              4.6. Os dados do Usu&aacute;rio coletados quando do acesso ou utiliza&ccedil;&atilde;o da Plataforma e/ou
              da contrata&ccedil;&atilde;o dos Servi&ccedil;os ser&atilde;o armazenados nos servidores da{" "}
            </span>
            <span className="c0">LUX CAPITAL, LLC</span>
            <span className="c2">.</span>
          </p>
          <p className="c1">
            <span className="c3">
              4.7. Ao acessar e utilizar a Plataforma e nos enviar seus dados pessoais, o Usu&aacute;rio concorda com a
              transfer&ecirc;ncia, armazenamento, processamento e uso de seus dados no formato exposto nesta
              Pol&iacute;tica de Privacidade.
            </span>
          </p>
          <div>
            <p className="c14">
              <span className="bold c17">&nbsp;</span>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Policy;
